<div class="summary-grid">
    <div class="stats-card-box-v2 BAPC">

        <div class="wrapper">
            <div class="icon">
                <i class='icon-text'>BAPC</i>
            </div>
            <div class="content">
                <span class="sub-title">BAPC (K case)</span>
                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                <h3><span>{{formatNumber(bapc.act)}}</span>
                    <span class='badge-v2 {{bapc.vsLy > 0 ? "" : "badge-red"}}'><i
                            class='bx {{bapc.vsLy > 0 ? "bx-up-arrow-alt" : "bx-down-arrow-alt"}}'></i>
                        {{formatPercent(bapc.vsLy)}}% vs
                        LY</span>
                </h3>

                <div class="progress-list">
                    <div class="bar-inner negative-line">
                        <div class="bar progress-line red-line"
                            attr.data-width="{{bapc.vsBp > 0 ? 100 : roundDownToNearest(100 + bapc.vsBp)}}">
                        </div>
                    </div>
                    <div class="bar-inner positive-line">
                        <div class="bar progress-line green-line"
                            attr.data-width="{{bapc.vsBp > 0 ? roundUpToNearest(bapc.vsBp): 0}}"></div>
                    </div>
                </div>
                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                <p class='{{bapc.vsBp > 0 ? "badge-green" : "badge-red"}}'>{{formatPercent(bapc.vsBp)}} % vs BP</p>
            </div>
        </div>

    </div>
    <div class="stats-card-box-v2 NSR">

        <div class="wrapper">
            <div class="icon">
                <i class='icon-text'>NSR</i>
            </div>
            <div class="content">
                <span class="sub-title">NSR (MM JPY)</span>
                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                <h3><span>{{formatNumber(nsr.act)}}</span> <span
                        class='badge-v2 {{nsr.vsLy > 0 ? "" : "badge-red"}}'><i
                            class='bx {{nsr.vsLy > 0 ? "bx-up-arrow-alt" : "bx-down-arrow-alt"}}'></i>
                        {{formatPercent(nsr.vsLy)}}% vs
                        LY</span></h3>

                <div class="progress-list">
                    <div class="bar-inner negative-line">
                        <div class="bar progress-line red-line"
                            attr.data-width="{{nsr.vsBp > 0 ? 100 : roundDownToNearest(100 + nsr.vsBp)}}">
                        </div>
                    </div>
                    <div class="bar-inner positive-line">
                        <div class="bar progress-line green-line"
                            attr.data-width="{{nsr.vsBp > 0 ? roundUpToNearest(nsr.vsBp): 0}}"></div>
                    </div>

                </div>

                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                <p class='{{nsr.vsBp > 0 ? "badge-green" : "badge-red"}}'>{{formatPercent(nsr.vsBp)}} % vs BP</p>
            </div>
        </div>
    </div>
    <div class="stats-card-box-v2 NSR-PC">
        <div class="wrapper">
            <div class="icon"><i class='icon-text'>NSR<br>PC</i></div>
            <div class="content">
                <span class="sub-title">NSR per case (JPY)</span>
                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                <!-- FPT-HoiLT2: [2022/11/23][SENSE][1203406614097422] Dashboard & Daily Report page corrections start-->
                <h3><span>{{formatNumber(nsrPerCase.act)}}</span> <span
                        class='badge-v2 {{nsrPerCase.diffLy > 0 ? "" : "badge-red"}}'><i
                            class='bx {{nsrPerCase.diffLy > 0 ? "bx-up-arrow-alt" : "bx-down-arrow-alt"}}'></i>
                        {{nsrPerCase.diffLy > 0 ? "+" + nsrPerCase.diffLy : nsrPerCase.diffLy}} Diff vs LY</span></h3>

                <div class="progress-list">
                    <!-- FPT-HoiLT2 [2022/12/05] [SENSE] fix UI progress-line for nsrPerCase limit 100 -->
                    <!-- Because Diff.BP is not percent number, we cannot determine what is MAX. -->
                    <!-- We check data of many days before and saw that 100 seems to be average of MAX. -->
                    <div class="bar-inner negative-line">
                        <!-- <div class="bar progress-line red-line"
                            attr.data-width="{{nsrPerCase.diffBp > 0 ? 100 : 100 + nsrPerCase.diffBp}}"></div> -->
                        <div class="bar progress-line red-line"
                            attr.data-width="{{nsrPerCase.vsBp > 0 ? 100 : roundDownToNearest(100 + nsrPerCase.vsBp)}}">
                        </div>
                    </div>
                    <div class="bar-inner positive-line">
                        <!-- <div class="bar progress-line green-line"
                            attr.data-width="{{nsrPerCase.diffBp > 0 ? nsrPerCase.diffBp: 0}}"></div> -->
                        <div class="bar progress-line green-line"
                            attr.data-width="{{nsrPerCase.vsBp > 0 ? roundUpToNearest(nsrPerCase.vsBp): 0}}"></div>
                    </div>
                </div>

                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                <p class='{{nsrPerCase.diffBp > 0 ? "badge-green" : "badge-red"}}'>{{nsrPerCase.diffBp > 0 ? "+" +
                    nsrPerCase.diffBp : nsrPerCase.diffBp}} Diff vs BP</p>
                <!-- FPT-HoiLT2: [2022/11/23][SENSE][1203406614097422] Dashboard & Daily Report page corrections end-->
            </div>
        </div>
    </div>
    <div class="stats-card-box-v2 GP">

        <div class="wrapper">
            <div class="icon"><i class='icon-text'>GP</i></div>
            <div class="content">
                <span class="sub-title">GP (MM JPY)</span>
                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                <h3><span>{{formatNumber(gp.act)}}</span> <span
                        class='badge-v2 {{gp.vsLy > 0 ? "" : "badge-red"}}'><i
                            class='bx {{gp.vsLy > 0 ? "bx-up-arrow-alt" : "bx-down-arrow-alt"}}'></i>
                        {{formatPercent(gp.vsLy)}}% vs
                        LY</span></h3>
                <div class="progress-list">
                    <div class="bar-inner negative-line">
                        <div class="bar progress-line red-line"
                            attr.data-width="{{gp.vsBp > 0 ? 100 : roundDownToNearest(100 + gp.vsBp)}}">
                        </div>
                    </div>
                    <div class="bar-inner positive-line">
                        <div class="bar progress-line green-line"
                            attr.data-width="{{gp.vsBp > 0 ? roundUpToNearest(gp.vsBp): 0}}"></div>
                    </div>

                </div>
                <!-- FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues -->
                <p class='{{gp.vsBp > 0 ? "badge-green" : "badge-red"}}'>{{formatPercent(gp.vsBp)}} % vs BP</p>
            </div>
        </div>
    </div>
</div>