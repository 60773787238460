<div class="row">
    <div class="col-lg-12 col-md-12">
        <div #scroll class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Share Diff vs LY</h3>
            </div>

            <div class="filter-section no-title mb-4">
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Weekly'}, {value: 'mtd', name: 'Monthly'}, {value: 'qtd', name: 'Quarterly'}, {value: 'ytd', name: 'Yearly'}]"
                    [model]="aggregateBy"
                    (change)="onSharevslyAggregateChange($event)">
                </single-dropdown>

                <moving-average-dropdown [class]="aggregateBy == 'wtd' ? '' : 'not-display'" title="Display"
                    [items]="[{value: 4, name: '4-Week Moving Average'}]"
                    (change)="onMovingAverageWindowSizeChange($event)"
                    [(model)]="movingAverageWindowSizes"
                    >
                </moving-average-dropdown>

            </div>

            <div class="filter-radio title-makers">
                <div class="items">
                    <div class="item" *ngFor="let maker of filterMakers(marketShareVsLyMakers); index as i;">
                        <input class="form-check-input" (change)="onShareVsLyMakersCheckedChanged(maker.maker)" type="checkbox" value="{{maker.maker}}" [checked]="maker.checked" [disabled]="marketShareVsLyHaveSingleSerie && maker.maker === marketShareVsLySingleSerie">
                        <label class="form-check-label" >{{maker.maker}}</label>
                    </div>
                </div>            
            </div>

            <div class="filter-section even-wrapper">

                <!-- <div class="ng-section summary-section native">
                    <b>Display</b>
                    <select class="form-control filter-by-category form-basic" (change)="onShareVsLyDisplayTypeChange($event.target.value)">
                        <option value="value">Value Share</option>
                        <option value="volume">Volume Share</option>
                    </select>
                </div> -->
                <single-dropdown title="Display"
                    [items]="[{value: 'value', name: 'Value Share'}, {value: 'volume', name: 'Volume Share'}]"
                    [model]="shareVsLyDisplayType"
                    (change)="onShareVsLyDisplayTypeChange($event)" >
                </single-dropdown>
                
                <div class="ng-section summary-section">
                    <b>Category</b>
                    <ng-select #ngFilterCategoryLy class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsLySelectedCategory[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilteLyCategory($event)"
                    (change)="onRemoveFilteLyCategory($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterLyCategory($event)"
                    [clearable]="shareVsLySelectedCategory.length > 1">
                        <ng-option *ngFor="let item of categoriesFilter" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{formatCategoryName(item)}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterCategoryLy.close();onShareVsLyCategoryChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Region</b>
                    <ng-select #ngFilterRegionLy class="form-control filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsLySelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterLyRegion($event)"
                    (change)="onRemoveFilterLyRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterLyRegion($event)"
                    [clearable]="shareVsLySelectedRegion.length > 1">
                        <ng-option *ngFor="let item of districts" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterRegionLy.close();onShareVsLyRegionChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div #screen class="card-body">
                <div id="intage-vm-market-share-vsLy-chart"></div>                
            </div>
            <ng-container *ngIf="(marketShareVsLyHaveSingleSerie && aggregateBy != 'wtd') || (aggregateBy == 'wtd' && movingAverageWindowSizes.length == 0)">
                <div class="single-series-container">
                    <span class="red-dot" [ngStyle]="{backgroundColor: marketShareVsLySingleSerieColor}"></span>
                    <span class="single-series">{{marketShareVsLySingleSerie}}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="!marketShareVsLyHaveData">
                <div class="no-data-container">
                    <span class="no-data-text">No data available for this combination. Please refine your selection</span>
                </div>
            </ng-container>
            <div class="flex-two-ends">
                <!-- <div class="ng-section summary-section week-start native">
                    <b>Start week</b>
                    <select class="form-control" (change)="onSelectedStartWeekChanged($event.target.value)">
                        <option *ngFor="let item of otcWeeks; index as i;" [value]="this.otcWeeks.indexOf(this.selectedWeek) - i + 1" [hidden]="this.otcWeeks.indexOf(this.selectedWeek) - i + 1 < 1" [selected]="this.otcWeeks.length - this.otcWeeks.indexOf(this.selectedWeek) + otcStartWeeks - 1 === otcWeeks.length - i">{{item}}</option>
                    </select>
                </div> -->
                <single-dropdown title="Start Week"
                    [items]="items"
                    (change)="onSelectedStartWeekChanged($event)"
                    [(model)]="otcStartWeeks">
                </single-dropdown>

                <download-button>
                    <button ngbDropdownItem (click)="exportSharevsLY(true)">Download PNG</button>
                    <button ngbDropdownItem (click)="exportSharevsLY(false)">Download CSV</button>
                </download-button>
            </div>            
            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>