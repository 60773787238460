<ng-container *ngIf="!isSankeyReady && !isScoreCardsReady && !isShareVsLyReady && !isOtcValueVsLyReady && !isValueShareTimelineReady">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<div [class]="isSankeyReady && isScoreCardsReady && isShareVsLyReady && isOtcValueVsLyReady && isValueShareTimelineReady ? '' : 'not-display' ">
    <div class="flex-two-ends">
        <!-- <div class="ng-section summary-section week native">
            <b>Week</b>
            <select class="form-control" (change)="onSelectedWeekChanged($event.target.value)">
                <option *ngFor="let item of weeks; index as i;" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
        <single-dropdown title="Week" class="single-dropdown-week"
            [items]="dropdownValueToNgSelectFormat(weeks)"
            [model] = "selectedWeek"
            (change)="onSelectedWeekChanged($event)">
        </single-dropdown>
        
    </div>
    <p></p>
    <intage_vm_score_cards [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="scoreCardsReady($event)"></intage_vm_score_cards>
    <ng-container>
        <intage-otc-value-vs-ly-vm [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="otcValueVsLyReady($event)"></intage-otc-value-vs-ly-vm>
    </ng-container>
     <ng-container>
        <intage_value-share-timeline-vm [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="valueShareTimelineReady($event)"></intage_value-share-timeline-vm>
    </ng-container> 
    <ng-container>
        <intage-share-vs-ly-vm [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="shareVsLyReady($event)"></intage-share-vs-ly-vm>
    </ng-container>
    <ng-container>
        <intage-sankey-summary-vm [selectedWeek]="selectedWeek" [intage]="intageData" [intageMonth] ="intageMonthData" (ready)="sankeyReady($event)"></intage-sankey-summary-vm>
    </ng-container>
</div>