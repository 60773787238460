import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRoots'
})
export class FilterRootsPipe implements PipeTransform {
  transform(items: any[], listKey: string[], listValue: string[]): any[] {
    if (!items || !listKey || !listValue || listKey.length !== listValue.length) {
      return items;
    }

    for (let i = 0; i < listKey.length; i++) {
      items = items.filter(item => item[listKey[i]]?.toLowerCase() == (listValue[i])?.toLowerCase());
    }

    return items;
  }
}