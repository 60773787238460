import { Component, Renderer2, OnChanges, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AnalyticsHelper} from 'src/app/helpers/analytics';
import { IntageDashboardHelper } from 'src/app/components/pages/intage/dashboard/intage_dashboard_helper';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import html2canvas from 'src/html2canvas';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { compileClassMetadata } from '@angular/compiler';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';
@Component({
  selector: 'intage-sankey-summary',
  templateUrl: './sankey-summary.component.html',
  styleUrls: ['./sankey-summary.component.scss']
})
export class IntageSankeySummaryComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  makerLogo: any;
  sankeyCases: any;
  isCheckDisplayAll: any;
  selectedSankeyCases: any;
  selectedOptionSankeyCases: string;
  selectedChannelTypeSankeyCases: string;
  selectedMakerSankeyCases: any;
  marketShareMakers: any;
  sankeyData: any;
  checkCloseCasePopup: boolean;
  sankeyWidth: number;
  sankeycolumnNames: any;
  sankeyOptions: any;
  sankeyChannels: any;
  sankeyCategories: any;
  sankeyRegions: any;
  sankeyPackageTypes: any;
  sankeyColorMode: string = "gradient";
  shareVsSankeySelectedChannel: any;
  shareVsSankeySelectedCategory: any;
  shareVsSankeySelectedRegion: any;
  shareVsSankeySelectedPackageType: any;
  populated: boolean;
  aggregateBy: string;
  chartId = "sankey-summary";

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }

  ngOnChanges() {
      if(!this.populated){
        this.sankeycolumnNames =[
          {"id":"1","label":"Month","pattern":"","type":"string"},
          {"id":"2","label":"Sales","pattern":"","type":"string"},
          {"id":"3","label":"No.","pattern":"","type":"number"},
          {"id":"4","label":"Percent","pattern":"","type":"number"},
          {"id":"5","label":"Fake","pattern":"","type":"string","role":"tooltip"},
        ];
        let colors = ['#a6cee3', '#b2df8a', '#fb9a99', '#fdbf6f',
        '#cab2d6', '#ffff99', '#1f78b4', '#33a02c'];
        this.sankeyOptions = {
          sankey: {
            link: {
              colorMode: this.sankeyColorMode,
              colors: colors
            }
          },
          tooltip: {
            isHtml: true,
            textStyle: {
              fontSize: 14
            },
            nodeFormatter: function() {
              var result = '123';
              return result;
          }
          }
        };
        //this.aggregateBy = 'wtd';
        this.isCheckDisplayAll = true;
        this.checkCloseCasePopup = true;
        this.shareVsSankeySelectedChannel = ['All'];
        this.shareVsSankeySelectedCategory = ['All'];
        this.shareVsSankeySelectedRegion = ['All'];
        this.shareVsSankeySelectedPackageType = ['All'];
        this.selectedSankeyCases = ["otcTotal","maker","channel","category","region","packageType"];
        this.selectedOptionSankeyCases = "otcTotal,maker,channel,category,region,packageType";
        this.selectedChannelTypeSankeyCases = "1";
        this.selectedMakerSankeyCases = "All";
        this.sankeyCases = [
          {
            key:"otcTotal",
            name:"OTC Total"
          },
          {
            key:"maker",
            name:"Maker"
          },          
          {
            key:"channel",
            name:"Channel"
          },          
          {
            key:"category",
            name:"Category"
          },
          {
            key:"region",
            name:"Region"
          },
          {
            key:"packageType",
            name:"Package Type"
          }
        ];
        this.makerLogo = IntageHelper.MakerLogos();
        this.marketShareMakers = [{maker:'All'}];
        this.makerLogo.forEach(x => {
          if(x.maker == 'Red Bull' && !this.shareVsSankeySelectedCategory.some(x => x == "Energy")){
            return;
          }

          this.marketShareMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked
          });          
        });
        this.populated = true;
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        this.handleSummary();
      }
   
      this.onResize();
  }
  onOpenPopup(){
    this.checkCloseCasePopup = false;
    this.SaveOptionValueTemp(false);
  }
  onClosePopup(){
    this.checkCloseCasePopup = true;
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["selectedSankeyCases"] = [...this.selectedSankeyCases];
      window["shareVsSankeySelectedChannel"] = [...this.shareVsSankeySelectedChannel];
      window["shareVsSankeySelectedCategory"] = [...this.shareVsSankeySelectedCategory];
      window["shareVsSankeySelectedRegion"] = [...this.shareVsSankeySelectedRegion];
      window["shareVsSankeySelectedPackageType"] = [...this.shareVsSankeySelectedPackageType];
    } else {
      if (window["selectedSankeyCases"] != null && window["selectedSankeyCases"].length > 0 && JSON.stringify(this.selectedSankeyCases) != JSON.stringify(window["selectedSankeyCases"])) 
      { 
        this.onSankeyCasesChange(window["selectedSankeyCases"].toString()); 
      }else{
        this.selectedSankeyCases = window["selectedSankeyCases"];
      }
      if (window["shareVsSankeySelectedChannel"] != null) { this.shareVsSankeySelectedChannel = window["shareVsSankeySelectedChannel"]; }
      if (window["shareVsSankeySelectedCategory"] != null) { this.shareVsSankeySelectedCategory = window["shareVsSankeySelectedCategory"]; }
      if (window["shareVsSankeySelectedRegion"] != null) { this.shareVsSankeySelectedRegion = window["shareVsSankeySelectedRegion"]; }
      if (window["shareVsSankeySelectedPackageType"] != null) { this.shareVsSankeySelectedPackageType = window["shareVsSankeySelectedPackageType"]; }
    }
  }
  onRemoveCaseSankey() {
    if(this.checkCloseCasePopup){
      this.SaveOptionValueTemp(false);
      this.onFilterSankeyCaseChange();
    }
  }

  onRemoveFilterSankeyChannel($event) {
    if(this.shareVsSankeySelectedChannel.length == 0){
      this.shareVsSankeySelectedChannel = ['All'];
      this.SaveOptionValueTemp(false);
      this.onFilterSankeyCaseChange()
    }
    if ($event.value === 'All') {
      this.shareVsSankeySelectedChannel = [];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event.value)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event.value);
      this.shareVsSankeySelectedChannel = this.shareVsSankeySelectedChannel.filter(function (el) {
        return arrChilds.indexOf(el) < 0;
      });
    }
    if (AnalyticsHelper.isSmddChild($event.value)) {
      var parent = AnalyticsHelper.getSmddParent($event.value);
      this.shareVsSankeySelectedChannel = this.shareVsSankeySelectedChannel.filter(e => e !== parent);
    }
  }
  onAddFilteSankeyChannel($event) {
    if ($event === 'All') {
      this.shareVsSankeySelectedChannel = ['All'];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event);
      this.shareVsSankeySelectedChannel = this.shareVsSankeySelectedChannel.filter(e => !arrChilds.includes(e));
    }
    if (AnalyticsHelper.isSmddChild($event)) {
      var parent = AnalyticsHelper.getSmddParent($event);
      var checkChildSelected = true;
      var arrChilds = AnalyticsHelper.getSmddChildIntage(parent);
      arrChilds.forEach(child => {
        if (this.sankeyChannels.some(x=>x == child) && !this.shareVsSankeySelectedChannel.includes(child)) {
          checkChildSelected = false;
        }
      });
      if (checkChildSelected) {
        var arrTemp = [parent];
        this.shareVsSankeySelectedChannel = this.shareVsSankeySelectedChannel.filter(e => !arrChilds.includes(e));
        this.shareVsSankeySelectedChannel = arrTemp.concat(this.shareVsSankeySelectedChannel);
      }else{
        this.shareVsSankeySelectedChannel = this.shareVsSankeySelectedChannel.filter(e => e !== parent);
      }
    }
    var checkParentFilter = true;
    var filterNotCheck = this.sankeyChannels.filter(e => !this.shareVsSankeySelectedChannel.includes(e) && e!='All');
    filterNotCheck.forEach(element => {
      if (!AnalyticsHelper.isSmddChild(element) || !this.shareVsSankeySelectedChannel.includes(AnalyticsHelper.getSmddParent(element))) {
        checkParentFilter=false;
      }
    });
    if(checkParentFilter){
      this.shareVsSankeySelectedChannel = ['All'];
    }else{
      this.shareVsSankeySelectedChannel = this.shareVsSankeySelectedChannel.filter(e => e !== 'All');
    }
  }
  onRemoveFilteSankeyCategory($event) {
    if(this.shareVsSankeySelectedCategory.length == 0){
      this.shareVsSankeySelectedCategory = ['All'];
      this.SaveOptionValueTemp(false);
      this.onFilterSankeyCaseChange()
    }
    if ($event.value === 'All') {
      this.shareVsSankeySelectedCategory = [];
      return;
    }
  }
  onAddFilterSankeyCategory($event) {
    if ($event === 'All') {
      this.shareVsSankeySelectedCategory = ['All'];
      return;
    }
    if(this.shareVsSankeySelectedCategory.filter(x=>x!='All').length==(this.sankeyCategories.length-1)){
      this.shareVsSankeySelectedCategory = ['All'];
    }else{
      this.shareVsSankeySelectedCategory = this.shareVsSankeySelectedCategory.filter(e => e !== 'All');;
    }
  } 
  onRemoveFilterSankeyRegion($event) {
    if(this.shareVsSankeySelectedRegion.length == 0){
      this.shareVsSankeySelectedRegion = ['All'];
      this.SaveOptionValueTemp(false);
      this.onFilterSankeyCaseChange()
    }
    if ($event.value === 'All') {
      this.shareVsSankeySelectedRegion = [];
      return;
    }
  }
  onAddFilterSankeyRegion($event) {
    if ($event === 'All') {
      this.shareVsSankeySelectedRegion = ['All'];
      return;
    }
    if(this.shareVsSankeySelectedRegion.filter(x=> x != 'All').length == (this.sankeyRegions.length-1)){
      this.shareVsSankeySelectedRegion = ['All'];
    }else{
      this.shareVsSankeySelectedRegion = this.shareVsSankeySelectedRegion.filter(e => e !== 'All');;
    }
  }   
  onRemoveFilterSankeyPackageType($event) {
    if(this.shareVsSankeySelectedPackageType.length == 0){
      this.shareVsSankeySelectedPackageType = ['All'];
      this.SaveOptionValueTemp(false);
      this.onFilterSankeyCaseChange()
    }
    if ($event.value === 'All') {
      this.shareVsSankeySelectedPackageType = [];
      return;
    }
  }
  onAddFilterSankeyPackageType($event) {
    if ($event === 'All') {
      this.shareVsSankeySelectedPackageType = ['All'];
      return;
    }
    if(this.shareVsSankeySelectedPackageType.filter(x=> x!='All').length == (this.sankeyPackageTypes.length-1)){
      this.shareVsSankeySelectedPackageType = ['All'];
    }else{
      this.shareVsSankeySelectedPackageType = this.shareVsSankeySelectedPackageType.filter(e => e !== 'All');;
    }
  }
  isSmddChild(channel: string){
    return AnalyticsHelper.isSmddChild(channel);
  }

  handleSummary(){
    this.sankeyData = [];
    this.SaveOptionValueTemp(false);
    this.sankeyData = this.SanKeyData(this.selectedWeek, 
      this.selectedMakerSankeyCases, 
      this.selectedSankeyCases, 
      this.selectedChannelTypeSankeyCases,
      this.shareVsSankeySelectedChannel,
      this.shareVsSankeySelectedCategory,
      this.shareVsSankeySelectedRegion,
      this.shareVsSankeySelectedPackageType
      );
    let t= this;
      setTimeout(() => {
        t.ready.emit(true);
      });
  
  }


  public onSankeyCasesChange(type: string) {
    var arr=type.split(',').filter(x=>x != '');
    if(arr != null && arr.length > 0) {
      if(!arr[0].startsWith('otcTotal')){
        this.selectedOptionSankeyCases = "All";
      }
      this.selectedSankeyCases = arr;
      this.SaveOptionValueTemp(false);
      this.sankeyData = this.SanKeyData(this.selectedWeek, 
        this.selectedMakerSankeyCases, 
        this.selectedSankeyCases, 
        this.selectedChannelTypeSankeyCases,
        this.shareVsSankeySelectedChannel,
        this.shareVsSankeySelectedCategory,
        this.shareVsSankeySelectedRegion,
        this.shareVsSankeySelectedPackageType
        );
    }
  }
  public onMakerSankeyCasesChange(type: any) {
    this.selectedMakerSankeyCases = type;
    this.sankeyData = this.SanKeyData(this.selectedWeek, 
      this.selectedMakerSankeyCases, 
      this.selectedSankeyCases, 
      this.selectedChannelTypeSankeyCases,
      this.shareVsSankeySelectedChannel,
      this.shareVsSankeySelectedCategory,
      this.shareVsSankeySelectedRegion,
      this.shareVsSankeySelectedPackageType
      );
  }

  public onSankeyAggregateChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
    this.sankeyData = this.SanKeyData(
      this.selectedWeek, 
      this.selectedMakerSankeyCases, 
      this.selectedSankeyCases, 
      this.selectedChannelTypeSankeyCases,
      this.shareVsSankeySelectedChannel,
      this.shareVsSankeySelectedCategory,
      this.shareVsSankeySelectedRegion,
      this.shareVsSankeySelectedPackageType
      )
  }
  public onChannelSankeyCasesChange(channeltype: string) {
    this.selectedChannelTypeSankeyCases = channeltype;
    this.sankeyData = this.SanKeyData(this.selectedWeek, 
      this.selectedMakerSankeyCases, 
      this.selectedSankeyCases, 
      this.selectedChannelTypeSankeyCases,
      this.shareVsSankeySelectedChannel,
      this.shareVsSankeySelectedCategory,
      this.shareVsSankeySelectedRegion,
      this.shareVsSankeySelectedPackageType
      );
  }
  public onFilterSankeyCaseChange(setKey: boolean = false){
    // this.makerLogo.forEach(x => {
    //   if(x.maker == 'Red Bull' && !this.shareVsSankeySelectedCategory.some(x => x == "Energy")){
    //     return;
    //   }
    //   if (!this.marketShareMakers.some(maker => maker.maker == x.maker))
    //   {
    //     this.marketShareMakers.push({
    //       maker: x.maker,
    //       logo: x.logo,
    //       checked: x.checked
    //     });  
    //   }    
    //   if (!this.shareVsSankeySelectedCategory.some(x => x == "Energy"))
    //   {
    //     this.marketShareMakers = this.marketShareMakers.filter(x => x.maker != 'Red Bull');
    //   }     
    // });

    // if(this.selectedMakerSankeyCases == "Red Bull" && !this.shareVsSankeySelectedCategory.some(x => x == "Energy")){
    //   this.selectedMakerSankeyCases = this.marketShareMakers[0].maker;
    //   this.SaveOptionValueTemp(false);
    // }
    
    this.sankeyData = this.SanKeyData(this.selectedWeek, 
      this.selectedMakerSankeyCases, 
      this.selectedSankeyCases, 
      this.selectedChannelTypeSankeyCases,
      this.shareVsSankeySelectedChannel,
      this.shareVsSankeySelectedCategory,
      this.shareVsSankeySelectedRegion,
      this.shareVsSankeySelectedPackageType,
      );
  }

  public SanKeyData(
    selectedWeek: string,
    maker: string,
    keys: any,
    channelType: string = '1',
    shareVsSankeySelectedChannel: any,
    shareVsSankeySelectedCategory: any,
    shareVsSankeySelectedRegion: any,
    shareVsSankeySelectedPackageType: any
  ) {
    
    let data = [];
    switch (this.aggregateBy) {
      case 'mtd':
        data = this.intage?.filter(x => (this.intageMonth.find(y => y.week == x.week).month == this.intageMonth.find(y => y.week == selectedWeek).month) && parseInt(x.week.replace('-','').replace('-','')) <= parseInt(selectedWeek.replace('-','').replace('-','')));  
        break;
      case 'qtd':
        data = this.intage?.filter(x => (this.quarterTransform(this.intageMonth.find(y => y.week == x.week).month) == this.quarterTransform(this.intageMonth.find(y => y.week == selectedWeek).month)) && parseInt(x.week.replace('-','').replace('-','')) <= parseInt(selectedWeek.replace('-','').replace('-','')));
        break;
      case 'ytd':
        data = this.intage?.filter(x => parseInt(x.week.substring(0, 4)) == parseInt(selectedWeek.substring(0, 4)) && parseInt(x.week.replace('-','').replace('-','')) <= parseInt(selectedWeek.replace('-','').replace('-','')));
        break;
      default:
        data = this.intage?.filter(x => x.week == selectedWeek);
        break;
    }
 
    // if (maker != null && maker != "All") {
    //   data = data.filter(x => x.maker == maker);
    // }
    var sankeyData = [];
    var allKeys = ["maker", "channel", "category", "packageType", "region"];
    allKeys = allKeys.filter(x => !keys.includes(x));
    //skip case not select but check option
    data = IntageDashboardHelper.filterOptionNotSelect(allKeys, data, shareVsSankeySelectedChannel, shareVsSankeySelectedCategory, shareVsSankeySelectedRegion, shareVsSankeySelectedPackageType, [maker]);
    for (var i = 0; i < keys.length - 1; i++) {
      var key_1 = keys[i];
      var key_2 = keys[i+1];
      var cases_1 = IntageDashboardHelper.GenerateModelData(keys[i], data, channelType,
        'All',
        'All',
        'All',
        'All',
        'All');
        var cases_2 = IntageDashboardHelper.GenerateModelData(keys[i+1], data, channelType,
        'All',
        'All',
        'All',
        'All',
        'All');
      if (cases_1.length == 0) {
        cases_2.forEach(element => {
          let currentData = data.filter(x => x[key_2] == element.name || element.subItems.includes(x[key_2]));
          let totalTy = currentData.map(a => parseFloat(a.ty)).reduce(function (a, b) { return a + b; });
          sankeyData.push(["OTC Total", element.nameForMap, totalTy, 0]);
        });
      } else if (cases_2.length == 0) {
        cases_1.forEach(element => {
          let currentData = data.filter(x => x[key_1] == element.name || element.subItems.includes(x[key_1]));
          let totalTy = currentData.map(a => parseFloat(a.ty)).reduce(function (a, b) { return a + b; });
          sankeyData.push([element.nameForMap, "OTC Total", totalTy, 0]);
        });
      } else {
        cases_1.forEach(key => {
          cases_2.forEach(element => {
            let currentData = data.filter(x => x[key_1] == key.name || key.subItems.includes(x[key_1]));
            currentData = currentData.filter(x => x[key_2] == element.name || element.subItems.includes(x[key_2]));
            // FPT-ThinhLD4: [SENSE][1204064865410458] Sankey chart - details box size needs to be adjusted
            let totalTy = 0;
            if (currentData && currentData.length > 0) {
              totalTy = currentData.map(a => parseFloat(a.ty)).reduce(function (a, b) { return a + b; });
            }
            sankeyData.push([key.nameForMap, element.nameForMap, totalTy, 0]);
          });
        });
      }
    }


    var result = [];
    sankeyData.forEach(element => {
      var existElement = result.find(x => x[0] == element[0] && x[1] == element[1]);
      if (existElement != null) {
        existElement[2] += element[2];
      } else {
        result.push(element);
      }
    });
    result.forEach(element => {
      if (element[0] == "NORTH KANTO/SOUTH TOHOKU")
      {
        element[0] = "N.KANTO/S.TOHOKU";
      }

      if (element[1] == "NORTH KANTO/SOUTH TOHOKU")
      {
        element[1] = "N.KANTO/S.TOHOKU";
      }
    });
    // calculator percent
    var groupKeys = [];
    result.reduce(function (res, value) {
      if (!res[value[0]]) {
        res[value[0]] = { name: value[0], total: 0 };
        groupKeys.push(res[value[0]])
      }
      res[value[0]].total += value[2];
      return res;
    }, {});

    result.forEach(element => {
      let total = groupKeys.find((x=>x.name == element[0]));
      element[3] = parseFloat(((element[2] * 100) / total.total).toFixed(1));
      element[4] = "<div class='tooltip-node'><h6>" + element[0] + ' -> ' + element[1] + "<br>TY: " + Math.round(element[2]).toLocaleString() + "<br>IMP: " + element[3] + "%</h6></div>";
    });
    return result;
  }

  public onResize(){
    const newWidth = document.getElementById('main').clientWidth - 100;
    const minWidth = 1200;
    if (newWidth > minWidth)
    {
      this.sankeyWidth = newWidth;
    }
    else 
    {
      this.sankeyWidth = newWidth > minWidth ? newWidth : minWidth;
    }
  }

  setColorMode(colorMode: string) {
    this.sankeyOptions.sankey.link.colorMode = colorMode;
    this.sankeyOptions = JSON.parse(JSON.stringify(this.sankeyOptions));
  }

  public setColorModeSource(){
    this.setColorMode("source")
  }

  public setColorModeGradient(){
    this.setColorMode("gradient")
  }

  public exportSankeySummary(isImage: boolean) {
    const fileName = 'Sankey Summary';

    // Update options reference to trigger a view change
    if (isImage) {
      const original = this.sankeyColorMode;
      this.setColorMode("source")
  
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();

          this.setColorMode(original)
        });
      }, 500);
    } else {
      let data =[];
      this.sankeyData.forEach(element => {
        data.push({
          week: this.selectedWeek,
          aggregateBy: this.aggregateBy == null ? "wtd" : this.aggregateBy,
          fromData: element[0],
          toData: element[1],
          ty: element[2],
          imp: element[3]
        });
      });
      new AngularCsv(data, fileName, {
        showLabels: true,
        headers: ["Selected Week", "Aggregate By", "From", "To", "TY", "IMP"]
      });
    }
    this.showOptionDownload()
    

  }

  quarterTransform(month: string): any {
    let monthOnly = month.substring(5,7);
    switch(monthOnly) {
      case '01':
      case '02':
      case '03':
        return month.substring(0,5)+'01';
      case '04':
      case '05':
      case '06':
        return month.substring(0,5)+'02';
      case '07':
      case '08':
      case '09':
        return month.substring(0,5)+'03';
      case '10':
      case '11':
      case '12':
        return month.substring(0,5)+'04';
    } 
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-value-share');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

}
