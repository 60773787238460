<header class="navbar _sticky-top flex-lg-nowrap shadow">
    <div class="left">
        <button class="navbar-toggler d-lg-none collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target=".sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
            aria-label="Toggle navigation">
            <img src="assets/img/Hamburguer-menu.png" class="menu-button" alt="menu">
        </button>
        <a href="/dashboard"><img src="assets/img/logo_sense.png" class="logo" alt="sense logo"></a>
    </div>
    <div class="right">
        <nav class="navbar top-navbar navbar-expand" id="navbarContent">
            <div class="collapse navbar-collapse" id="navbarSupportContent">
                <ul class="navbar-nav right-nav align-items-center">
                    <li id="process-image-outter" class="process-image-outter" (mouseenter)="onProcessImageHoverIn()" (mouseleave)="onProcessImageHoverOut()" style="display: none;">
                        <img id="process-image" alt="image" class="process-img" src="" />
                    </li>
                    <li class="nav-item notification-box">
                        <button class="nav-link" type="button" data-bs-toggle="modal"
                            data-bs-target="#notificationModal" (click)="clickBellIcon()">
                            <div class="notification-btn">
                                <div class="icon-base"><i
                                        class="bx bx-bell {{totalNotificationUnread > 0 ? 'bell-icon-ring' : 'bell-icon'}}"></i>
                                </div>
                                <span
                                    class="badge bg-danger {{totalNotificationUnread > 99 ? 'fs-7' :''}}">{{totalNotificationUnread
                                    > 99 ? '99+' : totalNotificationUnread}}</span>
                            </div>
                        </button>
                    </li>
                    <li class="nav-item dropdown profile-nav-item">
                        <button class="nav-link dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            <div class="menu-profile">
                                <span class="name">{{userName}}</span>
                                <img src="assets/img/Coca-Cocla Avatar.png" class="rounded-circle" alt="image">
                            </div>
                        </button>
                        <div class="dropdown-menu">
                            <div class="dropdown-header d-flex flex-column align-items-center">
                                <div class="figure mb-3">
                                    <img src="assets/img/Coca-Cocla Avatar.png" class="rounded-circle" alt="image">
                                </div>
                                <div class="info text-center">
                                    <span class="name">{{userName}}</span>
                                    <p class="mb-3 email">{{getUserAttribute("email")}}</p>
                                </div>
                            </div>
                            <div class="dropdown-body">
                                <ul class="profile-nav p-0 pt-3">
                                    <!-- <li class="nav-item">
                                        <a routerLink="/change-password" class="nav-link">
                                            <i class='bx bx-lock'></i> <span>Change password</span>
                                        </a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a routerLink="/contact-us" class="nav-link">
                                            <i class='bx bx-message-alt-dots'></i> <span>Contact Us</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="dropdown-footer">
                                <ul class="profile-nav">
                                    <li class="nav-item">
                                        <a href="#" class="nav-link" (click)="handleLogout()">
                                            <i class='bx bx-log-out'></i> <span>Logout</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>       
    </div>
</header>

<div class="modal" id="notificationModal" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content notification-content">
            <div class="modal-header notification-title">
                <div class="left">
                    <span class="close icon-close" data-bs-dismiss="modal">&times;</span>
                    <span class="modal-title font-bold">Notifications</span>
                </div>
                <button type="button" class="makeAllRead" (click)="makeAllAsReadClick()">Mark all as read</button>
            </div>
            <div class="modal-body px-0 notice-body" id="notificationBody">
                <ul class="collapsible px-0 notice-list" data-collapsible="accordion">
                    <li *ngFor="let notify of notifications | slice:paging.start - 1 : paging.end; index as i;" class='{{notify?.flag == 1 ? "msg-read" : "msg-unread" }}'>
                        <div class="notification-header">
                            <div class="collapsible-header"
                                (click)="notificationClick(i); createReadNotificationByUser(notify.flag !== 1 ? notify.id : '')">
                                <span class="li-item">
                                    <div class="left">
                                        <span class="bell"></span>
                                        <span class="text">
                                            <span class="title">{{notify?.title}}</span>
                                            <span class="notifyDate">{{notify?.date}}</span>                                        
                                        </span>
                                    </div>
                                    <i class="bx bx-chevron-down" *ngIf="!notify.state"></i>
                                    <i class="bx bx-chevron-up" *ngIf="notify.state"></i>
                                </span>

                            </div>
                        </div>
                        <div class="collapsible-body notification-des" *ngIf="notify.state">
                            {{notify?.description}}
                        </div>
                    </li>
                </ul>
            </div>
            <div class="notification-list-footer" [hidden]="getTotalPage() === 1" >
                <div class="notification-pagination-btn">
                    <button type="submit"
                        class="notification-pagination-prev d-inline-block {{paging.currentPage!==0?'active':'inactive'}}"
                        (click)="previousPage();switchScrollTop()"><i class='bx bx-chevron-left'></i></button>
                
                    <div class="pages">
                        <span *ngFor="let item of [].constructor(getTotalPage()); let i = index" (click)="gotoPage(i+1)"
                            class="{{paging.currentPage===i?'active':''}}">
                            {{i+1}}
                        </span>
                    </div>
                    <button type="submit"
                        class="notification-pagination-next d-inline-block {{paging.currentPage !== getTotalPage()-1?'active':'inactive'}}"
                        (click)="nextPage();switchScrollTop()"><i class='bx bx-chevron-right'></i></button>
                </div>
            </div>
        </div>        
    </div>
</div>

<div id="process-detail" class="dropdown-menu process-detail">
    <div class="dropdown-body">
        <p id="title-process" class="title-process"></p>
        <h1 id="percent-process" class="percent-process"></h1>
        <img src="" id="process-img-detail" class="process-img-detail" alt="image">
        <h5 id="des-process" class="des-process"></h5>
    </div>
</div>