import { Component, Renderer2, OnChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef, SimpleChanges, HostListener } from '@angular/core';
import { DOCUMENT, DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { ProgressRankingHelper } from 'src/app/components/pages/progress-ranking/progress-ranking-helper';
import { getSortColumnName } from 'src/app/helpers/sort';
import { downloadAsPDF as _downloadAsPDF } from '../../../../helpers/pdfExtend';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { FontStyle, PDFProperty } from 'src/app/model/pdfProperty.model';
import { PageModel  } from '../../../../model/page.model';
import { FILTER_CONFIGS_HQ, AnalyticsQueries } from 'src/app/model/constants';
import { FilterHelper } from 'src/app/components/elements/add-more-filter-hq/filter-helper-hq';
import { CsvService } from 'src/app/services/csv.services';

@Component({
  selector: 'area-sales-ranking',
  templateUrl: './area-sales-ranking.component.html',
  styleUrls: ['./area-sales-ranking.component.scss']
})
export class AreaSalesRankingComponent implements OnChanges {
  @Input() pageTitle: string = '';
  @Input() saleYearMonth : any[];
  @Input() lstOption_RankingUnit : any[];
  @ViewChild('downloadLink') downloadLink: ElementRef;

  componentTitle: string = 'Area Sales Ranking';
  areaSalesRankingData: any;
  listColumn: any;
  sortType : string      = "desc";
  sortColumn: string     ="NSR_DIFF_VS_BP";
  sortColumnName: string = '';
  listColspan: any[];

  FILTERS: any                  = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.KEY_NAME || [];
  AreaSalesRanking_FILTERS     = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.PROGRESS_RANKING?.COLLECTIONS;
  defaultVisibleFilters: number = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.PROGRESS_RANKING?.VISIBLE_COUNT ?? 4;

  listData_Filter: any            = null;
  lstOption_States: any           = {};
  lstOption_States_Applied: any   = {};
  lstOption_Selected: any         = {};
  lstOption_IsUsed: any           = {};
  lstOption_Selected_Applied: any = {};

  lstOption_Selected_RankingUnit: any;
  allCSVButtonText: any;

  paging : PageModel = {
    current : 1,
    totalItem : 0,
    maxSize : 10,
    sizeOnPage: undefined,
    hideFirstLast: false
  };

  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this._configurePagination();
  }

  aggregateBy: any;
  isLoading: boolean = true;

  constructor(public datepipe: DatePipe,
    private csvService: CsvService,
    private http: HttpClient) { }


  ngOnInit(): void {
    this._getFilterConfigs();
    this.listColumn    = ProgressRankingHelper.ListColumnAreaSalesRanking_Dealer();
    this.listColspan   = ProgressRankingHelper.ListColspanAreaSalesRanking_Dealer()
    this.sortColumnName = getSortColumnName('AREA_SALE_RANKING', this.sortColumn);
    this._configurePagination();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes["lstOption_RankingUnit"] && this.lstOption_RankingUnit.length >0){
      this.lstOption_Selected_RankingUnit = this.lstOption_RankingUnit[0].value;
    }

    if(this.saleYearMonth.length > 0 && this.lstOption_RankingUnit.length >0){
      this.onSaveChannelLevChange(this.lstOption_Selected_RankingUnit);
    }
  }

  private _getFilterConfigs() {
    const lstOption                 = FilterHelper.init_lstOption_States('AreasalesrankingComponent', this.FILTERS, this.AreaSalesRanking_FILTERS, this.defaultVisibleFilters) || {};
    this.lstOption_States           = {...lstOption?.lstOption_States};
    this.lstOption_States_Applied   = {...lstOption?.lstOption_States};
    this.lstOption_Selected         = {...lstOption?.lstOption_Selected};
    this.lstOption_Selected_Applied = {...lstOption?.lstOption_Selected};
    this.listData_Filter            = {...lstOption.listData_Filter};
    this.lstOption_IsUsed           = FilterHelper.getListIsUsedFilter(this.FILTERS, this.AreaSalesRanking_FILTERS) || {};
  }

  private _configurePagination(): void {
    const winWidth = window.innerWidth;

    if (winWidth > 1024) {
      this.paging.maxSize = 10
    }

    if (1024 >= winWidth && winWidth >= 768) {
      this.paging.maxSize = 7
    }

    if (winWidth < 768) {
      this.paging.maxSize = 3;
    }
  }

  onInitData() {
    if (this.areaSalesRankingData) {
      this.paging.totalPage = this.areaSalesRankingData?.TotalPage;
      this.handleMarket();
    }
  }

  onPageChange(newPage: number): void {
    this.paging.current = newPage;
    this.onSearchData();
  }

  sortTable(defaultSort: string,column: string) {
    if(column == this.sortColumn){
      this.sortType = this.sortType === 'desc' ? 'asc' : 'desc';
    } else {
      this.sortType = "desc";
    }
    this.sortColumn = column;
    this.paging.current =1;
    this.sortColumnName = getSortColumnName('AREA_SALE_RANKING', this.sortColumn);

    this.onSearchData();
  }

  formartNumber(category: string, number: any) {
    return ProgressRankingHelper.FormartNumber(category, number);
  }

  formartClassForColumn(isLunisolarColor: boolean, value: any) {
    return ProgressRankingHelper.formartClassForColumn(isLunisolarColor, value);
  }

  filteredRoots2(listRoot2: any, nameRoot1: string) {
    return listRoot2.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1);
  }

  filteredRoots3(listRoot3: any,nameRoot1: string, nameRoot2 : string) {
    return listRoot3.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1  && p["SALES_HQ_LEV2_NAME"] === nameRoot2);
  }

  filteredRoots4(listAll: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string ) {
    return listAll.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1  && p["SALES_HQ_LEV2_NAME"] === nameRoot2 && p["SALES_HQ_LEV3_NAME"] === nameRoot3);
  }

  filteredRoots5(listAll: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string , nameRoot4 : string) {
    return listAll.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1  && p["SALES_HQ_LEV2_NAME"] === nameRoot2 && p["SALES_HQ_LEV3_NAME"] === nameRoot3 && p["CHANNEL_LEV1_NAME"] === nameRoot4);
  }

  filteredRoots6(listAll: any,nameRoot1: string, nameRoot2 : string, nameRoot3 : string , nameRoot4 : string, nameRoot5 : string) {
    return listAll.filter(p => p["SALES_HQ_LEV1_NAME"] === nameRoot1  && p["SALES_HQ_LEV2_NAME"] === nameRoot2 && p["SALES_HQ_LEV3_NAME"] === nameRoot3 && p["CHANNEL_LEV1_NAME"] === nameRoot4 && p["CHANNEL_LEV2_NAME"] === nameRoot5);
  }

  handleMarket() {
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  onSearchData() {
    if (!this.saleYearMonth || this.saleYearMonth?.length === 0) {
      return;
    }

    this.isLoading = true;
    const analyticsQuery = ProgressRankingHelper.AreaSalesRankingSetQueryString(
      {
        ...this.lstOption_Selected_Applied,
        SALES_DATE: this.saleYearMonth
      },
      this.paging.current,
      this.sortType,
      this.sortColumn,
      false
    );

    ProgressRankingHelper.GetAreaSalesRankingAggregateKey(this.http, analyticsQuery, this.lstOption_Selected_RankingUnit)
      .then((data) => {
        if (data) {
          this.areaSalesRankingData = data;
          this.onInitData();
          this.isLoading = false;
        }
    });
  }

  onSaveChannelLevChange(e: string): void {
    if (!e) {
      return;
    }
    this.paging.current = 1;
    this.lstOption_Selected_RankingUnit = e;
    this.lstOption_RankingUnit.map((elt: any) => {
      const option = elt?.value || null;

      this.listColumn.forEach((column: any) => {
        const { category } = column;

        if (option?.replace(/ /g, '_').toUpperCase() === category) {
          column.isDisplay = this.lstOption_Selected_RankingUnit.includes(option) ? true : false;
        }
      });
    });

    switch(this.lstOption_Selected_RankingUnit){
      case "Dealer":
        this.listColumn    = ProgressRankingHelper.ListColumnAreaSalesRanking_Dealer();
        this.listColspan   = ProgressRankingHelper.ListColspanAreaSalesRanking_Dealer();
        this.sortColumn = "BAPC_TY";
        this.allCSVButtonText = "The first 5000 records CSV";
        break;
    case "Distribution Route":
      this.listColumn    = ProgressRankingHelper.ListColumnAreaSalesRanking_DistributionRoute();
      this.listColspan   = ProgressRankingHelper.ListColspanAreaSalesRanking_DistributionRoute();
      this.allCSVButtonText = "All pages CSV";
        break ;
    case "Center":
      this.listColumn    = ProgressRankingHelper.ListColumnAreaSalesRanking_Center();
      this.listColspan   = ProgressRankingHelper.ListColspanAreaSalesRanking_Center();
      this.allCSVButtonText = "All pages CSV";
        break;

    case "District":
      this.listColumn    = ProgressRankingHelper.ListColumnAreaSalesRanking_District();
      this.listColspan   = ProgressRankingHelper.ListColspanAreaSalesRanking_District();
      this.allCSVButtonText = "All pages CSV";
        break;
    }

    this.onSearchData();
  }

  public getListColspan(){
    return this.listColspan;
  }
  
  public updateAreaSalesRankingData(event: any) :void {
    const { lstOption_Selected, lstOption_States } = event;

    this.lstOption_Selected         = { ...lstOption_Selected };
    this.lstOption_Selected_Applied = { ...lstOption_Selected };
    this.lstOption_States_Applied   = { ...lstOption_States }

    this.paging.current = 1;
    this.onSearchData();
  }

  onResetFiltersGroup($event: any) {
    const {data} = $event;

    if (data && Array.isArray(data) && data.length > 0) {
      data.map(key => {
        if (key && key.length > 0) {
          this.lstOption_Selected[key] = ['All'];
        }
      });
    }
  }

  // Export data to CSV | PDF
  export_CSV(){
    const analyticsQuery = ProgressRankingHelper.AreaSalesRankingSetQueryString(
      {
        ...this.lstOption_Selected_Applied,
        SALES_DATE: this.saleYearMonth
      },
      this.paging.current,
      this.sortType,
      this.sortColumn,
      false,
    );

    this.csvService.getCsvUrl(AnalyticsQueries.areaSalesRankingCSV, analyticsQuery, this.lstOption_Selected_RankingUnit)
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  public handle_export_All_CSV(){
    if (this.lstOption_Selected_RankingUnit === 'Dealer' && this.paging?.totalPage >= 100) {
      this.openConfirmPopup();
    } else {
      this.exportAll_CSV();
    }
  }

  openConfirmPopup = () => {
    Swal.fire({
      title: `<p class="line1">Important Notice</p><p class="line2">Due to the large amount of data, records will be restricted to the first 5000 matches. This process may take approximately 7 seconds. For more refined results, please consider applying additional filters.</p>`,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Proceed",
      cancelButtonText: "Cancel "
    }).then((result) => {
      const {isConfirmed} = result;

      if (isConfirmed) {
        Swal.close();
        setTimeout(() => {
          this.exportAll_CSV();
        });
      }
    })
  }

  exportAll_CSV() {
    let analyticsQuery= ProgressRankingHelper.AreaSalesRankingSetQueryString(
      {
        ...this.lstOption_Selected_Applied,
        SALES_DATE: this.saleYearMonth
      },
      1,
      this.sortType,
      this.sortColumn,
      true);

      this.csvService.getCsvUrl(AnalyticsQueries.areaSalesRankingCSV, analyticsQuery, this.lstOption_Selected_RankingUnit)
        .subscribe((data) => {
          if (data) {
            this._downloadCsv(data?.Csv_Url);
          }
      });
  }

  renderCSV_District(listRoot : any, fileName: string){
    let data = [];
    let filters:any;
    filters = {
      saleYearMonth:this.saleYearMonth.toString(),
      saleHQLev1: this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME,
      saleHQLev2: this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME,
      saleHQLev3: this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME,
      saleCenter: this.lstOption_Selected_Applied.SALES_CENTER_NAME,
      saleBaseControl: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_NAME,
      saleRoute: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_ROUTE_CODE,
      channelLev1: this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME,
      channelLev2: this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME,
      channelLev3: this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME,
      channel_NL : this.lstOption_Selected_Applied.CHANNEL_NL
    };

    //const listRootSort = sortArrayByMultiPriority([...listRoot.ListAll], PRIORITY_ORDER?.SALES_HQ_LEV3_NAME);
    const listRootSort = listRoot.ListAll;
    listRoot.Total.forEach(root1=> {
      data.push(this.toRowCSV_District(root1,filters, true));
    });

    listRootSort.forEach(root1=> {
      data.push(this.toRowCSV_District(root1,filters));
    });

    new AngularCsv(data, fileName, {
      showLabels: true,
      headers: ['Sales Year Month',
      'Sales HQ Lev1', 
      'Sales HQ Lev2', 
      'Sales HQ Lev3', 
      'Sales Center', 
      'Sale Control Base', 
      'Sale Route' , 
      'Channel Lev1', 
      'Channel Lev2', 
      'Channel Lev3' ,
      'National/Local',

      'Sales HQ Lev1 Code',
      'Sales HQ Lev1 Name',

      'Sales HQ Lev2 Code',
      'Sales HQ Lev2 Name',

      'Sales HQ Lev3 Code',
      'Sales HQ Lev3 Name',

      'BAPC TY',
      'BAPC BP', //add
      'BAPC% VS BP', 
      'BAPC DIFF VS BP', 
      'BAPC LY', // add
      'BAPC% VS LY', 
      'BAPC DIFF VS LY', // add

      'NSR TY', 
      'NSR BP', // add
      'NSR% VS BP', 
      'NSR DIFF VS BP', 
      'NSR LY', // add
      'NSR% VS LY', 
      'NSR DIFF VS LY', // add

      'NSR PER CASE TY', 
      'NSR PER CASE BP',  // add
      'NSR PER CASE% VS BP',  // add
      'NSR PER CASE DIFF VS BP', 
      'NSR PER CASE LY', // add
      'NSR PER CASE% VS LY', // add
      'NSR PER CASE DIFF VS LY', 

      'GP TY', 
      'GP BP', // add
      'GP% VS BP', 
      'GP DIFF VS BP', 
      'GP LY', // add
      'GP% VS LY', 
      'GP DIFF VS LY', // add

      'GP PER CASE TY', 
      'GP PER CASE BP', // add
      'GP PER CASE% VS BP', //add 
      'GP PER CASE DIFF VS BP', 
      'GP PER CASE LY', //add 
      'GP PER CASE% VS LY',//add 
      'GP PER CASE DIFF VS LY'],
    });
  }

  toRowCSV_District(row:any, filters:any , isTotal: boolean = false){
    return {
      SALES_YEAR_MONTH: filters === null?'': filters.saleYearMonth,
      SALE_HQ_LEV1: filters === null?'': String(filters.saleHQLev1),
      SALE_HQ_LEV2: filters === null?'': String(filters.saleHQLev2),
      SALE_HQ_LEV3: filters === null?'': String(filters.saleHQLev3),
      SALE_CENTER: filters === null?'': String(filters.saleCenter),
      SALE_BASE_CONTROLLER: filters === null?'': String(filters.saleBaseControl),
      SALE_ROUTE: filters === null?'': String(filters.saleRoute).replace(/,/g, ', '),
      CHANNEL_LEV1: filters === null?'':String(filters.channelLev1),
      CHANNEL_LEV2: filters === null?'':String(filters.channelLev2),
      CHANNEL_LEV3: filters === null?'':String(filters.channelLev3),
      CHANNEL_NL : filters == null? '' : String(filters.channel_NL),

      SALES_HQ_LEV1_CODE: isTotal? 'Grand Total' :row['SALES_HQ_LEV1_CODE'] ,
      SALES_HQ_LEV1_NAME: isTotal? '': row['SALES_HQ_LEV1_NAME_Display'],

      SALES_HQ_LEV2_CODE: isTotal? '' :row['SALES_HQ_LEV2_CODE'],
      SALES_HQ_LEV2_NAME: isTotal? '' :row['SALES_HQ_LEV2_NAME_Display'],

      SALES_HQ_LEV3_CODE: isTotal? '' : row['SALES_HQ_LEV3_CODE'],
      SALES_HQ_LEV3_NAME: isTotal? '' : row['SALES_HQ_LEV3_NAME_Display'],


      "BAPC_TY": row['BAPC_TY_Display'] ,
      "BAPC_BP": row['BAPC_BP_Display'] ,
      "BAPC_VS_BP": row['BAPC_VS_BP_Display'] ,
      "BAPC_DIFF_VS_BP": row['BAPC_DIFF_VS_BP_Display'] ,
      "BAPC_LY": row['BAPC_LY_Display'] ,
      "BAPC_VS_LY": row['BAPC_VS_LY_Display'] ,
      "BAPC_DIFF_VS_LY": row['BAPC_DIFF_VS_LY_Display'] ,


      "NSR_TY": row['NSR_TY_Display'] ,
      "NSR_BP": row['NSR_BP_Display'] ,
      "NSR_VS_BP": row['NSR_VS_BP_Display'] ,
      "NSR_DIFF_VS_BP": row['NSR_DIFF_VS_BP_Display'] ,
      "NSR_LY": row['NSR_LY_Display'] ,
      "NSR_VS_LY": row['NSR_VS_LY_Display'] ,
      "NSR_DIFF_VS_LY": row['NSR_DIFF_VS_LY_Display'] ,

      "NSR_PER_CASE_TY": row['NSR_PER_CASE_TY_Display'] ,
      "NSR_PER_CASE_BP": row['NSR_PER_CASE_BP_Display'] ,
      "NSR_PER_CASE_VS_BP": row['NSR_PER_CASE_VS_BP_Display'] ,
      "NSR_PER_CASE_DIFF_VS_BP": row['NSR_PER_CASE_DIFF_VS_BP_Display'] ,
      "NSR_PER_CASE_LY": row['NSR_PER_CASE_LY_Display'] ,
      "NSR_PER_CASE_VS_LY": row['NSR_PER_CASE_VS_LY_Display'] ,
      "NSR_PER_CASE_DIFF_VS_LY": row['NSR_PER_CASE_DIFF_VS_LY_Display'] ,

      "GP_TY": row['GP_TY_Display'] ,
      "GP_BP": row['GP_BP_Display'] ,
      "GP_VS_BP": row['GP_VS_BP_Display'] ,
      "GP_DIFF_VS_BP": row['GP_DIFF_VS_BP_Display'] ,
      "GP_LY": row['GP_LY_Display'] ,
      "GP_VS_LY": row['GP_VS_LY_Display'] ,
      "GP_DIFF_VS_LY": row['GP_DIFF_VS_LY_Display'] ,

      "GP_PER_CASE_TY": row['GP_PER_CASE_TY_Display'] ,
      "GP_PER_CASE_BP": row['GP_PER_CASE_BP_Display'] ,
      "GP_PER_CASE_VS_BP": row['GP_PER_CASE_VS_BP_Display'] ,
      "GP_PER_CASE_DIFF_VS_BP": row['GP_PER_CASE_DIFF_VS_BP_Display'] ,
      "GP_PER_CASE_LY": row['GP_PER_CASE_LY_Display'] ,
      "GP_PER_CASE_VS_LY": row['GP_PER_CASE_VS_LY_Display'] ,
      "GP_PER_CASE_DIFF_VS_LY": row['GP_PER_CASE_DIFF_VS_LY_Display'] 
    }
  }


  renderCSV_Center(listRoot : any, fileName: string){
    let data = [];
    let filters:any;
    filters = {
      saleYearMonth:this.saleYearMonth.toString(),
      saleHQLev1: this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME,
      saleHQLev2: this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME,
      saleHQLev3: this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME,
      saleCenter: this.lstOption_Selected_Applied.SALES_CENTER_NAME,
      saleBaseControl: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_NAME,
      saleRoute: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_ROUTE_CODE,
      channelLev1: this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME,
      channelLev2: this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME,
      channelLev3: this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME,
      channel_NL : this.lstOption_Selected_Applied.CHANNEL_NL
    };


    listRoot.Total.forEach(root1=> {
      data.push(this.toRowCSV_Center(root1,filters, true));
    });

    //const listRootSort = sortArrayByMultiPriority([...listRoot.ListAll], PRIORITY_ORDER);
    const listRootSort = listRoot.ListAll;
    listRootSort.forEach(root1=> {
      data.push(this.toRowCSV_Center(root1,filters));
    });

    new AngularCsv(data, fileName, {
      showLabels: true,
      headers: ['Sales Year Month',
      'Sales HQ Lev1', 
      'Sales HQ Lev2', 
      'Sales HQ Lev3', 
      'Sales Center', 
      'Sale Control Base', 
      'Sale Route' , 
      'Channel Lev1', 
      'Channel Lev2', 
      'Channel Lev3' ,
      'National/Local',

      'Sales HQ Lev1 Code',
      'Sales HQ Lev1 Name',

      'Sales HQ Lev2 Code',
      'Sales HQ Lev2 Name',

      'Sales HQ Lev3 Code',
      'Sales HQ Lev3 Name',

      'Sales Center Code',
      'Sales Center Name',


      'BAPC TY',
      'BAPC BP', //add
      'BAPC% VS BP', 
      'BAPC DIFF VS BP', 
      'BAPC LY', // add
      'BAPC% VS LY', 
      'BAPC DIFF VS LY', // add

      'NSR TY', 
      'NSR BP', // add
      'NSR% VS BP', 
      'NSR DIFF VS BP', 
      'NSR LY', // add
      'NSR% VS LY', 
      'NSR DIFF VS LY', // add

      'NSR PER CASE TY', 
      'NSR PER CASE BP',  // add
      'NSR PER CASE% VS BP',  // add
      'NSR PER CASE DIFF VS BP', 
      'NSR PER CASE LY', // add
      'NSR PER CASE% VS LY', // add
      'NSR PER CASE DIFF VS LY', 

      'GP TY', 
      'GP BP', // add
      'GP% VS BP', 
      'GP DIFF VS BP', 
      'GP LY', // add
      'GP% VS LY', 
      'GP DIFF VS LY', // add

      'GP PER CASE TY', 
      'GP PER CASE BP', // add
      'GP PER CASE% VS BP', //add 
      'GP PER CASE DIFF VS BP', 
      'GP PER CASE LY', //add 
      'GP PER CASE% VS LY',//add 
      'GP PER CASE DIFF VS LY'],
    });
  }

  toRowCSV_Center(row:any, filters:any , isTotal: boolean = false){
    return {
      SALES_YEAR_MONTH: filters === null?'': filters.saleYearMonth,
      SALE_HQ_LEV1: filters === null?'': String(filters.saleHQLev1),
      SALE_HQ_LEV2: filters === null?'': String(filters.saleHQLev2),
      SALE_HQ_LEV3: filters === null?'': String(filters.saleHQLev3),
      SALE_CENTER: filters === null?'': String(filters.saleCenter),
      SALE_BASE_CONTROLLER: filters === null?'': String(filters.saleBaseControl),
      SALE_ROUTE: filters === null?'': String(filters.saleRoute).replace(/,/g, ', '),
      CHANNEL_LEV1: filters === null?'':String(filters.channelLev1),
      CHANNEL_LEV2: filters === null?'':String(filters.channelLev2),
      CHANNEL_LEV3: filters === null?'':String(filters.channelLev3),
      CHANNEL_NL : filters == null? '' : String(filters.channel_NL),

      SALES_HQ_LEV1_CODE: isTotal? 'Grand Total' :row['SALES_HQ_LEV1_CODE'] ,
      SALES_HQ_LEV1_NAME: isTotal? '': row['SALES_HQ_LEV1_NAME_Display'],

      SALES_HQ_LEV2_CODE: isTotal? '' :row['SALES_HQ_LEV2_CODE'],
      SALES_HQ_LEV2_NAME: isTotal? '' :row['SALES_HQ_LEV2_NAME_Display'],

      SALES_HQ_LEV3_CODE: isTotal? '' : row['SALES_HQ_LEV3_CODE'],
      SALES_HQ_LEV3_NAME: isTotal? '' : row['SALES_HQ_LEV3_NAME_Display'],

      SALES_CENTER_CODE: isTotal? '' : row['SALES_CENTER_CODE'],
      SALES_CENTER_NAME: isTotal? '' : row['SALES_CENTER_NAME_Display'],



      "BAPC_TY": row['BAPC_TY_Display'] ,
      "BAPC_BP": row['BAPC_BP_Display'] ,
      "BAPC_VS_BP": row['BAPC_VS_BP_Display'] ,
      "BAPC_DIFF_VS_BP": row['BAPC_DIFF_VS_BP_Display'] ,
      "BAPC_LY": row['BAPC_LY_Display'] ,
      "BAPC_VS_LY": row['BAPC_VS_LY_Display'] ,
      "BAPC_DIFF_VS_LY": row['BAPC_DIFF_VS_LY_Display'] ,


      "NSR_TY": row['NSR_TY_Display'] ,
      "NSR_BP": row['NSR_BP_Display'] ,
      "NSR_VS_BP": row['NSR_VS_BP_Display'] ,
      "NSR_DIFF_VS_BP": row['NSR_DIFF_VS_BP_Display'] ,
      "NSR_LY": row['NSR_LY_Display'] ,
      "NSR_VS_LY": row['NSR_VS_LY_Display'] ,
      "NSR_DIFF_VS_LY": row['NSR_DIFF_VS_LY_Display'] ,

      "NSR_PER_CASE_TY": row['NSR_PER_CASE_TY_Display'] ,
      "NSR_PER_CASE_BP": row['NSR_PER_CASE_BP_Display'] ,
      "NSR_PER_CASE_VS_BP": row['NSR_PER_CASE_VS_BP_Display'] ,
      "NSR_PER_CASE_DIFF_VS_BP": row['NSR_PER_CASE_DIFF_VS_BP_Display'] ,
      "NSR_PER_CASE_LY": row['NSR_PER_CASE_LY_Display'] ,
      "NSR_PER_CASE_VS_LY": row['NSR_PER_CASE_VS_LY_Display'] ,
      "NSR_PER_CASE_DIFF_VS_LY": row['NSR_PER_CASE_DIFF_VS_LY_Display'] ,

      "GP_TY": row['GP_TY_Display'] ,
      "GP_BP": row['GP_BP_Display'] ,
      "GP_VS_BP": row['GP_VS_BP_Display'] ,
      "GP_DIFF_VS_BP": row['GP_DIFF_VS_BP_Display'] ,
      "GP_LY": row['GP_LY_Display'] ,
      "GP_VS_LY": row['GP_VS_LY_Display'] ,
      "GP_DIFF_VS_LY": row['GP_DIFF_VS_LY_Display'] ,

      "GP_PER_CASE_TY": row['GP_PER_CASE_TY_Display'] ,
      "GP_PER_CASE_BP": row['GP_PER_CASE_BP_Display'] ,
      "GP_PER_CASE_VS_BP": row['GP_PER_CASE_VS_BP_Display'] ,
      "GP_PER_CASE_DIFF_VS_BP": row['GP_PER_CASE_DIFF_VS_BP_Display'] ,
      "GP_PER_CASE_LY": row['GP_PER_CASE_LY_Display'] ,
      "GP_PER_CASE_VS_LY": row['GP_PER_CASE_VS_LY_Display'] ,
      "GP_PER_CASE_DIFF_VS_LY": row['GP_PER_CASE_DIFF_VS_LY_Display'] 
    }
  }


  renderCSV_Distribution(listRoot : any, fileName: string){
    let data = [];
    let filters:any;
    filters = {
      saleYearMonth:this.saleYearMonth.toString(),
      saleHQLev1: this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME,
      saleHQLev2: this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME,
      saleHQLev3: this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME,
      saleCenter: this.lstOption_Selected_Applied.SALES_CENTER_NAME,
      saleBaseControl: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_NAME,
      saleRoute: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_ROUTE_CODE,
      channelLev1: this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME,
      channelLev2: this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME,
      channelLev3: this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME,
      channel_NL : this.lstOption_Selected_Applied.CHANNEL_NL
    };


    listRoot.Total.forEach(root1=> {
      data.push(this.toRowCSV_Distribution(root1,filters, true));
    });

    //const listRootSort = sortArrayByMultiPriority([...listRoot.ListAll], PRIORITY_ORDER);
    const listRootSort = listRoot.ListAll;
    listRootSort.forEach(root1=> {
      data.push(this.toRowCSV_Distribution(root1,filters));
    });

    new AngularCsv(data, fileName, {
      showLabels: true,
      headers: ['Sales Year Month',
      'Sales HQ Lev1', 
      'Sales HQ Lev2', 
      'Sales HQ Lev3', 
      'Sales Center', 
      'Sale Control Base', 
      'Sale Route' , 
      'Channel Lev1', 
      'Channel Lev2', 
      'Channel Lev3' ,
      'National/Local',

      'Sales HQ Lev1 Code',
      'Sales HQ Lev1 Name',

      'Sales HQ Lev2 Code',
      'Sales HQ Lev2 Name',

      'Sales HQ Lev3 Code',
      'Sales HQ Lev3 Name',

      'Sales Center Code',
      'Sales Center Name',

      'Sales Control Base Code',
      'Sales Control Base Name', 

      'Sales Control Base Route Code',

      'BAPC TY',
      'BAPC BP', //add
      'BAPC% VS BP', 
      'BAPC DIFF VS BP', 
      'BAPC LY', // add
      'BAPC% VS LY', 
      'BAPC DIFF VS LY', // add

      'NSR TY', 
      'NSR BP', // add
      'NSR% VS BP', 
      'NSR DIFF VS BP', 
      'NSR LY', // add
      'NSR% VS LY', 
      'NSR DIFF VS LY', // add

      'NSR PER CASE TY', 
      'NSR PER CASE BP',  // add
      'NSR PER CASE% VS BP',  // add
      'NSR PER CASE DIFF VS BP', 
      'NSR PER CASE LY', // add
      'NSR PER CASE% VS LY', // add
      'NSR PER CASE DIFF VS LY', 

      'GP TY', 
      'GP BP', // add
      'GP% VS BP', 
      'GP DIFF VS BP', 
      'GP LY', // add
      'GP% VS LY', 
      'GP DIFF VS LY', // add

      'GP PER CASE TY', 
      'GP PER CASE BP', // add
      'GP PER CASE% VS BP', //add 
      'GP PER CASE DIFF VS BP', 
      'GP PER CASE LY', //add 
      'GP PER CASE% VS LY',//add 
      'GP PER CASE DIFF VS LY'],
    });
  }

  toRowCSV_Distribution(row:any, filters:any , isTotal: boolean = false){
    return {
      SALES_YEAR_MONTH: filters === null?'': filters.saleYearMonth,
      SALE_HQ_LEV1: filters === null?'': String(filters.saleHQLev1),
      SALE_HQ_LEV2: filters === null?'': String(filters.saleHQLev2),
      SALE_HQ_LEV3: filters === null?'': String(filters.saleHQLev3),
      SALE_CENTER: filters === null?'': String(filters.saleCenter),
      SALE_BASE_CONTROLLER: filters === null?'': String(filters.saleBaseControl),
      SALE_ROUTE: filters === null?'': String(filters.saleRoute).replace(/,/g, ', '),
      CHANNEL_LEV1: filters === null?'':String(filters.channelLev1),
      CHANNEL_LEV2: filters === null?'':String(filters.channelLev2),
      CHANNEL_LEV3: filters === null?'':String(filters.channelLev3),
      CHANNEL_NL : filters == null? '' : String(filters.channel_NL),

      SALES_HQ_LEV1_CODE: isTotal? 'Grand Total' :row['SALES_HQ_LEV1_CODE'] ,
      SALES_HQ_LEV1_NAME: isTotal? '': row['SALES_HQ_LEV1_NAME_Display'],

      SALES_HQ_LEV2_CODE: isTotal? '' :row['SALES_HQ_LEV2_CODE'],
      SALES_HQ_LEV2_NAME: isTotal? '' :row['SALES_HQ_LEV2_NAME_Display'],

      SALES_HQ_LEV3_CODE: isTotal? '' : row['SALES_HQ_LEV3_CODE'],
      SALES_HQ_LEV3_NAME: isTotal? '' : row['SALES_HQ_LEV3_NAME_Display'],

      SALES_CENTER_CODE: isTotal? '' : row['SALES_CENTER_CODE'],
      SALES_CENTER_NAME: isTotal? '' : row['SALES_CENTER_NAME_Display'],

      SALES_CONTROL_BASE_CODE: isTotal? '' : row['SALES_CONTROL_BASE_CODE'],
      SALES_CONTROL_BASE_NAME: isTotal? '' : row['SALES_CONTROL_BASE_NAME_Display'],

      SALES_CONTROL_BASE_ROUTE_CODE: isTotal? '' : row['SALES_CONTROL_BASE_ROUTE_CODE_Display'],


      "BAPC_TY": row['BAPC_TY_Display'] ,
      "BAPC_BP": row['BAPC_BP_Display'] ,
      "BAPC_VS_BP": row['BAPC_VS_BP_Display'] ,
      "BAPC_DIFF_VS_BP": row['BAPC_DIFF_VS_BP_Display'] ,
      "BAPC_LY": row['BAPC_LY_Display'] ,
      "BAPC_VS_LY": row['BAPC_VS_LY_Display'] ,
      "BAPC_DIFF_VS_LY": row['BAPC_DIFF_VS_LY_Display'] ,


      "NSR_TY": row['NSR_TY_Display'] ,
      "NSR_BP": row['NSR_BP_Display'] ,
      "NSR_VS_BP": row['NSR_VS_BP_Display'] ,
      "NSR_DIFF_VS_BP": row['NSR_DIFF_VS_BP_Display'] ,
      "NSR_LY": row['NSR_LY_Display'] ,
      "NSR_VS_LY": row['NSR_VS_LY_Display'] ,
      "NSR_DIFF_VS_LY": row['NSR_DIFF_VS_LY_Display'] ,

      "NSR_PER_CASE_TY": row['NSR_PER_CASE_TY_Display'] ,
      "NSR_PER_CASE_BP": row['NSR_PER_CASE_BP_Display'] ,
      "NSR_PER_CASE_VS_BP": row['NSR_PER_CASE_VS_BP_Display'] ,
      "NSR_PER_CASE_DIFF_VS_BP": row['NSR_PER_CASE_DIFF_VS_BP_Display'] ,
      "NSR_PER_CASE_LY": row['NSR_PER_CASE_LY_Display'] ,
      "NSR_PER_CASE_VS_LY": row['NSR_PER_CASE_VS_LY_Display'] ,
      "NSR_PER_CASE_DIFF_VS_LY": row['NSR_PER_CASE_DIFF_VS_LY_Display'] ,

      "GP_TY": row['GP_TY_Display'] ,
      "GP_BP": row['GP_BP_Display'] ,
      "GP_VS_BP": row['GP_VS_BP_Display'] ,
      "GP_DIFF_VS_BP": row['GP_DIFF_VS_BP_Display'] ,
      "GP_LY": row['GP_LY_Display'] ,
      "GP_VS_LY": row['GP_VS_LY_Display'] ,
      "GP_DIFF_VS_LY": row['GP_DIFF_VS_LY_Display'] ,

      "GP_PER_CASE_TY": row['GP_PER_CASE_TY_Display'] ,
      "GP_PER_CASE_BP": row['GP_PER_CASE_BP_Display'] ,
      "GP_PER_CASE_VS_BP": row['GP_PER_CASE_VS_BP_Display'] ,
      "GP_PER_CASE_DIFF_VS_BP": row['GP_PER_CASE_DIFF_VS_BP_Display'] ,
      "GP_PER_CASE_LY": row['GP_PER_CASE_LY_Display'] ,
      "GP_PER_CASE_VS_LY": row['GP_PER_CASE_VS_LY_Display'] ,
      "GP_PER_CASE_DIFF_VS_LY": row['GP_PER_CASE_DIFF_VS_LY_Display'] 
    }
  }


  renderCSV_Dealer(listRoot : any, fileName: string){
    let data = [];
    let filters:any;
    filters = {
      saleYearMonth:this.saleYearMonth.toString(),
      saleHQLev1: this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME,
      saleHQLev2: this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME,
      saleHQLev3: this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME,
      saleCenter: this.lstOption_Selected_Applied.SALES_CENTER_NAME,
      saleBaseControl: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_NAME,
      saleRoute: this.lstOption_Selected_Applied.SALES_CONTROL_BASE_ROUTE_CODE,
      channelLev1: this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME,
      channelLev2: this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME,
      channelLev3: this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME,
      channel_NL : this.lstOption_Selected_Applied.CHANNEL_NL
    };


    listRoot.Total.forEach(root1=> {
      data.push(this.toRowCSV_Dealer(root1,filters, true));
    });

    //const listRootSort = sortArrayByMultiPriority([...listRoot.ListAll], PRIORITY_ORDER);
    const listRootSort = listRoot.ListAll;
    listRootSort.forEach(root1=> {
      data.push(this.toRowCSV_Dealer(root1,filters));
    });

    new AngularCsv(data, fileName, {
      showLabels: true,
      headers: ['Sales Year Month',
      'Sales HQ Lev1', 
      'Sales HQ Lev2', 
      'Sales HQ Lev3', 
      'Sales Center', 
      'Sale Control Base', 
      'Sale Route' , 
      'Channel Lev1', 
      'Channel Lev2', 
      'Channel Lev3' ,
      'National/Local',

      'Sales HQ Lev1 Code',
      'Sales HQ Lev1 Name',

      'Sales HQ Lev2 Code',
      'Sales HQ Lev2 Name',

      'Sales HQ Lev3 Code',
      'Sales HQ Lev3 Name',

      'Sales Center Code',
      'Sales Center Name',

      'Sales Control Base Code',
      'Sales Control Base Name', 

      'Rep Dearler',

      'BAPC TY',
      // 'BAPC BP', //add
      // 'BAPC% VS BP', 
      // 'BAPC DIFF VS BP', 
      'BAPC LY', // add
      'BAPC% VS LY', 
      'BAPC DIFF VS LY', // add

      'NSR TY', 
      // 'NSR BP', // add
      // 'NSR% VS BP', 
      // 'NSR DIFF VS BP', 
      'NSR LY', // add
      'NSR% VS LY', 
      'NSR DIFF VS LY', // add

      'NSR PER CASE TY', 
      // 'NSR PER CASE BP',  // add
      // 'NSR PER CASE% VS BP',  // add
      // 'NSR PER CASE DIFF VS BP', 
      'NSR PER CASE LY', // add
      'NSR PER CASE% VS LY', // add
      'NSR PER CASE DIFF VS LY', 

      'GP TY', 
      // 'GP BP', // add
      // 'GP% VS BP', 
      // 'GP DIFF VS BP', 
      'GP LY', // add
      'GP% VS LY', 
      'GP DIFF VS LY', // add

      'GP PER CASE TY', 
      // 'GP PER CASE BP', // add
      // 'GP PER CASE% VS BP', //add 
      // 'GP PER CASE DIFF VS BP', 
      'GP PER CASE LY', //add 
      'GP PER CASE% VS LY',//add 
      'GP PER CASE DIFF VS LY'],
    });
  }

  toRowCSV_Dealer(row:any, filters:any , isTotal: boolean = false){
    return {
      SALES_YEAR_MONTH: filters === null?'': filters.saleYearMonth,
      SALE_HQ_LEV1: filters === null?'': String(filters.saleHQLev1),
      SALE_HQ_LEV2: filters === null?'': String(filters.saleHQLev2),
      SALE_HQ_LEV3: filters === null?'': String(filters.saleHQLev3),
      SALE_CENTER: filters === null?'': String(filters.saleCenter),
      SALE_BASE_CONTROLLER: filters === null?'': String(filters.saleBaseControl),
      SALE_ROUTE: filters === null?'': String(filters.saleRoute).replace(/,/g, ', '),
      CHANNEL_LEV1: filters === null?'':String(filters.channelLev1),
      CHANNEL_LEV2: filters === null?'':String(filters.channelLev2),
      CHANNEL_LEV3: filters === null?'':String(filters.channelLev3),
      CHANNEL_NL : filters == null? '' : String(filters.channel_NL),

      SALES_HQ_LEV1_CODE: isTotal? 'Grand Total' :row['SALES_HQ_LEV1_CODE'] ,
      SALES_HQ_LEV1_NAME: isTotal? '': row['SALES_HQ_LEV1_NAME_Display'],

      SALES_HQ_LEV2_CODE: isTotal? '' :row['SALES_HQ_LEV2_CODE'],
      SALES_HQ_LEV2_NAME: isTotal? '' :row['SALES_HQ_LEV2_NAME_Display'],

      SALES_HQ_LEV3_CODE: isTotal? '' : row['SALES_HQ_LEV3_CODE'],
      SALES_HQ_LEV3_NAME: isTotal? '' : row['SALES_HQ_LEV3_NAME_Display'],

      SALES_CENTER_CODE: isTotal? '' : row['SALES_CENTER_CODE'],
      SALES_CENTER_NAME: isTotal? '' : row['SALES_CENTER_NAME_Display'],

      SALES_CONTROL_BASE_CODE: isTotal? '' : row['SALES_CONTROL_BASE_CODE'],
      SALES_CONTROL_BASE_NAME: isTotal? '' : row['SALES_CONTROL_BASE_NAME_Display'],

      REP_DEALER: isTotal? '' : row['REP_DEALER_Display'],


      "BAPC_TY": row['BAPC_TY_Display'] ,
      // "BAPC_BP": row['BAPC_BP_Display'] ,
      // "BAPC_VS_BP": row['BAPC_VS_BP_Display'] ,
      // "BAPC_DIFF_VS_BP": row['BAPC_DIFF_VS_BP_Display'] ,
      "BAPC_LY": row['BAPC_LY_Display'] ,
      "BAPC_VS_LY": row['BAPC_VS_LY_Display'] ,
      "BAPC_DIFF_VS_LY": row['BAPC_DIFF_VS_LY_Display'] ,


      "NSR_TY": row['NSR_TY_Display'] ,
      // "NSR_BP": row['NSR_BP_Display'] ,
      // "NSR_VS_BP": row['NSR_VS_BP_Display'] ,
      // "NSR_DIFF_VS_BP": row['NSR_DIFF_VS_BP_Display'] ,
      "NSR_LY": row['NSR_LY_Display'] ,
      "NSR_VS_LY": row['NSR_VS_LY_Display'] ,
      "NSR_DIFF_VS_LY": row['NSR_DIFF_VS_LY_Display'] ,

      "NSR_PER_CASE_TY": row['NSR_PER_CASE_TY_Display'] ,
      // "NSR_PER_CASE_BP": row['NSR_PER_CASE_BP_Display'] ,
      // "NSR_PER_CASE_VS_BP": row['NSR_PER_CASE_VS_BP_Display'] ,
      // "NSR_PER_CASE_DIFF_VS_BP": row['NSR_PER_CASE_DIFF_VS_BP_Display'] ,
      "NSR_PER_CASE_LY": row['NSR_PER_CASE_LY_Display'] ,
      "NSR_PER_CASE_VS_LY": row['NSR_PER_CASE_VS_LY_Display'] ,
      "NSR_PER_CASE_DIFF_VS_LY": row['NSR_PER_CASE_DIFF_VS_LY_Display'] ,

      "GP_TY": row['GP_TY_Display'] ,
      // "GP_BP": row['GP_BP_Display'] ,
      // "GP_VS_BP": row['GP_VS_BP_Display'] ,
      // "GP_DIFF_VS_BP": row['GP_DIFF_VS_BP_Display'] ,
      "GP_LY": row['GP_LY_Display'] ,
      "GP_VS_LY": row['GP_VS_LY_Display'] ,
      "GP_DIFF_VS_LY": row['GP_DIFF_VS_LY_Display'] ,

      "GP_PER_CASE_TY": row['GP_PER_CASE_TY_Display'] ,
      // "GP_PER_CASE_BP": row['GP_PER_CASE_BP_Display'] ,
      // "GP_PER_CASE_VS_BP": row['GP_PER_CASE_VS_BP_Display'] ,
      // "GP_PER_CASE_DIFF_VS_BP": row['GP_PER_CASE_DIFF_VS_BP_Display'] ,
      "GP_PER_CASE_LY": row['GP_PER_CASE_LY_Display'] ,
      "GP_PER_CASE_VS_LY": row['GP_PER_CASE_VS_LY_Display'] ,
      "GP_PER_CASE_DIFF_VS_LY": row['GP_PER_CASE_DIFF_VS_LY_Display'] 
    }
  }

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }


  @ViewChild('df_print_area_sales_ranking', { static: false }) df_print_area_sales_ranking: ElementRef;
  exportHQDistrict_PDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing-se pdf-printing");

    const fontStyle:FontStyle={
      fontName:'IPAMinchoRegular',
      path:'../../assets/fonts/IPAMinchoRegular.ttf'
    }

    const pdfProperty:PDFProperty={
      fontSize : 20,
      labelColor:'#818EA5',
      valueColor:'#333',
      fontStyle: fontStyle,
    }

    _downloadAsPDF(name, this.df_print_area_sales_ranking.nativeElement,false, pdfProperty );
  }
}

