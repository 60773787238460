import { DatePipe, DOCUMENT } from "@angular/common";
import { PageModel } from './../../../../model/page.model';
import { APP_CONFIG } from 'src/app/model/constants';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, Renderer2, SimpleChanges } from "@angular/core";
import { AnalyticsHelper } from "src/app/helpers/analytics";
import * as XLSX from 'xlsx';

const ALL_OBJ = {cd: 'All', name: 'All'};
const ALL_OBJ_NOCODE = {name: 'All'};
const sort_columns = ["bapc_ty", "bapc_ly", "bapc_per_ly", "nsr_ty", "nsr_ly", "nsr_per_ly", "gp_ty", "gp_ly", "gp_per_ly"];

@Component({
  selector: "customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Output() bindingTableData = new EventEmitter<any>();
  @Input() selectedDate: any;
  @Input() aggregateBy: any;
  @Input() customerDataInit: any;
  @Input() data: any;
  @Input() totalData: any;
  @Input() CustomerdisplayColumns: any;
  @Input() sortbyColumn: any;
  @Input() orderbyColumn: any;
  category=[ALL_OBJ_NOCODE];
  categoryList = [ALL_OBJ_NOCODE];
  categoryBinding = [ALL_OBJ_NOCODE];
  aggregatedCategory = [ALL_OBJ_NOCODE];
  selectedCategory=[];
  categoryBinding_previous = [];
  loadingCategory = false;
  salesAreaCdList = [];
  salesAreaNmList = [];
  salesDistrictCdList = [];
  salesDistrictNmList = [];
  channelLev1CdList = [];
  channelLev2CdList = [];
  channelLev3CdList = [];
  dealerCdList = [];
  dealerNmList = [];
  accLev2CdList = [];
  accLev2NmList = [];
  accLev3CdList = [];
  accLev3NmList = [];
  displayData = [];
  salesAreaList = [];
  salesDistrictList = [];
  salesArea=[ALL_OBJ];
  salesDistrict=[ALL_OBJ];
  sales_area_to_sales_district = {};
  aggregatedSalesArea = [ALL_OBJ];
  salesDistrictBinding = [];
  aggregatedSalesDistrict = [ALL_OBJ];
  channelLevel1List = [];
  channelLevel2List = [];
  channelLevel3List = [];
  channelLevel1Name = [];
  channelLevel2Name = [];
  channelLevel3Name = [];
  groupchannelLevel2toLevel3 = {};
  groupchannelLeve1toLevel2toLevel3 = {};
  channelLev1 = [ALL_OBJ];
  channelLev2 = [ALL_OBJ];
  channelLev3 = [ALL_OBJ];
  channelLev2Binding = [ALL_OBJ];
  channelLev3Binding = [ALL_OBJ];
  aggregatedChannelLevel1 = [ALL_OBJ];
  aggregatedChannelLevel2 = [ALL_OBJ];
  aggregatedChannelLevel3 = [ALL_OBJ];
  accountLevel2List = [];
  accountLevel2 = [ALL_OBJ];
  loadingAccountLevel2 = false;
  accountLevel2Binding = [ALL_OBJ];
  selectedAccountLevel2 = [];
  aggregatedAccountLevel2 = [ALL_OBJ];
  accountLevel3List = [];
  accountLevel3 = [ALL_OBJ];
  accountLevel3Binding = [ALL_OBJ];
  selectedAccountLevel3 = [];
  aggregatedAccountLevel3 = [ALL_OBJ];
  loadingAccountLevel3 = false;
  condition = "";
  current_sortcol = "";
  current_sortcol_order = "";
  aggregateByDisplay = "";
  previousAggregateby = "";
  previousSelectDate = "";
  pageNumber = 1;
  dataChange = false;
  pagination = { current: this.pageNumber, totalItem: 0, sizeOnPage: APP_CONFIG.paginationSize };
  bufferSize = 10;

  filters = [{name: 'Sales Area'}, {name: 'Sales District'}, {name: 'Category'}, {name: 'Channel Level 1'}, 
  {name: 'Channel Level 2'}, {name: 'Channel Level 3'}, {name: 'Account Level 2'}, {name: 'Account Level 3'}];
  groupByColumns = [ALL_OBJ_NOCODE];
  aggregatedgroupByColumns = [ALL_OBJ_NOCODE];
  columnList = [];
  displayColumns = [];
  CustomerMappingdisplayColumns = [];
  mapCustomerFiltersToDisplay = {'SALES_AREA_NAME': 'Sales Area', 'SALES_DISTRICT_NAME': 'Sales District', 'CATEGORY_NAME': 'Category', 'CHANNEL_LEV1_NAME': 'Channel Level 1', 
  'CHANNEL_LEV2_NAME': 'Channel Level 2', 'CHANNEL_LEV3_NAME': 'Channel Level 3', 'ACCOUNT_LEV2_NAME': 'Account Level 2', 'ACCOUNT_LEV3_NAME': 'Account Level 3'};
  mapCustomerFilters = {'Sales Area': 'SALES_AREA_NAME', 'Sales District': 'SALES_DISTRICT_NAME', 'Category': 'CATEGORY_NAME', 'Channel Level 1': 'CHANNEL_LEV1_NAME', 
  'Channel Level 2': 'CHANNEL_LEV2_NAME', 'Channel Level 3': 'CHANNEL_LEV3_NAME', 'Account Level 2': 'ACCOUNT_LEV2_NAME', 'Account Level 3': 'ACCOUNT_LEV3_NAME'};

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnChanges(changes: SimpleChanges) {

    if (("data" in changes || "customerDataInit" in changes) && this.dataChange)
    {
      window.scrollTo(0,document.body.scrollHeight);
      this.dataChange = false;
    }
    document.getElementsByClassName('sales-by-customer-area')[1].scrollTo(0,0);

    if (this.aggregateBy === "daily")
    {
      this.aggregateByDisplay = "Daily";
    }
    else if (this.aggregateBy === "mtd")
    {
      this.aggregateByDisplay = "MTD";
    }
    else if (this.aggregateBy === "ytd")
    {
      this.aggregateByDisplay = "YTD";
    }
    // set data to combobox
    if (this.customerDataInit && "customerDataInit" in changes)
    {
      this.changeDataDropDown();
    }

    if (this.CustomerdisplayColumns && this.CustomerdisplayColumns.length > 0)
    {
      this.CustomerMappingdisplayColumns = [];
      this.CustomerdisplayColumns.forEach(x => {
        if (x in this.mapCustomerFiltersToDisplay)
        {
          if (!this.CustomerMappingdisplayColumns.includes(this.mapCustomerFiltersToDisplay[x]))
          {
            this.CustomerMappingdisplayColumns.push(this.mapCustomerFiltersToDisplay[x]);
          }
        }
      })
    }

    if (this.previousAggregateby != "" && this.previousAggregateby != this.aggregateBy)
    {
      this.searchCustomerData();
    }

    if (this.previousSelectDate != "" && this.previousSelectDate != this.selectedDate)
    {

      this.condition = "";
      this.current_sortcol = "";
      this.current_sortcol_order = "";
      
      this.aggregatedSalesArea = [ALL_OBJ];
      this.salesArea = [ALL_OBJ];
      this.aggregatedSalesDistrict = [ALL_OBJ];
      this.salesDistrict = [ALL_OBJ];

      this.aggregatedCategory = [ALL_OBJ_NOCODE];
      this.category = [ALL_OBJ_NOCODE];

      this.aggregatedChannelLevel1 = [ALL_OBJ];
      this.channelLev1 = [ALL_OBJ];

      this.aggregatedChannelLevel2 = [ALL_OBJ];
      this.channelLev2 = [ALL_OBJ];

      this.aggregatedChannelLevel3 = [ALL_OBJ];
      this.channelLev3 = [ALL_OBJ];

      this.aggregatedAccountLevel2 = [ALL_OBJ];
      this.accountLevel2 = [ALL_OBJ];

      this.aggregatedAccountLevel3 = [ALL_OBJ];
      this.accountLevel3 = [ALL_OBJ];

      this.groupByColumns = [ALL_OBJ_NOCODE];
      this.aggregatedgroupByColumns = [ALL_OBJ_NOCODE];

      this.displayColumns = [];

      this.pageNumber = 1;
      this.pagination.current = 1;
      (<HTMLInputElement>document.getElementById("gotopagecustomer")).value = "";
    }

    this.previousAggregateby = this.aggregateBy;
    this.previousSelectDate = this.selectedDate;

    if (this.data)
    {
      this.displayData = this.data;
    }

    if (this.sortbyColumn != '' && this.orderbyColumn != '')
    {
      if (this.orderbyColumn == 'DESC')
      {
        if (document.getElementById(this.sortbyColumn + "DESCCTM") != null)
        {
          document.getElementById(this.sortbyColumn + "DESCCTM").style.color = "red";
        }

        if (document.getElementById(this.sortbyColumn + "ASCCTM") != null)
        {
          document.getElementById(this.sortbyColumn + "ASCCTM").style.color = "";
        } 
      }
      else
      {
        if (document.getElementById(this.sortbyColumn + "DESCCTM") != null)
        {
          document.getElementById(this.sortbyColumn + "DESCCTM").style.color = "";
        }

        if (document.getElementById(this.sortbyColumn + "ASCCTM") != null)
        {
          document.getElementById(this.sortbyColumn + "ASCCTM").style.color = "red";
        } 
      }

      sort_columns.forEach(s => {
          if (s !== this.sortbyColumn)
          {
            if (document.getElementById(s + "DESCCTM") != null)
            {
              document.getElementById(s + "DESCCTM").style.color = "";
            }

            if (document.getElementById(s + "ASCCTM") != null)
            {
              document.getElementById(s + "ASCCTM").style.color = "";
            }    
          }
        })
    }  

    if (this.displayData && this.displayData.length > 0)
    {
      (<HTMLInputElement>document.getElementById("gotopagecustomer")).removeAttribute('disabled');
    }
    else
    {
      (<HTMLInputElement>document.getElementById("gotopagecustomer")).setAttribute('disabled', '');
    }

    if (this.totalData > 0) {
      this.pagination.totalItem = this.totalData;
    }
    
    if (this.totalData == 0)
    {
      this.pagination.current = 1;
      this.pageNumber = 1;
      this.pagination.totalItem = this.totalData;
    }

    let t=this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  changeDataDropDown() {
    this.columnList = [ALL_OBJ_NOCODE,...this.filters];

    this.salesAreaList = [ALL_OBJ,...this.customerDataInit.areaData];
    this.salesDistrictList = [ALL_OBJ,...this.customerDataInit.districtData];
    this.categoryList = [ALL_OBJ_NOCODE,...this.customerDataInit.categoryData];
    if (!this.category.some(c => c.name == "All"))
    {
      this.categoryBinding = this.categoryBinding.filter(c => !this.selectedCategory.some(s => s.name == c.name));
    }
    else
    {
      this.categoryBinding = this.categoryList.slice(0, this.bufferSize).filter(c => !this.selectedCategory.some(s => s.name == c.name));
    }
    if (this.salesDistrict.some(c => c.name == "All"))
    {
      this.salesDistrictBinding = this.salesDistrictList;
    }

    this.sales_area_to_sales_district = this.customerDataInit.salesAreaTosalesDistrictData.sales_area_to_sales_district;

    this.channelLevel1List = [ALL_OBJ,...this.customerDataInit.channelLevel1Data];
    this.channelLevel2List = [ALL_OBJ,...this.customerDataInit.channelLevel2Data];
    this.channelLevel3List = [ALL_OBJ,...this.customerDataInit.channelLevel3Data];
    if (this.channelLev2.some(c => c.name == "All"))
    {
      this.channelLev2Binding = this.channelLevel2List;
    }

    if (this.channelLev3.some(c => c.name == "All"))
    {
      this.channelLev3Binding = this.channelLevel3List;
    }

    this.channelLevel1Name = this.customerDataInit.channelLevel1toLevel2toLevel3Data.channelLevel1List;
    this.channelLevel2Name = this.customerDataInit.channelLevel1toLevel2toLevel3Data.channelLevel2List;
    this.channelLevel3Name = this.customerDataInit.channelLevel1toLevel2toLevel3Data.channelLevel3List;

    this.groupchannelLevel2toLevel3 = this.customerDataInit.channelLevel1toLevel2toLevel3Data.groupchannelLevel2toLevel3;
    this.groupchannelLeve1toLevel2toLevel3 = this.customerDataInit.channelLevel1toLevel2toLevel3Data.groupchannelLeve1toLevel2toLevel3;

    this.accountLevel2List = [ALL_OBJ,...this.customerDataInit.accountLevel2Data];
    this.accountLevel3List = [ALL_OBJ,...this.customerDataInit.accountLevel3Data];

    if (!this.accountLevel2.some(p => p.name == "All"))
    {
      this.accountLevel2Binding = this.accountLevel2Binding.filter(p => !this.selectedAccountLevel2.some(s => s.name == p.name));
    }
    else
    {
      this.accountLevel2Binding = this.accountLevel2List.slice(0, this.bufferSize).filter(p => !this.selectedAccountLevel2.some(s => s.name == p.name));
    }

    if (!this.accountLevel3.some(p => p.name == "All"))
    {
      this.accountLevel3Binding = this.accountLevel3Binding.filter(p => !this.selectedAccountLevel3.some(s => s.name == p.name));
    }
    else
    {
      this.accountLevel3Binding = this.accountLevel3List.slice(0, this.bufferSize).filter(p => !this.selectedAccountLevel3.some(s => s.name == p.name));
    }
  }

  onRemoveFilterSalesArea($event) {
    if(this.salesArea.length == 0){
      this.salesArea = [ALL_OBJ];
      return;
    }  
  }

  onAddFilterSalesArea($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.salesArea = [ALL_OBJ];
      return;
    }
    this.salesArea = this.salesArea.filter(e => e.cd !== ALL_OBJ.cd);
  }

  setSalesArea()
  {
    this.salesArea = this.aggregatedSalesArea;
  }

  SalesAreaAggregate()
  {
    this.aggregatedSalesArea = this.salesArea;
    this.salesDistrict = [ALL_OBJ];
    this.salesDistrictBinding = this.salesDistrictList;
    this.aggregatedSalesDistrict = [ALL_OBJ];
    this.bindingSalesDistrictData();    
  }

  onCloseSalesArea()
  {
    this.salesArea = this.aggregatedSalesArea;
  }

  bindingSalesDistrictData()
  {
    if (!this.salesArea.some(x => x.name === "All"))
    {
      this.salesDistrict = [ALL_OBJ];
      this.salesDistrictBinding = [ALL_OBJ];
      this.salesArea.forEach(x => {
        var district_names = this.sales_area_to_sales_district[x.name];
        this.salesDistrictList.filter(d => district_names.includes(d.name)).forEach(s => {
          if (!this.salesDistrictBinding.some(x => x.name === s.name))
          {
            this.salesDistrictBinding.push(s);
          }          
        });       
      })
    }
  }

  onRemoveFilterSalesDistrict($event) {
    if(this.salesDistrict.length == 0){
      this.salesDistrict = [ALL_OBJ];      
      return;
    }
  }

  onAddFilterSalesDistrict($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.salesDistrict = [ALL_OBJ];
      return;
    }
    this.salesDistrict = this.salesDistrict.filter(e => e.cd !== ALL_OBJ.cd);
  }

  setSalesDistrict()
  {
      this.salesDistrict = this.aggregatedSalesDistrict;      
  }

  SalesDistrictAggregate()
  {
    this.aggregatedSalesDistrict = this.salesDistrict;
  }

  onCloseSalesDistrict()
  {
      this.salesDistrict = this.aggregatedSalesDistrict;   
  }

  GotoPageEvent()
  {
    var input = (<HTMLInputElement>document.getElementById("gotopagecustomer")).value.trim();
    if ((this.displayData && this.displayData.length > 0) && !input.match(/^[0]/) && !input.match(/[A-Za-z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/) && input.trim() != '')
    {
      var page_togo = (<HTMLInputElement>document.getElementById("gotopagecustomer")).value;
      if (Number.isNaN(parseInt(page_togo)))
      {
        return;
      } 
      var total_pages = Math.ceil(this.pagination.totalItem/this.pagination.sizeOnPage);
      if (parseInt(page_togo) > total_pages)
      {
        this.pageNumber = total_pages;
        this.pagination.current = total_pages;
      }
      else
      {
        this.pageNumber = parseInt(page_togo);
        this.pagination.current = parseInt(page_togo);
      }
      (<HTMLInputElement>document.getElementById("gotopagecustomer")).value = this.pageNumber.toString();
      if (this.groupByColumns.some(x => x.name == 'All'))
      {
        this.displayColumns = [];
      }
      this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: this.pageNumber, colName: this.current_sortcol, orderby: this.current_sortcol_order});
    }
    this.dataChange = true;
  }

  sort(colName) {
    var orderby = "";
    var color_descIcon = document.getElementById(colName + "DESCCTM").style.color;
    var color_ascIcon = document.getElementById(colName + "ASCCTM").style.color;

    if ((color_descIcon != "red" && color_ascIcon != "red") || color_descIcon == "red")
    {
      orderby = "ASC";
    }
    else if (color_ascIcon == "red")
    {
      orderby = "DESC";
    }

    this.current_sortcol = colName;
    this.current_sortcol_order = orderby;
    this.pagination.current = 1;
    (<HTMLInputElement>document.getElementById("gotopagecustomer")).value = "";
    if (this.groupByColumns.some(x => x.name == 'All'))
    {
      this.displayColumns = [];
    }
    this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: this.pageNumber, colName: this.current_sortcol, orderby: this.current_sortcol_order});   
    this.dataChange = true;
  }

  fetchMoreAccountLevel2(term) {
    if(term){
      const len = this.accountLevel2Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.accountLevel2List.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loadingAccountLevel2 = true;
      setTimeout(() => {
        this.loadingAccountLevel2 = false;
        let accountLevel2Filter = this.accountLevel2Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!accountLevel2Filter.includes(m))
          {
            accountLevel2Filter.push(m);
          }
        }); 
        this.accountLevel2Binding = accountLevel2Filter;
        this.accountLevel2Binding = this.accountLevel2Binding.filter(x=> !this.selectedAccountLevel2.includes(x));
      }, 200);
    }else{
      const len = this.accountLevel2Binding.length;
      const more = this.accountLevel2List.slice(len, this.bufferSize + len);
      this.loadingAccountLevel2 = true;
      setTimeout(() => {
        this.loadingAccountLevel2 = false;
        more.forEach(m => {
          if (!this.accountLevel2Binding.includes(m))
          {
            this.accountLevel2Binding.push(m);
          }
        })    
        this.accountLevel2Binding = this.accountLevel2Binding.filter(x=> !this.selectedAccountLevel2.includes(x));
      }, 200)
    }
  }

  searchAccountLevel2(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.accountLevel2List.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loadingAccountLevel2 = true;
      setTimeout(() => {
        this.loadingAccountLevel2 = false;
        this.accountLevel2Binding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        this.accountLevel2Binding = this.accountLevel2Binding.filter(x=> !this.selectedAccountLevel2.includes(x));
      }, 200)
    }
    else
    {
      AnalyticsHelper.hanldeCheckBoxSelect(event);
      this.accountLevel2Binding = this.accountLevel2List.slice(0, this.bufferSize).filter(x=> !this.selectedAccountLevel2.includes(x));
    }
  }

  onCloseAccountLevel2()
  {
    this.accountLevel2 = this.aggregatedAccountLevel2;
    this.accountLevel2Binding = this.accountLevel2List.slice(0,this.bufferSize);
    if (!this.accountLevel2.some(x => x.name == "All"))
    {
      this.selectedAccountLevel2 = [ALL_OBJ];
      this.accountLevel2.forEach(element => {
        if(!this.selectedAccountLevel2.includes(element)){
          this.selectedAccountLevel2.push(element);
        }
      });
    }
    this.accountLevel2Binding = this.accountLevel2Binding.filter(x=> !this.selectedAccountLevel2.some(s => s.name == x.name));
  }

  setAccountLevel2()
  {
    this.accountLevel2 = this.aggregatedAccountLevel2;
  }

  AccountLevel2Aggregate()
  {
    this.aggregatedAccountLevel2 = this.accountLevel2;
  }
  
  onRemoveFilterAccountLev2($event) {
    if(this.accountLevel2.length == 0){
      this.accountLevel2 = [ALL_OBJ];
    }
  }

  onAddFilterAccountLev2($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.accountLevel2 = [ALL_OBJ];
      return;
    }
    this.accountLevel2 = this.accountLevel2.filter(e => e.cd != ALL_OBJ.cd);
  }

  setAccountLevel3()
  {
    this.accountLevel3 = this.aggregatedAccountLevel3;
  }


  onRemoveFilterAccountLev3($event) {
    if(this.accountLevel3.length == 0){
      this.accountLevel3 = [ALL_OBJ];
    }
  }

  onAddFilterAccountLev3($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.accountLevel3 = [ALL_OBJ];
      return;
    }
    this.accountLevel3 = this.accountLevel3.filter(e => e.cd != ALL_OBJ.cd);
  }

  AccountLevel3Aggregate()
  {
    this.aggregatedAccountLevel3 = this.accountLevel3;
  }

  onCloseAccountLevel3()
  {
    this.accountLevel3 = this.aggregatedAccountLevel3;
    this.accountLevel3Binding = this.accountLevel3List.slice(0,this.bufferSize);
    if (!this.accountLevel3.some(x => x.name == "All"))
    {
      this.selectedAccountLevel3 = [ALL_OBJ];
      this.accountLevel3.forEach(element => {
        if(!this.selectedAccountLevel3.includes(element)){
          this.selectedAccountLevel3.push(element);
        }
      });
    }
    this.accountLevel3Binding = this.accountLevel3Binding.filter(x=> !this.selectedAccountLevel3.some(s => s.name == x.name));
  }

  fetchMoreAccountLevel3(term) {
    if(term){
      const len = this.accountLevel3Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.accountLevel3List.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loadingAccountLevel3 = true;
      setTimeout(() => {
        this.loadingAccountLevel3 = false;
        let accountLevel3Filter = this.accountLevel3Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!accountLevel3Filter.includes(m))
          {
            accountLevel3Filter.push(m);
          }
        }); 
        this.accountLevel3Binding = accountLevel3Filter;
        this.accountLevel3Binding = this.accountLevel3Binding.filter(x=> !this.selectedAccountLevel3.includes(x));
      }, 200);
    }else{
      const len = this.accountLevel3Binding.length;
      const more = this.accountLevel3List.slice(len, this.bufferSize + len);
      this.loadingAccountLevel3 = true;
      setTimeout(() => {
        this.loadingAccountLevel3 = false;
        more.forEach(m => {
          if (!this.accountLevel3Binding.includes(m))
          {
            this.accountLevel3Binding.push(m);
          }
        }) 
        this.accountLevel3Binding = this.accountLevel3Binding.filter(x=> !this.selectedAccountLevel3.includes(x));
      }, 200)
    }
  }

  searchAccountLevel3(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.accountLevel3List.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loadingAccountLevel3 = true;
      setTimeout(() => {
        this.loadingAccountLevel3 = false;
        this.accountLevel3Binding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        this.accountLevel3Binding = this.accountLevel3Binding.filter(x=> !this.selectedAccountLevel3.includes(x));
      }, 200)
    }
    else
    {
      AnalyticsHelper.hanldeCheckBoxSelect(event);
      this.accountLevel3Binding = this.accountLevel3List.slice(0, this.bufferSize).filter(x=> !this.selectedAccountLevel3.includes(x));
    }
  }

  onRemoveFilterchannelLev1($event) {
    if(this.channelLev1.length == 0){
      this.channelLev1 = [ALL_OBJ];
    }
  }
  onAddFilterchannelLev1($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.channelLev1 = [ALL_OBJ];
      return;
    }
    this.channelLev1 = this.channelLev1.filter(e => e.cd != ALL_OBJ.cd);
  }

  onCloseChannelLevel1()
  {
    this.channelLev1 = this.aggregatedChannelLevel1;
  }

  setChannelLevel1()
  {
    this.channelLev1 = this.aggregatedChannelLevel1;
  }

  channelLev1Aggregate()
  {
    this.aggregatedChannelLevel1 = this.channelLev1;
    this.aggregatedChannelLevel2 = [ALL_OBJ];
    this.aggregatedChannelLevel3 = [ALL_OBJ];
    this.channelLev2 = [ALL_OBJ];
    this.channelLev3 = [ALL_OBJ];
    this.bindingChannelLevel2ChannelLevel3();
  }

  bindingChannelLevel2ChannelLevel3()
  {
    if (this.channelLev1.some(c => c.name == "All"))
    {
      this.channelLev2Binding = this.channelLevel2List;
      this.channelLev3Binding = this.channelLevel3List;
    }
    else
    {
      this.channelLev2Binding = [ALL_OBJ];
      this.channelLev3Binding = [ALL_OBJ];
      this.channelLev1.forEach(c => {
        var channelLevel2_list = this.groupchannelLeve1toLevel2toLevel3[c.name]["channelLevel2"];
        channelLevel2_list.forEach(x => {
          var code = this.channelLevel2List.filter(c => c.name == x)[0].cd;
          if (!this.channelLev2Binding.some(c => c.name == x))
          {
            this.channelLev2Binding.push({cd: code, name: x});
          }                  
        })

        var channelLevel3_list = this.groupchannelLeve1toLevel2toLevel3[c.name]["channelLevel3"];
        channelLevel3_list.forEach(x => {
          var code = this.channelLevel3List.filter(c => c.name == x)[0].cd;
          if (!this.channelLev3Binding.some(c => c.name == x))
          {
            this.channelLev3Binding.push({cd: code, name: x});
          }                  
        })
      })
    }
  }

  setChannelLevel2()
  {
    this.channelLev2 = this.aggregatedChannelLevel2;
  }

  onCloseChannelLevel2()
  {
    this.channelLev2 = this.aggregatedChannelLevel2;
  }
  
  onRemoveFilterchannelLev2($event) {
    if(this.channelLev2.length == 0){
      this.channelLev2 = [ALL_OBJ];
    }
  }

  onAddFilterchannelLev2($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.channelLev2 = [ALL_OBJ];
      return;
    }
    this.channelLev2 = this.channelLev2.filter(e => e.cd != ALL_OBJ.cd);
  }

  channelLev2Aggregate()
  {    
    this.aggregatedChannelLevel2 = this.channelLev2;
    this.aggregatedChannelLevel3 = [ALL_OBJ];
    this.channelLev3 = [ALL_OBJ];
    this.bindingChannelLevel3();
  }

  bindingChannelLevel3()
  {
    if (this.channelLev2.some(c => c.name == "All"))
    {
      this.channelLev3Binding = [ALL_OBJ];
    }
    else
    {
      this.channelLev3Binding = [ALL_OBJ];
      this.channelLev2.forEach(c => {
        var channelLevel3_list = this.groupchannelLevel2toLevel3[c.name];
        channelLevel3_list.forEach(x => {
          var code = this.channelLevel3List.filter(c => c.name == x)[0].cd;
          if (!this.channelLev3Binding.some(c => c.name == x))
          {
            this.channelLev3Binding.push({cd: code, name: x});
          }                  
        })
      })
    }
  }

  onCloseChannelLevel3()
  {
    this.channelLev3 = this.aggregatedChannelLevel3;
  }

  channelLev3Aggregate()
  {
    this.aggregatedChannelLevel3 =  this.channelLev3;
  }

  setChannelLevel3()
  {
    this.channelLev3  = this.aggregatedChannelLevel3;
  }

  onRemoveFilterchannelLev3($event) {
    if(this.channelLev3.length == 0){
      this.channelLev3 = [ALL_OBJ];
    }
  }

  onAddFilterchannelLev3($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.channelLev3 = [ALL_OBJ];
      return;
    }
    this.channelLev3 = this.channelLev3.filter(e => e.cd != ALL_OBJ.cd);
  }

  onRemovegroupByColumns($event) {
    if(this.groupByColumns.length == 0){
      this.groupByColumns = [ALL_OBJ_NOCODE];
      return;
    }  
  }

  onAddgroupByColumns($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.groupByColumns = [ALL_OBJ_NOCODE];
      return;
    }
    this.groupByColumns = this.groupByColumns.filter(e => e.name !== ALL_OBJ_NOCODE.name);
  }

  setgroupByColumns()
  {
    this.groupByColumns = this.aggregatedgroupByColumns;
  }

  groupByColumnsAggregate()
  {
    this.aggregatedgroupByColumns = this.groupByColumns;   
  }

  onClosegroupByColumns()
  {
    this.groupByColumns = this.aggregatedgroupByColumns;
  }

  onCloseCategories(){
    this.category = this.categoryList.slice(0, this.bufferSize);
    this.category = this.aggregatedCategory;
    if (!this.category.some(x => x.name == "All"))
    {
      this.selectedCategory = [ALL_OBJ_NOCODE];
      this.category.forEach(element => {
        if(!this.selectedCategory.includes(element)){
          this.selectedCategory.push(element);
        }
      });
    }

    this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.includes(x));
  }

  searchCategories(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.categoryList.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loadingCategory = true;
      setTimeout(() => {
        this.loadingCategory = false;
        this.categoryBinding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        if (this.category.some(x => x.name == "All"))
        {
          this.selectedCategory = [];
        }
        this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.includes(x));
      }, 200)
    }
    else
    {
      AnalyticsHelper.hanldeCheckBoxSelect(event);
      this.categoryBinding = this.categoryList.slice(0, this.bufferSize).filter(x=> !this.selectedCategory.includes(x));
    }
  }

  fetchMoreCategories(term) {
    if(term){
      const len = this.categoryBinding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.categoryList.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loadingCategory = true;
      setTimeout(() => {
        this.loadingCategory = false;
        let categoryFilter = this.categoryBinding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!categoryFilter.includes(m))
          {
            categoryFilter.push(m);
          }
        });
        this.categoryBinding = categoryFilter;
        this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.includes(x));
      }, 200);
    }else{
      const len = this.categoryBinding.length;
      const more = this.categoryList.slice(len, this.bufferSize + len);
      this.loadingCategory = true;
      setTimeout(() => {
        this.loadingCategory = false;
        more.forEach(m => {
          if (!this.categoryBinding.includes(m))
          {
            this.categoryBinding.push(m);
          }
        }) 
        this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.includes(x));
      }, 200)
    }
  }

  onRemoveFilterCategory($event) {
    if(this.category.length == 0){
      this.category = [ALL_OBJ_NOCODE];
      return;
    }
    if ((this.category.length > 1 && this.category.some(b => b.name === "All")) || $event.cancelable == false || this.category.length > this.categoryBinding_previous.length)
    {
      return;
    }
    this.categoryBinding_previous = this.category;

  }

  onAddFilterCategory($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.category = [ALL_OBJ_NOCODE];
      return;
    }
    this.category = this.category.filter(e => e.name !== ALL_OBJ_NOCODE.name);
    this.categoryBinding_previous = this.category;
  }

  setCategory()
  {
    this.category = this.aggregatedCategory;   
  }

  CategoryAggregate()
  {
    this.aggregatedCategory = this.category;
  }

  searchCustomerData() {
    this.condition = "";
    if (this.salesArea[0].cd != ALL_OBJ.cd) {
      let salesAreaCond = ""
      this.salesArea.forEach(el => {
        salesAreaCond += "'" + el.cd.replace("'", "''") +"',";
      });
      salesAreaCond = salesAreaCond.slice(0, -1);
      this.condition += " AND SALES_AREA_CODE IN (" + salesAreaCond + ")";
    }

    // SALES_DISTRICT_CODE
    if (this.salesDistrict[0].cd != ALL_OBJ.cd) {
      let salesDistrictCond = ""
      this.salesDistrict.forEach(el => {
        salesDistrictCond += "'" + el.cd.replace("'", "''") +"',";
      });
      salesDistrictCond = salesDistrictCond.slice(0, -1);
      this.condition += " AND SALES_DISTRICT_CODE IN (" + salesDistrictCond + ")";
    }

    // CATEGORY_NAME
    if (this.category[0].name != ALL_OBJ_NOCODE.name) {
      let categoryCond = ""
      this.category.forEach(el => {
        categoryCond += "'" + el.name.replace("'", "''") +"',";
      });
      categoryCond = categoryCond.slice(0, -1);
      this.condition += " AND CATEGORY_NAME IN (" + categoryCond + ")";
    }

    // CHANNEL_LEV1_CODE
    if (this.channelLev1[0].cd != ALL_OBJ.cd) {
      let channelLev1Cond = ""
      this.channelLev1.forEach(el => {
        channelLev1Cond += "'" + el.cd.replace("'", "''") +"',";
      });
      channelLev1Cond = channelLev1Cond.slice(0, -1);
      this.condition += " AND CHANNEL_LEV1_CODE IN (" + channelLev1Cond + ")";
    }

    // CHANNEL_LEV2_CODE
    if (this.channelLev2[0].cd != ALL_OBJ.cd) {
      let channelLev2Cond = ""
      this.channelLev2.forEach(el => {
        channelLev2Cond += "'" + el.cd.replace("'", "''") +"',";
      });
      channelLev2Cond = channelLev2Cond.slice(0, -1);
      this.condition += " AND CHANNEL_LEV2_CODE IN (" + channelLev2Cond + ")";
    }

    // CHANNEL_LEV3_CODE
    if (this.channelLev3[0].cd != ALL_OBJ.cd) {
      let channelLev3Cond = ""
      this.channelLev3.forEach(el => {
        channelLev3Cond += "'" + el.cd.replace("'", "''") +"',";
      });
      channelLev3Cond = channelLev3Cond.slice(0, -1);
      this.condition += " AND CHANNEL_LEV3_CODE IN (" + channelLev3Cond + ")";
    }

    // ACCOUNT_LEV2_CODE
    if (this.accountLevel2[0].cd != ALL_OBJ.cd) {
      let accountLevel2Cond = ""
      this.accountLevel2.forEach(el => {
        accountLevel2Cond += "'" + el.cd.replace("'", "''") +"',";
      });
      accountLevel2Cond = accountLevel2Cond.slice(0, -1);
      this.condition += " AND ACCOUNT_LEV2_CODE IN (" + accountLevel2Cond + ")";
    }

    
    // ACCOUNT_LEV3_CODE
    if (this.accountLevel3[0].cd != ALL_OBJ.cd) {
      let accountLevel3Cond = ""
      this.accountLevel3.forEach(el => {
        accountLevel3Cond += "'" + el.cd.replace("'", "''") +"',";
      });
      accountLevel3Cond = accountLevel3Cond.slice(0, -1);
      this.condition += " AND ACCOUNT_LEV3_CODE IN (" + accountLevel3Cond + ")";
    }

    this.pageNumber = 1;
    this.pagination.current = 1;
    this.current_sortcol = "nsr_ty";
    this.current_sortcol_order = "DESC";
    (<HTMLInputElement>document.getElementById("gotopagecustomer")).value = "";
    if (this.groupByColumns.some(x => x.name != 'All'))
    {
      this.displayColumns = [];
      if (this.salesArea.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('SALES_AREA_NAME'))
        {
          this.displayColumns.push('SALES_AREA_NAME');
        }
      }
      if (this.salesDistrict.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('SALES_DISTRICT_NAME'))
        {
          this.displayColumns.push('SALES_DISTRICT_NAME');
        }
      }
      if (this.channelLev1.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('CHANNEL_LEV1_NAME'))
        {
          this.displayColumns.push('CHANNEL_LEV1_NAME');
        }
      }
      if (this.channelLev2.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('CHANNEL_LEV2_NAME'))
        {
          this.displayColumns.push('CHANNEL_LEV2_NAME');
        }
      }
      if (this.channelLev3.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('CHANNEL_LEV3_NAME'))
        {
          this.displayColumns.push('CHANNEL_LEV3_NAME');
        }
      }
      if (this.accountLevel2.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('ACCOUNT_LEV2_NAME'))
        {
          this.displayColumns.push('ACCOUNT_LEV2_NAME');
        }
      }
      if (this.accountLevel3.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('ACCOUNT_LEV3_NAME'))
        {
          this.displayColumns.push('ACCOUNT_LEV3_NAME');
        }
      }

      this.groupByColumns.forEach(x => {
        if (!this.displayColumns.includes(this.mapCustomerFilters[x.name]))
        {
          this.displayColumns.push(this.mapCustomerFilters[x.name]);
        }
      })
      this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: 1, isSearch: true});
    }    
    else
    {
      this.displayColumns = [];
      this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: 1, isSearch: true});
    }

    if (this.previousAggregateby != "" && this.previousAggregateby != this.aggregateBy)
    {
      this.dataChange = false;   
    }
    else
    {
      this.dataChange = true;  
    } 
  }

  handlePageChange(event) {
    this.pageNumber = event;
    this.pagination.current = event;
    if (this.groupByColumns.some(x => x.name == 'All'))
    {
      this.displayColumns = [];
    }
    this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: event, colName: this.current_sortcol, orderby: this.current_sortcol_order});
    this.dataChange = true;
  };

  formatNumber(number: any) {
    return AnalyticsHelper.formatNumber(number);
  }

  htmlTableToExcel(type){
    const data = document.createElement("table");
    data.innerHTML = document.getElementById('customerHeader').innerHTML + document.getElementById('customerId').innerHTML;
    // var data = document.getElementById('skuId');
    var wb = XLSX.utils.table_to_book(data, {sheet: "Sheet1"});
    var ws = wb.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(ws, [
    ], { origin: "A0" });
    var dateString = new Date().toLocaleString();
    XLSX.writeFile(wb, "Report_SalesByCustomer_" + dateString + ".xlsx");
  }
}
