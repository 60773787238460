import { Component, Renderer2, Inject, OnChanges, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import { IntageHelper as IntageHelperVM } from 'src/app/components/pages/intage-vm/intage_helper';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { NgxCaptureService } from 'ngx-capture';
import { downloadAsPDF as _downloadAsPDF } from './../../../../helpers/pdf';
import lodashClonedeep from 'lodash.clonedeep';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import html2canvas from 'src/html2canvas';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';

@Component({
  selector: 'intage-value-share-summary-flash',
  templateUrl: './value-share-summary-flash.component.html',
  styleUrls: ['./value-share-summary-flash.component.scss']
})
export class IntageValueShareSummaryFlashComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageVM: any;
  @Input() intageMonth: any;
  // FPT-ChienNX7 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  @ViewChild('capture', { static: true }) screen: any;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  categoryColors: any;
  makerLogo: any;
  makers: any;
  summaryData: any;
  selectedSummaryMaker: any;
  summaryDisplay: string;
  summaryRegions: any;
  summarySelectedRegion: any;
  summaryPackageTypes: any;
  populated: boolean;
  captureImage: string;
  weeks: any;
  categoriesVM: any;
  makersVM: any;
  diff_ly_VM: any;
  aggregateBy: any;
  chartId = "summary-value-share"

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient,
    private captureService: NgxCaptureService, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(!this.populated){
      this.summaryDisplay = 'vsLy';
      this.summarySelectedRegion = ['CCBJI Territory'];
      this.categoryColors = IntageHelper.CategoryColors();
      this.makerLogo = IntageHelper.MakerLogos();
      this.selectedSummaryMaker = [this.makerLogo[0].maker];
      this.summaryData = [];
      this.populated = true;
    }
    if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
      let commonData = IntageHelper.PopulateCommonData(this.intage, this.selectedWeek);
      this.makers = commonData.makers;
      this.summaryPackageTypes = commonData.summaryPackageTypes;
      this.summaryRegions = commonData.summaryRegions;
      this.handleSummary();
    }
    if (this.intageVM != null && this.selectedWeek != null && this.intageMonth != null)
    {
      let commonData = IntageHelperVM.PopulateCommonData(this.intageVM, this.selectedWeek);
      this.categoriesVM = [];
      commonData.categories.forEach(e => {
        let categoryIdx = this.categoryColors.find(x => x.category === e.category)?.index;
        this.categoriesVM.push({category: e.category, index: categoryIdx || 100});
      });
      this.categoriesVM.sort((a, b) => {
        if (a.index < b.index){
          return -1;
        }
        if (a.index > b.index){
          return 1;
        }
          return 0;
      });
      this.makersVM = commonData.makers;
      this.handleDiffLyVM();
    }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  onChangeSummaryMaker(){
    if(this.selectedSummaryMaker.length == 0){
      this.selectedSummaryMaker = ['COCA-COLA'];
      this.SaveOptionValueTemp(false);
      this.onFilterBySummaryMakerChange()
    }
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["summarySelectedRegion"] = [...this.summarySelectedRegion];
      window["selectedSummaryMaker"] = [...this.selectedSummaryMaker];
    } else {
      if (window["summarySelectedRegion"] != null) { this.summarySelectedRegion = window["summarySelectedRegion"]; }
      if (window["selectedSummaryMaker"] != null) { this.selectedSummaryMaker = window["selectedSummaryMaker"]; }
    }
  } 
  onRemoveFilterSummaryRegion($event) {
    if(this.summarySelectedRegion.length == 0){
      this.summarySelectedRegion = ['CCBJI Territory'];
      this.SaveOptionValueTemp(false);
      this.onFilterBySummaryRegionChange()
    }
    if ($event.value === 'CCBJI Territory') {
      this.summarySelectedRegion = [];
      return;
    }
  }
  onAddFilterSummaryRegion($event) {
    if ($event === 'CCBJI Territory') {
      this.summarySelectedRegion = ['CCBJI Territory'];
      return;
    }
    if(this.summarySelectedRegion.filter(x=> x!='CCBJI Territory').length == (this.summaryRegions.length-1)){
      this.summarySelectedRegion = ['CCBJI Territory'];
    }else{
      this.summarySelectedRegion = this.summarySelectedRegion.filter(e => e !== 'CCBJI Territory');;
    }
  }
  
  onScoreCardAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleSummary();
    this.handleDiffLyVM();
  }

  onResize(){
    if (this.intage != null && this.intageVM != null && this.selectedWeek != null && this.intageMonth != null)
    {
      this.handleSummary();
      this.handleDiffLyVM();
    }
  }

  async handleSummary(){
    switch (this.aggregateBy) {
      case 'mtd':
        var month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
        var weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();
        break;
      case 'qtd':
        var quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        var weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();    
        break;
      case 'ytd':
        var year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        var weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();
        break;
      default:
        weeks = [this.selectedWeek];
        this.handleIntageData(weeks);
        break;
    }
  }

  public async firstcheckIntageAllData()
  {
    var intage = await IndexDbHelper.Get('IntageFlashAllData'); 
    if (intage != null)
    {
      this.intage = intage;
      this.handleIntageData(window["weeks"]);
    }
    else
    {
      this.checkIntageFlashAllData();
    }
  }

  public async checkIntageFlashAllData()
  {
    setTimeout(async () => {
      var intage = await IndexDbHelper.Get('IntageFlashAllData');  
      if (intage != null)
      {
        this.intage = intage;
        this.handleIntageData(window["weeks"]);
      }
      else
      {
        this.checkIntageFlashAllData();
      }
    }, 200);
  }

  public handleIntageData(weeks: any)
  {
    var data = this.intage.filter(x=> weeks.includes(x.week));
    let totals = [{
      channel: 'OTC Total',
      category: 'Total',
      ty: 0,
      ly: 0
    }];
    for(var i=0;i<data.length;i++){
      let row = data[i];
      var summaryRegion = this.summaryRegions.find(x=> x == row.region);
      if(summaryRegion == null){
        this.summaryRegions.push(row.region);
      }

      if(row.packageType == ''){
        continue;
      }
      var summaryPackageType = this.summaryPackageTypes.find(x=> x == row.packageType);
      if(summaryPackageType == null){
        this.summaryPackageTypes.push(row.packageType);
      }
    }
    for(var i=0;i<data.length;i++){
      let row = data[i];
      if(!this.summarySelectedRegion.includes('CCBJI Territory') && !this.summarySelectedRegion.includes(row.region)){
        continue;
      }
      let total = totals.find(x=> x.channel == row.channel && x.category == row.category);
      if(total == null){
        total = {
          channel: row.channel,
          category: row.category,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(total);
      }else{
        total.ty +=parseFloat(row.ty);
        total.ly +=parseFloat(row.ly);
      }
      let categoryTotal = totals.find(x=> x.channel == row.channel && x.category == 'Total');
      if(categoryTotal == null){
        categoryTotal = {
          channel: row.channel,
          category: 'Total',
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(categoryTotal);
      }else{
        categoryTotal.ty +=parseFloat(row.ty);
        categoryTotal.ly +=parseFloat(row.ly);
      }
      let channelTotal = totals.find(x=> x.channel == 'OTC Total' && x.category == row.category);
      if(channelTotal == null){
        channelTotal = {
          channel: 'OTC Total',
          category: row.category,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(channelTotal);
      }else{
        channelTotal.ty +=parseFloat(row.ty);
        channelTotal.ly +=parseFloat(row.ly);
      }
      totals[0].ty +=parseFloat(row.ty);
      totals[0].ly +=parseFloat(row.ly);
    }

    this.summaryData = {
      channels: [
        {
          channel: 'SMDD',
          ty: 0,
          ly: 0,
          totalTy:0,
          totalLy:0,
          tyVolume:0,
          packageTypes: [
            {
              packageType: 'SS',
              ty: 0,
              ly: 0,
              totalTy:0,
              totalLy:0,
              tyVolume:0,
              categories: []
            },
            {
              packageType: 'MS',
              ty: 0,
              ly: 0,
              totalTy:0,
              totalLy:0,
              tyVolume:0,
              categories: []
            }
          ]
        },
        {
          channel: 'CVS',
          ty: 0,
          ly: 0,
          totalTy:0,
          totalLy:0,
          tyVolume:0,
          packageTypes: [
            {
              packageType: 'SS',
              ty: 0,
              ly: 0,
              totalTy:0,
              totalLy:0,
              tyVolume:0,
              categories: []
            },
            {
              packageType: 'MS',
              ty: 0,
              ly: 0,
              totalTy:0,
              totalLy:0,
              tyVolume:0,
              categories: []
            }
          ]
        },
        {
          channel: 'OTC Total',
          ty: 0,
          ly: 0,
          totalTy:0,
          totalLy:0,
          tyVolume:0,
          packageTypes: [
            {
              packageType: 'SS',
              ty: 0,
              ly: 0,
              totalTy:0,
              totalLy:0,
              tyVolume:0,
              categories: []
            },
            {
              packageType: 'MS',
              ty: 0,
              ly: 0,
              totalTy:0,
              totalLy:0,
              tyVolume:0,
              categories: []
            }
          ]
        }
      ]
    };

    let smddChannels = ["SM","Drug","Discounter"];
    for(var i=0;i<data.length;i++){
      let row = data[i];
      if(!this.selectedSummaryMaker.includes(row.maker)){
        continue;
      }
      if(!this.summarySelectedRegion.includes('CCBJI Territory') && !this.summarySelectedRegion.includes(row.region)){
        continue;
      }
      // if(row.channel == 'Other OTC'){
      //   continue;
      // }
      if (row.channel != 'Other OTC')
      {
        var channel = smddChannels.includes(row.channel) ? this.summaryData.channels[0] : this.summaryData.channels[1];
      }
      else
      {
        var channel = null;
      }

      if (channel != null && channel.channel == 'CVS')
      {
        var packageType = channel.packageTypes.find(x=> x.packageType == row.packageType && row.channel == 'CVS');
      }
      else if (channel != null && channel.channel == 'SMDD')
      {
        var packageType = channel.packageTypes.find(x=> x.packageType == row.packageType && smddChannels.some(channel => channel == row.channel));
      }
      else
      {
        var packageType = null;
      }

      if(packageType != null){
          let category = packageType.categories.find(x=> x.category == row.category);
          if(category == null){
            category = {
              category: row.category,
              ty: parseFloat(row.ty),
              ly: parseFloat(row.ly),
              totalTy: 0,
              totalLy:0,
              tyVolume:parseFloat(row.tyVolume),
            }
            packageType.categories.push(category);
          }
          else{
            category.ty += parseFloat(row.ty);
            category.ly += parseFloat(row.ly);
            category.tyVolume += parseFloat(row.tyVolume);
          }      
      }
      let totalChannel = this.summaryData.channels[2];
      let totalPackageType = totalChannel.packageTypes.find(x=> x.packageType == row.packageType);
      if(totalPackageType != null){
        let totalCategory = totalPackageType.categories.find(x=> x.category == row.category);
        if(totalCategory == null){
          totalCategory = {
            category: row.category,
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            totalTy: 0,
            totalLy:0,
            tyVolume:parseFloat(row.tyVolume),
          }
          totalPackageType.categories.push(totalCategory);
        }
        else{
          totalCategory.ty += parseFloat(row.ty);
          totalCategory.ly += parseFloat(row.ly);
          totalCategory.tyVolume += parseFloat(row.tyVolume);
        }
      }
      if(packageType != null){
        packageType.ty += parseFloat(row.ty);
        packageType.ly += parseFloat(row.ly);
        packageType.tyVolume += parseFloat(row.tyVolume);
      }
      if (channel != null)
      {
        channel.ty += parseFloat(row.ty);
        channel.ly += parseFloat(row.ly);
        channel.tyVolume += parseFloat(row.tyVolume);
      }
      if(totalPackageType != null){
        totalPackageType.ty += parseFloat(row.ty);
        totalPackageType.ly += parseFloat(row.ly);
        totalPackageType.tyVolume += parseFloat(row.tyVolume);
      }
      totalChannel.ty += parseFloat(row.ty);
      totalChannel.ly += parseFloat(row.ly);
      totalChannel.tyVolume += parseFloat(row.tyVolume);
    }

    let smddTotals = totals.filter(x=> smddChannels.includes(x.channel) && x.category == 'Total');
    let smddChannel = this.summaryData.channels[0];
    for(var i=0;i<smddTotals.length;i++){
      smddChannel.totalTy += smddTotals[i].ty;
      smddChannel.totalLy += smddTotals[i].ly;
    }
    smddChannel.share = smddChannel.totalTy == 0 ? 0.0 : (smddChannel.ty / smddChannel.totalTy) * 100;
    smddChannel.lyShare = smddChannel.totalLy == 0 ? 0.0 : (smddChannel.ly / smddChannel.totalLy) * 100;
    smddChannel.vsLy = (smddChannel.share - smddChannel.lyShare).toFixed(1);
    for(var i=0;i<smddChannel.packageTypes.length;i++){
      let packageType = smddChannel.packageTypes[i];
      packageType.totalTy = smddChannel.totalTy;
      packageType.totalLy = smddChannel.totalLy;
      packageType.share = packageType.totalTy == 0 ? null : (packageType.ty / packageType.totalTy) * 100 + '';
      packageType.lyShare = packageType.totalLy == 0 ? null : (packageType.ly / packageType.totalLy) * 100 + '';
      packageType.vsLy = (packageType.share == null || packageType.lyShare == null) ? '-' : (parseFloat(packageType.share) - parseFloat(packageType.lyShare)).toFixed(1);

      for(var j=0;j<packageType.categories.length;j++){
        let category = packageType.categories[j];
        let totalList = totals.filter(x=> smddChannels.includes(x.channel) && x.category == category.category);
        for(var k=0;k<totalList.length;k++){
          category.totalTy += totalList[k].ty;
          category.totalLy += totalList[k].ly;
        }

        category.share = category.totalTy == 0 ? null : (category.ty / category.totalTy) * 100 + '';
        category.lyShare = category.totalLy == 0 ? null : (category.ly / category.totalLy) * 100 + '';
        category.vsLy = (category.share == null || category.lyShare == null) ? '-' : (parseFloat(category.share) - parseFloat(category.lyShare)).toFixed(1);
      }
    }

    for(var i=1;i<this.summaryData.channels.length;i++){
      let channel = this.summaryData.channels[i];
      let totalChannel = totals.find(x=> x.category == 'Total' && x.channel == channel.channel);
      channel.totalTy = totalChannel.ty;
      channel.totalLy = totalChannel.ly;
      channel.share = channel.totalTy == 0 ? 0.0 : (channel.ty / channel.totalTy) * 100;
      channel.lyShare = channel.totalLy == 0 ? 0.0 : (channel.ly / channel.totalLy) * 100;
      channel.vsLy = (channel.share - channel.lyShare).toFixed(1);

      for(var j=0;j<channel.packageTypes.length;j++){
        let packageType = channel.packageTypes[j];
        let totalPackageType = totals.find(x=> x.category == 'Total' && x.channel == channel.channel);
        packageType.totalTy = totalPackageType.ty;
        packageType.totalLy = totalPackageType.ly;
        packageType.share = packageType.totalTy == 0 ? null : (packageType.ty / packageType.totalTy) * 100;
        packageType.lyShare = packageType.totalLy == 0 ? null : (packageType.ly / packageType.totalLy) * 100;
        packageType.vsLy = (packageType.share == null || packageType.lyShare == null) ? '-' : (packageType.share - packageType.lyShare).toFixed(1);

        for(var k=0;k<packageType.categories.length;k++){
          let category = packageType.categories[k];
          let total = totals.find(x=> x.category == category.category && x.channel == channel.channel);
          category.totalTy = total.ty;
          category.totalLy = total.ly;
          category.share = category.totalTy == 0 ? null : (category.ty / category.totalTy) * 100 + '';
          category.lyShare = category.totalLy == 0 ? null : (category.ly / category.totalLy) * 100 + '';
          category.vsLy = (category.share == null || category.lyShare == null) ? '-' : (parseFloat(category.share) - parseFloat(category.lyShare)).toFixed(1);
        }
      }
    }

    this.summaryData = this.summaryData.channels;
    let t= this;
    setTimeout(() => {
      t.ready.emit(true);
    });
    this.SaveOptionValueTemp(false);
    // setTimeout(() => {
    //   let lines = document.querySelectorAll('.level-4-wrapper');
    //   for(let i=0;i<lines.length;i++){
    //     let line = lines[i];
    //     let firstHeight= (line.childNodes[0] as HTMLElement).clientHeight;
    //     if(line.childNodes.length == 1){
    //       firstHeight = firstHeight/2 -20;
    //     }
    //     let secondHeight = line.childNodes.length ==1 ? 0 : (line.childNodes[1] as HTMLElement).clientHeight/2;
    //     let targetHeight = firstHeight + secondHeight + 45;
    //     let style = document.createElement('style');
    //     style.textContent = '#' + line.id + '::before{height: ' + targetHeight + 'px;}';
    //     document.head.appendChild(style);
    //   }
    // }, 1000);
  }
  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }
  public onFilterBySummaryMakerChange(){
    if(this.selectedSummaryMaker.length==0){
      this.selectedSummaryMaker = ['COCA-COLA'];
    }
    this.handleSummary();
    this.handleDiffLyVM();
  }

  public onSummaryDisplayChange(summaryDisplay: string){
    this.summaryDisplay = summaryDisplay;
    this.handleSummary();
  }
  public onFilterBySummaryRegionChange(){
    if(this.summarySelectedRegion.length==0){
      this.summarySelectedRegion = ['CCBJI Territory'];
    }
    this.handleSummary();
  }

  public getSummaryPts(channel: string, packageType: string = null){
    let currentChannel = this.summaryData.find(x=> x.channel == channel);
    if(currentChannel == null){
      return "-";
    }
    if(packageType == null){
      if(this.summaryDisplay == 'vsLy'){
        return (currentChannel.vsLy.indexOf("-") != -1 ? currentChannel.vsLy : '+' + currentChannel.vsLy) + 'pts';
      }
      else if(this.summaryDisplay == 'TY_Value'){
        return Math.round(currentChannel.ty).toLocaleString();
      }
      else if(this.summaryDisplay == 'TY_Volume'){
        return Math.round(currentChannel.tyVolume).toLocaleString();
      }
    }
    let currentPackageType = currentChannel.packageTypes.find(x=> x.packageType == packageType);
    if(currentPackageType == null){
      return "-";
    }
    if(this.summaryDisplay == 'vsLy'){
      return (currentPackageType.vsLy.indexOf("-") != -1 ? currentPackageType.vsLy : '+' + currentPackageType.vsLy) + 'pts';
    }
    else if(this.summaryDisplay == 'TY_Value'){
      return Math.round(currentPackageType.ty).toLocaleString();
    }
    else if(this.summaryDisplay == 'TY_Volume'){
      return Math.round(currentPackageType.tyVolume).toLocaleString();
    }
  }
  
  public getSummaryPtsVM(){
    if (this.diff_ly_VM != null)
    {
      return  (this.diff_ly_VM.indexOf("-") == 0 ? this.diff_ly_VM : '+' + this.diff_ly_VM) + 'pts';
    }
    else
    {
      return "N/A";
    }    
  }

  public handleDiffLyVM(){
    let intage = lodashClonedeep(this.intageVM);

    switch (this.aggregateBy) {
      case 'mtd':
        var month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
        var weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];
        var data = intage.filter(x=> weeks.includes(x.week) && x.botter_sci == 'CCBJI');
        break;
      case 'qtd':
        var quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        var weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))];
        var data = intage.filter(x=> weeks.includes(x.week) && x.botter_sci == 'CCBJI');        
        break;
      case 'ytd':
        var year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        var weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
        var data = intage.filter(x=> weeks.includes(x.week) && x.botter_sci == 'CCBJI');  
        break;
      default:
        var data = intage.filter(x=> x.week == this.selectedWeek && x.botter_sci == 'CCBJI');
        break;
    }

    data.map(x => {
      if (x.maker == 'KO')
      {
        x.maker = 'COCA-COLA';
      }
      if (x.maker == 'Others')
      {
        x.maker = 'Other';
      }
      x.maker = x.maker.toUpperCase();
    });

    this.makersVM.map(x => {
      if (x.maker == 'KO')
      {
        x.maker = 'COCA-COLA';
      }
    })
    var market_size_total_category_tyvalue = 0;
    var market_size_total_category_lyvalue = 0;
    data.forEach(x => {
      market_size_total_category_tyvalue += parseFloat(x.tyvalue);
      market_size_total_category_lyvalue += parseFloat(x.lyvalue);
    });

      var ty_value = 0;
      var ly_value = 0;
      var maker_filter = data.filter(x => x.maker == this.selectedSummaryMaker[0]); 
      maker_filter.forEach(x => {
        ty_value += parseFloat(x.tyvalue);
        ly_value += parseFloat(x.lyvalue);
      });
      if (maker_filter.length == 0)
      {
        ty_value = null;
        ly_value = null;
      }
      let share_ty_norounding =  (ty_value == null || market_size_total_category_tyvalue == 0) ? null : (ty_value / market_size_total_category_tyvalue * 100);
      let share_ly_norounding = (ly_value == null || market_size_total_category_lyvalue == 0) ? null : (ly_value / market_size_total_category_lyvalue * 100);
      let diff_ly = (share_ty_norounding == null || share_ly_norounding == null) ? null : (share_ty_norounding - share_ly_norounding).toFixed(1);
      this.diff_ly_VM = diff_ly; 
  }

  // FPT-ChienNX7 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  public exportSummary(isImage: boolean) {
    const fileName = 'Summary - Value Share';
    if (isImage) {
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      for (var i = 0; i < this.summaryData.length; i++) {
        let channel = this.summaryData[i];
        data.push({
          display: this.summaryDisplay,
          region: this.summarySelectedRegion,
          maker: JSON.stringify(this.selectedSummaryMaker),
          channel: channel.channel,
          ty: channel.ty,
          tyShare: channel.share,
          ly: channel.ly,
          lyShare: channel.lyShare,
          packageType: 'Total',
          category: 'Total',
          totalTy: channel.totalTy,
          totalLy: channel.totalLy,
          vsLy: channel.vsLy
        });
        for (var j = 0; j < channel.packageTypes.length; j++) {
          let packageType = channel.packageTypes[j];
          data.push({
            display: this.summaryDisplay,
            region: this.summarySelectedRegion,
            maker: JSON.stringify(this.selectedSummaryMaker),
            channel: channel.channel,
            ty: packageType.ty,
            tyShare: packageType.share,
            ly: packageType.ly,
            lyShare: packageType.lyShare,
            packageType: packageType.packageType,
            category: 'Total',
            totalTy: packageType.totalTy,
            totalLy: packageType.totalLy,
            vsLy: packageType.vsLy
          });
          for (var k = 0; k < packageType.categories.length; k++) {
            var category = packageType.categories[k];
            data.push({
              display: this.summaryDisplay,
              region: this.summarySelectedRegion,
              maker: JSON.stringify(this.selectedSummaryMaker),
              channel: channel.channel,
              ty: category.ty,
              tyShare: category.share,
              ly: category.ly,
              lyShare: category.lyShare,
              packageType: packageType.packageType,
              category: category.category,
              totalTy: category.totalTy,
              totalLy: category.totalLy,
              vsLy: category.vsLy
            });
          }
        }
      }
      if (this.summaryDisplay == "vsLy")
      {
        new AngularCsv(data, fileName, {
          showLabels: true,
          headers: ["Display", "Region", "Maker", "Channel", "TY", "TY_Share", "LY", "LY_Share", "Package Type", "Category", "Total_TY", "Total_LY", "Diff_LY"],
        });
      }
      else if (this.summaryDisplay == "TY_Value")
      {
        new AngularCsv(data, fileName, {
          showLabels: true,
          headers: ["Display", "Region", "Maker", "Channel", "TY_Value", "TY_Share_Value", "LY_Value", "LY_Share_Value", "Package Type", "Category", "Total_TY_Value", "Total_LY_Value", "Diff_LY_Value"],
        });
      }
      else 
      {
        new AngularCsv(data, fileName, {
          showLabels: true,
          headers: ["Display", "Region", "Maker", "Channel", "TY_Volume", "TY_Share_Volume", "LY_Volume", "LY_Share_Volume", "Package Type", "Category", "Total_TY_Volume", "Total_LY_Volume", "Diff_LY_Volume"],
        });
      }

    }
    this.showOptionDownload();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-value-share');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  @ViewChild('pdf_print_area_intage_flash_summary', { static: false }) pdf_print_area_intage_flash_summary: ElementRef;
  public downloadAsPDF(name) {
    return _downloadAsPDF(name, this.pdf_print_area_intage_flash_summary.nativeElement)
  }


}
