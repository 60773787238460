// sales-date.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SalesDateService {
  private dateInJobClearCache = new BehaviorSubject<string[]>([]);

  constructor(private http: HttpClient) {}

  setSalesDate(value: string[]) {
    return this.dateInJobClearCache.next(value);
  }

  getSalesDate() {
    return this.dateInJobClearCache.asObservable();
  }

  getDateInJobClearCache() {
    let queryUrl = environment.hygraphGetSEJobUpdate;
    let httpOptions: Object = {
    headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
    responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions)
    .subscribe(
        response => {
            let res = JSON.parse(response);
            if (res.data && res.data[environment.clearCacheSEJobStatus] != null && res.data[environment.clearCacheSEJobStatus].length > 0) {
              const latest_date = res.data[environment.clearCacheSEJobStatus][0].date;
              const year = latest_date.substring(0, 4) || '';
              const month = latest_date.substring(4, 6) || '';
              const day = latest_date.substring(6, 8) || '';
              localStorage.setItem('se.dateinjobclearcache', `${year}-${month}-${day}`);
              this.setSalesDate([`${year}-${month}-${day}`]);
            } else {
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              const year  = yesterday.getFullYear();
              const month = (yesterday.getMonth() + 1).toString().padStart(2, '0');
              const day  = yesterday.getDate().toString().padStart(2, '0');
              localStorage.setItem('se.dateinjobclearcache', `${year}-${month}-${day}`);
              this.setSalesDate([`${year}-${month}-${day}`]);
            } 
        },
        error => 
        {
            console.log(error);                        
        }
    );
  }
}
