<ng-container *ngIf="!oldDomainRedirect">
  <ng-container *ngIf="!standalone">
    <!-- -->

    <!-- TO-DO: Make this a component -->
    <div class="top-sticky">
      <div class="notification-bar">
        <span id="last-updated">
          <ng-icon name="ionCalendarClearOutline" size="16px" strokeWidth="46px"></ng-icon>&nbsp;&nbsp;<b>Last
            update:</b>&nbsp;<span>{{lastUpdated}}</span>
        </span>
      </div>
      <app-header class="app-header"></app-header>
    </div>

    <!-- TO-DO: What is this? -->
    <div class="notification-container"></div>

    <div class="container-fluid">
      <div class="row" style="height: 100%;">

        <nav id="sidebarMenu" class="sidebarMenu col-lg-3 d-lg-block sidebar collapse">
          <app-sidebar></app-sidebar>
        </nav>

        <main id="main" class="col-lg-9 p-4">
          <router-outlet (activate)="intageCheckLastUpdate()"></router-outlet>
          <app-footer></app-footer>
        </main>

      </div>

      <div class="overlay sidebarMenu d-lg-block collapse" data-bs-toggle="collapse" data-bs-target=".sidebarMenu"></div>

    </div>
    <!-- -->
  </ng-container>

  <ng-container *ngIf="standalone">
    <router-outlet *ngIf="!isloginredirect"></router-outlet>
    <app-login [message]="message" *ngIf="isloginredirect"></app-login>
  </ng-container>
</ng-container>