<div class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center sanky-header">
                <h3>Sankey Summary</h3>
            </div>
            <div class="filter-section no-title filter-sankey">
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Week to Date'}, {value: 'mtd', name: 'Month to Date'}, {value: 'qtd', name: 'Quarter to Date'}, {value: 'ytd', name: 'Year to Date'}]"
                    [model]="aggregateBy"
                    (change)="onSankeyAggregateChange($event)">
                </single-dropdown>
            </div>
            <div class="filter-section filter-sankey">
                <div class="ng-section summary-section select-key">
                    <b>Select Key</b>
                    <ng-select #ngFiltersankey class="form-control filter-by-category option-sankey cases-select" [items]="sankeyCases"
                        [multiple]="true" [closeOnSelect]="false" bindLabel="name" bindValue="key"
                        [(ngModel)]="selectedSankeyCases" 
                        (open)="onOpenPopup();onChangeCheckBox($event)"
                        (close)="onClosePopup()"
                        (change)="onRemoveCaseSankey();onChangeCheckBox($event)"
                        (blur)="SaveOptionValueTemp(true)">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input type="checkbox" style="pointer-events: none"/> {{item.name}}
                        </ng-template>
                        <ng-template ng-footer-tmp>
                                <button type="button" class="btn btn-primary" [disabled]="selectedSankeyCases == null || selectedSankeyCases.length == 1"
                                (click)="SaveOptionValueTemp(false);ngFiltersankey.close();onFilterSankeyCaseChange(true)">Aggregate</button>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="card-body sankey-chart" style="overflow-x: scroll">
                <div #screen>
                    <ng-container *ngIf="sankeyData">
                        <google-chart #chart title="" type="Sankey" [data]="sankeyData" [width]="sankeyWidth" [columns]="sankeycolumnNames"
                            [options]="sankeyOptions" height="700" (window:resize)="onResize()">
                        </google-chart>
                    </ng-container>
                    <ng-container *ngIf="!sankeyData">
                        <div class="no-data-container sankey-chart-no-data">
                            <span class="no-data-text">No data available for this combination. Please refine your selection</span>
                        </div>
                    </ng-container>                    
                </div>
            </div>
            <div class="d-flex flex-row-reverse mt-4 hide-pdf-print">
                <download-button>
                    <button ngbDropdownItem (click)="exportSankeySummary(true)">Download PNG</button>
                    <button ngbDropdownItem (click)="exportSankeySummary(false)">Download CSV</button>
                </download-button>
            </div>
            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>