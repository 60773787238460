import { Renderer2 } from "@angular/core";
import Swal from 'sweetalert2';
import { dispatchNgSelectChange } from "./tracking";
export class AnalyticsHelper {
    static loadScript(renderer: Renderer2, document: Document) {
        let script = renderer.createElement('script');
        script.type = 'application/javascript';
        script.src = '/assets/js/analytics-data.js?v=' + this.random(10);
        renderer.appendChild(document.body, script);
    }
    static formatNumber(number: any) {
        let no = parseFloat(parseFloat(number).toFixed(2));
        let parts = no.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    }
    static transform(data: any, key: string, time: string, group: string, subGroup: string, date: string, isUseChannel: boolean = false, channelGroup: string = "", round: boolean = true) {
        if (time != 'ytd') {
            if (isUseChannel) {
                if (round) {
                    return {
                        act: Math.round(AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].act)),
                        bp: Math.round(AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].bp)),
                        ly: Math.round(AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].ly)),
                        vsLy: AnalyticsHelper.percent(AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].act), AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].ly)),
                        vsBp: AnalyticsHelper.percent(AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].act), AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].bp))
                    }
                } else {
                    return {
                        act: AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].act),
                        bp: AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].bp),
                        ly: AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].ly),
                        vsLy: AnalyticsHelper.percent(AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].act), AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].ly)),
                        vsBp: AnalyticsHelper.percent(AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].act), AnalyticsHelper.getValue((Object.values(data[key][group][time])[0] as any).channels[subGroup][channelGroup].bp))
                    }
                }
            }
            if (round) {
                return {
                    act: Math.round(AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].act)),
                    bp: Math.round(AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].bp)),
                    ly: Math.round(AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].ly)),
                    vsLy: AnalyticsHelper.percent(AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].act), AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].ly)),
                    vsBp: AnalyticsHelper.percent(AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].act), AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].bp))
                }
            } else {
                return {
                    act: AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].act),
                    bp: AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].bp),
                    ly: AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].ly),
                    vsLy: AnalyticsHelper.percent(AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].act), AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].ly)),
                    vsBp: AnalyticsHelper.percent(AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].act), AnalyticsHelper.getValue(Object.values(data[key][group][time])[0][subGroup].bp))
                }
            }
        } else {
            if (isUseChannel) {
                if (round) {
                    return {
                        act: Math.round(AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].act))),
                        bp: Math.round(AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].bp))),
                        ly: Math.round(AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].ly))),
                        vsLy: AnalyticsHelper.percent(AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].act)), AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].ly))),
                        vsBp: AnalyticsHelper.percent(AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].act)), AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].bp)))
                    }
                } else {
                    return {
                        act: AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].act)),
                        bp: AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].bp)),
                        ly: AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].ly)),
                        vsLy: AnalyticsHelper.percent(AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].act)), AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].ly))),
                        vsBp: AnalyticsHelper.percent(AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].act)), AnalyticsHelper.getValue((data[key][group][time].channels[subGroup][channelGroup].bp)))
                    }
                }
            }
            if (round) {
                return {
                    act: Math.round(AnalyticsHelper.getValue(data[key][group][time][subGroup].act)),
                    bp: Math.round(AnalyticsHelper.getValue(data[key][group][time][subGroup].bp)),
                    ly: Math.round(AnalyticsHelper.getValue(data[key][group][time][subGroup].ly)),
                    vsLy: AnalyticsHelper.percent(AnalyticsHelper.getValue(data[key][group][time][subGroup].act), AnalyticsHelper.getValue(data[key][group][time])[subGroup].ly),
                    vsBp: AnalyticsHelper.percent(AnalyticsHelper.getValue(data[key][group][time][subGroup].act), AnalyticsHelper.getValue(data[key][group][time])[subGroup].bp)
                }
            } else {
                return {
                    act: AnalyticsHelper.getValue(data[key][group][time][subGroup].act),
                    bp: AnalyticsHelper.getValue(data[key][group][time][subGroup].bp),
                    ly: AnalyticsHelper.getValue(data[key][group][time][subGroup].ly),
                    vsLy: AnalyticsHelper.percent(AnalyticsHelper.getValue(data[key][group][time][subGroup].act), AnalyticsHelper.getValue(data[key][group][time])[subGroup].ly),
                    vsBp: AnalyticsHelper.percent(AnalyticsHelper.getValue(data[key][group][time][subGroup].act), AnalyticsHelper.getValue(data[key][group][time])[subGroup].bp)
                }
            }
        }
    }
    static percent(num1: number, num2: number, decimal: number = 1) {
        if (num2 == 0) {
            return '∞';
        }
        return parseFloat((num1 * 100 / num2 - 100).toFixed(decimal));
    }
    static getValue(value: number) {
        if (value != null) {
            return value;
        }
        return 0;
    }
    static openSweetAlert(message: string, success: boolean = false, text: string = '', redirect: string = '', confirmationButtonText: string = 'Close') {
        Swal.fire({
            title: message,
            icon: success ? 'success' : 'warning',
            confirmButtonText: confirmationButtonText,
            text: text
        }).then((result) => {
            console.log(result);
            if(redirect != ''){
                location.href = redirect;
            }
        })
    }
    static getFromCache(key: string) {
        if ((<any>window).db == null) {
            location.reload();
            return null;
        }
        let tx = (<any>window).db.transaction("Report", "readwrite");
        let store = tx.objectStore("Report");
        let fromDb = store.get(key);
        return fromDb;
    }

    static removeFromCache(key: string) {
        let tx = (<any>window).db.transaction("Report", "readwrite");
        let store = tx.objectStore("Report");
        store.delete(key);
    }

    private static random(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    public static isSmddChild(channel: string) {
        if (channel == "SM" || channel == "Drug" || channel == "Discounter" || channel == "D&D") {
            return true;
        }
        // [FPT-HoiLT][1204061681619804] Dashboard Channel filter update
        if (channel == "Retail" || channel == "Food") {
            return true;
        }
        return false;
    }
    public static isSmddParent(channel: string) {
        if (channel == "SMDD") {
            return true;
        }
        // [FPT-HoiLT][1204061681619804] Dashboard Channel filter update
        if (channel == "Retail/Food") {
            return true;
        }
        return false;
    }
    public static getSmddChild(channel: string) {
        if (channel == "SMDD") {
            return ['SM', 'D&D'];
        }
        // [FPT-HoiLT][1204061681619804] Dashboard Channel filter update
        if (channel == "Retail/Food") {
            return ['Retail', 'Food'];
        }
        return [];
    }
    public static getSmddChildIntage(channel: string) {
        if (channel == "SMDD") {
            return ['SM', 'Discounter', 'Drug'];
        }
        return [];
    }
    public static getSmddParent(channel: string) {
        if (channel == "SM" || channel == "D&D" || channel == "Drug" || channel == "Discounter") {
            return 'SMDD';
        }
        // [FPT-HoiLT][1204061681619804] Dashboard Channel filter update
        if (channel == "Retail" || channel == "Food") {
            return 'Retail/Food';
        }
        return null;
    }
    public static sumArray(a: number[], b: number[]) {
        var c = [];
        for (var i = 0; i < Math.max(a.length, b.length); i++) {
            c.push((a[i] || 0) + (b[i] || 0));
        }
        return c;
    }
    public static percentArray(a: number[], b: number[]) {
        var c = [];
        for (var i = 0; i < Math.max(a.length, b.length); i++) {
            if (b[i] == 0) {
                c.push(null);
                continue;
            }
            c.push(((((a[i] || 0) - (b[i] || 0)) / (b[i] || 0)) * 100).toFixed(1));
        }
        return c;
    }
    static hanldeCheckBoxSelect(event) {

        dispatchNgSelectChange(event);

        setTimeout(() => {
            let checkboxs = document.querySelectorAll('.ng-option-selected input[type=checkbox]');
            checkboxs.forEach(e => {
                var element = e as HTMLInputElement | null;
                element.checked = true;
            });            
            let notcheckboxs = document.querySelectorAll('.ng-option:not(.ng-option-selected) input[type=checkbox]');
            notcheckboxs.forEach(e => {
                var element = e as HTMLInputElement | null;
                element.checked = false;
            });
        }, 100);
    }

    static ChannelLv2(): Array<{ code: Array<number>, channel: string, level: number, region?: Array<string> }>{
        return [
            {
                code: [21,22],
                channel: 'Vending',
                level: 1
            },
            {
                code: [21],
                channel: 'Vending_FS',
                level: 2
            },
            {
                code: [22],
                channel: 'Vending_REG',
                level: 2
            },
            {
                code: [11,12],
                channel: 'SMDD',
                level: 1
            },
            {
                code: [11],
                channel: 'SM',
                level: 2
            },
            {
                code: [12],
                channel: 'D&D',
                level: 2
            },
            {
                code: [13],
                channel: 'CVS',
                level: 1
            }, {
                code: [31, 32],
                channel: 'Retail/Food',
                level: 1
            },
            {
                code: [31],
                channel: 'Retail',
                level: 2
            },
            {
                code: [32],
                channel: 'Food',
                level: 2
            },
            {
                code: [33],
                channel: 'On-Line',
                level: 1
            },
            {
                code: [41],
                channel: 'Others',
                level: 1,
                // [FPT-ThinhLD4][1204061681619804] Dashboard Channel filter update
                region: ['9100']
            }
        ];
    }

    static ChannelLv3(): Array<{ code: Array<number>, channel: string }>{
        return [
            // {
            //     code: [111],
            //     channel: 'SM'
            // },
            // {
            //     code: [121,123],
            //     channel: 'D&D'
            // },
            {
                code: [131],
                channel: 'CVS sales'
            },
            {
                code: [331, 332],
                channel: 'On-Line sales'
            },
            // {
            //     code: [321],
            //     channel: 'Food'
            // }
        ];
    }

    static Branches(): Array<{ code: number, name: string }>{
        return [
            {
                code: 2170,
                name: 'East Japan'
            },
            {
                code: 2160,
                name: 'Kanto'
            },
            {
                code: 3210,
                name: 'Tokyo'
            },
            {
                code: 4130,
                name: 'Central Japan'
            },
            {
                code: 5110,
                name: 'Kinki'
            },
            {
                code: 6110,
                name: 'Chushikoku'
            },
            {
                code: 6210,
                name: 'Kyushu'
            },
            {
                code: 3510,
                name: 'VM Area Other'
            }
        ];
    }

    static FoodBranches(): Array<{ code: number, name: string }>{
        return [
            {
                code: 1120,
                name: 'FS North Kanto/South Tohoku'
            },
            {
                code: 3300,
                name: 'FS Metropolitan'
            },
            {
                code: 5160,
                name: 'FS Kansai/Chubu'
            },
            {
                code: 6300,
                name: 'FS Kyushu/Chushikoku'
            },
            {
                code: 5100,
                name: 'FS Area Other'
            }
        ];
    }

    static Regions(): Array<{ code: number, name: string }>{
        return [
            {
                code: 1000,
                name: 'North Kanto/South Tohoku'
            },
            {
                code: 2000,
                name: 'Metropolitan'
            },
            {
                code: 4000,
                name: 'Kansai/Chubu'
            },
            {
                code: 6000,
                name: 'Kyushu/Chushikoku'
            }
        ];
    }

    public static calcPerCase(act: any, measure: any){
        return this._calcPerCase({
            act: act.act,
            measure: measure.act,
            bp: measure.bp == 0 ? 0 : act.bp / measure.bp,
            ly: measure.ly == 0 ? 0 : act.ly / measure.ly,
        });
    }
    private static _calcPerCase({ act, measure, ly, bp }) {
        act = act && measure ? (act / measure) * 1000 : 0;
        bp *= 1000;
        ly *= 1000;
        act = act;
        bp = bp;
        ly = ly;
        return {
            act,
            bp,
            ly,
            vsBp: AnalyticsHelper.percent(act, bp),
            vsLy: AnalyticsHelper.percent(act, ly)
        };
    }

  /**
   * @author FPT-HoiLT2
   * @Desciption [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
   */
    static formatPercent(number: any, decimal: number = 1) {
        if (!isNaN(parseFloat(number)))
        {
            return parseFloat(number).toFixed(decimal)
        }
        return '-';
     
    }
    /**
     * @author FPT-HoiLT2
     * @Desciption [2022/11/23][SENSE][1203406614097422] Dashboard & Daily Report page corrections
     */
    static diff = (a, b) => 
    {
        if (b == 0)
        {
            return '-';
        }
        return a - b;
    }
    static formatDiff(number: any, roundup: any) {
        if (!isNaN(Math.round(number/roundup)))
        {
            return Math.round(number/roundup);
        }
        return '-';   
    }
}