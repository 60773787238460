import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'moving-average-dropdown',
  host: {'class': 'ng-section summary-section'},
  template: `
  <b>{{title}}</b>
  <ng-select [items]="items" bindLabel="name" bindValue="value"
    [ngModel]="model || items[0].value" (ngModelChange)="change.emit($event);"
    [searchable]="false" [multiple]="true">
  </ng-select>
  `,
})
export class MovingAverageComponent {
  @Input() title: string;
  @Input() items: any[];
  @Input() ngModel: any[];
  @Input() model: string; // Optional
  @Output() modelChange = new EventEmitter<string>();
  @Output("change") change: EventEmitter<any> = new EventEmitter();

  value = {target : {value: ""}};

  handleValueChange = (value) => {
    this.value.target.value = value;
  }

}