
<ng-container *ngIf="!ishqLevel1Ready || !ishqLevel2Ready || !ishqTimelineReady">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<div *ngIf="isDownloadLoading" class="csv-loading-overlay">
    <div class="text-center spinner-wrapper">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>
</div>

<div id="pdf_print_area_intage_flash_vm_full" #pdf_print_area_intage_flash_vm_full [class]="ishqLevel1Ready && ishqLevel2Ready && ishqTimelineReady ? '' : 'not-show'">
    <div class="row" class="row">
        <div class="col-lg-12 col-md-12">
            <div class="card mb-30">
                <div class="hq-title-header">
                    <h2>Region Progress</h2>
                </div>

                <sales-date-filter
                    pageName="HQProgress"
                    (onDateTypeSaveItemsChange)="onDateTypeSaveItemsChange($event)"></sales-date-filter>
            </div>
        </div>
    </div>

    <hq-level1
    [fromDate] = "fromDate" [toDate]="toDate"
    (event_Aggregated_SalesHQLev1)="receive_Aggregated_SalesHQLev1($event)"
    (ready)="hqLevel1Ready($event)"></hq-level1>

    <hq-level2
    [lstOption_Selected_SalesHQLev1]="lstOption_Aggregated_SalesHQLev1"
    [fromDate] = "fromDate" [toDate]="toDate"
    (ready)="hqLevel2Ready($event)"></hq-level2>

    <hq-national-local
    [lstOption_Selected_SalesHQLev1]="lstOption_Aggregated_SalesHQLev1"
    [fromDate] = "fromDate" [toDate]="toDate"
    (ready)="hqLevel2Ready($event)"></hq-national-local>

    <hq-progress-timeline
        [fromDate]="fromDate"
        [toDate]="toDate"
        (ready)="hqTimelineReady($event)"></hq-progress-timeline>
</div>
