<div class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center sanky-header">
                <h3>Sankey Summary</h3>
            </div>
            <div class="filter-section no-title filter-sankey">

                <!-- <div class="ng-section summary-section native">
                    <b>Aggregate By</b>
                    <select class="form-control filter-by-category form-basic option-sankey" (change)="onSankeyAggregateChange($event.target.value)">
                        <option value="wtd">Weekly</option>
                        <option value="mtd">Monthly</option>
                        <option value="qtd">Quarterly</option>
                        <option value="ytd">Yearly</option>
                    </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Week to Date'}, {value: 'mtd', name: 'Month to Date'}, {value: 'qtd', name: 'Quarter to Date'}, {value: 'ytd', name: 'Year to Date'}]"
                    [model]="aggregateBy"
                    (change)="onSankeyAggregateChange($event)">
                </single-dropdown>

            </div>
            <div class="filter-section filter-sankey">

                <!-- <div class="ng-section summary-section display native">
                    <b>Display</b>
                    <select class="form-control filter-by-category form-basic" [(ngModel)]="selectedChannelTypeSankeyCases" (change)="onChannelSankeyCasesChange($event.target.value)" [style]="'width: auto'">
                        <option value="1">Channel Level 1</option>
                        <option value="2">Channel Level 2</option>
                    </select>
                </div> -->
                <single-dropdown title="Display"
                    [items]="[{value: '1', name: 'Channel Level 1'}, {value: '2', name: 'Channel Level 2'}]"
                    (change)="onChannelSankeyCasesChange($event)"
                    [(model)]="selectedChannelTypeSankeyCases" >
                </single-dropdown>

                <div class="ng-section summary-section select-key">
                    <b>Select Key</b>
                    <ng-select #ngFiltersankey class="form-control filter-by-category option-sankey cases-select" [items]="sankeyCases"
                        [multiple]="true" [closeOnSelect]="false" bindLabel="name" bindValue="key"
                        [(ngModel)]="selectedSankeyCases" 
                        (open)="onOpenPopup();onChangeCheckBox($event)"
                        (close)="onClosePopup()"
                        (change)="onRemoveCaseSankey();onChangeCheckBox($event)"
                        (blur)="SaveOptionValueTemp(true)">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input type="checkbox" style="pointer-events: none"/> {{item.name}}
                        </ng-template>
                        <ng-template ng-footer-tmp>
                                <button type="button" class="btn btn-primary" [disabled]="selectedSankeyCases == null || selectedSankeyCases.length == 1"
                                (click)="SaveOptionValueTemp(false);ngFiltersankey.close();onFilterSankeyCaseChange(true)">Aggregate</button>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <!-- <div class="filter-section filter-sankey">
                <div class="ng-section summary-section native">
                    <b>Display Maker</b>
                    <select class="form-control filter-by-category form-basic"
                        (change)="onMakerSankeyCasesChange($event.target.value)">
                        <option *ngFor="let maker of marketShareMakers; index as i;" value="{{maker.maker}}">
                            {{maker.maker}}</option>
                    </select>
                </div>
                <div class="ng-section summary-section">
                    <b>Display Channels</b>
                    <ng-select #ngFilterChannelSankey class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsSankeySelectedChannel[0]" [multiple]="false" [closeOnSelect]="false"
                    [clearable]="shareVsSankeySelectedChannel.length > 1"
                    (remove)="onRemoveFilterSankeyChannel($event)"
                    (change)="onRemoveFilterSankeyChannel($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilteSankeyChannel($event)"
                    [clearable]="shareVsSankeySelectedChannel.length > 1">
                        <ng-option *ngFor="let item of sankeyChannels" [value]="item">
                            <input type="checkbox" class="cb-multi-select"/> {{isSmddChild(item) ? "&nbsp;&nbsp;&nbsp;" : ""}}{{item}}</ng-option>
                            <ng-template ng-footer-tmp>
                                <button type="submit" class="btn btn-primary"
                                (click)="SaveOptionValueTemp(false);ngFilterChannelSankey.close();onFilterSankeyCaseChange()">Aggregate</button>
                            </ng-template>                                
                            <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Display Categories</b>
                    <ng-select #ngFilterCategorySankey class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsSankeySelectedCategory[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilteSankeyCategory($event)"
                    (change)="onRemoveFilteSankeyCategory($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterSankeyCategory($event)"
                    [clearable]="shareVsSankeySelectedCategory.length > 1">
                        <ng-option *ngFor="let item of sankeyCategories" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterCategorySankey.close();onFilterSankeyCaseChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Display Regions</b>
                    <ng-select #ngFilterRegionSankey class="form-control filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsSankeySelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterSankeyRegion($event)"
                    (change)="onRemoveFilterSankeyRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterSankeyRegion($event)"
                    [clearable]="shareVsSankeySelectedRegion.length > 1">
                        <ng-option *ngFor="let item of sankeyRegions" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterRegionSankey.close();onFilterSankeyCaseChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Display Package Types</b>
                    <ng-select #ngFilterPackageTypeSankey class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="shareVsSankeySelectedPackageType[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterSankeyPackageType($event)"
                    (change)="onRemoveFilterSankeyPackageType($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterSankeyPackageType($event)"
                    [clearable]="shareVsSankeySelectedPackageType.length > 1">
                        <ng-option *ngFor="let item of sankeyPackageTypes" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterPackageTypeSankey.close();onFilterSankeyCaseChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div> -->
            <div class="card-body sankey-chart" style="overflow-x: scroll">
                <div #screen>
                    <ng-container *ngIf="sankeyData">
                        <google-chart #chart title="" type="Sankey" [data]="sankeyData" [width]="sankeyWidth" [columns]="sankeycolumnNames"
                            [options]="sankeyOptions" height="700" (window:resize)="onResize()">
                        </google-chart>
                    </ng-container>
                    <ng-container *ngIf="!sankeyData">
                        <div class="no-data-container sankey-chart-no-data">
                            <span class="no-data-text">No data available for this combination. Please refine your selection</span>
                        </div>
                    </ng-container>                    
                </div>
            </div>
            <div class="d-flex flex-two-ends mt-4 hide-pdf-print">
                <!-- <comment-button title1="Sankey Summary" [screen]="screen" (beforeScreen)="setColorModeSource()" (afterScreen)="setColorModeGradient()"
                [chartId]="chartId" [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd', selectedChannelTypeSankeyCases, selectedSankeyCases}}]"></comment-button> -->

                <download-button>
                    <button ngbDropdownItem (click)="exportSankeySummary(true)">Download PNG</button>
                    <button ngbDropdownItem (click)="exportSankeySummary(false)">Download CSV</button>
                </download-button>
            </div>
            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>