<!-- Start Login Area -->
<div class="login-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="login-form">
                <div class="logo">
                    <img src="assets/img/logo_sense2.svg" class="mg-auto" alt="image">
                </div>

                <h2>Welcome</h2>

                <form (ngSubmit)="login()">

                    <button type="submit" class="login-btn">Login with Microsoft Account</button>
                    <p style="color: red"> {{message}}</p>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- End Login Area -->