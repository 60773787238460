<ng-container *ngIf="!skuReady || !customerReady">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>
<div [class]="skuReady && customerReady? '' : 'not-show' ">
    <div class="row">
        <div class="col-lg-2 col-md-6 col-xs-90">
            <input [(ngModel)]="selectedDate" (dateSelect)="onDateSelect($event)" type="text" ngbDatepicker
                [minDate]="{year: 2022, month: 1, day: 1}"
                [maxDate]="{year: startDate.getFullYear(), month: startDate.getMonth() + 1, day: startDate.getDate()}"
                #d="ngbDatepicker" class="form-control" readonly placeholder="Choose a date" />
        </div>
        <div class="col-lg-2 col-md-6 col-xs-10 calendar-icon">
            <span class="icon" (click)="d.toggle()"><i class="bx bx-calendar"
                    style="font-size: 35px;left: -20px;position: relative;"></i></span>
        </div>
        <div class="col-lg-4 col-md-6 aggregate-col">
            <label class="aggregate-label"><b>Aggregate By</b></label>
            <select class="form-control" (change)="onAggregateByChange($event.target.value)">
                <option value="daily">Date Numbers</option>
                <option value="mtd">Month to Date Numbers</option>
                <option value="ytd">Year to Date Numbers</option>
            </select>
        </div>
    </div>
    <p></p>
    <sku [selectedDate]="selectedDate" [sortbyColumn]="SKUsortbyColumn" [orderbyColumn]="SKUorderbyColumn" [aggregateBy]="aggregateBy" [data]="skuData" [totalData]="skuCountData" [dataInit]="skuDataInit" [SKUdisplayColumns]="SKUdisplayColumns" (ready)="skuCheckReady($event)" (bindingTableData)="bindingTableData($event)"></sku>
    <customer [selectedDate]="selectedDate" [sortbyColumn]="CustomersortbyColumn" [orderbyColumn]="CustomerorderbyColumn" [aggregateBy]="aggregateBy" [data]="customerData" [customerDataInit]="customerDataInit" [totalData]="customerCountData" [CustomerdisplayColumns]="CustomerdisplayColumns" (ready)="customerCheckReady($event)" (bindingTableData)="bindingCustomerTableData($event)"></customer>
</div>