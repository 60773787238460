<!-- Start Error 404 Area -->
<div class="error-404-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="notfound">
                <div class="notfound-bg">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <h1>Oops!</h1>
                <h3>Error 500 : Internal Server Error</h3>
                <p>There might be some errors occured or it's temporarily unavailable.</p>
                <a routerLink="/sku-customer" class="default-btn">Go back</a>
            </div>
        </div>
    </div>
</div>
<!-- End Error 404 Area -->