import { Component, Renderer2, OnInit, Inject, ViewChild, Output, EventEmitter, Input, OnChanges, HostListener } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
@Component({
  selector: 'summary-dashboard',
  templateUrl: './summary-dashboard.component.html',
  styleUrls: ['./summary-dashboard.component.scss']
})
export class SummaryDashboardComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedDate: any;
  @Input() aggregateBy: any;
  @Input() dailyData: any;
  @Input() monthlyData: any;
  bapc: any;
  nsr: any;
  nsrPerCase: any;
  gp: any;
  qtd: any;

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit() {
    this.qtd = {'01': ['01'], '02': ['01', '02'], '03': ['01', '02', '03'], '04': ['04'], '05': ['04', '05'], '06': ['04', '05', '06'],
    '07': ['07'], '08': ['07', '08'], '09': ['07', '08', '09'], '10': ['10'], '11': ['10', '11'], '12': ['10', '11', '12']};
  }

  ngOnChanges() {
    //Init data
    this.bapc = {
      act: 0,
      bp: 0,
      ly: 0,
      vsLy: 0,
      vsBp: 0,
      diffBp: 0,
      diffLy: 0
    };
    this.nsr = {
      act: 0,
      bp: 0,
      ly: 0,
      vsLy: 0,
      vsBp: 0,
      diffBp: 0,
      diffLy: 0
    };
    this.nsrPerCase = {
      act: 0,
      bp: 0,
      ly: 0,
      vsLy: 0,
      vsBp: 0,
      diffBp: 0,
      diffLy: 0
    };
    this.gp = {
      act: 0,
      bp: 0,
      ly: 0,
      vsLy: 0,
      vsBp: 0,
      diffBp: 0,
      diffLy: 0
    };

    if (this.dailyData != null && this.selectedDate != null)
    {
      if (this.aggregateBy != null && this.aggregateBy == 'daily')
      {
        this.bindNsrChannelsChartSummary(new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day));
      }
    }
    
    if (this.monthlyData != null && this.selectedDate != null)
    {
      if (this.aggregateBy != null && this.aggregateBy != 'daily')
      {
        this.bindNsrChannelsChartSummary(new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day));
      }
    }
  }

  public bindNsrChannelsChartSummary(selectedDate: Date) {
    let month = this.datepipe.transform(selectedDate, "yyyyMM");
    let year = this.datepipe.transform(selectedDate, "yyyy");
    if(this.aggregateBy == 'daily'){
      for(var i=0;i<this.dailyData.length;i++){
        let row = this.dailyData[i];
        this.bapc.act += parseFloat(row.bapc_ty);
        this.bapc.bp += parseFloat(row.bapc_bp);
        this.bapc.ly += parseFloat(row.bapc_ly);
        this.nsr.act += parseFloat(row.nsr_ty);
        this.nsr.bp += parseFloat(row.nsr_bp);
        this.nsr.ly += parseFloat(row.nsr_ly);
        this.gp.act += parseFloat(row.gp_ty);
        this.gp.bp += parseFloat(row.gp_bp);
        this.gp.ly += parseFloat(row.gp_ly);
      }
    }else {
      for(var i=0;i<this.monthlyData.length;i++){
        let row = this.monthlyData[i];
        if(this.aggregateBy == 'mtd' && row.month != month){
          continue;
        }
        if(row.month.indexOf(year) < 0){
          continue;
        }
        if (this.aggregateBy == 'qtd')
        {
          let quarters = this.qtd[month.slice(4)];
          if (!quarters.includes(row.month.slice(4)))
            {
              continue;
            }
        }
        this.bapc.act += parseFloat(row.bapc_ty);
        this.bapc.bp += parseFloat(row.bapc_bp);
        this.bapc.ly += parseFloat(row.bapc_ly);
        this.nsr.act += parseFloat(row.nsr_ty);
        this.nsr.bp += parseFloat(row.nsr_bp);
        this.nsr.ly += parseFloat(row.nsr_ly);
        this.gp.act += parseFloat(row.gp_ty);
        this.gp.bp += parseFloat(row.gp_bp);
        this.gp.ly += parseFloat(row.gp_ly);
      }
    }
    // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues START
    this.bapc.vsLy = AnalyticsHelper.percent(this.bapc.act, this.bapc.ly);
    this.bapc.vsBp = AnalyticsHelper.percent(this.bapc.act, this.bapc.bp);
    this.nsr.vsLy = AnalyticsHelper.percent(this.nsr.act, this.nsr.ly);
    this.nsr.vsBp = AnalyticsHelper.percent(this.nsr.act, this.nsr.bp);
    this.gp.vsLy = AnalyticsHelper.percent(this.gp.act, this.gp.ly);
    this.gp.vsBp = AnalyticsHelper.percent(this.gp.act, this.gp.bp);
    this.nsrPerCase = AnalyticsHelper.calcPerCase(this.nsr, this.bapc);
    // FPT-HoiLT2: [2022/11/23][SENSE][1203406614097422] Dashboard & Daily Report page corrections Start
    this.nsrPerCase.diffBp = AnalyticsHelper.diff(this.nsrPerCase.act, this.nsrPerCase.bp);
    this.nsrPerCase.diffLy = AnalyticsHelper.diff(this.nsrPerCase.act, this.nsrPerCase.ly);
    // FPT-HoiLT2: [2022/11/23][SENSE][1203406614097422] Dashboard & Daily Report page corrections Start
    
    this.bapc.act = Math.round(this.bapc.act/1000);
    this.bapc.bp = Math.round(this.bapc.bp/1000);
    this.bapc.ly = Math.round(this.bapc.ly/1000);
    this.nsr.act = Math.round(this.nsr.act/1000000);
    this.nsr.bp = Math.round(this.nsr.bp/1000000);
    this.nsr.ly = Math.round(this.nsr.ly/1000000);
    this.gp.act = Math.round(this.gp.act/1000000);
    this.gp.bp = Math.round(this.gp.bp/1000000);
    this.gp.ly = Math.round(this.gp.ly/1000000);
    this.nsrPerCase.act = Math.round(this.nsrPerCase.act/1000);
    this.nsrPerCase.bp = Math.round(this.nsrPerCase.bp/1000);
    this.nsrPerCase.ly = Math.round(this.nsrPerCase.ly/1000);
    // FPT-HoiLT2: [2022/11/23][SENSE][1203406614097422] Dashboard & Daily Report page corrections Start
    this.nsrPerCase.diffBp = AnalyticsHelper.formatDiff(this.nsrPerCase.diffBp, 1000); 
    this.nsrPerCase.diffLy = AnalyticsHelper.formatDiff(this.nsrPerCase.diffLy, 1000);
    // FPT-HoiLT2: [2022/11/23][SENSE][1203406614097422] Dashboard & Daily Report page corrections Start
    // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues END

    let t=this;
    setTimeout(() => {
      t.ready.emit(true);
    });
    //Make sure height of different charts are the same
    setTimeout(function () {
      let bars = document.querySelectorAll('.progress-line');
      for (var i = 0; i < bars.length; i++) {
        let bar = bars[i] as HTMLElement;
        bar.style.width = bar.getAttribute('data-width') + '%';
      }
    }, 800);
  }

  formatNumber(number: any) {
    return AnalyticsHelper.formatNumber(number);
  }
  /**
     * @author FPT-HoiLT2
     * @Desciption [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
     */
  formatPercent(number: any) {
    return AnalyticsHelper.formatPercent(number);
  }
  public isSmddChild(channel: string) {
    if (channel == "SM" || channel == "Drug" || channel == "Discounter" || channel == "D&D") {
      return true;
    }
    return false;
  }

  roundDownToNearest(number: any) {
    return Math.floor(number / 4) * 4;
  }
  roundUpToNearest(number: any) {
    return Math.ceil(number / 4) * 4;
  }  
}
