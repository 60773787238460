import { Component, OnInit } from '@angular/core';
import { tokens } from 'src/app/model/constants';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.scss']
})
export class ProcessingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.checkuser();
  }

  public checkuser()
  {
    var token = localStorage.getItem(tokens.accessToken);
    var userinfo = localStorage.getItem("userinfo");
    if (token == null || userinfo == null)
    {
      setInterval(this.checkuser, 200);
    }
    else
    {
      var userinfo_parsed = JSON.parse(userinfo);
      var redirect_page = userinfo_parsed[environment.customer][0]?.senseGroup?.redirectpage?.url;
      if (redirect_page != null)
      {
        location.href = redirect_page;
      }
      else
      {
        location.href = '/dashboard';
      }     
    } 
  }

}
