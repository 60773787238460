import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'download-button',
  templateUrl: './download-button.component.html'
})
export class DownloadButtonComponent implements OnInit {
  @Input() title: string = 'Download';

  constructor() { }

  ngOnInit(): void {
  }

}
