import { APP_CONFIG, SKUQueries } from 'src/app/model/constants';
import { PageModel } from './../../../../model/page.model';
import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, EventEmitter, Inject, Input, OnChanges, Output, Renderer2, SimpleChanges } from "@angular/core";
import { AnalyticsHelper } from "src/app/helpers/analytics";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as tsvParse from 'd3-dsv';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

const ALL_OBJ = {cd: 'All', name: 'All'};
const ALL_OBJ_NOCODE = {name: 'All'};
const sort_columns = ["bapc_ty", "bapc_ly", "bapc_per_ly", "nsr_ty", "nsr_ly", "nsr_per_ly", "gp_ty", "gp_ly", "gp_per_ly"];


@Component({
  selector: "sku",
  templateUrl: "./sku.component.html",
  styleUrls: ["./sku.component.scss"],
})
export class SkuComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Output() bindingTableData = new EventEmitter<any>();
  @Input() selectedDate: any;
  @Input() aggregateBy: any;
  @Input() data: any;
  @Input() totalData: any;
  @Input() dataInit: any;
  @Input() SKUdisplayColumns: any;
  @Input() sortbyColumn: any;
  @Input() orderbyColumn: any;
  salesArea=[ALL_OBJ];
  salesDistrict=[ALL_OBJ];
  janCode=[ALL_OBJ_NOCODE];
  selectedJanCode=[];
  selectedBrand=[];
  selectedCategory=[];
  selectedProductName=[];
  selectedContainer=[];
  selectedPackageLev1=[];
  productNameJP=[ALL_OBJ_NOCODE];
  brandName=[ALL_OBJ_NOCODE];
  category=[ALL_OBJ_NOCODE];
  packageLev1=[ALL_OBJ_NOCODE];
  channelLev1=[ALL_OBJ];
  container=[ALL_OBJ_NOCODE];
  salesAreaList = [];
  salesDistrictList = [];
  salesDistrictBinding = [];
  channelLev1List = [];
  channelLev1NmList = [];
  categoryList = [ALL_OBJ_NOCODE];
  categoryBinding = [ALL_OBJ_NOCODE];
  unselectedCategoryBinding = [];
  pkgLev1List = [ALL_OBJ_NOCODE];
  pkgLev1Binding = [];
  containerList = [ALL_OBJ_NOCODE];
  containerBinding = [];
  displayData = [];
  pageNumber = 1;
  pagination = { current: this.pageNumber, totalItem: 0, sizeOnPage: APP_CONFIG.paginationSize };
  initFlg = true;
  condition = "";
  skuReady = false;
  sales_area_to_sales_district = {};
  groupcategorypackagecontainer = {};
  grouppackagecontainer = {};
  jancodeList = [ALL_OBJ_NOCODE];
  janCode_Binding = [];
  productname_enList = [ALL_OBJ_NOCODE];
  productname_enBinding = [];
  productname_jpList = [ALL_OBJ_NOCODE];
  productname_jpBinding = [];
  unselectedproductname_jpBinding = [];
  brandnameList = [ALL_OBJ_NOCODE];
  brandnameList_Binding = [];
  groupBrandnameCategoryProductNameJp = {};
  groupBrandnameCategory = {};
  groupCategoryProduct = {};
  brandNameBinding_previous = [];
  categoryBinding_previous = [];
  productname_jps_with_brandname_category = [];
  aggregateByDisplay = "";
  current_sortcol = "";
  current_sortcol_order = "";
  bufferSize = 10;
  loading = false;
  loadingJanCode = false;
  loadingBrandList = false;
  loadingCategory = false;
  loadingPackageLev1 = false;
  loadingContainer = false;
  aggregatedSalesArea = [ALL_OBJ];
  aggregatedSalesDistrict = [ALL_OBJ];
  aggregatedChannelLevel1 = [ALL_OBJ];
  aggregatedBrandName = [ALL_OBJ_NOCODE];
  aggregatedCategory = [ALL_OBJ_NOCODE];
  aggregatedProductName = [ALL_OBJ_NOCODE];
  aggregatedJanCode = [ALL_OBJ_NOCODE];
  aggregatedContainer = [ALL_OBJ_NOCODE];
  aggregatedPackageLev1 = [ALL_OBJ_NOCODE];
  isaggregatedJancode = false;
  totalCategoryBinding = [];
  totalProductNameBinding = [];
  previousAggregateby = "";
  previousSelectDate = "";
  dataChange = false;
  filters = [{name: 'Sales Area'}, {name: 'Sales District'}, {name: 'Channel Level 1'}, 
  {name: 'Brand Name'}, {name: 'Category'}, {name: 'Product Name JP'}, {name: 'Jan Code'}, {name: 'Container'}, {name: 'Package Lev1'}];
  groupByColumns = [ALL_OBJ_NOCODE];
  aggregatedgroupByColumns = [ALL_OBJ_NOCODE];
  columnList = [];
  displayColumns = [];
  SKUMappingdisplayColumns = [];
  mapSKUFiltersToDisplay = {'SALES_AREA_NAME': 'Sales Area', 'SALES_DISTRICT_NAME': 'Sales District', 'CHANNEL_LEV1_NAME': 'Channel Level 1', 
  'BRAND_NAME_JP': 'Brand Name', 'CATEGORY_NAME': 'Category', 'CURRENT_JAN_NAME_JP': 'Product Name JP', 'JAN_CODE': 'Jan Code', 'DDIS_CONTAINER_TYPE_NAME': 'Container', 'CCEJ_PACKAGE_NAME': 'Package Lev1'};
  mapSKUFilters = {'Sales Area': 'SALES_AREA_NAME', 'Sales District': 'SALES_DISTRICT_NAME', 'Channel Level 1': 'CHANNEL_LEV1_NAME', 
  'Brand Name': 'BRAND_NAME_JP', 'Category': 'CATEGORY_NAME', 'Product Name JP': 'CURRENT_JAN_NAME_JP', 'Jan Code': 'JAN_CODE', 'Container': 'DDIS_CONTAINER_TYPE_NAME', 'Package Lev1': 'CCEJ_PACKAGE_NAME'};


  constructor(public datepipe: DatePipe, private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document, private http: HttpClient) { }

  ngOnInit() {      

  }
  ngOnChanges(changes: SimpleChanges) {
    if (("data" in changes || "dataInit" in changes) && this.dataChange)
    {
      window.scrollTo(0,100);
      this.dataChange = false;
    }
    document.getElementsByClassName('sales-by-sku-area')[1].scrollTo(0,0);
    // set data to combobox
    if (this.aggregateBy === "daily")
    {
      this.aggregateByDisplay = "Daily";
    }
    else if (this.aggregateBy === "mtd")
    {
      this.aggregateByDisplay = "MTD";
    }
    else if (this.aggregateBy === "ytd")
    {
      this.aggregateByDisplay = "YTD";
    }

    if (this.dataInit && "dataInit" in changes) {      
      this.changeDataDropDown();
    }

    if ((this.previousAggregateby != "" && this.previousAggregateby != this.aggregateBy))
    {
      this.searchSkuData();
    }

    if (this.SKUdisplayColumns && this.SKUdisplayColumns.length > 0)
    {
      this.SKUMappingdisplayColumns = [];
      this.SKUdisplayColumns.forEach(x => {
        if (x in this.mapSKUFiltersToDisplay)
        {
          if (!this.SKUMappingdisplayColumns.includes(this.mapSKUFiltersToDisplay[x]))
          {
            this.SKUMappingdisplayColumns.push(this.mapSKUFiltersToDisplay[x]);
          }
        }
      })
    }



    if (this.previousSelectDate != "" && this.previousSelectDate != this.selectedDate)
    {
      this.condition = "";
      this.current_sortcol = "";
      this.current_sortcol_order = "";

      this.aggregatedSalesArea = [ALL_OBJ];
      this.salesArea = [ALL_OBJ];
      this.aggregatedSalesDistrict = [ALL_OBJ];
      this.salesDistrict = [ALL_OBJ];

      this.aggregatedChannelLevel1 = [ALL_OBJ];
      this.channelLev1 = [ALL_OBJ];

      this.aggregatedBrandName = [ALL_OBJ_NOCODE];
      this.brandName = [ALL_OBJ_NOCODE];

      this.aggregatedCategory = [ALL_OBJ_NOCODE];
      this.category = [ALL_OBJ_NOCODE];

      this.aggregatedProductName = [ALL_OBJ_NOCODE];
      this.productNameJP = [ALL_OBJ_NOCODE];

      this.aggregatedJanCode = [ALL_OBJ_NOCODE];
      this.janCode = [ALL_OBJ_NOCODE];

      this.aggregatedContainer = [ALL_OBJ_NOCODE];
      this.container = [ALL_OBJ_NOCODE];

      this.aggregatedPackageLev1 = [ALL_OBJ_NOCODE];
      this.packageLev1 = [ALL_OBJ_NOCODE];

      this.groupByColumns = [ALL_OBJ_NOCODE];
      this.aggregatedgroupByColumns = [ALL_OBJ_NOCODE];

      this.displayColumns = [];

      this.pageNumber = 1;
      this.pagination.current = 1;
      (<HTMLInputElement>document.getElementById("gotopage")).value = "";
    }

    if (this.data && !(this.previousAggregateby != "" && this.previousAggregateby != this.aggregateBy))
    {
      this.displayData = this.data;
    }

    if (this.sortbyColumn != '' && this.orderbyColumn != '')
    {
      if (this.orderbyColumn == 'DESC')
      {
        if (document.getElementById(this.sortbyColumn + "DESC") != null)
        {
          document.getElementById(this.sortbyColumn + "DESC").style.color = "red";
        }

        if (document.getElementById(this.sortbyColumn + "ASC") != null)
        {
          document.getElementById(this.sortbyColumn + "ASC").style.color = "";
        } 
      }
      else
      {
        if (document.getElementById(this.sortbyColumn + "DESC") != null)
        {
          document.getElementById(this.sortbyColumn + "DESC").style.color = "";
        }

        if (document.getElementById(this.sortbyColumn + "ASC") != null)
        {
          document.getElementById(this.sortbyColumn + "ASC").style.color = "red";
        } 
      }

      sort_columns.forEach(s => {
          if (s !== this.sortbyColumn)
          {
            if (document.getElementById(s + "DESC") != null)
            {
              document.getElementById(s + "DESC").style.color = "";
            }

            if (document.getElementById(s + "ASC") != null)
            {
              document.getElementById(s + "ASC").style.color = "";
            }    
          }
        })
    }    

    this.previousAggregateby = this.aggregateBy;
    this.previousSelectDate = this.selectedDate;

    if (this.displayData && this.displayData.length > 0)
    {
      (<HTMLInputElement>document.getElementById("gotopage")).removeAttribute('disabled');
    }
    else
    {
      (<HTMLInputElement>document.getElementById("gotopage")).setAttribute('disabled', '');
    }

    if (this.totalData > 0) {
      this.pagination.totalItem = this.totalData;
    }

    if (this.totalData == 0)
    {
      this.pagination.current = 1;
      this.pageNumber = 1;
      this.pagination.totalItem = this.totalData;
    }

    let t=this;
    setTimeout(() => {
      this.skuReady = true;
      t.ready.emit(true);
    });
  }

  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }

  changeDataDropDown() {

    this.columnList = [ALL_OBJ_NOCODE,...this.filters];

    this.salesAreaList = [ALL_OBJ,...this.dataInit.areaData];
    this.salesDistrictList = [ALL_OBJ,...this.dataInit.districtData];
    if (this.salesDistrict.some(c => c.name == "All"))
    {
      this.salesDistrictBinding = this.salesDistrictList;
    }
    this.channelLev1List = [ALL_OBJ,...this.dataInit.channelLevel1Data];
    this.sales_area_to_sales_district = this.dataInit.salesAreaTosalesDistrictData.sales_area_to_sales_district;
    this.groupBrandnameCategoryProductNameJp = this.dataInit.groupBrandnameCategoryProductNameJpData.groupBrandnameCategoryProductNameJp;
    this.groupBrandnameCategory = this.dataInit.groupBrandnameCategoryProductNameJpData.groupBrandnameCategory;
    this.groupCategoryProduct = this.dataInit.groupBrandnameCategoryProductNameJpData.groupCategoryProduct;   
    this.groupcategorypackagecontainer = this.dataInit.groupcategoryPackageContainerData.groupcategorypackagecontainer;
    this.grouppackagecontainer = this.dataInit.groupcategoryPackageContainerData.grouppackagecontainer;
    this.dataInit.groupBrandnameCategoryProductNameJpData.brandnameList.forEach(x => {
      if (!this.brandnameList.some(b => b.name == x))
      {
        this.brandnameList.push({name: x})
      } 
    });

    this.brandnameList_Binding = this.brandnameList.slice(0, this.bufferSize).filter(b => !this.selectedBrand.some(s => s.name == b.name));

    this.dataInit.groupBrandnameCategoryProductNameJpData.productname_jpList.forEach(x => {
      if (!this.productname_jpList.some(p => p.name == x))
      {
        this.productname_jpList.push({name: x})
      }
    });

    this.dataInit.janCodeData.forEach(x => {
      if (!this.jancodeList.some(j => j.name == x))
      {
        this.jancodeList.push({name: x});
      }
    })

    this.dataInit.groupcategoryPackageContainerData.categoryList.forEach(x => {
      if (!this.categoryList.some(c => c.name == x))
      {
        this.categoryList.push({name: x});
      }
    });
    this.dataInit.groupcategoryPackageContainerData.pkgLev1List.forEach(x => {
      if (!this.pkgLev1List.some(s => s.name == x)) {
        this.pkgLev1List.push({ name: x });
      }
    });
    this.dataInit.groupcategoryPackageContainerData.containerList.forEach(x => {
      if (!this.containerList.some(c => c.name == x))
      {
        this.containerList.push({name: x});
      }
    });
    this.janCode_Binding = this.janCode;

    if (!this.productNameJP.some(p => p.name == "All"))
    {
      this.productname_jpBinding = this.productname_jpBinding.filter(p => !this.selectedProductName.some(s => s.name == p.name));
    }
    else
    {
      this.productname_jpBinding = this.productname_jpList.slice(0, this.bufferSize).filter(p => !this.selectedProductName.some(s => s.name == p.name));
    }

    if (!this.category.some(c => c.name == "All"))
    {
      this.categoryBinding = this.categoryBinding.filter(c => !this.selectedCategory.some(s => s.name == c.name));
    }
    else
    {
      this.categoryBinding = this.categoryList.slice(0, this.bufferSize).filter(c => !this.selectedCategory.some(s => s.name == c.name));
    }

    if (!this.packageLev1.some(p => p.name == "All"))
    {
      this.pkgLev1Binding = this.pkgLev1Binding.filter(p => !this.selectedPackageLev1.some(s => s.name == p.name));
    }
    else
    {
      this.pkgLev1Binding = this.pkgLev1List.slice(0, this.bufferSize).filter(p => !this.selectedPackageLev1.some(s => s.name == p.name));
    }

    if (!this.container.some(p => p.name == "All"))
    {
      this.containerBinding = this.containerBinding.filter(p => !this.selectedContainer.some(s => s.name == p.name));
    }
    else
    {
      this.containerBinding = this.containerList.slice(0, this.bufferSize).filter(p => !this.selectedContainer.some(s => s.name == p.name));
    }
  }
  fetchMore(term) {
    if(term){
      const len = this.productname_jpBinding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.productname_jpList.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        let productname_jpFilter = this.productname_jpBinding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!productname_jpFilter.includes(m))
          {
            productname_jpFilter.push(m);
          }
        }); 

        this.productname_jpBinding = productname_jpFilter;
        this.productname_jpBinding = this.productname_jpBinding.filter(x=> !this.selectedProductName.includes(x));
      }, 200);
    }else{
      const len = this.productname_jpBinding.length;
      const more = this.productname_jpList.slice(len, this.bufferSize + len);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        more.forEach(m => {
          if (!this.productname_jpBinding.includes(m))
          {
            this.productname_jpBinding.push(m);
          }
        }) 
        this.productname_jpBinding = this.productname_jpBinding.filter(x=> !this.selectedProductName.includes(x));
      }, 200)
    }
  }
  searchProductNameJP(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.productname_jpList.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.productname_jpBinding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        // if (this.productNameJP.some(x => x.name == 'All'))
        // {
        //   this.selectedProductName = [];
        // }
        this.productname_jpBinding = this.productname_jpBinding.filter(x=> !this.selectedProductName.includes(x));
      }, 200)
    }
    else
    {
      AnalyticsHelper.hanldeCheckBoxSelect(event);
      this.productname_jpBinding = this.productname_jpList.slice(0, this.bufferSize).filter(x=> !this.selectedProductName.includes(x));
    }
  }

  fetchMoreJanCode(term) {
    if(term){
      const len = this.janCode_Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.jancodeList.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loadingJanCode = true;
      setTimeout(() => {
        this.loadingJanCode = false;
        let janCodeFilter = this.janCode_Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!janCodeFilter.includes(m))
          {
            janCodeFilter.push(m);
          }
        });
        this.janCode_Binding = janCodeFilter;
        this.janCode_Binding = this.janCode_Binding.filter(x=> !this.selectedJanCode.includes(x));
      }, 200);
    }else{
      const len = this.janCode_Binding.length;
      const more = this.jancodeList.slice(len, this.bufferSize + len);
      this.loadingJanCode = true;
      setTimeout(() => {
        this.loadingJanCode = false;
        more.forEach(m => {
          if (!this.janCode_Binding.includes(m))
          {
            this.janCode_Binding.push(m);
          }
        }) 
        this.janCode_Binding = this.janCode_Binding.filter(x=> !this.selectedJanCode.includes(x));
      }, 200)
    }
  }
  searchJanCode(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.jancodeList.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loadingJanCode = true;
      setTimeout(() => {
        this.loadingJanCode = false;
        this.janCode_Binding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        this.janCode_Binding = this.janCode_Binding.filter(x=> !this.selectedJanCode.includes(x));
      }, 200);
    }
  }
  onCloseJanCodes(){
    this.janCode = this.aggregatedJanCode;
    this.selectedJanCode = this.aggregatedJanCode;
    if (this.isaggregatedJancode)
    {
      var all_jancode = [ALL_OBJ_NOCODE];
      if (!this.selectedJanCode.some(x => x.name == "All"))
      {
        this.selectedJanCode = all_jancode.concat(this.selectedJanCode);
      } 
      this.janCode_Binding = [];
    }
    else
    {
      this.janCode_Binding = [];
    } 
    // this.janCode.forEach(element => {
    //   if(!this.selectedJanCode.includes(element)){
    //     this.selectedJanCode.push(element);
    //   }
    // });
    // this.janCode_Binding = this.janCode_Binding.filter(x=> !this.selectedJanCode.includes(x));
  }
  //BrandName
  fetchMoreBrandName(term) {
    if(term){
      const len = this.brandnameList_Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.brandnameList.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loadingBrandList = true;
      setTimeout(() => {
        this.loadingJanCode = false;
        let brandNameFilter = this.brandnameList_Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!brandNameFilter.includes(m))
          {
            brandNameFilter.push(m);
          }
        }); 
        this.brandnameList_Binding = brandNameFilter;
        this.brandnameList_Binding = this.brandnameList_Binding.filter(x=> !this.selectedBrand.includes(x));
      }, 200);
    }else{
      const len = this.brandnameList_Binding.length;
      const more = this.brandnameList.slice(len, this.bufferSize + len);
      this.loadingBrandList = true;
      setTimeout(() => {
        this.loadingBrandList = false;
        more.forEach(m => {
          if (!this.brandnameList_Binding.includes(m))
          {
            this.brandnameList_Binding.push(m);
          }
        }) 
        this.brandnameList_Binding = this.brandnameList_Binding.filter(x=> !this.selectedBrand.includes(x));
      }, 200)
    }
  }
  searchBrandName(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.brandnameList.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loadingBrandList = true;
      setTimeout(() => {
        this.loadingBrandList = false;
        this.brandnameList_Binding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        this.brandnameList_Binding = this.brandnameList_Binding.filter(x=> !this.selectedBrand.includes(x));
      }, 200)
    }
    else
    {
      AnalyticsHelper.hanldeCheckBoxSelect(event);
      this.brandnameList_Binding = this.brandnameList.slice(0, this.bufferSize).filter(x=> !this.selectedBrand.includes(x));
    }
  }
  onCloseBrandName(){
    this.brandnameList_Binding = this.brandnameList.slice(0,this.bufferSize);
    this.brandName = this.aggregatedBrandName;
    if (!this.brandName.some(x => x.name == "All"))
    {
      this.selectedBrand = [ALL_OBJ_NOCODE];
      this.brandName.forEach(element => {
        if(!this.selectedBrand.includes(element)){
          this.selectedBrand.push(element);
        }
      });
    }

    this.brandnameList_Binding = this.brandnameList_Binding.filter(x=> !this.selectedBrand.some(s => s.name == x.name));
  }
  //Categories
  fetchMoreCategories(term) {
    if(term){
      const len = this.categoryBinding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.categoryList.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loadingCategory = true;
      setTimeout(() => {
        this.loadingCategory = false;
        let categoryFilter = this.categoryBinding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!categoryFilter.includes(m))
          {
            categoryFilter.push(m);
          }
        });
        this.categoryBinding = categoryFilter;
        this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.includes(x));
      }, 200);
    }else{
      const len = this.categoryBinding.length;
      const more = this.categoryList.slice(len, this.bufferSize + len);
      this.loadingCategory = true;
      setTimeout(() => {
        this.loadingCategory = false;
        more.forEach(m => {
          if (!this.categoryBinding.includes(m))
          {
            this.categoryBinding.push(m);
          }
        }) 
        this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.includes(x));
      }, 200)
    }
  }
  searchCategories(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.categoryList.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loadingCategory = true;
      setTimeout(() => {
        this.loadingCategory = false;
        this.categoryBinding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        if (this.category.some(x => x.name == "All"))
        {
          this.selectedCategory = [];
        }
        this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.includes(x));
      }, 200)
    }
    else
    {
      AnalyticsHelper.hanldeCheckBoxSelect(event);
      this.categoryBinding = this.categoryList.slice(0, this.bufferSize).filter(x=> !this.selectedCategory.includes(x));
    }
  }
  onCloseCategories(){
    if (this.brandName.some(x => x.name === "All"))
    {
      this.categoryBinding = this.categoryList.slice(0, this.bufferSize);
    }
    this.category = this.aggregatedCategory;
    if (!this.category.some(x => x.name == "All"))
    {
      this.selectedCategory = [ALL_OBJ_NOCODE];
      this.category.forEach(element => {
        if(!this.selectedCategory.includes(element)){
          this.selectedCategory.push(element);
        }
      });
    }

    if (this.totalCategoryBinding.length > 0 && !this.category.some(x => x.name == "All"))
    {
      this.categoryBinding = this.totalCategoryBinding.filter(t => t.name != "All" && !this.selectedCategory.some(s => s.name == t.name))
    }
    else
    {
      this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.includes(x));
      this.totalCategoryBinding = this.selectedCategory.concat(this.categoryBinding);
    }
  }
  //PackageLev1 
  fetchMorePackageLev1(term) {
    if(term){
      const len = this.pkgLev1Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.pkgLev1List.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loadingPackageLev1 = true;
      setTimeout(() => {
        this.loadingPackageLev1 = false;
        let packageLev1Filter = this.pkgLev1Binding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!packageLev1Filter.includes(m))
          {
            packageLev1Filter.push(m);
          }
        }); 
        this.pkgLev1Binding = packageLev1Filter;
        this.pkgLev1Binding = this.pkgLev1Binding.filter(x=> !this.selectedPackageLev1.includes(x));
      }, 200);
    }else{
      const len = this.pkgLev1Binding.length;
      const more = this.pkgLev1List.slice(len, this.bufferSize + len);
      this.loadingPackageLev1 = true;
      setTimeout(() => {
        this.loadingPackageLev1 = false;
        more.forEach(m => {
          if (!this.pkgLev1Binding.includes(m))
          {
            this.pkgLev1Binding.push(m);
          }
        })
        this.pkgLev1Binding = this.pkgLev1Binding.filter(x=> !this.selectedPackageLev1.includes(x));
      }, 200)
    }
  }
  searchPackageLev1(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.pkgLev1List.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loadingPackageLev1 = true;
      setTimeout(() => {
        this.loadingPackageLev1 = false;
        this.pkgLev1Binding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        this.pkgLev1Binding = this.pkgLev1Binding.filter(x=> !this.selectedPackageLev1.includes(x));
      }, 200)
    }
    else
    {
      AnalyticsHelper.hanldeCheckBoxSelect(event);
      this.pkgLev1Binding = this.pkgLev1List.slice(0, this.bufferSize).filter(x=> !this.selectedPackageLev1.includes(x));
    }
  }
  onClosePackageLev1(){
    this.pkgLev1Binding = this.pkgLev1List.slice(0, this.bufferSize);
    this.packageLev1 = this.aggregatedPackageLev1;   
    if (!this.packageLev1.some(p => p.name == "All"))
    {
      this.selectedPackageLev1 = [ALL_OBJ_NOCODE];
      this.packageLev1.forEach(element => {
        if(!this.selectedPackageLev1.includes(element)){
          this.selectedPackageLev1.push(element);
        }
      });
    }

    this.pkgLev1Binding = this.pkgLev1Binding.filter(x=> !this.selectedPackageLev1.includes(x));
  }
  //Container  
  fetchMoreContainer(term) {
    if(term){
      const len = this.containerBinding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).length;
      const more = this.containerList.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1).slice(len, this.bufferSize + len);
      this.loadingContainer = true;
      setTimeout(() => {
        this.loadingContainer = false;
        let containerFilter = this.containerBinding.filter(x=>x.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
        more.forEach(m => {
          if (!containerFilter.includes(m))
          {
            containerFilter.push(m);
          }
        }); 
        this.containerBinding = containerFilter;
        this.containerBinding = this.containerBinding.filter(x=> !this.selectedContainer.includes(x));
      }, 200);
    }else{
      const len = this.containerBinding.length;
      const more = this.containerList.slice(len, this.bufferSize + len);
      this.loadingContainer = true;
      setTimeout(() => {
        this.loadingContainer = false;
        more.forEach(m => {
          if (!this.containerBinding.includes(m))
          {
            this.containerBinding.push(m);
          }
        }) 
        this.containerBinding = this.containerBinding.filter(x=> !this.selectedContainer.includes(x));
      }, 200)
    }
  }
  searchContainer(event: any) {
    const text = event.term;
    if(text.length){
      var newList = this.containerList.filter(x=>x.name.toLocaleLowerCase().indexOf(text.toLocaleLowerCase()) > -1);
      this.loadingContainer = true;
      setTimeout(() => {
        this.loadingContainer = false;
        this.containerBinding = newList.slice(0, this.bufferSize);
        AnalyticsHelper.hanldeCheckBoxSelect(event);
        this.containerBinding = this.containerBinding.filter(x=> !this.selectedContainer.includes(x));
      }, 200)
    }
    else
    {
      AnalyticsHelper.hanldeCheckBoxSelect(event);
      this.containerBinding = this.containerList.slice(0, this.bufferSize).filter(x=> !this.selectedContainer.includes(x));
    }
  }
  onCloseContainer(){
    this.containerBinding = this.containerList.slice(0, this.bufferSize);
    this.container = this.aggregatedContainer; 
    if (!this.container.some(c => c.name == "All"))
    {
      this.selectedContainer = [ALL_OBJ_NOCODE]; 
      this.container.forEach(element => {
        if(!this.selectedContainer.includes(element)){
          this.selectedContainer.push(element);
        }
      });
    }
    this.containerBinding = this.containerBinding.filter(x=> !this.selectedContainer.includes(x));
  }
  onRemoveFilterSalesArea($event) {
    if(this.salesArea.length == 0){
      this.salesArea = [ALL_OBJ];
      return;
    }  
  }

  onAddFilterSalesArea($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.salesArea = [ALL_OBJ];
      return;
    }
    this.salesArea = this.salesArea.filter(e => e.cd !== ALL_OBJ.cd);
  }

  setSalesArea()
  {
    this.salesArea = this.aggregatedSalesArea;
  }

  SalesAreaAggregate()
  {
    this.aggregatedSalesArea = this.salesArea;
    this.salesDistrict = [ALL_OBJ];
    this.salesDistrictBinding = this.salesDistrictList;
    this.aggregatedSalesDistrict = [ALL_OBJ];
    this.bindingSalesDistrictData();    
  }

  onCloseSalesArea()
  {
    this.salesArea = this.aggregatedSalesArea;
  }

  bindingSalesDistrictData()
  {
    if (!this.salesArea.some(x => x.name === "All"))
    {
      this.salesDistrict = [ALL_OBJ];
      this.salesDistrictBinding = [ALL_OBJ];
      this.salesArea.forEach(x => {
        var district_names = this.sales_area_to_sales_district[x.name];
        this.salesDistrictList.filter(d => district_names.includes(d.name)).forEach(s => {
          if (!this.salesDistrictBinding.some(x => x.name === s.name))
          {
            this.salesDistrictBinding.push(s);
          }          
        });       
      })
    }
  }

  onRemoveFilterSalesDistrict($event) {
    if(this.salesDistrict.length == 0){
      this.salesDistrict = [ALL_OBJ];      
      return;
    }
  }

  onAddFilterSalesDistrict($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.salesDistrict = [ALL_OBJ];
      return;
    }
    this.salesDistrict = this.salesDistrict.filter(e => e.cd !== ALL_OBJ.cd);
  }

  setSalesDistrict()
  {
      this.salesDistrict = this.aggregatedSalesDistrict;      
  }

  SalesDistrictAggregate()
  {
    this.aggregatedSalesDistrict = this.salesDistrict;
  }

  onCloseSalesDistrict()
  {
      this.salesDistrict = this.aggregatedSalesDistrict;   
  }
  
  onRemoveFilterJanCode($event) {
    if(this.janCode.length == 0){
      this.janCode = [ALL_OBJ_NOCODE];
      return;
    }
  }

  onAddFilterJanCode($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.janCode = [ALL_OBJ_NOCODE];
      return;
    }
    this.janCode = this.janCode.filter(e => e.name !== ALL_OBJ_NOCODE.name);
  }

  setJanCode()
  {
    this.janCode = this.aggregatedJanCode;
    this.selectedJanCode = this.aggregatedJanCode;
    if (this.isaggregatedJancode)
    {
      var all_jancode = [ALL_OBJ_NOCODE];
      if (!this.selectedJanCode.some(x => x.name == "All"))
      {
        this.selectedJanCode = all_jancode.concat(this.selectedJanCode);
      } 
      this.janCode_Binding = [];
    }
    else
    {
      this.janCode_Binding = [];
    }   
  }

  JanCodeAggregate()
  {
    this.isaggregatedJancode = true;
    this.aggregatedJanCode = this.janCode;
  }

  onRemoveFilterProductNameJP($event) {
    if(this.productNameJP.length == 0){
      this.productNameJP = [ALL_OBJ_NOCODE];
      return;
    }
    // this.bindingProductName_JPData();
  }
  onAddFilterProductNameJP($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.productNameJP = [ALL_OBJ_NOCODE];
      return;
    }
    this.productNameJP = this.productNameJP.filter(e => e.name !== ALL_OBJ_NOCODE.name);
  }

  setProductName()
  {
    this.productNameJP = this.aggregatedProductName;   
  }

  onCloseProductname(){
    //this.productname_jpBinding = this.productname_jpList.slice(0,this.bufferSize);
    this.productNameJP = this.aggregatedProductName;

    if (!this.productNameJP.some(x => x.name == "All"))
    {
      this.selectedProductName = [ALL_OBJ_NOCODE];
      this.productNameJP.forEach(element => {
        if(!this.selectedProductName.includes(element)){
          this.selectedProductName.push(element);
        }
      });
    }

    if (this.totalProductNameBinding.length > 0 && !this.productNameJP.some(x => x.name == "All"))
    {
      this.productname_jpBinding = this.totalProductNameBinding.filter(t => t.name != "All" && !this.selectedProductName.some(s => s.name == t.name))
    }
    else
    {
      this.productname_jpBinding = this.productname_jpBinding.filter(x=> !this.selectedProductName.includes(x));
      this.totalProductNameBinding = this.selectedProductName.concat(this.productname_jpBinding);
    }

  }

  ProductNameAggregate()
  {
    this.aggregatedProductName = this.productNameJP;
  }

  onRemoveFilterBrandName($event) {
    if(this.brandName.length == 0){
      this.brandName = [ALL_OBJ_NOCODE];
      return;
    }
    if ((this.brandName.length > 1 && this.brandName.some(b => b.name === "All")) || $event.cancelable == false || this.brandName.length > this.brandNameBinding_previous.length)
    {
      return;
    }
    this.brandNameBinding_previous = this.brandName;
  }

  onAddFilterBrandName($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.brandName = [ALL_OBJ_NOCODE];
      return;
    }
    this.brandName = this.brandName.filter(e => e.name !== ALL_OBJ_NOCODE.name);
    this.brandNameBinding_previous = this.brandName;
  }

  setBrandName()
  {
    this.brandName = this.aggregatedBrandName;
  }

  BrandNameAggregate()
  {
    this.totalCategoryBinding = [];
    this.totalProductNameBinding = [];
    this.selectedCategory = [];
    this.selectedProductName = [];
    this.aggregatedBrandName = this.brandName;
    this.aggregatedCategory = [ALL_OBJ_NOCODE];
    this.aggregatedProductName = [ALL_OBJ_NOCODE];
    if (this.brandName.some(b => b.name == "All"))
    {
      this.selectedBrand = [];
      this.category = [ALL_OBJ_NOCODE];
      this.categoryBinding = this.categoryList.slice(0,this.bufferSize);
      this.productNameJP = [ALL_OBJ_NOCODE];
      this.productname_jpBinding = this.productname_jpList;
    }
    this.bindingCategoryProducname_JPData();
  }

  bindingCategoryProducname_JPData()
  {
      var brands = this.brandName.some(x => x.name === "All") ? this.brandnameList : this.brandName;
      this.category = [ALL_OBJ_NOCODE];
      this.categoryBinding = [ALL_OBJ_NOCODE];
      this.productNameJP = [ALL_OBJ_NOCODE];

      brands.filter(x=>x.name != 'All').forEach(x => {
        this.groupBrandnameCategory[x.name].map(g => {
          return {name: g}
        }).forEach(b => {
          if (!this.categoryBinding.some(c => c.name == b.name))
          {
            this.categoryBinding.push(b);
          }
        })
      });

      this.categoryBinding = this.categoryBinding.filter(x=> !this.selectedCategory.some(s => s.name == x.name));
      this.bindingProductName_JPData();
  }

  bindingProductName_JPData()
  {
    if (!this.brandName.some(x => x.name === "All"))
    {
      var categories = this.category.some(x => x.name === "All") ? this.categoryList : this.category;
      this.productNameJP = [ALL_OBJ_NOCODE];
      this.productname_jpList = [ALL_OBJ_NOCODE];
      categories.forEach(c => {
        this.brandName.forEach(b => {
          if (c.name in this.groupBrandnameCategoryProductNameJp[b.name])
          {
            this.groupBrandnameCategoryProductNameJp[b.name][c.name].map(x => {
              return {name: x}
            }).forEach(e => {    
              if (!this.productname_jpList.some(p => p.name == e.name))
              {
                this.productname_jpList.push(e);
              }
            })
          }
        })
      });
    }
    else
    {
      this.productNameJP = [ALL_OBJ_NOCODE];
      this.productname_jpList = [ALL_OBJ_NOCODE];
      this.dataInit.groupBrandnameCategoryProductNameJpData.productname_jpList.forEach(x => {
        this.productname_jpList.push({name: x})
      });
    }
    this.productname_jpBinding = this.productname_jpList.slice(0, this.bufferSize);
    this.productname_jpBinding = this.productname_jpBinding.filter(x=> !this.selectedProductName.some(s => s.name == x.name));
  }

  onRemoveFilterCategory($event) {
    if(this.category.length == 0){
      this.category = [ALL_OBJ_NOCODE];
      return;
    }
    if ((this.category.length > 1 && this.category.some(b => b.name === "All")) || $event.cancelable == false || this.category.length > this.categoryBinding_previous.length)
    {
      return;
    }
    this.categoryBinding_previous = this.category;

  }

  onAddFilterCategory($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.category = [ALL_OBJ_NOCODE];
      return;
    }
    this.category = this.category.filter(e => e.name !== ALL_OBJ_NOCODE.name);
    this.categoryBinding_previous = this.category;
  }

  setCategory()
  {
    this.category = this.aggregatedCategory;   
  }

  CategoryAggregate()
  {
    this.aggregatedCategory = this.category;
    this.totalProductNameBinding = [];
    this.selectedProductName = [];
    this.aggregatedProductName = [ALL_OBJ_NOCODE];
    this.aggregatedPackageLev1 = [ALL_OBJ_NOCODE];
    this.aggregatedContainer = [ALL_OBJ_NOCODE];
    this.packageLev1 = [ALL_OBJ_NOCODE];
    this.pkgLev1Binding = this.pkgLev1List.slice(0, this.bufferSize);
    this.container = [ALL_OBJ_NOCODE];
    this.containerBinding = this.containerList.slice(0, this.bufferSize);
    this.productNameJP = [ALL_OBJ_NOCODE];
    this.productname_jpBinding = this.productname_jpList;
    this.bindingProductName_JPData();
    this.bindingPkgLev1ContainerData();
  }

  bindingPkgLev1ContainerData()
  {
      var categories = this.category.some(x => x.name === "All") ? this.categoryList : this.category;
      this.packageLev1 = [ALL_OBJ_NOCODE];
      this.pkgLev1Binding = [ALL_OBJ_NOCODE];
      this.container = [ALL_OBJ_NOCODE];
      this.containerBinding = [ALL_OBJ_NOCODE];

      categories.filter(x=>x.name != "All").forEach(x => {
        // var pgkLev_names = this.groupcategorypackagecontainer[x.name]["package_name"];
        var container_names = this.groupcategorypackagecontainer[x.name]["container_name"];
        // this.pkgLev1List.filter(p => pgkLev_names.includes(p.name)).forEach(s => {
        //   if (!this.pkgLev1Binding.some(x => x.name === s.name))
        //   {
        //     this.pkgLev1Binding.push(s);
        //   }
        // })
        this.containerList.filter(c => container_names.includes(c.name)).forEach(s => {
          if (!this.containerBinding.some(x => x.name === s.name))
          {
            this.containerBinding.push(s);
          }
        })
      });
      this.containerBinding = this.containerBinding.filter(x=> !this.selectedContainer.some(s => s.name == x.name));
      this.bindingPkgLev1Data();
  }

  bindingPkgLev1Data()
  {
    this.packageLev1 = [ALL_OBJ_NOCODE];
    this.pkgLev1List = [ALL_OBJ_NOCODE];
    if(this.container.some(x => x.name === "All")){
      this.dataInit.groupcategoryPackageContainerData.pkgLev1List.forEach(x => {
        if (!this.pkgLev1List.some(s => s.name === x)) {
          this.pkgLev1List.push({ name: x });
        }
        });  
    }else{
      this.container.forEach(x => {
        var pgkLev_names = this.grouppackagecontainer[x.name];
        pgkLev_names?.forEach(element => {
          if (!this.pkgLev1List.some(s => s.name === element))
          {
            this.pkgLev1List.push({name: element});
          }
        });
        this.selectedPackageLev1 = [];
      });
    }
    this.pkgLev1Binding = this.pkgLev1List.slice(0, this.bufferSize);
    this.pkgLev1Binding = this.pkgLev1Binding.filter(x=> !this.selectedPackageLev1.some(s => s.name == x.name));
  }

  onRemoveFilterPackageLev1($event) {
    if(this.packageLev1.length == 0){
      this.packageLev1 = [ALL_OBJ_NOCODE];
      return;
    }
  }

  onAddFilterPackageLev1($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.packageLev1 = [ALL_OBJ_NOCODE];
      return;
    }
    this.packageLev1 = this.packageLev1.filter(e => e.name !== ALL_OBJ_NOCODE.name);
  }

  setPackageLev1()
  {
    this.packageLev1 = this.aggregatedPackageLev1;
  }

  PackageLev1Aggregate()
  {
    this.aggregatedPackageLev1 = this.packageLev1;
  }
  
  onRemoveFilterChannelLev1($event) {
    if(this.channelLev1.length == 0){
      this.channelLev1 = [ALL_OBJ];
      this.onChannelLev1Change();
    }
  }
  onAddFilterChannelLev1($event) {
    if ($event.cd === ALL_OBJ.cd) {
      this.channelLev1 = [ALL_OBJ];
      return;
    }
    this.channelLev1 = this.channelLev1.filter(e => e.cd !== ALL_OBJ.cd);
  }

  setChannelLevel1()
  {
    this.channelLev1 = this.aggregatedChannelLevel1;
  }

  ChannelLevel1Aggregate()
  {
    this.aggregatedChannelLevel1 = this.channelLev1;
  }

  onCloseChannelLevel1()
  {
    this.channelLev1 = this.aggregatedChannelLevel1;
  }

  onRemoveFilterContainer($event) {
    if(this.container.length == 0){
      this.container = [ALL_OBJ_NOCODE];

      return;
    }
  }

  onAddFilterContainer($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.container = [ALL_OBJ_NOCODE];
    }else{
      this.container = this.container.filter(e => e.name !== ALL_OBJ_NOCODE.name);
    }
  }

  setContainer()
  {
    this.container = this.aggregatedContainer;    
  }

  ContainerAggregate()
  {
    this.aggregatedContainer = this.container;
    this.aggregatedPackageLev1 = [ALL_OBJ_NOCODE];
    this.packageLev1 = [ALL_OBJ_NOCODE];
    this.bindingPkgLev1Data();
  }

  onChannelLev1Change(){
    if(this.channelLev1.length==0){
      this.channelLev1 = [ALL_OBJ];
    }
  }

  onRemovegroupByColumns($event) {
    if(this.groupByColumns.length == 0){
      this.groupByColumns = [ALL_OBJ_NOCODE];
      return;
    }  
  }

  onAddgroupByColumns($event) {
    if ($event.name === ALL_OBJ_NOCODE.name) {
      this.groupByColumns = [ALL_OBJ_NOCODE];
      return;
    }
    this.groupByColumns = this.groupByColumns.filter(e => e.name !== ALL_OBJ_NOCODE.name);
  }

  setgroupByColumns()
  {
    this.groupByColumns = this.aggregatedgroupByColumns;
  }

  groupByColumnsAggregate()
  {
    this.aggregatedgroupByColumns = this.groupByColumns;   
  }

  onClosegroupByColumns()
  {
    this.groupByColumns = this.aggregatedgroupByColumns;
  }

  handleSKU() {};
  handlePageChange(event) {
    (<HTMLInputElement>document.getElementById("gotopage")).value = "";
    this.pageNumber = event;
    this.pagination.current = event;
    if (this.groupByColumns.some(x => x.name == 'All'))
    {
      this.displayColumns = [];
    }
    this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: event, colName: this.current_sortcol, orderby: this.current_sortcol_order});
    this.dataChange = true;
  };

  GotoPageEvent()
  {
    var input = (<HTMLInputElement>document.getElementById("gotopage")).value.trim();
    if ((this.displayData && this.displayData.length > 0) && !input.match(/^[0]/) && !input.match(/[A-Za-z!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/) && input.trim() != '')
    {
      var page_togo = (<HTMLInputElement>document.getElementById("gotopage")).value; 
      if (Number.isNaN(parseInt(page_togo)))
      {
        return;
      }     
      var total_pages = Math.ceil(this.pagination.totalItem/this.pagination.sizeOnPage);
      if (parseInt(page_togo) > total_pages)
      {
        this.pageNumber = total_pages;
        this.pagination.current = total_pages;
      }
      else
      {
        this.pageNumber = parseInt(page_togo);
        this.pagination.current = parseInt(page_togo);
      }
      (<HTMLInputElement>document.getElementById("gotopage")).value = this.pageNumber.toString();
      if (this.groupByColumns.some(x => x.name == 'All'))
      {
        this.displayColumns = [];
      }
      this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: this.pageNumber, colName: this.current_sortcol, orderby: this.current_sortcol_order});
    }
    this.dataChange = true;
  }

  sort(colName) {
    var orderby = "";
    var color_descIcon = document.getElementById(colName + "DESC").style.color;
    var color_ascIcon = document.getElementById(colName + "ASC").style.color;

    if ((color_descIcon != "red" && color_ascIcon != "red") || color_descIcon == "red")
    {
      orderby = "ASC";
    }
    else if (color_ascIcon == "red")
    {
      orderby = "DESC";
    }

    this.current_sortcol = colName;
    this.current_sortcol_order = orderby;
    this.pagination.current = 1;
    (<HTMLInputElement>document.getElementById("gotopage")).value = "";
    if (this.groupByColumns.some(x => x.name == 'All'))
    {
      this.displayColumns = [];
    }
    this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: 1, colName: colName, orderby: orderby});

  
    this.dataChange = true;
  }

  searchSkuData() {
    // SALES_AREA_CODE
    this.condition = "";
    if (this.salesArea[0].cd != ALL_OBJ.cd) {
      let salesAreaCond = ""
      this.salesArea.forEach(el => {
        salesAreaCond += "'" + el.cd.replace("'", "''") +"',";
      });
      salesAreaCond = salesAreaCond.slice(0, -1);
      this.condition += " AND SALES_AREA_CODE IN (" + salesAreaCond + ")";
    }

    // SALES_DISTRICT_CODE
    if (this.salesDistrict[0].cd != ALL_OBJ.cd) {
      let salesDistrictCond = ""
      this.salesDistrict.forEach(el => {
        salesDistrictCond += "'" + el.cd.replace("'", "''") +"',";
      });
      salesDistrictCond = salesDistrictCond.slice(0, -1);
      this.condition += " AND SALES_DISTRICT_CODE IN (" + salesDistrictCond + ")";
    }

    // CHANNEL_LEV1_CODE
    if (this.channelLev1[0].cd != ALL_OBJ.cd) {
      let channelLev1Cond = ""
      this.channelLev1.forEach(el => {
        channelLev1Cond += "'" + el.cd.replace("'", "''") +"',";
      });
      channelLev1Cond = channelLev1Cond.slice(0, -1);
      this.condition += " AND CHANNEL_LEV1_CODE IN (" + channelLev1Cond + ")";
    }

    // JAN_CODE
    if (this.janCode[0].name != ALL_OBJ_NOCODE.name) {
      let janCodeCond = ""
      this.janCode.forEach(el => {
        janCodeCond += "'" + el.name.replace("'", "''") +"',";
      });
      janCodeCond = janCodeCond.slice(0, -1);
      this.condition += " AND JAN_CODE IN (" + janCodeCond + ")";
    }

    // CURRENT_JAN_NAME_JP
    if (this.productNameJP[0].name != ALL_OBJ_NOCODE.name) {
      let productNameJPCond = ""
      this.productNameJP.forEach(el => {
        productNameJPCond += "'" + el.name.replace("'", "''") +"',";
      });
      productNameJPCond = productNameJPCond.slice(0, -1);
      this.condition += " AND CURRENT_JAN_NAME_JP IN (" + productNameJPCond + ")";
    }

    // BRAND_NAME_JP
    if (this.brandName[0].name != ALL_OBJ_NOCODE.name) {
      let brandNameCond = ""
      this.brandName.forEach(el => {
        brandNameCond += "'" + el.name.replace("'", "''") +"',";
      });
      brandNameCond = brandNameCond.slice(0, -1);
      this.condition += " AND BRAND_NAME_JP IN (" + brandNameCond + ")";
    }

    // CATEGORY_NAME
    if (this.category[0].name != ALL_OBJ_NOCODE.name) {
      let categoryCond = ""
      this.category.forEach(el => {
        categoryCond += "'" + el.name.replace("'", "''") +"',";
      });
      categoryCond = categoryCond.slice(0, -1);
      this.condition += " AND CATEGORY_NAME IN (" + categoryCond + ")";
    }

    // DDIS_CONTAINER_TYPE_NAME
    if (this.container[0].name != ALL_OBJ_NOCODE.name) {
      let containerCond = ""
      this.container.forEach(el => {
        containerCond += "'" + el.name.replace("'", "''") +"',";
      });
      containerCond = containerCond.slice(0, -1);
      this.condition += " AND DDIS_CONTAINER_TYPE_NAME IN (" + containerCond + ")";     
    }

    // CCEJ_PACKAGE_NAME
    if (this.packageLev1[0].name != ALL_OBJ_NOCODE.name) {
      let packageLev1Cond = ""
      this.packageLev1.forEach(el => {
        packageLev1Cond += "'" + el.name.replace("'", "''") +"',";
      });
      packageLev1Cond = packageLev1Cond.slice(0, -1);
      this.condition += " AND CCEJ_PACKAGE_NAME IN (" + packageLev1Cond + ")";
    }

    this.pageNumber = 1;
    this.pagination.current = 1;
    this.current_sortcol = "nsr_ty";
    this.current_sortcol_order = "DESC";
    (<HTMLInputElement>document.getElementById("gotopage")).value = "";

    if (this.groupByColumns.some(x => x.name != 'All'))
    {
      this.displayColumns = [];
      if (this.salesArea.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('SALES_AREA_NAME'))
        {
          this.displayColumns.push('SALES_AREA_NAME');
        }
      }
      if (this.salesDistrict.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('SALES_DISTRICT_NAME'))
        {
          this.displayColumns.push('SALES_DISTRICT_NAME');
        }
      }
      if (this.channelLev1.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('CHANNEL_LEV1_NAME'))
        {
          this.displayColumns.push('CHANNEL_LEV1_NAME');
        }
      }

      if (this.brandName.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('BRAND_NAME_JP'))
        {
          this.displayColumns.push('BRAND_NAME_JP');
        }
      }

      if (this.category.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('CATEGORY_NAME'))
        {
          this.displayColumns.push('CATEGORY_NAME');
        }
      }

      if (this.productNameJP.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('CURRENT_JAN_NAME_JP'))
        {
          this.displayColumns.push('CURRENT_JAN_NAME_JP');
        }
      }

      if (this.janCode.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('JAN_CODE'))
        {
          this.displayColumns.push('JAN_CODE');
        }
      }

      if (this.container.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('DDIS_CONTAINER_TYPE_NAME'))
        {
          this.displayColumns.push('DDIS_CONTAINER_TYPE_NAME');
        }
      }

      if (this.packageLev1.some(s => s.name != 'All'))
      {
        if (!this.displayColumns.includes('CCEJ_PACKAGE_NAME'))
        {
          this.displayColumns.push('CCEJ_PACKAGE_NAME');
        }
      }

      this.groupByColumns.forEach(x => {
        if (!this.displayColumns.includes(this.mapSKUFilters[x.name]))
        {
          this.displayColumns.push(this.mapSKUFilters[x.name]);
        }
      })
      this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: 1, isSearch: true});
    }    
    else
    {
      this.displayColumns = [];
      this.bindingTableData.emit({condition: this.condition, displayColumns: this.displayColumns, pageNumber: 1, isSearch: true});
    }

    this.skuReady = false;
    this.dataChange = true;

  }
  
  formatNumber(number: any) {
    return AnalyticsHelper.formatNumber(number);
  }

  bindingSalesAreaAndSalesDistrict(queryUrl){
    const httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
        responseType: 'text'
    }
    this.http.get<any>(queryUrl, httpOptions).subscribe(
        (response) => {
          var res = tsvParse.tsvParse("area_name	district_name\n" + response);
            res.forEach(x => {
                if (!(x.area_name in this.sales_area_to_sales_district))
                {
                  //bind data to sales_district
                    this.sales_area_to_sales_district[x.area_name] = [];
                    this.sales_area_to_sales_district[x.area_name].push(x.district_name);
                }
                else 
                {
                    this.sales_area_to_sales_district[x.area_name].push(x.district_name);

                }
            })
        },
        (error) => {
            console.log(error);
        }
    );
}

  customSearchFn(term: string, item) {
      term = term.toLocaleLowerCase();
      return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  htmlTableToExcel(type){
    const data = document.createElement("table");
    data.innerHTML = document.getElementById('skuHeader').innerHTML + document.getElementById('skuId').innerHTML;
    // var data = document.getElementById('skuId');
    var wb = XLSX.utils.table_to_book(data, {sheet: "Sheet1"});
    var ws = wb.Sheets["Sheet1"];
    XLSX.utils.sheet_add_aoa(ws, [
    ], { origin: "A0" });
    var dateString = new Date().toLocaleString();
    XLSX.writeFile(wb, "Report_SalesBySku_" + dateString + ".xlsx");
  }
}
