<div class="row">
    <div class="col-lg-12 col-md-12">
        <div #scroll class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Value vs LY​​</h3>
            </div>
            <div class="filter-section no-title mb-4">
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Weekly'}, {value: 'mtd', name: 'Monthly'}, {value: 'qtd', name: 'Quarterly'}, {value: 'ytd', name: 'Yearly'}]"
                    [model]="aggregateBy"
                    (change)="onOtcValueAggregateChange($event)"
                    >
                </single-dropdown>

                <moving-average-dropdown [class]="aggregateBy == 'wtd' ? '' : 'not-display'" title="Display"
                    [items]="[{value: 4, name: '4-Week Moving Average'}]"
                    (change)="onMovingAverageWindowSizeChange($event)"
                    [(model)]="movingAverageWindowSizes"
                    >
                </moving-average-dropdown>

            </div>

            <div class="filter-radio title-makers">
                <div class="items">
                    <div class="item" *ngFor="let maker of filterMakers(marketShareMakers); index as i;">
                        <input class="form-check-input" (change)="onShareVsLyMakersCheckedChanged(maker.maker)" type="checkbox" value="{{maker.maker}}" [checked]="maker.checked" [disabled]="otcHaveSingleSerie && maker.maker === otcSingleSerie">
                        <label class="form-check-label" >{{maker.maker}}</label>
                    </div>
                </div>            
            </div>

            <div class="filter-section even-wrapper">
                <div class="ng-section summary-section">
                    <b>Category</b>
                    <ng-select #ngFilterCategoryChannel class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="selectedCategory[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterOTCCategory($event)"
                    (change)="onRemoveFilterOTCCategory($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterOTCCategory($event)"
                    [clearable]="selectedCategory.length > 1">
                        <ng-option *ngFor="let item of categoriesFilter" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{formatCategoryName(item)}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="ngFilterCategoryChannel.close();SaveOptionValueTemp(false);onFilterByCategoryChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Region</b>
                    <ng-select #ngFilterOTCRegion class="form-control filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="otcSelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterOTCRegion($event)"
                    (change)="onRemoveFilterOTCRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterOTCRegion($event)"
                    [clearable]="otcSelectedRegion.length > 1">
                        <ng-option *ngFor="let item of districts" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterOTCRegion.close();onFilterByOtcRegionChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div #screen class="card-body">
                <div id="intage-vm-market-value-vsLy-chart"></div>
            </div>
            <ng-container *ngIf="otcHaveSingleSerie">
                <div class="single-series-container {{aggregateBy ?? 'wtd'}} movingAverageWindowSizes-{{movingAverageWindowSizes.length}}">
                    <span class="red-dot" [ngStyle]="{backgroundColor: otcSingleSerieColor}"></span>
                    <span class="single-series">{{otcSingleSerie}}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="!otcHaveData">
                <div class="no-data-container">
                    <span class="no-data-text">No data available for this combination. Please refine your selection</span>
                </div>
            </ng-container>
            <div class="flex-two-ends">
                <single-dropdown title="Start Week"
                    [items]="items"
                    (change)="onSelectedStartWeekChanged($event)"
                    [model]="otcStartWeeks">
                </single-dropdown>

                <download-button>
                    <button ngbDropdownItem (click)="exportOTCValuevsLY(true)">Download PNG</button>
                    <button ngbDropdownItem (click)="exportOTCValuevsLY(false)">Download CSV</button>
                </download-button>
            </div>
            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>