import { HttpClient, HttpHeaders, HttpXhrBackend } from '@angular/common/http';
import html2canvas from 'src/html2canvas';
import { jsPDF } from 'jspdf';
import { environment } from 'src/environments/environment';
import { PDFProperty } from '../model/pdfProperty.model';

export async function downloadAsPDF(filename, ref, sendToServer = false,  pdfProperty : PDFProperty = {} ) {

	const $ = window["jQuery"];

	$("body").addClass("pdf-printing");

	const makerLogo = $("img[class^='maker-logo']");
	makerLogo.each(function(index){
		const el = $(this);
		el.css("height", parseInt(el.height())+'px');
	});

	$("select").each(function(index){
		const el = $(this);
		el.after(`<span class="ng-value-label" style="margin-right: 4em !important;display: inline-block !important;">&nbsp;&nbsp;${el.find(":selected").text()}<span>`);
	});

	$('.market-size').css('height', 'auto')

	if(sendToServer){
		html2canvas(ref).then(function(canvas) {
			// Convert the canvas to blob
			canvas.toBlob(function(blob){
				var formData = new FormData();
				const httpClient = new HttpClient(new HttpXhrBackend({ 
					build: () => new XMLHttpRequest() 
				}));
				formData.append('File', blob);
				let httpOptions: Object = {
					headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
					responseType: 'json'
				  }
				httpClient.post<any>(environment.saveReportPdf, formData, httpOptions)
					.subscribe(
					  response => {
						console.log(response);
					  },
					  error => {
						console.log(error);
					  }
				);
	
			},'image/png');
		});
		return;
	}

    const pdf = new jsPDF('p', 'px', 'a0', true);

	if(pdfProperty.fontStyle!==undefined && pdfProperty.fontStyle!==null){
		pdf.addFont(pdfProperty.fontStyle.path, pdfProperty.fontStyle.fontName, 'normal');
		pdf.setFont(pdfProperty.fontStyle.fontName);
	}
	else{
		pdf.setFont("helvetica");
	}

	// To override the version of html2canvas in jspdf
	window['html2canvas'] = html2canvas;

	pdf.html(ref, {
		callback: function (pdf) {
			pdf.save(filename);

			$("body").removeClass("pdf-printing");

			makerLogo.each(function(e){
				const el = $(this);
				el.removeAttr("style");
			});

			$("select").each(function(index){
				const el = $(this);
				el.siblings(".ng-value-label").remove();
			});

			$('.market-size').removeAttr("style");

			// Reset after
			window['html2canvas'] = null;
			
		}
	});

}