export function dispatchDateSelectChange(e) {
    const event = new CustomEvent('dateSelectChange', {
        detail: {
            ...e
        }
    });
    document.dispatchEvent(event);
}

export function dispatchNgSelectChange(e) {
    const event = new CustomEvent('ngSelectChange', {
        detail: {
            ...e
        }
    });
    document.dispatchEvent(event);
}