export class IntageVMDashboardHelper {
  static GenerateModelData(key: string,
    data: any,
    shareVsSankeySelectedCategory: any,
    shareVsSankeySelectedRegion: any,
    shareVsSankeySelectedMaker: any) {
    var regions = [...new Map(data.map(item => [item["botter_sci"], item.botter_sci])).values()];
    var categories = [...new Map(data.map(item => [item["category"], item.category])).values()];
    var makers = [...new Map(data.map(item => [item["maker"], item.maker])).values()];
    var sankeyDataModel = [];

    if (key == "category") {
      categories.forEach(element => {
        if (shareVsSankeySelectedCategory.includes("All") || shareVsSankeySelectedCategory.includes(element)) {
          sankeyDataModel.push(
            {
              name: element,
              nameForMap: element == "Other" ? element + " (CTG)" : element,
              tyValue: 0,
              subItems: []
            });
        }
      });
    }
    else if (key == "botter_sci") {
      regions.forEach(element => {
        if (shareVsSankeySelectedRegion.includes("All") || shareVsSankeySelectedRegion.includes(element)) {
          sankeyDataModel.push(
            {
              name: element,
              nameForMap: element,
              tyValue: 0,
              subItems: []
            });
        }
      });
    }
    else if (key == "maker") {
      makers.forEach(element => {
        if (shareVsSankeySelectedMaker.includes("All") || shareVsSankeySelectedMaker.includes(element)) {
          sankeyDataModel.push(
            {
              name: element,
              nameForMap: element,
              tyValue: 0,
              subItems: []
            });
        }
      });
    }    
    return sankeyDataModel;
  }
  static filterOptionNotSelect(keys: any, data: any,
    shareVsSankeySelectedCategory: any,
    shareVsSankeySelectedRegion: any,
    shareVsSankeySelectedMaker: any) {
    keys.forEach(key => {
      if (key == "category") {
        if (!shareVsSankeySelectedCategory.includes("All")) {
          data = data.filter(x => shareVsSankeySelectedCategory.includes(x.category));
        }
      }
      else if (key == "region") {
        if (!shareVsSankeySelectedRegion.includes("All")) {
          data = data.filter(x => shareVsSankeySelectedRegion.includes(x.botter_sci));
        }
      }
      else if (key == "maker") {
        if (!shareVsSankeySelectedMaker.includes("All")) {
          data = data.filter(x => shareVsSankeySelectedMaker.includes(x.maker));
        }
      }
    });
    return data;
  }
} 