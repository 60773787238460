import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {AnalyticsHelper} from 'src/app/helpers/analytics';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  constructor(private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document) {

  }

  ngOnInit() {
    
  }
}
