    <div class="card" id="nsr-channels">
        <div class="card-header d-flex justify-content-between align-items-center">
            <h3>
                <ng-container *ngIf="aggregateBy == 'daily'">
                    NSR % IMP Daily
                </ng-container>
                <ng-container *ngIf="aggregateBy == 'qtd'">
                    NSR % IMP QTD
                </ng-container>
                <ng-container *ngIf="aggregateBy == 'mtd'">
                    NSR % IMP MTD
                </ng-container>
                <ng-container *ngIf="aggregateBy == 'ytd'">
                    NSR % IMP YTD
                </ng-container>
            </h3>
        </div>

        <div class="card-body">
            <div id="nsr-chart"></div>

            <div class="d-flex flex-two-ends mt-4 hide-pdf-print">
                <!-- <comment-button title1="NSR % IMP Daily" chartId="nsr-chart" [chartRef]="nsrByChannelChart" [chatIdDependency]="[{selectedDate, aggregateBy}]"></comment-button> -->

                <download-button>
                    <button ngbDropdownItem (click)="downloadPng()">Download PNG</button>
                    <button ngbDropdownItem (click)="downloadCSV()">Download CSV</button>
                </download-button>
            </div>
        </div>
    </div>
