<ng-container *ngIf="!isVsLyCategoryReady || !isVsLyBrandReady || !isVsLyPackageReady || !isVsLySKUReady || !isVsLyTimelineReady">
  <div class="text-center spinner-wrapper">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>
</ng-container>

<div *ngIf="isLoading" class="loading-overlay">
  <div class="text-center spinner-wrapper">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
</div>

<div id="pdf_print_sku_deepdive" #pdf_print_sku_deepdive 

[class]="isVsLyCategoryReady && isVsLyBrandReady && isVsLyPackageReady  && isVsLySKUReady && isVsLyTimelineReady ? '' : 'not-show' ">
  <div id="pdf_print_area_2" #pdf_print_area_2 class="row" class="row">
    <div class="col-lg-12 col-md-12">
      <div class="card mb-30">
        <div class="sku-title-header">
          <h2>Product Breakdown</h2>
        </div>

        <sales-date-filter
          pageName="SkuDeepdive"
          (onDateTypeSaveItemsChange)="onDateTypeSaveItemsChange($event)"></sales-date-filter>
      </div>
    </div>
  </div>

  <app-sku-deepdive-vs-ly-category
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    (ready)="vsLyCategoryReady($event)"></app-sku-deepdive-vs-ly-category>

  <app-sku-deepdive-vs-ly-brand
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    (ready)="vsLyBrandReady($event)"></app-sku-deepdive-vs-ly-brand>

  <app-sku-deepdive-vs-ly-package
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    (ready)="vsLyPackageReady($event)"></app-sku-deepdive-vs-ly-package>

  <app-sku-deepdive-vs-ly-sku
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    (ready)="vsLySKUReady($event)"></app-sku-deepdive-vs-ly-sku>

    <app-sku-deepdive-vs-ly-timeline
    [lstAllOptionsCalendarData]="lstAllOptionsCalendarData"
    [lstAllOptionsAreaData]="lstAllOptionsAreaData"
    [lstAllOptionsAccountData]="lstAllOptionsAccountData"
    [lstAllOptionsProductData]="lstAllOptionsProductData"
    [lstOption_Selected_DateType]="lstOption_Aggregated_DateType"
    [lstOption_Selected_SalesDate]="lstOption_Selected_SalesDate"
    (ready)="vsLyTimeLineReady($event)"></app-sku-deepdive-vs-ly-timeline>
</div>
