export function processShow({ imageProcess, titleProcess, percentageProcess, desProcess }) {
    document.getElementById("process-image").setAttribute('src', imageProcess);
    document.getElementById("title-process").innerHTML = titleProcess;
    document.getElementById("percent-process").innerHTML = percentageProcess + "%";
    document.getElementById("process-img-detail").setAttribute('src', imageProcess);
    document.getElementById("des-process").innerHTML = desProcess;
    document.getElementById("process-image-outter").style.display = "block";
}

export function processHide() {
    document.getElementById("process-image-outter").style.display = "none";
}