import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';

import { CognitoIdentityProvider,AttributeType } from "@aws-sdk/client-cognito-identity-provider";
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { tokens } from 'src/app/model/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AnalyticsHelper } from 'src/app/helpers/analytics';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  public contactForm: FormGroup;
  private userAttributes: AttributeType[];
  public userName: string;

  constructor(private formBuilder: FormBuilder, private router: Router, private http: HttpClient) { }

  ngOnInit() {
    this.initContactUsForm();
  }

  public initContactUsForm(): void {
    let emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, 
        this.customPatternValid({ pattern: emailRegex, msg: 'Invalid email address' })
      ]],
      message: ['', [Validators.required]]
    });
  }

  public customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      let urlRegEx: RegExp = config.pattern;
      let matchPattern: boolean = config.matchPattern != null ? config.matchPattern : true;
      if (control.value && 
        ((!control.value.match(urlRegEx) && matchPattern) || (control.value.match(urlRegEx) && !matchPattern))
        ) {
        return {
          invalidMsg: config.msg
        };
      } else {
        return null;
      }
    };
  }

  public matchValidator(matchTo: string, msg: string): ValidatorFn {
    return (control: AbstractControl): 
    ValidationErrors | null => {
      return !!control.parent &&
        !!control.parent.value &&
        control.value === 
        (control.parent?.controls as any)[matchTo].value
        ? null
        : 
        { 
          invalidMsg: msg 
        };
    };
  }

  public contactSubmit(){

    const name = this.contactForm.get('name').value;
    const email = this.contactForm.get('email').value;
    const message = this.contactForm.get('message').value;
    let payload = {
      name: name,
      email: email,
      message: message
    };
    let httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'json'
    }

    this.http.post<any>(environment.contactApi, payload, httpOptions)
      .subscribe(
        response => {
          if(response == true){
            AnalyticsHelper.openSweetAlert('Successfully! Your request has been sent', true);
          }
          else{
            AnalyticsHelper.openSweetAlert('Something went wrong');
          }
        },
        error => {
          console.log(error);
          AnalyticsHelper.openSweetAlert('Something went wrong');
        }
      );
  }

  public closeContact(){
    location.href='/dashboard';
  }
}
