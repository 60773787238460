<ng-container *ngIf="!isdistsrictReady ">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<div id="pdf_print_area_intage_flash_vm_full" #pdf_print_area_intage_flash_vm_full [class]="isdistsrictReady ? '' : 'not-show' ">
    <div id="pdf_print_area_2" #pdf_print_area_2 class="row" class="row">
        <div class="col-lg-12 col-md-12">
            <div class="card mb-30">
                <div class="hq-title-header">
                    <h2>{{pageTitle}}</h2>
                </div>

                <sales-date-filter
                    pageName="ProgressRanking"
                    (onDateTypeSaveItemsChange)="onDateTypeSaveItemsChange($event)"></sales-date-filter>
        </div>
    </div>

    <area-sales-ranking
        [pageTitle]="pageTitle"
        [saleYearMonth]="lstOption_Selected_SalesDate_Applied"
        [lstOption_RankingUnit]= "lstOption_RankingUnitany"
        (ready)="distsrictReady($event)"></area-sales-ranking>
</div>
