import html2canvas from 'src/html2canvas';
import { jsPDF } from 'jspdf';
import { PDFProperty } from '../model/pdfProperty.model';

export async function downloadAsPDF(filename, ref, sendToServer = false,  pdfProperty : PDFProperty = {}, orientation?: string ) {
	const $       = window["jQuery"];
	let pdf = null;
	let pdfWidth  = null; // Width of the PDF
	let pdfHeight = null; // Height of each page
	orientation = orientation || 'l';

	if(pdfProperty.canvas !== undefined) {
		if (orientation && orientation === 'l') {
			pdfWidth  = 3460;
			pdfHeight = 2471;
			pdf = new jsPDF({
				orientation: 'l',
				unit: 'px',
				format: [pdfWidth, pdfHeight],
				putOnlyUsedFonts: true,
				floatPrecision: 16, // or "smart", default is 16
				hotfixes: ["px_scaling"]
			 });
		} else {
			pdfWidth  = 3460;
			pdfHeight = 4844;

			pdf = new jsPDF({
				orientation: 'p',
				unit: 'px',
				format: [pdfWidth, pdfHeight],
				putOnlyUsedFonts: true,
				floatPrecision: 16, // or "smart", default is 16
				hotfixes: ["px_scaling"]
			 });
		}

		if(pdfProperty.fontStyle!==undefined){
			pdf.addFont(pdfProperty.fontStyle.path, pdfProperty.fontStyle.fontName, 'normal');
			pdf.setFont(pdfProperty.fontStyle.fontName);
		}
		else{
			pdf.setFont("helvetica");
		}

		const canvasWidth = pdfProperty.canvas.width;
		const scaleFactor = pdfWidth / canvasWidth;
		const scaledCanvasHeight = pdfProperty.canvas.height * scaleFactor;

		const totalPages = Math.ceil(scaledCanvasHeight / pdfHeight);

		for (let currentPage = 0; currentPage < totalPages; currentPage++) {
			if (currentPage > 0) {
				pdf.addPage();
			}

			const canvas = document.createElement('canvas');
			canvas.width = pdfWidth;
			canvas.height = currentPage < totalPages - 1 ? pdfHeight : scaledCanvasHeight - currentPage * pdfHeight;

			const ctx = canvas.getContext('2d');
			ctx.imageSmoothingEnabled = false
			const sourceY = currentPage * pdfHeight / scaleFactor;
			const sourceHeight = canvas.height / scaleFactor;

			ctx.drawImage(
				pdfProperty.canvas, 
				0, 
				sourceY, 
				canvasWidth, 
				sourceHeight, 
				0, 
				0, 
				pdfWidth, 
				canvas.height,
			);

			const ctxImage = canvas.toDataURL('image/png');
			pdf.addImage(ctxImage, 'PNG', 0, 0, pdfWidth, canvas.height);
		}

		pdf.save(filename);
		$("body").removeClass("pdf-printing-se pdf-printing");
		$(".table-container").removeAttr('style');
		$(".market-size").removeAttr('style');
		$(".filter-section").removeAttr('style');
		$(".subtitle").removeAttr('style');
		$(".hidden-cell").css('display', 'flex');
	} else {
		const pdf = new jsPDF('p', 'px', 'a0', true);
		if(pdfProperty.fontStyle!==undefined){
			pdf.addFont(pdfProperty.fontStyle.path, pdfProperty.fontStyle.fontName, 'normal');
			pdf.setFont(pdfProperty.fontStyle.fontName);
		}
		else{
			pdf.setFont("helvetica");
		}

		pdf.html(ref, {
			margin: [20,20,20,20],// margin page
			callback: function (pdf) {
			  	pdf.save(filename);
				$("body").removeClass("pdf-printing-se pdf-printing");
			}
		});
	}
}
