import { Component, Renderer2, Inject, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {AnalyticsHelper} from 'src/app/helpers/analytics';
import { IntageHelper } from 'src/app/components/pages/intage-vm/intage_helper';
import lodashClonedeep from 'lodash.clonedeep';
@Component({
  selector: 'intage_vm_score_cards',
  templateUrl: './score_cards_vm.component.html',
  styleUrls: ['./score_cards_vm.component.scss']
})
export class ScoreCardsVMComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  categoryColors: any;
  categoriesFilter: any;
  marketShareData: any;
  makerLogo: any;
  scoreCardData: any;
  marketShareMakers: any;
  summaryPackageTypes: any;
  scoreCardSelectedMaker: any;
  scoreCardSelectedCategory: any;
  scoreCardSelectedRegion: any;
  populated: boolean;
  aggregateBy: any;
  districts = ['CCBJI', 'East Japan', 'Kanto', 'Tokyo', 'Central Japan', 'Kinki', 'Chugoku-Shikoku', 'Kyushu'];

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }
  ngOnChanges() {
    window.scrollTo(0,0);
      if(!this.populated){
        this.scoreCardSelectedMaker = ['All'];
        this.scoreCardSelectedCategory = ['Total'];
        this.scoreCardSelectedRegion = ['CCBJI'];
        this.categoryColors = IntageHelper.CategoryColors();
        this.makerLogo = IntageHelper.MakerLogos();
        this.marketShareMakers = [{maker:'All'}];
        this.scoreCardData = {
          maker: '',
          logo: '',
          valueVsLy: '',
          unitVsLy: ''
        };
        this.makerLogo.forEach(x => {
          this.marketShareMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked
          });
        });
        this.populated = true;
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        this.categoriesFilter = IntageHelper.categoriesFilter();
        this.handleMarketShare();      
      }    
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["scoreCardSelectedMaker"] = [...this.scoreCardSelectedMaker];
      window["scoreCardSelectedCategory"] = [...this.scoreCardSelectedCategory];
      window["scoreCardSelectedRegion"] = [...this.scoreCardSelectedRegion];
    } 
    else 
    {
      if (window["scoreCardSelectedMaker"] != null) { 
        this.scoreCardSelectedMaker = window["scoreCardSelectedMaker"]; 
      }
      if (window["scoreCardSelectedCategory"] != null) { this.scoreCardSelectedCategory = window["scoreCardSelectedCategory"]; }
      if (window["scoreCardSelectedRegion"] != null) { this.scoreCardSelectedRegion = window["scoreCardSelectedRegion"]; }
    }
  }

  onRemoveFilterMakers($event) {
    if(this.scoreCardSelectedMaker.length == 0){
      this.scoreCardSelectedMaker = ['All'];
      this.SaveOptionValueTemp(false);
    }
    if ($event.value === 'All') {
      this.scoreCardSelectedMaker = [];
      return;
    }
  }
  onAddFilterMakers($event) {
    if ($event === 'All') {
      this.scoreCardSelectedMaker = ['All'];
      return;
    }
    if(this.scoreCardSelectedMaker.filter(x=>x!='All').length==(this.marketShareMakers.length-1)){
      this.scoreCardSelectedMaker = ['All'];
    }else{
      this.scoreCardSelectedMaker = this.scoreCardSelectedMaker.filter(e => e !== 'All');
    }
  }  
  onRemoveFilterScoreCategory($event) {
    if(this.scoreCardSelectedCategory.length == 0){
      this.scoreCardSelectedCategory = ['Total'];
      this.SaveOptionValueTemp(false);
    }
    if ($event.value === 'Total') {
      this.scoreCardSelectedCategory = [];
      return;
    }
  }
  onAddFilterScoreCategory($event) {
    if ($event === 'Total') {
      this.scoreCardSelectedCategory = ['Total'];
      return;
    }
    if(this.scoreCardSelectedCategory.filter(x=>x!='Total').length==(this.categoriesFilter.length-1)){
      this.scoreCardSelectedCategory = ['Total'];
    }else{
      this.scoreCardSelectedCategory = this.scoreCardSelectedCategory.filter(e => e !== 'Total');;
    }
  }
  onRemoveFilterScoreRegion($event) {
    if(this.scoreCardSelectedRegion.length == 0){
      this.scoreCardSelectedRegion = ['CCBJI'];
      this.SaveOptionValueTemp(false);
    }
    if ($event.value === 'CCBJI') {
      this.scoreCardSelectedRegion = [];
      return;
    }
  }

  onAddFilterScoreRegion($event) {
    if ($event === 'CCBJI') {
      this.scoreCardSelectedRegion = ['CCBJI'];
      return;
    }
    if(this.scoreCardSelectedRegion.filter(x=> x != 'CCBJI').length == (this.districts.length-1)){
      this.scoreCardSelectedRegion = ['CCBJI'];
    }else{
      this.scoreCardSelectedRegion = this.scoreCardSelectedRegion.filter(e => e !== 'CCBJI');;
    }
  }
  
  onScoreCardAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketShare();
  }

  handleMarketShare(){
      var weeks = [];

      switch (this.aggregateBy) {
        case 'mtd':
          let month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
          weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];          
          break;
        case 'qtd':
          let quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
          weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))]; 
          break;
        case 'ytd':
          let year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
          weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
          break;
        default:
          weeks = [this.selectedWeek];
          break;
      }  

      let data = lodashClonedeep(this.intage.filter(x=> weeks.includes(x.week) && x.tyvalue != "" && x.lyvalue != "" && x.tyunit != "" && x.lyunit != ""));
      this.marketShareData = [];
      this.marketShareMakers = [{maker:'All'}];
      for (let index = 0; index < this.makerLogo.length; index++) {
        this.marketShareMakers.push({
          maker: this.makerLogo[index].maker,
          logo: this.makerLogo[index].logo,
          checked: this.makerLogo[index].checked
        });
      }

      var dataFilter = lodashClonedeep(data.filter(x => x.botter_sci == this.scoreCardSelectedRegion[0]));
      if (!this.scoreCardSelectedCategory.includes('Total'))
      {
        dataFilter = dataFilter.filter(x => x.category == this.scoreCardSelectedCategory[0]);
      }

      dataFilter.map(x => {
        x.tyvalue = parseFloat(x.tyvalue);
        x.lyvalue = parseFloat(x.lyvalue);
        x.tyunit = parseFloat(x.tyunit);
        x.lyunit = parseFloat(x.lyunit);
      })
      
      this.marketShareData = [];

      this.marketShareMakers.forEach(maker => {
        if (maker.maker == 'All')
        {
          return;
        }
        let array = dataFilter.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase());
        let tyValueMaker = array.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let lyValueMaker = array.reduce((acc, curr) => acc + curr.lyvalue, 0);
        let valueVsLyNumber = (tyValueMaker - lyValueMaker) / lyValueMaker * 100;
        let valueVsLy = lyValueMaker == 0 ? '-' : IntageHelper.formatIntageScorecardNumber(valueVsLyNumber).toString();

        let tyUnitMaker = array.reduce((acc, curr) => acc + curr.tyunit, 0);
        let lyUnitMaker = array.reduce((acc, curr) => acc + curr.lyunit, 0);
        let unitVsLyNumber = (tyUnitMaker - lyUnitMaker) / lyUnitMaker * 100;
        let unitVsLy = lyUnitMaker == 0 ? '-' : IntageHelper.formatIntageScorecardNumber(unitVsLyNumber).toString();
        this.marketShareData.push(
          {
            maker: maker.maker,
            logo: maker.logo,
            valueVsLy: valueVsLy,
            unitVsLy: unitVsLy
          }
        )
      })

      if (this.scoreCardSelectedMaker.some(x => x == 'All'))
      {
        let tyValueMarketSize = dataFilter.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let lyValueMarketSize = dataFilter.reduce((acc, curr) => acc + curr.lyvalue, 0);
        let valueVsLyNumber = (tyValueMarketSize - lyValueMarketSize) / lyValueMarketSize * 100;
        let valueVsLy = lyValueMarketSize == 0 ? '-' : IntageHelper.formatIntageScorecardNumber(valueVsLyNumber).toString();

        let tyUnitMarketSize = dataFilter.reduce((acc, curr) => acc + curr.tyunit, 0);
        let lyUnitMarketSize = dataFilter.reduce((acc, curr) => acc + curr.lyunit, 0);
        let unitVsLyNumber = (tyUnitMarketSize - lyUnitMarketSize) / lyUnitMarketSize * 100;
        let unitVsLy = lyUnitMarketSize == 0 ? '-' : IntageHelper.formatIntageScorecardNumber(unitVsLyNumber).toString();
        this.scoreCardData = {
          maker: '',
          logo: '',
          valueVsLy: valueVsLy,
          unitVsLy: unitVsLy
        };
      }
      else
      {
        this.scoreCardData = this.marketShareData.find(x => x.maker.toLowerCase() == this.scoreCardSelectedMaker[0].toLowerCase())
      }

    let t= this;
    setTimeout(() => {
      t.ready.emit(true);
    });    
    }
    public getCategoryDisplayName(category: string){
      return IntageHelper.GetCategoryDisplayName(category);
    }
    public onScoreCardMakerChange(){
      if(this.scoreCardSelectedMaker.length == 0){
        this.scoreCardSelectedMaker=['All'];
      }
      this.handleMarketShare();
    }
  
    public onScoreCardCategoryChange(){
      if(this.scoreCardSelectedCategory.length==0){
        this.scoreCardSelectedCategory=['Total'];
      }
      this.handleMarketShare();
    }
  
    public onScoreCardRegionChange(){
      if(this.scoreCardSelectedRegion.length==0){
        this.scoreCardSelectedRegion= ['CCBJI'];
      }
      this.handleMarketShare();
    }

    public formatCategoryName(categoryName: any)
    {
      return IntageHelper.formatCategoryName(categoryName);
    }
}


 


