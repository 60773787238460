import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { NgbDateStruct, NgbInputDatepicker } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";

@Component({
    selector: "date-range-picker",
    templateUrl: "./date-range-picker.component.html",
    styleUrls: ["./date-range-picker.component.scss"],
})
export class DateRangePickerComponent implements OnInit {
    @ViewChild("dp") private datePicker: NgbInputDatepicker;
    @Input() from: Date;
    @Input() to: Date;
    @Input() maxDate : any ;
    @Input() minDate : any ;
    @Input() placeholder = "starting today";
    @Input() className: string;
    @Input() pickerName: string;
    @Input() id: string = "dateRangePicker";
    @Output() dateRangeSelection = new EventEmitter<{ from: Date; to: Date }>();
    hoveredDate: Date;
    isOpen = false;

    @HostListener("document:click", ["$event.target"]) onClick(element) {
        const host = document.getElementById(this.id);
        if (
            this.datePicker &&
            this.isOpen &&
            !this.isDescendant(host, element)
        ) {
            if (!this.to)
            {
                this.to = this.from;
            }
            this.emit(true);
        }
    }

    constructor() {

    }

    ngOnInit() {
    }

    private emit(close?: boolean) {
        const dateRange = {
            from: this.from,
            to: this.to,
        };
        this.dateRangeSelection.emit(dateRange);

        if (close) {
            this.isOpen = false;
            this.datePicker.close();
        }
    }

    /*
     * Check whether or not an element is a child of another element
     */
    private isDescendant(parent, child) {
        let node = child;
        while (node !== null) {
            if (node === parent) {
                return true;
            } else {
                node = node.parentNode;
            }
        }
        return false;
    }

    get formattedDateRange(): string {
        if (!this.from) {
            return `missing start date`;
        }

        const fromFormatted = moment(this.from).format("YYYY/MM/DD");

        return this.to
            ? `${fromFormatted}` +
                  ` to ` +
                  `${moment(this.to).format("YYYY/MM/DD")}`
            : `${fromFormatted}`;
    }

    onDateSelection(date: NgbDateStruct) {
        if (!this.from && !this.to) {
          this.from = this.toDate(date);
        } else if (this.from && !this.to && this.toMoment(date).isAfter(this.from)) {
          this.to = this.toDate(date);
          this.emit(true);
        } else if (this.from && !this.to && this.toMoment(date).isSame(this.from)) {
          this.to = this.toDate(date);
          this.emit(true);
        } else {
          this.to = null;
          this.from = this.toDate(date);
        }
      }

    toDate(dateStruct: NgbDateStruct): Date {
        return dateStruct
            ? new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day)
            : null;
    }

    toMoment(dateStruct: NgbDateStruct): moment.Moment {
        return moment(this.toDate(dateStruct));
    }

    isHovered = (date: NgbDateStruct) =>
        this.from &&
        !this.to &&
        this.hoveredDate &&
        this.toMoment(date).isAfter(this.from) &&
        this.toMoment(date).isBefore(this.hoveredDate);

    isInside = (date: NgbDateStruct) =>
        this.toMoment(date).isAfter(moment(this.from).startOf("day")) &&
        this.toMoment(date).isBefore(moment(this.to).startOf("day"));

    isFrom = (date: NgbDateStruct) =>
        this.toMoment(date).isSame(this.from, "d");
    isTo = (date: NgbDateStruct) => this.toMoment(date).isSame(this.to, "d");
}
