import Swal from 'sweetalert2';

export const isObjEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export const objectToQueryString = (obj: Object) => {
  return Object.entries(obj)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
}

export const openWarningPopup = () => {
  try {
    Swal.fire({
      title: '',
      text: 'We are preparing the report, please wait...',
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {}
    }).then(result => {
      if (result.dismiss === Swal.DismissReason.backdrop || result.dismiss === Swal.DismissReason.esc) {
        console.warn('Popup was dismissed by user interaction');
      }
    });
  } catch (error) {
    console.error('Error opening SweetAlert2 popup:', error);
  }
}

export const closeWarningPopup = () => {
  try {
    setTimeout(() => {
      Swal.close();
    }, 1000);
  } catch (error) {
    console.log(error);
  }
};

export const getPageName = (name: string) => {
  let page = '';

  switch (name) {
    case 'VsLyBrandComponent':
    case 'VsLyCategoryComponent':
    case 'VsLyPackageComponent':
    case 'VsLySKUComponent':
    case 'VsLyTimelineComponent':
      page = 'sku-deepdive';
      break;

    case 'VsLyAreaComponent':
    case 'VsLyChannelComponent':
    case 'VsLyDealerComponent':
    case 'VsLyRouteComponent':
    case 'VsLyAreaTimelineComponent':
      page = 'area-deepdive';
      break;

    case 'ProgressByDistrictComponent':
    case 'ProgressByRouteComponent':
    case 'DistrictTimelineComponent':
      page = 'progress-district';
      break;

    default:
      break;
  }

  return page;
}
