import { Router } from "@angular/router";

export function getChartStateFromUrl(router: Router, chartId: string) {
    try {
        const chatId = router.parseUrl(router.url).queryParams['chatId'] || '';
        const match = chatId.match(new RegExp(`^${chartId}\\[(.*?)\\]$`));
        if (match) {
            const arr = JSON.parse(atob(match[1]));
            for (const key in arr[0]) {
                if (key === "state") {
                    return arr[0][key];
                }
            }
        } else {
            return {}
        }
    } catch (error) {
        return {}
    }
}

export function getSelectedDateFromUrl(router: Router) {
    return getFromUrl(router, "selectedDate")
}

export function getSelectedWeekFromUrl(router: Router) {
    return getFromUrl(router, "selectedWeek")
}

export function getAggregateByFromUrl(router: Router) {
    return getFromUrl(router, "aggregateBy")
}

function getFromUrl(router: Router, inputKey: string) {
    try {
        const chatId = router.parseUrl(router.url).queryParams['chatId'] || '';
        const match = chatId.match(new RegExp(`\\[(.*?)\\]$`));
        if (match) {
            const arr = JSON.parse(atob(match[1]));
            for (const key in arr[0]) {
                if (key === inputKey) {
                    return arr[0][key];
                }
            }
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}