<form>
    <div [class]="'date-range-picker' + (isOpen ? ' opened' : '')" [id]="id">
        <b>{{pickerName}}</b>
        <input [class]="className" id="dateRange" [placeholder]="placeholder" name="dp" [value]="formattedDateRange"
            ngbDatepicker #dp="ngbDatepicker" outsideDays="collapsed" (dateSelect)="onDateSelection($event)"
            [displayMonths]="1" [dayTemplate]="t" [autoClose]="false" (click)="dp.toggle(); isOpen = !isOpen"
            [maxDate]="maxDate" [minDate]="minDate"
            readonly />
    </div>
</form>

<ng-template #t let-date="date" let-focused="focused">
    <span class="custom-day"
        [class.focused]="focused"
        [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = toDate(date)"
        (mouseleave)="hoveredDate = null">
        {{ date.day }}
    </span>
</ng-template>
