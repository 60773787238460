<div class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30">
            <!-- Filter area -->
            <!-- <div class="filter-section filter-section__hq-progress even-wrapper">
                <multi-dropdown dataTitle="Sales HQ Lev2" [keyName]="FILTERS?.SALES_HQ_LEV2_NAME" [showTooltip]="false"
                    [initialData]="lstOption_SalesHQLev2" [initialSelected]="lstOption_Selected_SalesHQLev2"
                    (saveItemsChange)="onSaveItemsChange($event)"></multi-dropdown>

                <multi-dropdown dataTitle="Channel Lev1" [keyName]="FILTERS?.CHANNEL_LEV1_NAME" [showTooltip]="false"
                    [initialData]="lstOption_ChannelLev1" [initialSelected]="lstOption_Selected_ChannelLev1"
                    (saveItemsChange)="onSaveItemsChange($event)"></multi-dropdown>

                <multi-dropdown dataTitle="Channel Lev2" [keyName]="FILTERS?.CHANNEL_LEV2_NAME" [showTooltip]="false"
                    [initialData]="lstOption_ChannelLev2" [initialSelected]="lstOption_Selected_ChannelLev2"
                    (saveItemsChange)="onSaveItemsChange($event)"></multi-dropdown>

                <multi-dropdown dataTitle="Channel Lev3" [keyName]="FILTERS?.CHANNEL_LEV3_NAME" [showTooltip]="false"
                    [initialData]="lstOption_ChannelLev3" [initialSelected]="lstOption_Selected_ChannelLev3"
                    (saveItemsChange)="onSaveItemsChange($event)"></multi-dropdown>

                <multi-dropdown dataTitle="National/Local" [keyName]="FILTERS?.CHANNEL_NL" [showTooltip]="false"
                    [initialData]="lstOption_Channel_NL" [initialSelected]="lstOption_Selected_Channel_NL"
                    (saveItemsChange)="onSaveItemsChange($event)"></multi-dropdown>
            </div> -->

            <!-- Search button area -->
            <!-- <div class="filter-section d-flex justify-content-center">
                <button type="button" class="btn btn-primary" (click)="updateHQLevel2Data()">Apply</button>
            </div> -->

            <div>
                <app-add-more-filter-hq
                    [isAlwaysShowApplyBtn]  = "true"
                    [filterModuleName] = "'HQLevel2Component'"
                    [className]="'hq-progress-timeline'"
                    [filters]="FILTERS"
                    [listData_Filter] = "listData_Filter"
                    [lstOption_Parrent_Selected]="lstOption_Selected_SalesHQLev1"
                    [lstOption_Selected]="lstOption_Selected"
                    [lstOption_States]="lstOption_States"
                    [lstOption_IsUsed]="lstOption_IsUsed"
                    [defaultVisibleFilters]="defaultVisibleFilters"
                    (onUpdateChart)="onUpdateChart($event)"
                    (onResetFiltersGroup)="onResetFiltersGroup($event)"></app-add-more-filter-hq>
            </div>

            <div class="filter-section filter-section__hq-progress even-wrapper">
                <single-dropdown
                    title="Channel Lev"
                    [items]="lstOption_ChannelLev"
                    [model]="lstOption_Selected_ChannelLev"
                    (change)="onSaveChannelLevChange($event)">
                </single-dropdown>
            </div>

            <div *ngIf="isLoading" class="text-center spinner-wrapper spinner-wrapper-icon">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
            </div>

            <div #pdf_print_hq_lev2>
                <div class="filter-container-pdf">
                    <div class="filter-list row">
                        <b class="filter-list-header col-1half">Sales Date: </b>
                        <div class="filter-list-content col-10half">
                            <span>{{saleDate}}</span>
                        </div>
                    </div>
                    <div class="filter-list row">
                        <b class="filter-list-header col-1half">Sales HQ Lev2:</b>
                        <div class="filter-list-content col-10half">
                            <span>{{lstOption_Selected_Applied.SALES_HQ_LEV2_NAME}}</span>
                        </div>
                    </div>
                    <div class="filter-list row">
                        <b class="filter-list-header col-1half">Channel Lev1:</b>
                        <div class="filter-list-content col-10half">
                            <span>{{lstOption_Selected_Applied.CHANNEL_LEV1_NAME}}</span>
                        </div>
                    </div>
                    <div class="filter-list row">
                        <b class="filter-list-header col-1half">Channel Lev2:</b>
                        <div class="filter-list-content col-10half">
                            <span>{{lstOption_Selected_Applied.CHANNEL_LEV2_NAME}}</span>
                        </div>
                    </div>
                    <div class="filter-list row">
                        <b class="filter-list-header col-1half">Channel Lev3:</b>
                        <div class="filter-list-content col-10half">
                            <span>{{lstOption_Selected_Applied.CHANNEL_LEV3_NAME}}</span>
                        </div>
                    </div>
                    <div class="filter-list row">
                        <b class="filter-list-header col-1half">National/Local:</b>
                        <div class="filter-list-content col-10half">
                            <span>{{lstOption_Selected_Applied.CHANNEL_NL}}</span>
                        </div>
                    </div>
                    <div class="filter-list row">
                        <b class="filter-list-header col-1half">Sorted by:</b>
                        <div class="filter-list-content col-10half">
                            <span>{{sortColumnName}} {{sortType=="asc" ? "ascending" : "descending"}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-header d-flex justify-content-between align-items-center hq-progress-title">
                    <h3>Progress by HQ Lv2 </h3>
                </div>

                <div class="card-body">
                    <div class="market-size-hq-progress-leve2">
                        <table #screen class="intage-market-size hq-progress hq-progress-lev2">
                            <thead>
                                <tr class="row__first">
                                    <ng-container *ngFor="let item of listColumn; index as j;">
                                        <th [class]="item.category.toLowerCase()"  *ngIf="item.isDisplay!==false && item.enableSort==false" rowspan="2">
                                            <div>
                                                {{item.displayName}}
                                            </div>
                                    </th>
                                    </ng-container>
                                    <th colspan="4"><div class="text-center"><span>BAPC (K cases)</span></div></th>
                                    <th colspan="4"><div class="text-center"><span>NSR (MM JPY)</span></div></th>
                                    <th colspan="4"><div class="text-center"><span>GP (MM JPY)</span></div></th>
                                </tr>

                                <tr class="row__second">
                                    <ng-container *ngFor="let item of listColumn; index as j;">
                                        <ng-container>
                                            <th class="border-right-3 background-color-none" [class]="' ' + item.category.toLowerCase()" *ngIf="item.isDisplay!==false && item.enableSort!=false">
                                                <div class="th-header-content sort-column">
                                                    <div>
                                                        <span class="text">{{item.displayName}}</span>
                                                    </div>

                                                    <!-- <div class="icon-container none-pdf sort-column" >
                                                        <span id="NSR_DIFF_VS_BPASC" class='icon-up-arrow' [ngClass]="sortType == 'asc' && sortColumn ==item.category ? 'red' : ''"></span>
                                                        <span id="NSR_DIFF_VS_BPDESC" class='icon-down-arrow' [ngClass]="sortType == 'desc' && sortColumn ==item.category ? 'red' : ''"></span>
                                                    </div> -->
                                                </div>
                                            </th>
                                        </ng-container>
                                    </ng-container>
                                </tr>
                            </thead>

                            <tbody *ngIf="!isLoading">
                                <ng-container *ngFor="let root1 of hqLevel2RawData?.ListRoot1; index as i;">
                                    <tr>
                                        <ng-container *ngFor="let item of listColumn; index as j;">
                                            <td class="item-chanel-1" [class]="' ' + item.category.toLowerCase()" *ngIf="item.isDisplay!==false">
                                                <span>
                                                    <b [class]="(!item.isLunisolarColor|| !root1[item.category]) ? '': root1[item.category].toString().indexOf('-') == 0 ? 'text-red' : 'text-green' + ' ' + item.category.toLowerCase()" >
                                                        {{root1[item.category +'_Display']}}
                                                    </b>
                                                </span>
                                            </td>
                                        </ng-container>
                                    </tr>

                                    <ng-container *ngFor="let root2 of hqLevel2RawData?.ListRoot2 | filterRoots: ['SALES_HQ_LEV2_NAME'] : [root1['SALES_HQ_LEV2_NAME']] ; index as k;">
                                        <tr>
                                            <ng-container *ngFor="let item of listColumn; index as j;">
                                                <td *ngIf="item.isDisplay !== false" class="item-chanel-2" [ngClass]="j === 1 ? 'first-item-chanel-2' : (j === 2 ? 'second-item-chanel-2' : '')" [class]="item.category.toLowerCase()">
                                                    <span *ngIf="j>=1" [class]="(!item.isLunisolarColor|| !root2[item.category]) ? '': root2[item.category].toString().indexOf('-') == 0 ? 'text-red' : 'text-green'">
                                                        {{root2[item.category +'_Display']}}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row-reverse mt-4 hide-pdf-print">
                <download-button [ngClass]=" isLoading ? 'disable-button' :''">
                    <a #downloadLink style="display: none;"></a>
                    <button *ngIf="!isLoading" ngbDropdownItem (click)="exportHqLevel2_Excel_by_Url()">Download CSV</button>
                    <!--<button *ngIf="!isLoading" ngbDropdownItem (click)="exportHqLevel2_Excel()">Download CSV</button>-->
                    <!--<button *ngIf="!isLoading" ngbDropdownItem (click)="exportHQLEV_PDF('Progress by HQ Lv2')">Download PDF</button>-->
                </download-button>
            </div>
        </div>
    </div>
</div>