<!-- Start Error 404 Area -->
<div class="error-404-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="notfound">
                <div class="notfound-bg">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>

                <h1>Notice!</h1>
                <h3>Unauthorized</h3>
                <p>You're not authorized to access this page.</p>
                <a routerLink="/dashboard" class="default-btn">Go back</a>
            </div>
        </div>
    </div>
</div>
<!-- End Error 404 Area -->