import { FILTER_CONFIGS_HQ } from 'src/app/model/constants';
import { localStorageHelper } from 'src/app/helpers/localStorage';

export class FilterHelper {
  static isAllSelected = (arr1: any) => {
    return JSON.stringify(arr1) === JSON.stringify(['All']);
  }

  static getQueryString(filters: any, keyName: string, lstOptionSelected: any) {
    let queryString = '';

    switch (keyName) {
      case filters.CHANNEL_LEV1_NAME:
        break;

      case filters.CHANNEL_LEV2_NAME:
        if (!isAllSelected(lstOptionSelected.CHANNEL_LEV1_NAME)) {
          queryString += `&CHANNEL_LEV1_NAME=${encodeURIComponent(lstOptionSelected.CHANNEL_LEV1_NAME)}`;
        }
        break;

      case filters.CHANNEL_LEV3_NAME:
        break;

      case filters.SALES_HQ_LEV1_NAME:
        break;

      case filters.SALES_HQ_LEV2_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryString += `&SALES_HQ_LEV1_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV1_NAME)}`;
        }
        break;

      case filters.SALES_HQ_LEV3_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryString += `&SALES_HQ_LEV1_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV1_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV2_NAME)) {
          queryString += `&SALES_HQ_LEV2_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV2_NAME)}`;
        }
        break;

      case filters.SALES_CENTER_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryString += `&SALES_HQ_LEV1_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV1_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV2_NAME)) {
          queryString += `&SALES_HQ_LEV2_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV2_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV3_NAME)) {
          queryString += `&SALES_HQ_LEV3_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV3_NAME)}`;
        }
        break;

      case filters.SALES_ROUTE_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryString += `&SALES_HQ_LEV1_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV1_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV2_NAME)) {
          queryString += `&SALES_HQ_LEV2_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV2_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV3_NAME)) {
          queryString += `&SALES_HQ_LEV3_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV3_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_CENTER_NAME)) {
          queryString += `&SALES_CENTER_NAME=${encodeURIComponent(lstOptionSelected.SALES_CENTER_NAME)}`;
        }
        break;


      case filters.COM_DIST_SALES_REP_NAME:
        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV1_NAME)) {
          queryString += `&SALES_HQ_LEV1_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV1_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV2_NAME)) {
          queryString += `&SALES_HQ_LEV2_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV2_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_HQ_LEV3_NAME)) {
          queryString += `&SALES_HQ_LEV3_NAME=${encodeURIComponent(lstOptionSelected.SALES_HQ_LEV3_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_CENTER_NAME)) {
          queryString += `&SALES_CENTER_NAME=${encodeURIComponent(lstOptionSelected.SALES_CENTER_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.SALES_ROUTE_NAME)) {
          queryString += `&SALES_ROUTE_NAME=${encodeURIComponent(lstOptionSelected.SALES_ROUTE_NAME)}`;
        }
        break;

      case filters.DEALER_NAME:
        break;

      case filters.KAM_DEPT_LV2_NAME:
        break;

      case filters.KAM_DEPT_LV3_NAME:
        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV2_NAME)) {
          queryString += `&KAM_DEPT_LV2_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV2_NAME)}`;
        }
        break;

      case filters.KAM_DEPT_LV4_NAME:
        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV2_NAME)) {
          queryString += `&KAM_DEPT_LV2_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV2_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV3_NAME)) {
          queryString += `&KAM_DEPT_LV3_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV3_NAME)}`;
        }
        break;

      case filters.KAM_CONTACT_PERSON_NAME:
        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV2_NAME)) {
          queryString += `&KAM_DEPT_LV2_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV2_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV3_NAME)) {
          queryString += `&KAM_DEPT_LV3_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV3_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV4_NAME)) {
          queryString += `&KAM_DEPT_LV4_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV4_NAME)}`;
        }
        break;

      case filters.ACCOUNT_LEV2_NAME_EN:
        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV2_NAME)) {
          queryString += `&KAM_DEPT_LV2_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV2_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV3_NAME)) {
          queryString += `&KAM_DEPT_LV3_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV3_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.KAM_DEPT_LV4_NAME)) {
          queryString += `&KAM_DEPT_LV4_NAME=${encodeURIComponent(lstOptionSelected.KAM_DEPT_LV4_NAME)}`;
        }

        if (!isAllSelected(lstOptionSelected.KAM_CONTACT_PERSON_NAME)) {
          queryString += `&KAM_CONTACT_PERSON_NAME=${encodeURIComponent(lstOptionSelected.KAM_CONTACT_PERSON_NAME)}`;
        }
        break;

      case filters.ACCOUNT_LEV3_NAME_EN:
        break;

      case filters.CATEGORY_NAME_EN:
        break;

      case filters.SUBCATEGORY_NAME_EN:
        if (!isAllSelected(lstOptionSelected.CATEGORY_NAME_EN)) {
          queryString += `&CATEGORY_NAME_EN=${encodeURIComponent(lstOptionSelected.CATEGORY_NAME_EN)}`;
        }
        break;

      case filters.BRAND_NAME_EN:
        if (!isAllSelected(lstOptionSelected.CATEGORY_NAME_EN)) {
          queryString += `&CATEGORY_NAME_EN=${encodeURIComponent(lstOptionSelected.CATEGORY_NAME_EN)}`;
        }

        if (!isAllSelected(lstOptionSelected.SUBCATEGORY_NAME_EN)) {
          queryString += `&SUBCATEGORY_NAME_EN=${encodeURIComponent(lstOptionSelected.SUBCATEGORY_NAME_EN)}`;
        }
        break;

      case filters.PACKAGE_LEV1_NAME_EN:
        if (!isAllSelected(lstOptionSelected.CATEGORY_NAME_EN)) {
          queryString += `&CATEGORY_NAME_EN=${encodeURIComponent(lstOptionSelected.CATEGORY_NAME_EN)}`;
        }

        if (!isAllSelected(lstOptionSelected.SUBCATEGORY_NAME_EN)) {
          queryString += `&SUBCATEGORY_NAME_EN=${encodeURIComponent(lstOptionSelected.SUBCATEGORY_NAME_EN)}`;
        }

        if (!isAllSelected(lstOptionSelected.BRAND_NAME_EN)) {
          queryString += `&BRAND_NAME_EN=${encodeURIComponent(lstOptionSelected.BRAND_NAME_EN)}`;
        }
        break;

      case filters.PACKAGE_LEV2_NAME_EN:
        if (!isAllSelected(lstOptionSelected.CATEGORY_NAME_EN)) {
          queryString += `&CATEGORY_NAME_EN=${encodeURIComponent(lstOptionSelected.CATEGORY_NAME_EN)}`;
        }

        if (!isAllSelected(lstOptionSelected.SUBCATEGORY_NAME_EN)) {
          queryString += `&SUBCATEGORY_NAME_EN=${encodeURIComponent(lstOptionSelected.SUBCATEGORY_NAME_EN)}`;
        }

        if (!isAllSelected(lstOptionSelected.BRAND_NAME_EN)) {
          queryString += `&BRAND_NAME_EN=${encodeURIComponent(lstOptionSelected.BRAND_NAME_EN)}`;
        }

        if (!isAllSelected(lstOptionSelected.PACKAGE_LEV1_NAME_EN)) {
          queryString += `&PACKAGE_LEV1_NAME_EN=${encodeURIComponent(lstOptionSelected.PACKAGE_LEV1_NAME_EN)}`;
        }
        break;

      case filters.PACKAGE_DTL_NAME:
        break;

      default:
        break;
    }

    return queryString;
  }

  static getFilterConfigs = (config: any, visibleLimit: number, filterList: Array<string>): { [key: string]: string } => {
    const result: { [key: string]: string } = {};
    const visibleList = filterList.slice(0, visibleLimit);

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (visibleList.includes(key)) {
          result[key] = 'enable';
        } else {
          result[key] = 'disable';
        }
      }
    }

    return result;
  }


  static resetFilterConfigs1 = (config: { [key: string]: string }, visibleLimit: number) => {
    let count = 0;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (count < visibleLimit) {
          config[key] = 'enableTemp';
        } else {
          config[key] = 'tentative';
        }

        count++;
      }
    }
  }

  static resetFilterConfigs2 = (config: { [key: string]: string }, visibleLimit: number) => {
    let count = 0;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (count < visibleLimit) {
          config[key] = 'enable';
        } else {
          config[key] = 'disable';
        }

        count++;
      }
    }
  }

  static resetHQFilterConfigs1 = (config: { [key: string]: string }, visibleArrCustom : string[] =null) => {
    let visibleArr = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME'];

    if(visibleArrCustom){
      visibleArr = [...visibleArrCustom];
    }

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (visibleArr.includes(key)) {
          config[key] = 'enableTemp';
        } else {
          config[key] = 'tentative';
        }
      }
    }
  }

  static resetHQFilterConfigs2 = (config: { [key: string]: string }, visibleArrCustom : string[] =null) => {
    let visibleArr = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME'];

    if(visibleArrCustom){
      visibleArr = [...visibleArrCustom];
    }

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (visibleArr.includes(key)) {
          config[key] = 'enable';
        } else {
          config[key] = 'disable';
        }
      }
    }
  }

  static resetDistrictFilterConfigs1 = (config: { [key: string]: string }, visibleArrCustom : string[] =null) => {
    let visibleArr = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME'];
    
    if(visibleArrCustom){
      visibleArr = [...visibleArrCustom];
    }
    
    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (visibleArr.includes(key)) {
          config[key] = 'enableTemp';
        } else {
          config[key] = 'tentative';
        }
      }
    }
  }

  static resetDistrictFilterConfigs2 = (config: { [key: string]: string }, visibleArrCustom : string[] =null) => {
    let visibleArr = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME'];
    
    if(visibleArrCustom){
      visibleArr = [...visibleArrCustom];
    }
    
    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (visibleArr.includes(key)) {
          config[key] = 'enable';
        } else {
          config[key] = 'disable';
        }
      }
    }
  }

  static resetProgressRankingFilterConfigs1 = (config: { [key: string]: string }) => {
    const visibleArr = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME'];

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (visibleArr.includes(key)) {
          config[key] = 'enableTemp';
        } else {
          config[key] = 'tentative';
        }
      }
    }
  }

  static resetProgressRankingFilterConfigs2 = (config: { [key: string]: string }) => {
    const visibleArr = ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME'];

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (visibleArr.includes(key)) {
          config[key] = 'enable';
        } else {
          config[key] = 'disable';
        }
      }
    }
  }


  static convertAllDisabledToTentative = (config: { [key: string]: string }) => {
    for (const key in config) {
      if (config.hasOwnProperty(key) && config[key] === 'disable') {
        config[key] = 'tentative';
      }
    }
  }

  static convertAllTentativeToDisable = (config: { [key: string]: string }) => {
    for (const key in config) {
      if (config.hasOwnProperty(key) && config[key] === 'tentative') {
        config[key] = 'disable';
      }
    }
  }

  static convertAllEnableTempToEnable = (config: { [key: string]: string }) => {
    for (const key in config) {
      if (config.hasOwnProperty(key) && config[key] === 'enableTemp') {
        config[key] = 'enable';
      }
    }
  }

  static convertAllEnableToEnableTemp = (config: { [key: string]: string }) => {
    let count = 0;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (config[key] === 'enable') {
          config[key] = 'enableTemp';
        }
      }
    }
  }

  static convertTentativeToEnableTemp = (config: { [key: string]: string }, specifiedKey: string) => {
    if (config.hasOwnProperty(specifiedKey) && config[specifiedKey] === 'tentative') {
      config[specifiedKey] = 'enableTemp';
    }
  }

  static convertEnableTempToTentative = (config: { [key: string]: string }, specifiedKey: string) => {
    if (config.hasOwnProperty(specifiedKey) && config[specifiedKey] === 'enableTemp') {
      config[specifiedKey] = 'tentative';
    }
  }

  static isShowBtnResetFilter = ( lstOption_States: { [key: string]: string }, lstOption_Default) => {
    for (const key in lstOption_States) {
      if (lstOption_States.hasOwnProperty(key) && (lstOption_States[key] === 'enableTemp' || lstOption_States[key] === 'enable')) {
        if(!lstOption_Default.includes(key)) {
          return true;
        }
      }

      if (lstOption_States.hasOwnProperty(key) && (lstOption_States[key] === 'tentative' || lstOption_States[key] === 'disable')) {
        if(lstOption_Default.includes(key)) {
          return true;
        }
      }
    }
    return false;
  }

  static isShowBtnApply = (config: { [key: string]: string }) => {
    for (const key in config) {
      if (config.hasOwnProperty(key) && (config[key] === 'enableTemp' || config[key] === 'enable')) {
        return true;
      }
    }

    return false;
  }

  static convertGroupEnableTempToTentative(config: { [key: string]: string }, visibleLimit:number, keyName: string) {
    let count        = 0;
    let filtersGroup = getFiltersChild(keyName);
    let { data: childFilters } = filtersGroup;

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        if (count < visibleLimit) {
          config[key] = 'enable';
        } else {
          if (keyName === key || childFilters.includes(key)) {
            config[key] = 'tentative';
          }
        }

        count++;
      }
    }
  }

  static getFiltersChildGroup(keyName: string) {
    return getFiltersChild(keyName);
  }

  static getHQFiltersChild(keyName: string) {
    return getHQFiltersChild(keyName);
  }

  static getHQFiltersParent(keyName: string) {
    return getHQFiltersParent(keyName);
  }

  static getHQFiltersClosestParent(keyName: string) {
    return getHQFiltersClosestParent(keyName);
  }

  static getDistrictFiltersChild(keyName: string) {
    return getDistrictFiltersChild(keyName);
  }

  static getDistrictFiltersClosestParent(keyName: string) {
    return getDistrictFiltersClosestParent(keyName);
  }


  static getProgressRankingFiltersChild(keyName: string) {
    return getProgressRankingFiltersChild(keyName);
  }

  static getProgressRankingFiltersClosestParent(keyName: string) {
    return getProgressRankingFiltersClosestParent(keyName);
  }

  static getDefaultSelectedFilter(config: any) {
    const obj = {};

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        obj[key] = ['All'];
      }
    }

    return obj;
  }

  static getDefaultFilterList(config: any) {
    const obj = {};

    for (const key in config) {
      if (config.hasOwnProperty(key)) {
        obj[key] = [];
      }
    }

    return obj;
  }

  static getDefaultVisibleFilterList(config: any) {
    const arr = [];

    for (const key in config) {
      if (config.hasOwnProperty(key) && config[key] === 'enable') {
        arr.push(key);
      }
    }

    return arr;
  }

  static getDefaultVisibleFilterListHQ(pageName) {
    if (pageName === 'HQProgress') {
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME'];
    }

    if (pageName === 'HQProgressLevel1') {
      return ['SALES_HQ_LEV1_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME','CHANNEL_NL'];
    }

    if (pageName === 'HQProgressLevel2') {
      return ['SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME','CHANNEL_NL'];
    }

    if (pageName === 'HQProgressTimeLine') {
      return ['SALES_HQ_LEV1_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME','CHANNEL_NL'];
    }

    if (pageName === 'DistrictProgress') {
      return ['SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];
    }

    if (pageName === 'DistrictProgressByRoute') {
      return ['SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV3_NAME'];
    }

    if(pageName === 'ProgressRanking'){
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME'];
    }
  }

  static getListIsUsedFilter(config: any, arr: any) {
    const obj = {};

    for (const key in config) {
      obj[key] = arr.includes(key) ? true : false;
    }

    return obj;
  }

  static init_lstOption_States(filterModuleName:string, config: any, filterList: Array<string>, visibleLimit: number) {
    let option_data = localStorageHelper.get_Option_Filter_States(filterModuleName);

    if (option_data) {
      return option_data;
    } else {
      return {
        lstOption_States : this.getFilterConfigs(config, visibleLimit, filterList) || {},
        lstOption_Selected : this.getDefaultSelectedFilter(config) || {},
        lstOptions_IndexDB : null
      }
    }
  }

  static get_DateTypeAndSaleDate_States(filterModuleName:string){
    let option_data = localStorageHelper.get_Option_Filter_States(filterModuleName);
    if(option_data){
      return option_data;
    }

    return null;
  }

  static getPageName(moduleName: string) {
    return pageName(moduleName);
  }


  static store_lstOption_States(filterModuleName:string,
    lstOption_States: any = null ,
    lstOption_Selected: any = null ,
    lstOptions: any = null,
    lstAllOptionsFilteredCalendarData : any = null,
    lstAllOptionsFilteredAreaData : any = null,
    lstAllOptionsFilteredAccountData : any = null,
    lstAllOptionsFilteredProductData : any = null,
    lstOption_Selected_DateType: any = null ,
    lstOption_Selected_SalesDate : any = null,
    dailySalesDateRange: any = null)
    {
    localStorageHelper.set_Option_Filter_States(filterModuleName,
        lstOption_States,
        lstOption_Selected,
        lstOptions ,
        lstAllOptionsFilteredCalendarData ,
        lstAllOptionsFilteredAreaData,
        lstAllOptionsFilteredAccountData,
        lstAllOptionsFilteredProductData,
        lstOption_Selected_DateType,
        lstOption_Selected_SalesDate,
        dailySalesDateRange);
  }
}

const isAllSelected = (arr1: any) => {
  return JSON.stringify(arr1) === JSON.stringify(['All']);
}

const getFiltersChild = (keyName: string) => {
  const filtersGroup: any = {};

  switch (keyName) {
    case 'CHANNEL_LEV1_NAME':
      filtersGroup.data = ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.calendarFilter || '';
      break;

    case 'CHANNEL_LEV2_NAME':
      filtersGroup.data = ['CHANNEL_LEV3_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.calendarFilter || '';
      break;

    case 'CHANNEL_LEV3_NAME':
      filtersGroup.data = [];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.calendarFilter || '';
      break;

    case 'SALES_HQ_LEV1_NAME':
      filtersGroup.data = ['SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_ROUTE_NAME', 'COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.areaFilter || '';
      break;

    case 'SALES_HQ_LEV2_NAME':
      filtersGroup.data = ['SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_ROUTE_NAME', 'COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.areaFilter || '';
      break;

    case 'SALES_HQ_LEV3_NAME':
      filtersGroup.data = ['SALES_CENTER_NAME', 'SALES_ROUTE_NAME', 'COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.areaFilter || '';
      break;

    case 'SALES_CENTER_NAME':
      filtersGroup.data = ['SALES_ROUTE_NAME', 'COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.areaFilter || '';
      break;

    case 'SALES_ROUTE_NAME':
      filtersGroup.data = ['COM_DIST_SALES_REP_NAME', 'DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.areaFilter || '';
      break;

    case 'COM_DIST_SALES_REP_NAME':
      filtersGroup.data = ['DEALER_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.areaFilter || '';
      break;

    case 'DEALER_NAME':
      filtersGroup.data = [];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.areaFilter || '';
      break;

    case 'KAM_DEPT_LV2_NAME':
      filtersGroup.data = ['KAM_DEPT_LV3_NAME', 'KAM_DEPT_LV4_NAME', 'KAM_CONTACT_PERSON_NAME', 'ACCOUNT_LEV2_NAME_EN', 'ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.accountFilter || '';
      break;

    case 'KAM_DEPT_LV3_NAME':
      filtersGroup.data = ['KAM_DEPT_LV4_NAME', 'KAM_CONTACT_PERSON_NAME', 'ACCOUNT_LEV2_NAME_EN', 'ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.accountFilter || '';
      break;

    case 'KAM_DEPT_LV4_NAME':
      filtersGroup.data = ['KAM_CONTACT_PERSON_NAME', 'ACCOUNT_LEV2_NAME_EN', 'ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.accountFilter || '';
      break;

    case 'KAM_CONTACT_PERSON_NAME':
      filtersGroup.data = ['ACCOUNT_LEV2_NAME_EN', 'ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.accountFilter || '';
      break;

    case 'ACCOUNT_LEV2_NAME_EN':
      filtersGroup.data = ['ACCOUNT_LEV3_NAME_EN'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.accountFilter || '';
      break;

    case 'ACCOUNT_LEV3_NAME_EN':
      filtersGroup.data = [];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.accountFilter || '';
      break;

    case 'CATEGORY_NAME_EN':
      filtersGroup.data = ['SUBCATEGORY_NAME_EN', 'BRAND_NAME_EN', 'PACKAGE_LEV1_NAME_EN', 'PACKAGE_LEV2_NAME_EN', 'PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.productFilter || '';
      break;

    case 'SUBCATEGORY_NAME_EN':
      filtersGroup.data = ['BRAND_NAME_EN', 'PACKAGE_LEV1_NAME_EN', 'PACKAGE_LEV2_NAME_EN', 'PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.productFilter || '';
      break;

    case 'BRAND_NAME_EN':
      filtersGroup.data = ['PACKAGE_LEV1_NAME_EN', 'PACKAGE_LEV2_NAME_EN', 'PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.productFilter || '';
      break;

    case 'PACKAGE_LEV1_NAME_EN':
      filtersGroup.data = ['PACKAGE_LEV2_NAME_EN', 'PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.productFilter || '';
      break;

    case 'PACKAGE_LEV2_NAME_EN':
      filtersGroup.data = ['PACKAGE_DTL_NAME'];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.productFilter || '';
      break;

    case 'PACKAGE_DTL_NAME':
      filtersGroup.data = [];
      filtersGroup.name = FILTER_CONFIGS_HQ?.ADD_MORE_FILTER?.COMMONS?.GROUP_NAME?.productFilter || '';
      break;

    default:
      filtersGroup.data = [];
      filtersGroup.name = '';
      break;
  }

  return filtersGroup;
}

const getHQFiltersChild = (keyName: string) => {
  switch (keyName) {
    case 'SALES_HQ_LEV1_NAME':
      return ['SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'SALES_HQ_LEV2_NAME':
      return ['CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'CHANNEL_LEV1_NAME':
      return ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'CHANNEL_LEV2_NAME':
      return ['CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'CHANNEL_LEV3_NAME':
      return ['CHANNEL_NL'];

    case 'CHANNEL_NL':
      return [];

    default:
      return [];
  }
}

const getHQFiltersParent = (keyName: string) => {
  switch (keyName) {
    case 'SALES_HQ_LEV1_NAME':
      return [];

    case 'SALES_HQ_LEV2_NAME':
      return ['SALES_HQ_LEV1_NAME'];

    case 'CHANNEL_LEV1_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME'];

    case 'CHANNEL_LEV2_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME'];

    case 'CHANNEL_LEV3_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME'];

    case 'CHANNEL_NL':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'];

    default:
      return [];
  }
}

const getHQFiltersClosestParent = (keyName: string) => {
  switch (keyName) {
    case 'SALES_HQ_LEV1_NAME':
      return [];

    case 'SALES_HQ_LEV2_NAME':
      return ['SALES_HQ_LEV1_NAME'];

    case 'CHANNEL_LEV1_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME'];

    case 'CHANNEL_LEV2_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME'];

    case 'CHANNEL_LEV3_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME'];

    case 'CHANNEL_NL':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'];

    default:
      return [];
  }
}

const getDistrictFiltersChild = (keyName: string) => {
  switch (keyName) {
    case 'SALES_HQ_LEV1_NAME':
      return ['SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'SALES_HQ_LEV2_NAME':
      return ['SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'SALES_HQ_LEV3_NAME':
      return ['SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'SALES_CENTER_NAME':
      return ['SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'SALES_CONTROL_BASE_NAME':
      return ['SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'SALES_CONTROL_BASE_ROUTE_CODE':
      return ['CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'CHANNEL_LEV1_NAME':
      return ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'CHANNEL_LEV2_NAME':
      return ['CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'CHANNEL_LEV3_NAME':
      return ['CHANNEL_NL'];

    case 'CHANNEL_NL':
        return [];

    default:
      return [];
  }
}

const getDistrictFiltersClosestParent = (keyName: string) => {
  switch (keyName) {
    case 'SALES_HQ_LEV1_NAME':
      return [];

    case 'SALES_HQ_LEV2_NAME':
      return ['SALES_HQ_LEV1_NAME'];

    case 'SALES_HQ_LEV3_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME'];

    case 'SALES_CENTER_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME'];

    case 'SALES_CONTROL_BASE_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME'];

    case 'SALES_CONTROL_BASE_ROUTE_CODE':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME'];

    case 'CHANNEL_LEV1_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE'];

    case 'CHANNEL_LEV2_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME'];

    case 'CHANNEL_LEV3_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME'];

    case 'CHANNEL_NL':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'];

    default:
      return [];
  }
}


const getProgressRankingFiltersChild = (keyName: string) => {
  switch (keyName) {
    case 'SALES_HQ_LEV1_NAME':
      return ['SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'SALES_HQ_LEV2_NAME':
      return ['SALES_HQ_LEV3_NAME',  'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'SALES_HQ_LEV3_NAME':
      return [ 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];
    case 'CHANNEL_LEV1_NAME':
      return ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'CHANNEL_LEV2_NAME':
      return ['CHANNEL_LEV3_NAME','CHANNEL_NL'];

    case 'CHANNEL_LEV3_NAME':
      return ['CHANNEL_NL'];

    case 'CHANNEL_NL':
        return [];

    default:
      return [];
  }
}

const getProgressRankingFiltersClosestParent = (keyName: string) => {
  switch (keyName) {
    case 'SALES_HQ_LEV1_NAME':
      return [];

    case 'SALES_HQ_LEV2_NAME':
      return ['SALES_HQ_LEV1_NAME'];

    case 'SALES_HQ_LEV3_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME'];

    case 'CHANNEL_LEV1_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME'];

    case 'CHANNEL_LEV2_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME'];

    case 'CHANNEL_LEV3_NAME':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME'];

    case 'CHANNEL_NL':
      return ['SALES_HQ_LEV1_NAME', 'SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'];

    default:
      return [];
  }
}

const pageName = (moduleName: string) => {
  let type = null;

  switch (moduleName) {
    case 'HQLevel1Component':
      type = 'HQProgressLevel1';
      break;
    case 'HQLevel2Component':
      type = 'HQProgressLevel2';
      break;
    case 'HQNationalLocalComponent':
      type = 'HQProgress';
      break;
    case 'ProgressTimelineComponent':
      type = 'HQProgressTimeLine';
      break;

    case 'VsLyCategoryComponent':
    case 'VsLyBrandComponent':
    case 'VsLyPackageComponent':
    case 'VsLySKUComponent':
    case 'VsLyTimelineComponent':
      type = 'SkuDeepdive'
      break;

    case 'ProgressByDistrictComponent':
      type = 'DistrictProgress';
      break;
    case 'ProgressByRouteComponent':
      type = 'DistrictProgressByRoute';
      break;
    case 'DistrictTimelineComponent':
      type = 'DistrictProgress';
      break;

    case 'AreasalesrankingComponent':
      type = 'ProgressRanking';
      break;

    default:
      break;
  }

  return type;
}
