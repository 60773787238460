import { Component, Renderer2, OnChanges, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import html2canvas from 'src/html2canvas';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { DropdownValueToNgSelectFormat } from 'src/app/helpers/dropdown';
import { getColorsWithMovingAverage, getSeriesWithMovingAverage } from 'src/app/helpers/movingAverage';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';
@Component({
  selector: 'intage-otc-value-vs-ly',
  templateUrl: './otc-value-vs-ly.component.html',
  styleUrls: ['./otc-value-vs-ly.component.scss']
})
export class IntageOtcValueVsLyComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  categories: any;
  otcTotal: any;
  otcByChannels: any;
  intageOtcChart: ApexCharts;
  otcChannels: any;
  otcWeeks: any;
  otcStartWeeks: number;
  otcHaveSingleSerie: boolean;
  otcHaveData: boolean;
  otcSingleSerie: string;
  otcSingleSerieColor: string;
  categoryColors: any;
  selectedCategory: any;
  categoriesFilter: any;
  makerLogo: any;
  marketShareMakers: any;
  summaryRegions: any;
  summaryPackageTypes: any;
  otcSelectedPackageType: any;
  otcSelectedMaker:any;
  otcSelectedRegion: any;
  populated: boolean;
  selectedChannelType: string;
  series: any;
  aggregateBy: any;
  otcMonths: any;
  otcYears: any;
  otcQuarters: any;
  start_week: any;
  movingAverageWindowSizes: number[] = [4];
  chartId = "value-vs-ly";

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }

  ngOnChanges() {
      if(!this.populated){
        this.otcSelectedPackageType = ['All'];
        this.otcSelectedMaker = ['All'];
        this.otcSelectedRegion = ['CCBJI Territory'];
        this.categoryColors = IntageHelper.CategoryColors();
        this.selectedCategory = ['Total'];
        this.makerLogo = IntageHelper.MakerLogos();
        this.marketShareMakers = [{maker:'All'}];
        this.selectedChannelType = "1";
        this.makerLogo.forEach(x => {
          this.marketShareMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked
          });
        });
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        let commonData = IntageHelper.PopulateCommonData(this.intage, this.selectedWeek);
        if(!this.populated){
          this.otcChannels = commonData.otcChannels;
          this.populated = true;
        }
        this.categories = commonData.categories;
        this.summaryRegions = commonData.summaryRegions;
        this.summaryPackageTypes = commonData.summaryPackageTypes;
        this.categoriesFilter = commonData.categories;
        this.otcStartWeeks = 52;
        this.handleIntageOtc();
        //this.renderIntageOtcChart();
      }

      // Reset the start week when end date changed

     
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["selectedCategory"] = [...this.selectedCategory];
      window["otcSelectedRegion"] = [...this.otcSelectedRegion];
      window["otcSelectedPackageType"] = [...this.otcSelectedPackageType];
      window["otcSelectedMaker"] = [...this.otcSelectedMaker];
    } else {
      if (window["selectedCategory"] != null) { this.selectedCategory = window["selectedCategory"]; }
      if (window["otcSelectedRegion"] != null) { this.otcSelectedRegion = window["otcSelectedRegion"]; }
      if (window["otcSelectedPackageType"] != null) { this.otcSelectedPackageType = window["otcSelectedPackageType"]; }
      if (window["otcSelectedMaker"] != null) { this.otcSelectedMaker = window["otcSelectedMaker"]; }
    }
  } 
  onRemoveFilterOTCCategory($event) {
    if(this.selectedCategory.length == 0){
      this.selectedCategory = ['Total'];
      this.SaveOptionValueTemp(false);
      this.onFilterByCategoryChange()
    }
    if ($event.value === 'Total') {
      this.selectedCategory = [];
      return;
    }
  }
  onAddFilterOTCCategory($event) {
    if ($event === 'Total') {
      this.selectedCategory = ['Total'];
      return;
    }
    if(this.selectedCategory.filter(x=>x!='Total').length==(this.categoriesFilter.length-1)){
      this.selectedCategory = ['Total'];
    }else{
      this.selectedCategory = this.selectedCategory.filter(e => e !== 'Total');;
    }
  } 
  onRemoveFilterOTCMaker($event) {
    if(this.otcSelectedMaker.length == 0){
      this.otcSelectedMaker = ['All'];
      this.SaveOptionValueTemp(false);
      this.onFilterByOtcMakerChange()
    }
    if ($event.value === 'All') {
      this.otcSelectedMaker = [];
      return;
    }
  }
  onAddFilterOTCMaker($event) {
    if ($event === 'All') {
      this.otcSelectedMaker = ['All'];
      return;
    }
    if(this.otcSelectedMaker.filter(x=>x!='All').length==(this.marketShareMakers.length-1)){
      this.otcSelectedMaker = ['All'];
    }else{
      this.otcSelectedMaker = this.otcSelectedMaker.filter(e => e !== 'All');;
    }
  } 
  onRemoveFilterOTCRegion($event) {
    if(this.otcSelectedRegion.length == 0){
      this.otcSelectedRegion = ['CCBJI Territory'];
      this.SaveOptionValueTemp(false);
      this.onFilterByOtcRegionChange()
    }
    if ($event.value === 'CCBJI Territory') {
      this.otcSelectedRegion = [];
      return;
    }
  }
  onAddFilterOTCRegion($event) {
    if ($event === 'CCBJI Territory') {
      this.otcSelectedRegion = ['CCBJI Territory'];
      return;
    }
    if(this.otcSelectedRegion.filter(x=> x!='CCBJI Territory').length == (this.summaryRegions.length-1)){
      this.otcSelectedRegion = ['CCBJI Territory'];
    }else{
      this.otcSelectedRegion = this.otcSelectedRegion.filter(e => e !== 'CCBJI Territory');;
    }
  } 
  onRemoveFilterOTCPackageType($event) {
    if(this.otcSelectedPackageType.length == 0){
      this.otcSelectedPackageType = ['All'];
      this.SaveOptionValueTemp(false);
      this.onFilterByOtcPackageTypeChange()
    }
    if ($event.value === 'All') {
      this.otcSelectedPackageType = [];
      return;
    }
  }
  onAddFilterOTCPackageType($event) {
    if ($event === 'All') {
      this.otcSelectedPackageType = ['All'];
      return;
    }
    if(this.otcSelectedPackageType.filter(x=> x!='All').length == (this.summaryPackageTypes.length-1)){
      this.otcSelectedPackageType = ['All'];
    }else{
      this.otcSelectedPackageType = this.otcSelectedPackageType.filter(e => e !== 'All');;
    }
  }
    
  handleIntageOtc(){
    try
    {
      this.otcWeeks = [...new Set(this.intage.map(x=> x.week))];
      if(this.selectedWeek == null || this.selectedWeek == ''){
        this.selectedWeek = this.otcWeeks[0] as string;
      }
      this.marketShareMakers = [{maker:'All'}];
      for (let index = 0; index < this.makerLogo.length; index++) {
        if(!this.selectedCategory.some(x => x == "Energy") && this.makerLogo[index].maker == 'Red Bull'){
          continue;
        }
        this.marketShareMakers.push({
          maker: this.makerLogo[index].maker,
          logo: this.makerLogo[index].logo,
          checked: this.makerLogo[index].checked
        });
      }
      if(this.otcSelectedMaker.some(x => x == "Red Bull") && !this.marketShareMakers.some(x => x.maker == "Red Bull")){
        this.otcSelectedMaker = [this.marketShareMakers[0].maker];
        this.SaveOptionValueTemp(false);
      }
      let otcData = [];
      let selectedWeekInt = parseInt(this.selectedWeek.replace('-','').replace('-',''));
      for(var i = 0;i<this.intage.length;i++){
        let row = this.intage[i];
        if(parseInt(row.week.replace('-','').replace('-','')) > selectedWeekInt){
          continue;
        }
        else if(this.selectedCategory.includes('Total') || this.selectedCategory.includes(row.category)){
          if(this.otcSelectedRegion.includes('CCBJI Territory') || this.otcSelectedRegion.includes(row.region)){
            if(this.otcSelectedPackageType.includes('All') || this.otcSelectedPackageType.includes(row.packageType)){
              if(this.otcSelectedMaker.includes('All') || this.otcSelectedMaker.includes(row.maker)){
                otcData.push(row);
              }
            }
          }
        }
      }

      this.otcWeeks = [...new Set(otcData.map(x=> x.week))];     
      
      if (this.otcWeeks.length > this.otcStartWeeks - 1)
      {
        this.start_week = this.otcWeeks[this.otcStartWeeks - 1];
      }
      else 
      {
        this.start_week = this.otcWeeks[this.otcWeeks.length - 1];
      }
      
      this.otcTotal = [];
      //Aggregated by channel
      this.otcByChannels = [{
        channel: 'SMDD',
        weeks: []
      }];
      let smddChanel = this.otcByChannels[0];
      for(var i=0;i<otcData.length;i++){
        //Channel
        let row = otcData[i];
        if(this.otcChannels.find(x=> x.channel == row.channel) == null){
          this.otcChannels.push({
            channel: row.channel,
            checked: false
          });
        }
        let channel = this.otcByChannels.find(x=> x.channel == row.channel);
        if(channel == null){
          channel = {
            channel: row.channel,
            weeks: [{
              week: row.week,
              ty: parseFloat(row.ty),
              ly: parseFloat(row.ly),
              vsLy: ''
            }]
          };
          this.otcByChannels.push(channel);
        }
        else{
          let week = channel.weeks.find(x=> x.week == row.week);
          if(week == null){
            week = {
              week: row.week,
              ty: parseFloat(row.ty),
              ly: parseFloat(row.ly),
              vsLy: ''
            }
            channel.weeks.push(week);
          }
          else{
            week.ty += parseFloat(row.ty);
            week.ly += parseFloat(row.ly);
          }
        }
        //Total
        let total = this.otcTotal.find(x=> x.week == row.week);
        if(total == null){
          total = {
            week: row.week,
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            vsLy: ''
          };
          this.otcTotal.push(total);
        }
        else{
          total.ty += parseFloat(row.ty);
          total.ly += parseFloat(row.ly);
        }
        //SMDD
        if(row.channel == 'SM' || row.channel == 'Drug' || row.channel == 'Discounter'){
          let smddWeek = smddChanel.weeks.find(x=> x.week == row.week);
          if(smddWeek == null){
            smddWeek = {
              week: row.week,
              ty: parseFloat(row.ty),
              ly: parseFloat(row.ly),
              vsLy: ''
            };
            smddChanel.weeks.push(smddWeek);
          }
          else{
            smddWeek.ty += parseFloat(row.ty);
            smddWeek.ly += parseFloat(row.ly);
          }
        }
      }

      switch (this.aggregateBy) {
        case 'mtd':
          this.handleIntageOTCMonthly();
          break;
        case 'qtd':
          this.handleIntageOTCQuarterly();          
          break;
        case 'ytd':
          this.handleIntageOTCYearly();
          break;
        default:
          this.handleIntageOTCWeekly();
          break;
      }      
    }
    catch(error){
      console.log(error);
    }
    let t= this;

      setTimeout(() => {
        t.ready.emit(true);
      });    
  }

  handleIntageOTCWeekly()
  {
    for(var i=0;i<this.otcByChannels.length;i++){
          let channel = this.otcByChannels[i];
          for(let j=0;j<channel.weeks.length;j++){
            let week = channel.weeks[j];
            if (week.ly == 0)
            {
              week.vsLy = null;
            }
            else if (week.ty == 0)
            {
              week.vsLy = '-100.0';
            }
            else
            {
              week.vsLy = ((week.ty/week.ly) * 100 - 100).toFixed(1);
            }
          }
        }
      for(var i=0;i<this.otcTotal.length;i++){
        let total = this.otcTotal[i];
        if (total.ly == 0)
        {
          total.vsLy = null;
        }
        else if (total.ty == 0)
        {
          total.vsLy = '-100.0';
        }
        else
        {
          total.vsLy = ((total.ty/total.ly) * 100 - 100).toFixed(1);
        }
      }   
      this.otcTotal.reverse();
      this.otcWeeks.reverse();
      for(var i=0;i<this.otcByChannels.length;i++){    
        this.otcByChannels[i].weeks.reverse();
      }
      if(this.intageOtcChart != null){
        this.intageOtcChart.destroy();
      }
  
      this.series = [];
      let colors = [];
      if(this.otcChannels[0].checked){
        this.series.push({
          name: "OTC Total",
          data: this.otcTotal.map(x=> x.vsLy)
        });
        colors.push(IntageHelper.GetMakerColor(this.series[this.series.length-1].name));
      }
      for(var i=1;i<this.otcChannels.length;i++){
        if(this.otcChannels[i].checked){
          let otcChannel = this.otcByChannels.find(x=> x.channel == this.otcChannels[i].channel);
          if(otcChannel != null){
            otcChannel.weeks = otcChannel.weeks.filter(x => this.otcWeeks.slice(-1 * this.otcStartWeeks).includes(x.week));
            var weeks_channel = otcChannel.weeks.map(x => x.week);
  
            var nullweek = this.otcWeeks.slice(-1 * this.otcStartWeeks).filter(x => !weeks_channel.includes(x));
  
            nullweek.forEach(week => {
              otcChannel.weeks.push(
                {
                  week: week,
                  vsLy: null
                }
              )
            })
  
            otcChannel.weeks =  otcChannel.weeks.sort((prev, curr) => 
            {
              if (prev.week < curr.week)
              {
                return -1;
              }
              return 1;
  
            });
  
            let serie = {
              name : this.otcChannels[i].channel,
              data : otcChannel.weeks.map(x=> x.vsLy)
            };
            this.series.push(serie);
            colors.push(IntageHelper.GetMakerColor(serie.name));
          }
          else{
            let serie = {
              name : this.otcChannels[i].channel,
              data : []
            };
            this.series.push(serie);
            colors.push(IntageHelper.GetMakerColor(serie.name));
          }
        }
      }
  
      this.otcHaveData = false;
      for(var i=0;i<this.series.length;i++){
        if(this.series[i].data.length > 0){
          this.otcHaveData = true;
          break;
        }
      }
      this.otcHaveSingleSerie = false;
      if(this.otcHaveData && this.series.length == 1){
        this.otcHaveSingleSerie = true;
        this.otcSingleSerie = this.series[0].name;
        this.otcSingleSerieColor = colors[0];
      }
  
      // otcStartWeeks    
      for (let i = 0; i < this.series.length; i++) {
        this.series[i]['data'] = this.series[i]['data'].slice(-1 * this.otcStartWeeks)      
      }
  
      this.renderIntageOtcChart(colors, this.otcWeeks.slice(-1 * this.otcStartWeeks));
  }

  handleIntageOTCMonthly()
  {
    this.otcByChannels.forEach(channel => {
      channel.weeks = channel.weeks.slice(0, this.otcStartWeeks)
    });

    this.otcTotal = this.otcTotal.slice(0, this.otcStartWeeks);

    var otcTotal = [];

    this.otcTotal.forEach(week => {
      let month = this.intageMonth.filter(x => this.otcWeeks.includes(x.week) && x.week == week.week && week.week >= this.start_week);
      if (month.length > 0)
      {
        if (otcTotal.some(x => x.month == month[0].month))
        {
          var month_data = otcTotal.filter(x => x.month == month[0].month)[0];
          month_data.ty += week.ty;
          month_data.ly += week.ly;
        }
        else
        {
          otcTotal.push(
            {
              month : month[0].month,
              ty : week.ty,
              ly: week.ly
            }
          )
        }
      }
    })

    this.otcMonths = [...new Set(this.intageMonth.map(x=> 
      {
        if (x.week >= this.start_week && x.week <= this.otcWeeks[0])
        {
          return x.month;
        }
      }))];

    this.otcMonths = this.otcMonths.filter(x => x != null);

    this.otcByChannels.forEach(channel => {
      channel.months = [];
      channel.weeks.forEach(week => {
        let month = this.intageMonth.filter(x => this.otcWeeks.includes(x.week) && x.week == week.week && week.week >= this.start_week);
        if (month.length > 0)
        {
          if (channel.months.some(x => x.month == month[0].month))
          {
            var month_data = channel.months.filter(x => x.month == month[0].month)[0];
            month_data.ty += week.ty;
            month_data.ly += week.ly;
          }
          else 
          {
            channel.months.push(
              {
                month : month[0].month,
                ty : week.ty,
                ly: week.ly
              }
            )
          }
        }
      })
      this.otcMonths.forEach(month => {
        if (!channel.months.some(x => x.month == month))
        {
          channel.months.push(
            {
              month : month,
              ty : null,
              ly: null
            }
          )
        }
      })
    })

    for(var i=0;i<this.otcByChannels.length;i++){
      let channel = this.otcByChannels[i];
      for(let j=0;j<channel.months.length;j++){
        let month = channel.months[j];
        if (month.ly == 0)
        {
          month.vsLy = null;
        }
        else if (month.ty == 0)
        {
          month.vsLy = '-100.0';
        }
        else
        {
          month.vsLy = ((month.ty/month.ly) * 100 - 100).toFixed(1);
        }
      }
    }
    for(var i=0;i<otcTotal.length;i++){
      let total = otcTotal[i];
      if (total.ly == 0)
      {
        total.vsLy = null;
      }
      else if (total.ty == 0)
      {
        total.vsLy = '-100.0';
      }
      else
      {
        total.vsLy = ((total.ty/total.ly) * 100 - 100).toFixed(1);
      }
    }
    this.otcTotal = otcTotal;
    this.otcTotal.reverse();
    this.otcWeeks.reverse();
    for(var i=0;i<this.otcByChannels.length;i++){    
      this.otcByChannels[i].months.reverse();
    }

    if(this.intageOtcChart != null){
      this.intageOtcChart.destroy();
    }

    this.series = [];
    let colors = [];
    if(this.otcChannels[0].checked){
      this.series.push({
        name: "OTC Total",
        data: this.otcTotal.map(x=> x.vsLy)
      });
      colors.push(IntageHelper.GetMakerColor(this.series[this.series.length-1].name));
    }
    for(var i=1;i<this.otcChannels.length;i++){
      if(this.otcChannels[i].checked){
        let otcChannel = this.otcByChannels.find(x=> x.channel == this.otcChannels[i].channel);
        if(otcChannel != null){
          otcChannel.months =  otcChannel.months.sort((prev, curr) => 
          {
            if (prev.month < curr.month)
            {
              return -1;
            }
            return 1;

          });

          let serie = {
            name : this.otcChannels[i].channel,
            data : otcChannel.months.map(x=> x.vsLy)
          };
          this.series.push(serie);
          colors.push(IntageHelper.GetMakerColor(serie.name));
        }
        else{
          let serie = {
            name : this.otcChannels[i].channel,
            data : []
          };
          this.series.push(serie);
          colors.push(IntageHelper.GetMakerColor(serie.name));
        }
      }
    }

    this.otcHaveData = false;
    for(var i=0;i<this.series.length;i++){
      if(this.series[i].data.length > 0){
        this.otcHaveData = true;
        break;
      }
    }
    this.otcHaveSingleSerie = false;
    if(this.otcHaveData && this.series.length == 1){
      this.otcHaveSingleSerie = true;
      this.otcSingleSerie = this.series[0].name;
      this.otcSingleSerieColor = colors[0];
    }

    this.renderIntageOtcChart(colors, this.otcMonths);
  }

  handleIntageOTCQuarterly()
  {
    this.otcByChannels.forEach(channel => {
      channel.weeks = channel.weeks.slice(0, this.otcStartWeeks)
    });

    this.otcTotal = this.otcTotal.slice(0, this.otcStartWeeks);

    var otcTotal = [];

    this.otcTotal.forEach(week => {
      let quarter = this.intageMonth.filter(x => this.otcWeeks.includes(x.week) && x.week == week.week && week.week >= this.start_week);
      if (quarter.length > 0)
      {
        if (otcTotal.some(x => x.quarter == quarter[0].quarter))
        {
          var quarter_data = otcTotal.filter(x => x.quarter == quarter[0].quarter)[0];
          quarter_data.ty += week.ty;
          quarter_data.ly += week.ly;
        }
        else
        {
          otcTotal.push(
            {
              quarter : quarter[0].quarter,
              ty : week.ty,
              ly: week.ly
            }
          )
        }
      }
    })

    this.otcQuarters = [...new Set(this.intageMonth.map(x=> 
      {
        if (x.week >= this.start_week && x.week <= this.otcWeeks[0])
        {
          return x.quarter;
        }
      }))];

    this.otcQuarters = this.otcQuarters.filter(x => x != null);

    this.otcByChannels.forEach(channel => {
      channel.quarters = [];
      channel.weeks.forEach(week => {
        let quarter = this.intageMonth.filter(x => this.otcWeeks.includes(x.week) && x.week == week.week && week.week >= this.start_week);
        if (quarter.length > 0)
        {
          if (channel.quarters.some(x => x.quarter == quarter[0].quarter))
          {
            var quarter_data = channel.quarters.filter(x => x.quarter == quarter[0].quarter)[0];
            quarter_data.ty += week.ty;
            quarter_data.ly += week.ly;
          }
          else 
          {
            channel.quarters.push(
              {
                quarter : quarter[0].quarter,
                ty : week.ty,
                ly: week.ly
              }
            )
          }
        }
      })
      this.otcQuarters.forEach(quarter => {
        if (!channel.quarters.some(x => x.quarter == quarter))
        {
          channel.quarters.push(
            {
              quarter : quarter,
              ty : null,
              ly: null
            }
          )
        }
      })
    })

    for(var i=0;i<this.otcByChannels.length;i++){
      let channel = this.otcByChannels[i];
      for(let j=0;j<channel.quarters.length;j++){
        let quarter = channel.quarters[j];
        if (quarter.ly == 0)
        {
          quarter.vsLy = null;
        }
        else if (quarter.ty == 0)
        {
          quarter.vsLy = '-100.0';
        }
        else
        {
          quarter.vsLy = ((quarter.ty/quarter.ly) * 100 - 100).toFixed(1);
        }
      }
    }
    for(var i=0;i<otcTotal.length;i++){
      let total = otcTotal[i];
      if (total.ly == 0)
      {
        total.vsLy = null;
      }
      else if (total.ty == 0)
      {
        total.vsLy = '-100.0';
      }
      else
      {
        total.vsLy = ((total.ty/total.ly) * 100 - 100).toFixed(1);
      }
    }
    this.otcTotal = otcTotal;
    this.otcTotal.reverse();
    this.otcWeeks.reverse();
    for(var i=0;i<this.otcByChannels.length;i++){    
      this.otcByChannels[i].quarters.reverse();
    }

    if(this.intageOtcChart != null){
      this.intageOtcChart.destroy();
    }

    this.series = [];
    let colors = [];
    if(this.otcChannels[0].checked){
      this.series.push({
        name: "OTC Total",
        data: this.otcTotal.map(x=> x.vsLy)
      });
      colors.push(IntageHelper.GetMakerColor(this.series[this.series.length-1].name));
    }
    for(var i=1;i<this.otcChannels.length;i++){
      if(this.otcChannels[i].checked){
        let otcChannel = this.otcByChannels.find(x=> x.channel == this.otcChannels[i].channel);
        if(otcChannel != null){
          otcChannel.quarters =  otcChannel.quarters.sort((prev, curr) => 
          {
            if (prev.quarter < curr.quarter)
            {
              return -1;
            }
            return 1;

          });

          let serie = {
            name : this.otcChannels[i].channel,
            data : otcChannel.quarters.map(x=> x.vsLy)
          };
          this.series.push(serie);
          colors.push(IntageHelper.GetMakerColor(serie.name));
        }
        else{
          let serie = {
            name : this.otcChannels[i].channel,
            data : []
          };
          this.series.push(serie);
          colors.push(IntageHelper.GetMakerColor(serie.name));
        }
      }
    }

    this.otcHaveData = false;
    for(var i=0;i<this.series.length;i++){
      if(this.series[i].data.length > 0){
        this.otcHaveData = true;
        break;
      }
    }
    this.otcHaveSingleSerie = false;
    if(this.otcHaveData && this.series.length == 1){
      this.otcHaveSingleSerie = true;
      this.otcSingleSerie = this.series[0].name;
      this.otcSingleSerieColor = colors[0];
    }

    this.renderIntageOtcChart(colors, this.otcQuarters);
  }

  handleIntageOTCYearly()
  {
    this.otcByChannels.forEach(channel => {
      channel.weeks = channel.weeks.slice(0, this.otcStartWeeks)
    });

    this.otcTotal = this.otcTotal.slice(0, this.otcStartWeeks);

    var otcTotal = [];

    this.otcTotal.forEach(week => {
      let year = this.intageMonth.filter(x => this.otcWeeks.includes(x.week) && x.week == week.week && week.week >= this.start_week);
      if (year.length > 0)
      {
        if (otcTotal.some(x => x.year == year[0].year))
        {
          var year_data = otcTotal.filter(x => x.year == year[0].year)[0];
          year_data.ty += week.ty;
          year_data.ly += week.ly;
        }
        else
        {
          otcTotal.push(
            {
              year : year[0].year,
              ty : week.ty,
              ly: week.ly
            }
          )
        }
      }
    })

    this.otcYears = [];

    this.otcYears = [...new Set(this.intageMonth.map(x=> 
      {
        if (x.week >= this.start_week && x.week <= this.otcWeeks[0])
        {
          return x.year;
        }
      }))];

    this.otcYears = this.otcYears.filter(x => x != null);

    this.otcByChannels.forEach(channel => {
      channel.years = [];
      channel.weeks.forEach(week => {
        let year = this.intageMonth.filter(x => this.otcWeeks.includes(x.week) && x.week == week.week && week.week >= this.start_week);
        if (year.length > 0)
        {
          if (!this.otcYears.includes(year[0].year))
          {
            this.otcYears.push(year[0].year);
          }
          if (channel.years.some(x => x.year == year[0].year))
          {
            var year_data = channel.years.filter(x => x.year == year[0].year)[0];
            year_data.ty += week.ty;
            year_data.ly += week.ly;
          }
          else 
          {
            channel.years.push(
              {
                year : year[0].year,
                ty : week.ty,
                ly: week.ly
              }
            )
          }
        }
      })
      this.otcYears.forEach(year => {
        if (!channel.years.some(x => x.year == year))
        {
          channel.years.push(
            {
              year : year,
              ty : null,
              ly: null
            }
          )
        }
      })
    })

    for(var i=0;i<this.otcByChannels.length;i++){
      let channel = this.otcByChannels[i];
      for(let j=0;j<channel.years.length;j++){
        let year = channel.years[j];
        if (year.ly == 0)
        {
          year.vsLy = null;
        }
        else if (year.ty == 0)
        {
          year.vsLy = '-100.0';
        }
        else
        {
          year.vsLy = ((year.ty/year.ly) * 100 - 100).toFixed(1);
        }
      }
    }
    for(var i=0;i<otcTotal.length;i++){
      let total = otcTotal[i];
      if (total.ly == 0)
      {
        total.vsLy = null;
      }
      else if (total.ty == 0)
      {
        total.vsLy = '-100.0';
      }
      else
      {
        total.vsLy = ((total.ty/total.ly) * 100 - 100).toFixed(1);
      }
    }
    this.otcTotal = otcTotal;
    this.otcTotal.reverse();
    this.otcWeeks.reverse();
    for(var i=0;i<this.otcByChannels.length;i++){    
      this.otcByChannels[i].years.reverse();
    }

    if(this.intageOtcChart != null){
      this.intageOtcChart.destroy();
    }

    this.series = [];
    let colors = [];
    if(this.otcChannels[0].checked){
      this.series.push({
        name: "OTC Total",
        data: this.otcTotal.map(x=> x.vsLy)
      });
      colors.push(IntageHelper.GetMakerColor(this.series[this.series.length-1].name));
    }
    for(var i=1;i<this.otcChannels.length;i++){
      if(this.otcChannels[i].checked){
        let otcChannel = this.otcByChannels.find(x=> x.channel == this.otcChannels[i].channel);
        if(otcChannel != null){
          otcChannel.years =  otcChannel.years.sort((prev, curr) => 
          {
            if (prev.year < curr.year)
            {
              return -1;
            }
            return 1;

          });

          let serie = {
            name : this.otcChannels[i].channel,
            data : otcChannel.years.map(x=> x.vsLy)
          };
          this.series.push(serie);
          colors.push(IntageHelper.GetMakerColor(serie.name));
        }
        else{
          let serie = {
            name : this.otcChannels[i].channel,
            data : []
          };
          this.series.push(serie);
          colors.push(IntageHelper.GetMakerColor(serie.name));
        }
      }
    }

    this.otcHaveData = false;
    for(var i=0;i<this.series.length;i++){
      if(this.series[i].data.length > 0){
        this.otcHaveData = true;
        break;
      }
    }
    this.otcHaveSingleSerie = false;
    if(this.otcHaveData && this.series.length == 1){
      this.otcHaveSingleSerie = true;
      this.otcSingleSerie = this.series[0].name;
      this.otcSingleSerieColor = colors[0];
    }

    this.renderIntageOtcChart(colors, this.otcYears);
  }

  renderIntageOtcChart(colors: any, categories: any){
    const options = {
      chart: {
          height: 500,
          type: 'line',
          zoom: {
              enabled: false
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
            }
          }
      },
      dataLabels: {
          enabled: this.otcHaveSingleSerie ? true : false,
          style:{
            colors: [
              function (w) {
                if (w.series[w.seriesIndex][w.dataPointIndex] > 0) {
                  return w.w.config.colors[w.seriesIndex];
                } else {
                  return "#FF2626";
                }
              },
            ],
            fontSize : "14px"
          }
      },
      stroke: {
          //width: [5, 5, 5],
          curve: 'smooth',
          //dashArray: [5, 5, 5]
      },
      colors: getColorsWithMovingAverage({ colors, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
      series: getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
      legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: true
          }
      },
      markers: {
          size: this.otcHaveSingleSerie ? 0 : 5,
          hover: {
              sizeOffset: 6
          }
      },
      xaxis: {
          categories: categories
      },
      grid: {
          borderColor: '#f1f1f1'
      },
      yaxis: {
        forceNiceScale: true,
        //min: -50
      }
    };  
    // FPT-ChienNX7 [2022/12/14][SENSE][1203444563290052] Rename file name png when export start

    document.querySelector("#intage-otc-chart").innerHTML = '';
    this.intageOtcChart = new ApexCharts(
        document.querySelector("#intage-otc-chart"),
        options
    );
    this.intageOtcChart.render();
  }

  public onOtcChannelCheckedChanged(channel: string){
    let otcChannel = this.otcChannels.find(x=> x.channel == channel);
    otcChannel.checked = !otcChannel.checked;
    this.handleIntageOtc();
  }
 
  public onFilterByOtcRegionChange(){
    if(this.otcSelectedRegion.length==0){
      this.otcSelectedRegion = ['CCBJI Territory'];
    }
    this.handleIntageOtc();
  }

  public onFilterByOtcPackageTypeChange(){
    if(this.otcSelectedPackageType.length == 0){
      this.otcSelectedPackageType = ['All'];
    }
    this.handleIntageOtc();
  }

  public onFilterByOtcMakerChange(){
    if(this.otcSelectedMaker.length==0){
      this.otcSelectedMaker = ['All'];
    }
    this.handleIntageOtc();
  }

  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }

  public onFilterByCategoryChange(){
    if(this.selectedCategory.length==0){
      this.selectedCategory = ['Total'];
    }
    this.handleIntageOtc();
  }

  public onSelectedStartWeekChanged($event){
    this.otcStartWeeks = $event;
    this.handleIntageOtc();
  }

  public onChannelCasesChange(type: any) {
    this.selectedChannelType = type;
    for (let i = 0; i < this.otcChannels.length; i++) {
      this.otcChannels[i].checked = i === 0 ? true : false;      
    }
    this.handleIntageOtc();
  }

  public onOtcValueAggregateChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
    this.handleIntageOtc();
  }

  public shouldChannelBeHidden(channel: string) {
    if (this.selectedChannelType === '1') {
      if (channel === 'SM' || channel === 'Drug' || channel === 'Discounter') {
        return true;
      }
    }
    if (this.selectedChannelType === '2') {
      if (channel === 'SMDD') {
        return true;
      }
    }
    return false
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-otc-value');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  public exportOTCValuevsLY(isImage: boolean) {
    let weeks = this.otcWeeks.slice(-1 * this.otcStartWeeks);
    switch (this.aggregateBy) {
      case 'mtd':
        var fileName = 'OTC Value vs LY From ' +  this.otcMonths[0] + ' To ' + this.otcMonths[this.otcMonths.length - 1];  
        break;
      case 'qtd':
        var fileName = 'OTC Value vs LY From ' +  this.otcQuarters[0] + ' To ' + this.otcQuarters[this.otcQuarters.length - 1];       
        break;
      case 'ytd':
        var fileName = 'OTC Value vs LY From ' +  this.otcYears[0] + ' To ' + this.otcYears[this.otcYears.length - 1];
        break;
      default:
        var fileName = 'OTC Value vs LY From ' +  weeks[0] + ' To ' + weeks[weeks.length - 1];
        break;
    }   
  
    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];

      switch (this.aggregateBy) {
        case 'mtd':
          for (var i = 0; i < this.otcMonths.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  month: this.otcMonths[i],
                  display: this.selectedChannelType == "1" ? "Channel Level 1" : "Channel Level 2",
                  channel: this.series[k].name,
                  category: this.selectedCategory,
                  region: this.otcSelectedRegion,
                  packagetype: this.otcSelectedPackageType,
                  maker: this.otcSelectedMaker,
                  vsLy: this.series[k].data[i]
                });
              }
            }
            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: ["Month", "Display", "Channel", "Category", "Region", "Package Type", "Maker", "% vs LY"]
            });
          break;
        case 'qtd':
          for (var i = 0; i < this.otcQuarters.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  quarter: this.otcQuarters[i],
                  display: this.selectedChannelType == "1" ? "Channel Level 1" : "Channel Level 2",
                  channel: this.series[k].name,
                  category: this.selectedCategory,
                  region: this.otcSelectedRegion,
                  packagetype: this.otcSelectedPackageType,
                  maker: this.otcSelectedMaker,
                  vsLy: this.series[k].data[i]
                });
              }
            }
            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: ["Quarter", "Display", "Channel", "Category", "Region", "Package Type", "Maker", "% vs LY"]
            });         
          break;
        case 'ytd':
          for (var i = 0; i < this.otcYears.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  year: this.otcYears[i],
                  display: this.selectedChannelType == "1" ? "Channel Level 1" : "Channel Level 2",
                  channel: this.series[k].name,
                  category: this.selectedCategory,
                  region: this.otcSelectedRegion,
                  packagetype: this.otcSelectedPackageType,
                  maker: this.otcSelectedMaker,
                  vsLy: this.series[k].data[i]
                });
              }
            }
            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: ["Year", "Display", "Channel", "Category", "Region", "Package Type", "Maker", "% vs LY"]
            });
          break;
        default:
          var series = getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes });      
            for (var k = 0; k < series.length; k++)
            {
              for (var i = 0; i < weeks.length; i++) {
                data.push({
                  week: weeks[i],
                  display: this.selectedChannelType == "1" ? "Channel Level 1" : "Channel Level 2",
                  channel: series[k].name,
                  category: this.selectedCategory,
                  region: this.otcSelectedRegion,
                  packagetype: this.otcSelectedPackageType,
                  maker: this.otcSelectedMaker,
                  vsLy: series[k].data[i]
                });
              }
            }
            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: ["Week", "Display", "Channel/MAV", "Category", "Region", "Package Type", "Maker", "% vs LY"]
            });
          break;
      }
    }
    this.showOptionDownload();
  }

  dropdownValueToNgSelectFormat(value) {
    const values = DropdownValueToNgSelectFormat(value);
    if (values?.length && this?.otcStartWeeks) {
      if (values.length < this.otcStartWeeks) {
        setTimeout(() => {
          this.otcStartWeeks = values.length
        }, 500);
      }
    }
    return values
  }

  onMovingAverageWindowSizeChange(value){
    this.movingAverageWindowSizes = value;
    this.handleIntageOtc();
  }

}
