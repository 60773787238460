import { Component, Renderer2, OnChanges, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {AnalyticsHelper} from 'src/app/helpers/analytics';
import { IntageHelper } from 'src/app/components/pages/intage-vm/intage_helper';
import { IntageHelper as IntageDashboardHelper } from 'src/app/components/pages/intage/intage_helper';
import html2canvas from 'src/html2canvas';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DropdownValueToNgSelectFormat } from 'src/app/helpers/dropdown';
import { getColorsWithMovingAverage, getSeriesWithMovingAverage } from 'src/app/helpers/movingAverage';
import lodashClonedeep from 'lodash.clonedeep';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
@Component({
  selector: 'intage-share-vs-ly-vm',
  templateUrl: './share-vs-ly-vm.component.html',
  styleUrls: ['./share-vs-ly-vm.component.scss']
})
export class IntageVMShareVsLyComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  otcWeeks: any;
  otcStartWeeks: number;  
  categoryColors: any;
  makerLogo: any;
  shareVsLyDisplayType: string;
  marketShareVsLyChart: ApexCharts;
  marketShareVsLyHaveSingleSerie: boolean;
  marketShareVsLyHaveData: boolean;
  marketShareVsLySingleSerie: string;
  marketShareVsLySingleSerieColor: string;
  marketShareVsLyMakers: any;
  shareVsLySelectedCategory: any;
  shareVsLySelectedRegion: any;
  marketShareVsLyData: any;
  populated: boolean;
  series: any;
  aggregateBy: any;
  monthSet: any;
  quarterSet: any;
  yearSet: any;
  movingAverageWindowSizes: number[] = [4];
  categoriesFilter: any;
  districts: any;
  items = [{}];
  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }
  ngOnChanges() {
      if(!this.populated){
        this.shareVsLySelectedCategory = ['Total'];
        this.shareVsLySelectedRegion = ['CCBJI'];
        this.categoryColors = IntageHelper.CategoryColors();
        this.shareVsLyDisplayType = 'value';
        this.makerLogo = IntageHelper.MakerLogos();
        this.marketShareVsLyMakers = [];
        this.makerLogo.forEach(x => {
          this.marketShareVsLyMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked,
            show: true
          });
        });
        this.populated = true;
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        this.categoriesFilter = IntageHelper.categoriesFilter();
        this.districts = IntageHelper.district();
        this.aggregateBy = 'wtd';
        this.otcStartWeeks = 52;
        this.handleMarketShareVsLy(); 
      }      
  }
  filterMakers(makers: any): any {
    return makers.filter(e => e.show);
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["shareVsLySelectedCategory"] = [...this.shareVsLySelectedCategory];
      window["shareVsLySelectedRegion"] = [...this.shareVsLySelectedRegion];
    } else {
      if (window["shareVsLySelectedCategory"] != null) { this.shareVsLySelectedCategory = window["shareVsLySelectedCategory"]; }
      if (window["shareVsLySelectedRegion"] != null) { this.shareVsLySelectedRegion = window["shareVsLySelectedRegion"]; }
    }
  }
 
  onRemoveFilteLyCategory($event) {
    if(this.shareVsLySelectedCategory.length == 0){
      this.shareVsLySelectedCategory = ['Total'];
      this.SaveOptionValueTemp(false);
      this.onShareVsLyCategoryChange()
    }
    if ($event.value === 'Total') {
      this.shareVsLySelectedCategory = [];
      return;
    }
  }

  onAddFilterLyCategory($event) {
    if ($event === 'Total') {
      this.shareVsLySelectedCategory = ['Total'];
      return;
    }
    if(this.shareVsLySelectedCategory.filter(x=>x!='Total').length==(this.categoriesFilter.length-1)){
      this.shareVsLySelectedCategory = ['Total'];
    }else{
      this.shareVsLySelectedCategory = this.shareVsLySelectedCategory.filter(e => e !== 'Total');;
    }
  }

  onRemoveFilterLyRegion($event) {
    if(this.shareVsLySelectedRegion.length == 0){
      this.shareVsLySelectedRegion = ['CCBJI'];
      this.SaveOptionValueTemp(false);
    }
    if ($event.value === 'CCBJI') {
      this.shareVsLySelectedRegion = [];
      return;
    }
  }

  onAddFilterLyRegion($event) {
    if ($event === 'CCBJI') {
      this.shareVsLySelectedRegion = ['CCBJI'];
      return;
    }
    if(this.shareVsLySelectedRegion.filter(x=> x != 'CCBJI').length == (this.districts.length-1)){
      this.shareVsLySelectedRegion = ['CCBJI'];
    }else{
      this.shareVsLySelectedRegion = this.shareVsLySelectedRegion.filter(e => e !== 'CCBJI');;
    }
  }
   
  onSharevslyAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketShareVsLy();
  }

  async handleMarketShareVsLy(){
    try
    {
      this.otcWeeks = await IndexDbHelper.Get('IntageVmWeeks');
      this.otcWeeks = this.otcWeeks.filter(x => x <= this.selectedWeek);
      let startWeek = this.otcWeeks.length >= this.otcStartWeeks ? this.otcWeeks[this.otcStartWeeks - 1] : this.otcWeeks[this.otcWeeks.length - 1]; 
      this.otcWeeks.reverse();
      this.items = this.dropdownValueToNgSelectFormat(this.otcWeeks);

      let data = lodashClonedeep(this.intage.filter(x => x.week >= startWeek && x.week <= this.selectedWeek && x.tyvalue != "" && x.lyvalue != "" && x.tyunit != "" && x.lyunit != ""));

      data = data.filter(x => x.botter_sci == this.shareVsLySelectedRegion[0]);
      if (!this.shareVsLySelectedCategory.includes('Total'))
      {
        data = data.filter(x => x.category == this.shareVsLySelectedCategory[0]);
      }

      switch (this.aggregateBy) {
        case 'mtd':
          this.handleIntageOTCMonthly(data);
          break;
        case 'qtd':
          this.handleIntageOTCQuarterly(data);          
          break;
        case 'ytd':
          this.handleIntageOTCYearly(data);
          break;
        default:
          this.handleIntageOTCWeekly(data);
          break;
      }      
    }
    catch(e){

    }
    let t= this;
      setTimeout(() => {
        t.ready.emit(true);
      });
    
  }

  handleIntageOTCWeekly(data: any)
  {
    this.marketShareVsLyData = [];
    let weeks = [...new Set(data.map(x=> x.week))];
    data = IntageHelper.transformData(data);

    this.marketShareVsLyMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      weeks.forEach(week => {
        let dataMakerWeek = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && x.week == week);
        let tyValueMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let tyValueMakeretSize = data.filter(x => x.week == week).reduce((acc, curr) => acc + curr.tyvalue, 0);

        let lyValueMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.lyvalue, 0);
        let lyValueMakeretSize = data.filter(x => x.week == week).reduce((acc, curr) => acc + curr.lyvalue, 0);

        let sharedifflyNumber = (tyValueMakeretSize == 0 || lyValueMakeretSize == 0) ? NaN : tyValueMaker / tyValueMakeretSize * 100 - lyValueMaker / lyValueMakeretSize * 100;
        let sharedifflyValue = IntageHelper.formatIntageNumber(sharedifflyNumber);
 
        let tyUnitMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.tyunit, 0);
        let tyUnitMakeretSize = data.filter(x => x.week == week).reduce((acc, curr) => acc + curr.tyunit, 0);
  
        let lyUnitMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.lyunit, 0);
        let lyUnitMakeretSize = data.filter(x => x.week == week).reduce((acc, curr) => acc + curr.lyunit, 0);

        let sharedifflyUnitNumber = (tyUnitMakeretSize == 0 || lyUnitMakeretSize == 0) ? NaN : tyUnitMaker / tyUnitMakeretSize * 100 - lyUnitMaker / lyUnitMakeretSize * 100;
        let sharedifflyUnit = IntageHelper.formatIntageNumber(sharedifflyUnitNumber);

        let marketShareTimeLineWeek = this.marketShareVsLyData.find(x => x.week == week);
        if (marketShareTimeLineWeek == null)
        {
          this.marketShareVsLyData.push({
            week: week,
            makers: [
              {
                maker: maker.maker,
                sharedifflyValue: sharedifflyValue,
                sharedifflyUnit: sharedifflyUnit
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineWeek.makers.push(
            {
              maker: maker.maker,
              sharedifflyValue: sharedifflyValue,
              sharedifflyUnit: sharedifflyUnit
            }
          )
        }
      })
    })

    this.marketShareVsLyData.reverse();
    weeks.reverse();
    this.renderMarketShareVsLyTimelineChart(weeks);
  }

  handleIntageOTCMonthly(data: any)
  {
    this.marketShareVsLyData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_month = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.monthSet = [...new Set(intage_month.map(x=> x.month))];

    data = IntageHelper.transformData(data);
    this.marketShareVsLyMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.monthSet.forEach(month => {
        let intageMonth = intage_month.filter(x => x.month == month);

        let dataMakerMonth = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageMonth.some(m => m.week == x.week));
        let tyValueMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let dataMarketSizeMonth = data.filter(x => intageMonth.some(m => m.week == x.week));
        let tyValueMakeretSize = dataMarketSizeMonth.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let lyValueMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.lyvalue, 0);
        let lyValueMakeretSize = dataMarketSizeMonth.reduce((acc, curr) => acc + curr.lyvalue, 0);

        let sharedifflyValueNumber = (tyValueMakeretSize == 0 || lyValueMakeretSize == 0) ? NaN : tyValueMaker / tyValueMakeretSize * 100 - lyValueMaker / lyValueMakeretSize * 100;
        let sharedifflyValue = IntageHelper.formatIntageNumber(sharedifflyValueNumber);

        let tyUnitMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.tyunit, 0);
        let tyUnitMakeretSize = dataMarketSizeMonth.reduce((acc, curr) => acc + curr.tyunit, 0);

        let lyUnitMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.lyunit, 0);
        let lyUnitMakeretSize = dataMarketSizeMonth.reduce((acc, curr) => acc + curr.lyunit, 0);

        let sharedifflyUnitNumber = (tyUnitMakeretSize == 0 || lyUnitMakeretSize == 0) ? NaN : tyUnitMaker / tyUnitMakeretSize * 100 - lyUnitMaker / lyUnitMakeretSize * 100;
        let sharedifflyUnit = IntageHelper.formatIntageNumber(sharedifflyUnitNumber);
  
        let marketShareTimeLineMonth = this.marketShareVsLyData.find(x => x.month == month);
        if (marketShareTimeLineMonth == null)
        {
          this.marketShareVsLyData.push({
            month: month,
            makers: [
              {
                maker: maker.maker,
                sharedifflyValue: sharedifflyValue,
                sharedifflyUnit: sharedifflyUnit
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineMonth.makers.push(
            {
              maker: maker.maker,
              sharedifflyValue: sharedifflyValue,
              sharedifflyUnit: sharedifflyUnit
            }
          )
        }
      })
    })

    this.renderMarketShareVsLyTimelineChart(this.monthSet);
  }

  handleIntageOTCQuarterly(data: any)
  {
    this.marketShareVsLyData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_quarter = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.quarterSet = [...new Set(intage_quarter.map(x=> x.quarter))];

    data = IntageHelper.transformData(data);
    this.marketShareVsLyMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.quarterSet.forEach(quarter => {
        let intageQuarter = intage_quarter.filter(x => x.quarter == quarter);

        let dataMakerQuarter = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageQuarter.some(m => m.week == x.week));
        let tyValueMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let dataMarketSizeQuarter = data.filter(x => intageQuarter.some(m => m.week == x.week));
        let tyValueMakeretSize = dataMarketSizeQuarter.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let lyValueMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.lyvalue, 0);
        let lyValueMakeretSize = dataMarketSizeQuarter.reduce((acc, curr) => acc + curr.lyvalue, 0);

        let sharedifflyValueNumber = (tyValueMakeretSize == 0 || lyValueMakeretSize == 0) ? NaN : tyValueMaker / tyValueMakeretSize * 100 - lyValueMaker / lyValueMakeretSize * 100;
        let sharedifflyValue = IntageHelper.formatIntageNumber(sharedifflyValueNumber);

        let tyUnitMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.tyunit, 0);
        let tyUnitMakeretSize = dataMarketSizeQuarter.reduce((acc, curr) => acc + curr.tyunit, 0);

        let lyUnitMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.lyunit, 0);
        let lyUnitMakeretSize = dataMarketSizeQuarter.reduce((acc, curr) => acc + curr.lyunit, 0);

        let sharedifflyUnitNumber = (tyUnitMakeretSize == 0 || lyUnitMakeretSize == 0) ? NaN : tyUnitMaker / tyUnitMakeretSize * 100 - lyUnitMaker / lyUnitMakeretSize * 100;
        let sharedifflyUnit = IntageHelper.formatIntageNumber(sharedifflyUnitNumber);
  
        let marketShareTimeLineQuarter = this.marketShareVsLyData.find(x => x.quarter == quarter);
        if (marketShareTimeLineQuarter == null)
        {
          this.marketShareVsLyData.push({
            quarter: quarter,
            makers: [
              {
                maker: maker.maker,
                sharedifflyValue: sharedifflyValue,
                sharedifflyUnit: sharedifflyUnit
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineQuarter.makers.push(
            {
              maker: maker.maker,
              sharedifflyValue: sharedifflyValue,
              sharedifflyUnit: sharedifflyUnit
            }
          )
        }
      })
    })

    this.renderMarketShareVsLyTimelineChart(this.quarterSet);
  }

  handleIntageOTCYearly(data: any)
  {
    this.marketShareVsLyData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_year = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.yearSet = [...new Set(intage_year.map(x=> x.year))];

    data = IntageHelper.transformData(data);
    this.marketShareVsLyMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.yearSet.forEach(year => {
        let intageYear = intage_year.filter(x => x.year == year);

        let dataMakerYear = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageYear.some(m => m.week == x.week));
        let tyValueMaker = dataMakerYear.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let dataMarketSizeYear = data.filter(x => intageYear.some(m => m.week == x.week));
        let tyValueMakeretSize = dataMarketSizeYear.reduce((acc, curr) => acc + curr.tyvalue, 0);

        let lyValueMaker = dataMakerYear.reduce((acc, curr) => acc + curr.lyvalue, 0);
        let lyValueMakeretSize = dataMarketSizeYear.reduce((acc, curr) => acc + curr.lyvalue, 0);

        let sharedifflyValueNumber = (tyValueMakeretSize == 0 || lyValueMakeretSize == 0) ? NaN : tyValueMaker / tyValueMakeretSize * 100 - lyValueMaker / lyValueMakeretSize * 100;
        let sharedifflyValue = IntageHelper.formatIntageNumber(sharedifflyValueNumber);

        let tyUnitMaker = dataMakerYear.reduce((acc, curr) => acc + curr.tyunit, 0);
        let tyUnitMakeretSize = dataMarketSizeYear.reduce((acc, curr) => acc + curr.tyunit, 0);
       
        let lyUnitMaker = dataMakerYear.reduce((acc, curr) => acc + curr.lyunit, 0);
        let lyUnitMakeretSize = dataMarketSizeYear.reduce((acc, curr) => acc + curr.lyunit, 0);

        let sharedifflyUnitNumber = (tyUnitMakeretSize == 0 || lyUnitMakeretSize == 0) ? NaN : tyUnitMaker / tyUnitMakeretSize * 100 - lyUnitMaker / lyUnitMakeretSize * 100;
        let sharedifflyUnit = IntageHelper.formatIntageNumber(sharedifflyUnitNumber);
  
        let marketShareTimeLineYear = this.marketShareVsLyData.find(x => x.year == year);
        if (marketShareTimeLineYear == null)
        {
          this.marketShareVsLyData.push({
            year: year,
            makers: [
              {
                maker: maker.maker,
                sharedifflyValue: sharedifflyValue,
                sharedifflyUnit: sharedifflyUnit
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineYear.makers.push(
            {
              maker: maker.maker,
              sharedifflyValue: sharedifflyValue,
              sharedifflyUnit: sharedifflyUnit
            }
          )
        }
      })
    })

    this.renderMarketShareVsLyTimelineChart(this.yearSet);
  }

  renderMarketShareVsLyTimelineChart(categories: any){
    if(this.marketShareVsLyChart != null){
      this.marketShareVsLyChart.destroy();
    }

    this.series = [];
    let colors = [];
    for(let i=0;i<this.marketShareVsLyMakers.length;i++){
      let maker = this.marketShareVsLyMakers[i];
      if(maker.checked){
        let serie = {
          name : maker.maker,
          data : []
        };
        for(let j=0;j<this.marketShareVsLyData.length;j++){
          let currentMaker = this.marketShareVsLyData[j].makers.find(x=> x.maker == maker.maker);
          if(currentMaker == null){
            serie.data.push('-');
            continue;
          }
          if(this.shareVsLyDisplayType == 'value')
          {
            serie.data.push(currentMaker.sharedifflyValue);
          }
          else if(this.shareVsLyDisplayType == 'volume')
          {
            serie.data.push(currentMaker.sharedifflyUnit);
          }
        }
        
        this.series.push(serie);
        colors.push(IntageDashboardHelper.GetMakerColor(serie.name));
      }
    }
    this.marketShareVsLyHaveData = false;
    for(let i=0;i<this.series.length;i++){
      if(this.series[i].data.length > 0){
        this.marketShareVsLyHaveData = true;
        break;
      }
    }
    this.marketShareVsLyHaveSingleSerie = false;
    if(this.marketShareVsLyHaveData && this.series.length == 1){
      this.marketShareVsLyHaveSingleSerie = true;
      this.marketShareVsLySingleSerie = this.series[0].name;
      this.marketShareVsLySingleSerieColor = colors[0];
    }
    // otcStartWeeks    
    for (let i = 0; i < this.series.length; i++) {
      this.series[i]['data'] = this.series[i]['data'].slice(-1 * this.otcStartWeeks)      
    }
    const options = {
        chart: {
            height: 500,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
              show: true,
              tools: {
                download: false
              }
            }
        },
        dataLabels: {
            enabled: this.marketShareVsLyHaveSingleSerie ? true: false,
            style:{
              colors: [
                function (w) {
                  if (w.series[w.seriesIndex][w.dataPointIndex] > 0) {
                    return w.w.config.colors[w.seriesIndex];
                  } else {
                    return "#FF2626";
                  }
                },
              ],
              fontSize : "14px"
            }
        },
        stroke: {
            //width: [5, 5, 5],
            curve: 'smooth',
            //dashArray: [5, 5, 5]
        },
        colors: getColorsWithMovingAverage({ colors, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        series: getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        legend: {
            show: true,
            onItemClick: {
              toggleDataSeries: true
            }
        },
        markers: {
            size: this.marketShareVsLyHaveSingleSerie ? 0 : 5,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: categories
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        yaxis: {
          forceNiceScale: true,
          //min: -50
        }
      }; 
    // FPT-ChienNX7 [2022/12/14][SENSE][1203444563290052] Rename file name png when export start

    document.querySelector("#intage-vm-market-share-vsLy-chart").innerHTML = '';
    this.marketShareVsLyChart = new ApexCharts(
        document.querySelector("#intage-vm-market-share-vsLy-chart"),
        options
    );
    this.marketShareVsLyChart.render();
  }

  public onShareVsLyMakersCheckedChanged(maker: string){
    let currentMaker = this.marketShareVsLyMakers.find(x=> x.maker == maker);
    currentMaker.checked = !currentMaker.checked;
    this.handleMarketShareVsLy();
  }

  public onShareVsLyDisplayTypeChange(type: string){
    this.shareVsLyDisplayType = type;
    this.handleMarketShareVsLy();
  }

  public onShareVsLyCategoryChange(){
    if(this.shareVsLySelectedCategory.length==0){
      this.shareVsLySelectedCategory = ['Total'];
    }
    this.handleMarketShareVsLy();
  }

  public onShareVsLyRegionChange(){
    if(this.shareVsLySelectedRegion.length==0){
      this.shareVsLySelectedRegion = ['CCBJI Territory'];
    }
    this.handleMarketShareVsLy();
  }
 
  public onSelectedStartWeekChanged($event){
    this.otcStartWeeks = $event;
    this.handleMarketShareVsLy();
  } 

  showOptionDownload() {
    const download = document.getElementById('download-option-share-vs-ly');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  public exportSharevsLY(isImage: boolean) {
    let weeks = this.otcWeeks.slice(-1 * this.otcStartWeeks);
    let formattedWeek = this.selectedWeek.replace(/-/g, "");
    let fileName = ``;
    switch (this.aggregateBy) {
      case 'mtd':
        fileName = this.shareVsLyDisplayType == 'value' ? `Intage_VM_Dashboard_Value_Share_Diff_VsLy_Monthly_${formattedWeek}` : `Intage_VM_Dashboard_Volume_Share_Diff_VsLy_Monthly_${formattedWeek}`; 
      break;
      case 'qtd':
        fileName = this.shareVsLyDisplayType == 'value' ? `Intage_VM_Dashboard_Value_Share_Diff_VsLy_Quarterly_${formattedWeek}` : `Intage_VM_Dashboard_Volume_Share_Diff_VsLy_Monthly_${formattedWeek}`; 
      break;
      case 'ytd':
        fileName = this.shareVsLyDisplayType == 'value' ? `Intage_VM_Dashboard_Value_Share_Diff_VsLy_Yearly_${formattedWeek}` : `Intage_VM_Dashboard_Volume_Share_Diff_VsLy_Monthly_${formattedWeek}`; 
      break;
      default:
        fileName = this.shareVsLyDisplayType == 'value' ? `Intage_VM_Dashboard_Value_Share_Diff_VsLy_Weekly_${formattedWeek}` : `Intage_VM_Dashboard_Volume_Share_Diff_VsLy_Monthly_${formattedWeek}`; 
      break;
    }

    if (isImage) {
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      switch (this.aggregateBy) {
        case 'mtd':
          for (let i = 0; i < this.monthSet.length; i++) {
            for (let k = 0; k < this.series.length; k++)
              {
                data.push({
                  month: this.monthSet[i],
                  maker: this.series[k].name,
                  display: this.shareVsLyDisplayType == 'value' ? "Value Share" : "Volume Share",
                  category: this.shareVsLySelectedCategory,
                  region: this.shareVsLySelectedRegion,             
                  diffLy: IntageHelper.formatValueCsv(this.series[k].data[i])
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Month", "Maker", "Display", "Category", "Region", "Diff.LY"]
          });
          break;
        case 'qtd':
          for (let i = 0; i < this.quarterSet.length; i++) {
            for (let k = 0; k < this.series.length; k++)
              {
                data.push({
                  quarter: this.quarterSet[i],
                  maker: this.series[k].name,
                  display: this.shareVsLyDisplayType == 'value' ? "Value Share" : "Volume Share",
                  category: this.shareVsLySelectedCategory,
                  region: this.shareVsLySelectedRegion,              
                  diffLy: IntageHelper.formatValueCsv(this.series[k].data[i])
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Quarter", "Maker", "Display", "Category", "Region", "Diff.LY"]
          });   
          break;
        case 'ytd':
          for (let i = 0; i < this.yearSet.length; i++) {
            for (let k = 0; k < this.series.length; k++)
              {
                data.push({
                  year: this.yearSet[i],
                  maker: this.series[k].name,
                  display: this.shareVsLyDisplayType == 'value' ? "Value Share" : "Volume Share",
                  category: this.shareVsLySelectedCategory,
                  region: this.shareVsLySelectedRegion,             
                  diffLy: IntageHelper.formatValueCsv(this.series[k].data[i])
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Year", "Maker", "Display", "Category", "Region", "Diff.LY"]
          });
          break;
        default:
          let series = getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes });      
            for (let k = 0; k < series.length; k++)
            {
              for (let i = 0; i < weeks.length; i++) {
                data.push({
                  week: weeks[i],
                  maker: series[k].name,
                  display: this.shareVsLyDisplayType == 'value' ? "Value Share" : "Volume Share",
                  category: this.shareVsLySelectedCategory,
                  region: this.shareVsLySelectedRegion,             
                  diffLy: IntageHelper.formatValueCsv(series[k].data[i])
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Week", "Maker/MAV", "Display", "Category", "Region", "Diff.LY"]
          });
          break;
      }
      
    }
    this.showOptionDownload();
  }

  dropdownValueToNgSelectFormat(value) {
    let values = DropdownValueToNgSelectFormat(value);
    if (values?.length && this?.otcStartWeeks) {
      if (!values.some(x => x.value == null || x.name == null))
      {
        if (values.length < this.otcStartWeeks) {
          setTimeout(() => {
            this.otcStartWeeks = values.length
          }, 500);
        }
      }     
    }    
    return values
  }

  onMovingAverageWindowSizeChange(value){
    this.movingAverageWindowSizes = value;
    this.handleMarketShareVsLy();
  }
  
  public formatCategoryName(categoryName: any)
  {
    return IntageHelper.formatCategoryName(categoryName);
  }
}
