export class MultiDropdownHelper {
  static checkObjectExistence = (data: any[], obj: any): boolean => {
    // Use the find method to search for the object in the array
    const foundObject = data.find(item => item.key === obj.key && item.value === obj.value);

    // If foundObject is not null, it means the object exists in the array
    return !!foundObject;
  }

  static sortArrayByPriority = (arr, order) => {
    const firstElement = arr[0];
    const restArray = arr.slice(1);

    const sortedRestArray = restArray.sort((a, b) => {
      const indexA = order.indexOf(a.value);
      const indexB = order.indexOf(b.value);


      if(!order.indexOf('NULL')){
        if (a.value === 'NULL' && b.value === 'NULL') {
          return 0; // Both are null
        } else if (a.value === 'NULL') {
          return -1; // a is null, b is not
        } else if (b.value === 'NULL') {
          return 1; // a is not null, b is null
        }
      }
      

      if (indexA === -1 && indexB === -1) {
        return 0; // Both elements are not in the priorityOrder
      } else if (indexA === -1) {
        return 1; // a is not in the priorityOrder, b is in the priorityOrder
      } else if (indexB === -1) {
        return -1; // a is in the priorityOrder, b is not in the priorityOrder
      } else {
        return indexA - indexB; // Both elements are in the priorityOrder
      }
    });

    // If the first element is not in the priorityOrder, keep it at the beginning
    if (order.indexOf(firstElement.name) === -1) {
      return [firstElement, ...sortedRestArray.map(item => ({ text: item.text, value: item.value }))];
    } else {
      // Otherwise, include the first element in the sorted array
      const sortedArray = [firstElement, ...sortedRestArray];
      return sortedArray.map(item => ({ text: item.text, value: item.value }));
    }
  }

  static sortArrayByAsc(arr) {
    const firstElement = arr[0];
    const restArray = arr.slice(1);

    const sortedRestArray = restArray.sort((a, b) => {
      return a.value.localeCompare(b.value); // Sort alphabetically in ascending order
    });

    // If the first element's value is "All", keep it at the beginning
    if (firstElement.value === "All") {
      return [firstElement, ...sortedRestArray.map(item => ({ text: item.text, value: item.value }))];
    } else {
      // Otherwise, include the first element in the sorted array
      const sortedArray = [firstElement, ...sortedRestArray];
      return sortedArray.map(item => ({ text: item.text, value: item.value }));
    }
  }

}
