import { environment } from "src/environments/environment";

export function authorize() {
	const href = location.origin + location.pathname;
	const page = href.substring(href.lastIndexOf('/'));
	const userinfo = JSON.parse(localStorage.getItem("userinfo"));
	const user = userinfo[environment.customer][0];
	if (user.senseGroup.pages.some(x => x.url == page)) {
		return true;
	}
	return false;
}