import { Component, Renderer2, OnChanges, Input, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import html2canvas from 'src/html2canvas';
import { downloadAsPDF as _downloadAsPDF } from './../../../../helpers/pdf';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';

@Component({
  selector: 'intage-otc-market-share-by-region',
  templateUrl: './otc-market-share-by-region.component.html',
  styleUrls: ['./otc-market-share-by-region.component.scss']
})
export class IntageOtcMarketShareByRegionComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intageMonth: any;
  @Input() intage: any;
  // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  categories: any;
  categoryColors: any;
  smddChanelOrder: any;
  marketShareByRegionData: any;
  selectedRegionChannel: any;
  selectedMaker: any;
  makerLogo: any;
  makers: any;
  summaryPackageTypes: any;
  filterByRegionSelectedPackageType: any;
  populated: boolean;
  aggregateBy: any;
  chartId = "otc-market-value-share-by-region";

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }    

  ngOnChanges() {
    if(!this.populated){
      this.filterByRegionSelectedPackageType = ['All'];
      this.categoryColors = IntageHelper.CategoryColorsOTCByRegion();
      this.smddChanelOrder = IntageHelper.SmddChannels();
      this.selectedRegionChannel = ['OTC Total'];
      this.makerLogo = IntageHelper.MakerLogos();
      this.selectedMaker = [this.makerLogo[0].maker];
      this.populated = true;
    }
    if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
      let commonData = IntageHelper.PopulateCommonData(this.intage, this.selectedWeek);
      this.categories = commonData.categories;      
      this.makers = commonData.makers;
      this.summaryPackageTypes = commonData.summaryPackageTypes;
      this.handleMarketShareByRegion();
    }
  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["selectedMaker"] = [...this.selectedMaker];
      window["selectedRegionChannel"] = [...this.selectedRegionChannel];
      window["filterByRegionSelectedPackageType"] = [...this.filterByRegionSelectedPackageType];
    } else {
      this.selectedMaker = window["selectedMaker"];
      this.selectedRegionChannel = window["selectedRegionChannel"];
      this.filterByRegionSelectedPackageType = window["filterByRegionSelectedPackageType"];
    }
  }
  isSmddChild(channel: string){
    return AnalyticsHelper.isSmddChild(channel);
  }
  onChangeMakerOTC() {
    if(this.selectedMaker.length == 0){
      this.selectedMaker = ['COCA-COLA'];
      this.SaveOptionValueTemp(false);
      this.onFilterByMakerChange()
    }
  }
  onRemoveFilterShareChannel($event) {
    if(this.selectedRegionChannel.length == 0){
      this.selectedRegionChannel = ['OTC Total'];
      this.SaveOptionValueTemp(false);
      this.onFilterByRegionChannelChange()
    }
    if ($event.value === 'OTC Total') {
      this.selectedRegionChannel = [];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event.value)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event.value);
      this.selectedRegionChannel = this.selectedRegionChannel.filter(function (el) {
        return arrChilds.indexOf(el) < 0;
      });
    }
    if (AnalyticsHelper.isSmddChild($event.value)) {
      var parent = AnalyticsHelper.getSmddParent($event.value);
      this.selectedRegionChannel = this.selectedRegionChannel.filter(e => e !== parent);
    }
  }
  onAddFilterShareChannel($event) {
    if ($event === 'OTC Total') {
      this.selectedRegionChannel = ['OTC Total'];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event);
      this.selectedRegionChannel = this.selectedRegionChannel.filter(e => !arrChilds.includes(e));
    }
    if (AnalyticsHelper.isSmddChild($event)) {
      var parent = AnalyticsHelper.getSmddParent($event);
      var checkChildSelected = true;
      var arrChilds = AnalyticsHelper.getSmddChildIntage(parent);
      arrChilds.forEach(child => {
        if (this.smddChanelOrder.some(x=>x.channel==child) && !this.selectedRegionChannel.includes(child)) {
          checkChildSelected = false;
        }
      });
      if (checkChildSelected) {
        var arrTemp = [parent];
        this.selectedRegionChannel = this.selectedRegionChannel.filter(e => !arrChilds.includes(e));
        this.selectedRegionChannel = arrTemp.concat(this.selectedRegionChannel);
      }else{
        this.selectedRegionChannel = this.selectedRegionChannel.filter(e => e !== parent);
      }
    }
    var checkParentFilter = true;
    var filterNotCheck = this.smddChanelOrder.filter(e => !this.selectedRegionChannel.includes(e.channel) && e.channel!='OTC Total');
    filterNotCheck.forEach(element => {
      if (!AnalyticsHelper.isSmddChild(element.channel) || !this.selectedRegionChannel.includes(AnalyticsHelper.getSmddParent(element.channel))) {
        checkParentFilter=false;
      }
    });
    if(checkParentFilter){
      this.selectedRegionChannel = ['OTC Total'];
    }else{
      this.selectedRegionChannel = this.selectedRegionChannel.filter(e => e !== 'OTC Total');
    }
  }
  onRemoveFilterSharePackageType($event) {
    if(this.filterByRegionSelectedPackageType.length == 0){
      this.filterByRegionSelectedPackageType = ['All'];
      this.SaveOptionValueTemp(false);
      this.onFilterByRegionPackageTypeChange()
    }
    if ($event.value === 'All') {
      this.filterByRegionSelectedPackageType = [];
      return;
    }
  }
  onAddFilterSharePackageType($event) {
    if ($event === 'All') {
      this.filterByRegionSelectedPackageType = ['All'];
      return;
    }
    if(this.filterByRegionSelectedPackageType.filter(x=> x!='All').length == (this.summaryPackageTypes.length-1)){
      this.filterByRegionSelectedPackageType = ['All'];
    }else{
      this.filterByRegionSelectedPackageType = this.filterByRegionSelectedPackageType.filter(e => e !== 'All');;
    }
  } 

  onOTCMarketsizeAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketShareByRegion();
  }

  handleMarketShareByRegion(){
    switch (this.aggregateBy) {
      case 'mtd':
        var month = this.intageMonth.find(x => x.week == this.selectedWeek).month;
        var weeks = [...new Set(this.intageMonth.filter(x => x.month == month && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();
        break;
      case 'qtd':
        var quarter = this.intageMonth.find(x => x.week == this.selectedWeek).quarter;
        var weeks = [...new Set(this.intageMonth.filter(x => x.quarter == quarter && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData();        
        break;
      case 'ytd':
        var year = this.intageMonth.find(x => x.week == this.selectedWeek).year;
        var weeks = [...new Set(this.intageMonth.filter(x => x.year == year && x.week <= this.selectedWeek).map(x=> x.week))];
        window["weeks"] = weeks;
        this.firstcheckIntageAllData(); 
        break;
      default:
        weeks = [this.selectedWeek];
        this.handleIntageData(weeks);
        break;
    }
  }

  public async firstcheckIntageAllData()
  {
    var intage = await IndexDbHelper.Get('IntageFlashAllData'); 
    if (intage != null)
    {
      this.intage = intage;
      this.handleIntageData(window["weeks"]);
    }
    else
    {
      this.checkIntageFlashAllData();
    }
  }

  public async checkIntageFlashAllData()
  {
    setTimeout(async () => {
      var intage = await IndexDbHelper.Get('IntageFlashAllData');  
      if (intage != null)
      {
        this.intage = intage;
        this.handleIntageData(window["weeks"]);
      }
      else
      {
        this.checkIntageFlashAllData();
      }
    }, 200);
  }

  public handleIntageData(weeks: any)
  {
    var data = this.intage.filter(x=> weeks.includes(x.week));
    let smddChannel = null;
    if(!this.selectedRegionChannel.includes('OTC Total')){
      smddChannel = this.smddChanelOrder.find(x=> this.selectedRegionChannel.includes(x.channel));
      if(smddChannel != null){
        if(smddChannel.childChannels == null){
          data = data.filter(x=> this.selectedRegionChannel.includes(x.channel));
        }else{
          data = data.filter(x=> smddChannel.childChannels.includes(x.channel));
        }
      }
    }
    this.marketShareByRegionData = [];
    let totals = [{
      channel: 'OTC Total',
      category: 'Total',
      region: 'CCBJI Territory',
      ty: 0,
      ly: 0
    }];
    let index = 0;
    this.marketShareByRegionData.push({
      region: 'CCBJI Territory',
      categories: [
        {
          category: 'Total',
          ty: 0,
          ly: 0,
          totalTy: 0,
          totalLy: 0,
          share: '',
          vsLy: ''
        }
      ],
      index: index
    });
    index++;
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let total = totals.find(x=> x.channel == row.channel && x.category == row.category && x.region == row.region);
      if(total == null){
        total = {
          channel: row.channel,
          category: row.category,
          region: row.region,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(total);
      }else{
        total.ty +=parseFloat(row.ty);
        total.ly +=parseFloat(row.ly);
      }
      let categoryTotal = totals.find(x=> x.channel == row.channel && x.category == 'Total' && x.region == row.region);
      if(categoryTotal == null){
        categoryTotal = {
          channel: row.channel,
          category: 'Total',
          region: row.region,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(categoryTotal);
      }else{
        categoryTotal.ty +=parseFloat(row.ty);
        categoryTotal.ly +=parseFloat(row.ly);
      }
      let channelTotal = totals.find(x=> x.channel == 'OTC Total' && x.category == row.category && x.region == row.region);
      if(channelTotal == null){
        channelTotal = {
          channel: 'OTC Total',
          category: row.category,
          region: row.region,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(channelTotal);
      }else{
        channelTotal.ty +=parseFloat(row.ty);
        channelTotal.ly +=parseFloat(row.ly);
      }
      let regionTotal = totals.find(x=> x.channel == row.channel && x.category == row.category && x.region == 'CCBJI Territory');
      if(regionTotal == null){
        regionTotal = {
          channel: row.channel,
          category: row.category,
          region: 'CCBJI Territory',
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(regionTotal);
      }else{
        regionTotal.ty +=parseFloat(row.ty);
        regionTotal.ly +=parseFloat(row.ly);
      }
      let categoryAndChannelTotal = totals.find(x=> x.channel == 'OTC Total' && x.category == 'Total' && x.region == row.region);
      if(categoryAndChannelTotal == null){
        categoryAndChannelTotal = {
          channel: 'OTC Total',
          category: 'Total',
          region: row.region,
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(categoryAndChannelTotal);
      }else{
        categoryAndChannelTotal.ty +=parseFloat(row.ty);
        categoryAndChannelTotal.ly +=parseFloat(row.ly);
      }
      let categoryAndRegionTotal = totals.find(x=> x.channel == row.channel && x.category == 'Total' && x.region == 'CCBJI Territory');
      if(categoryAndRegionTotal == null){
        categoryAndRegionTotal = {
          channel: row.channel,
          category: 'Total',
          region: 'CCBJI Territory',
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(categoryAndRegionTotal);
      }else{
        categoryAndRegionTotal.ty +=parseFloat(row.ty);
        categoryAndRegionTotal.ly +=parseFloat(row.ly);
      }
      let channelAndRegionTotal = totals.find(x=> x.channel == 'OTC Total' && x.category == row.category && x.region == 'CCBJI Territory');
      if(channelAndRegionTotal == null){
        channelAndRegionTotal = {
          channel: 'OTC Total',
          category: row.category,
          region: 'CCBJI Territory',
          ty: parseFloat(row.ty),
          ly: parseFloat(row.ly)
        }
        totals.push(channelAndRegionTotal);
      }else{
        channelAndRegionTotal.ty +=parseFloat(row.ty);
        channelAndRegionTotal.ly +=parseFloat(row.ly);
      }
      totals[0].ty +=parseFloat(row.ty);
      totals[0].ly +=parseFloat(row.ly);

      if(!this.filterByRegionSelectedPackageType.includes('All') && !this.filterByRegionSelectedPackageType.includes(row.packageType)){
        continue;
      }

      if(this.selectedMaker.includes(row.maker)){
        let region = this.marketShareByRegionData.find(x=> x.region == row.region);
        if(region == null){
          region = {
            region: row.region,
            categories: [
              {
                category: 'Total',
                ty: 0,
                ly: 0,
                totalTy: 0,
                totalLy: 0,
                share: '',
                vsLy: ''
              }
            ],
            index: index
          };
          this.marketShareByRegionData.push(region);
          index++;
        }

        let category = region.categories.find(x=> x.category == row.category);
        if(category == null){
          category = {
            category: row.category,
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            totalTy: 0,
            totalLy: 0,
            share: '',
            vsLy: row.vsLy
          };
          region.categories.push(category);
        }
        else{
          category.ty += parseFloat(row.ty);
          category.ly += parseFloat(row.ly);
        }
        let totalCategory = this.marketShareByRegionData[0].categories.find(x=> x.category == row.category);
        if(totalCategory == null){
          totalCategory = {
            category: row.category,
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            totalTy: 0,
            totalLy: 0,
            share: '',
            vsLy: ''
          };
          this.marketShareByRegionData[0].categories.push(totalCategory);
        }
        else{
          totalCategory.ty += parseFloat(row.ty);
          totalCategory.ly += parseFloat(row.ly);
        }
        region.categories[0].ty+= parseFloat(row.ty);
        region.categories[0].ly+= parseFloat(row.ly);
        this.marketShareByRegionData[0].categories[0].ty+= parseFloat(row.ty);
        this.marketShareByRegionData[0].categories[0].ly+= parseFloat(row.ly);
      }
    }

    for(var i=0;i<this.marketShareByRegionData.length;i++){
      let region = this.marketShareByRegionData[i];
      for(var j=0;j<region.categories.length;j++){
        let category = region.categories[j];
        let total = null;
        if((smddChannel != null && smddChannel.childChannels == null) || smddChannel == null){
          total = totals.find(x=> x.category == category.category && this.selectedRegionChannel.includes(x.channel) && x.region == region.region);
        }
        else{
          let totalList = totals.filter(x=> x.category == category.category && smddChannel.childChannels.includes(x.channel) && x.region == region.region);
          total = {
            ty:0,
            ly:0
          };
          for(var k=0;k<totalList.length;k++){
            total.ty += totalList[k].ty;
            total.ly += totalList[k].ly;
          }
        }

        category.totalTy = total.ty;
        category.totalLy = total.ly;

        category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);
        let share_noround =  category.totalTy == 0 ? null : ((category.ty / category.totalTy) * 100) + '';
        let shareLy_noround = category.totalLy == 0 ? null : ((category.ly / category.totalLy) * 100) + '';
        category.vsLy = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);
      }
    }

    this.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    this.marketShareByRegionData.sort((a, b) => a.index > b.index ? 1 : -1);
    let t= this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }
  public getCategoryColor(category: string){
    return IntageHelper.GetCategoryColor(category);
  }

  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }

  public onFilterByRegionChannelChange(){
    if(this.selectedRegionChannel.length == 0){
      this.selectedRegionChannel = ['OTC Total'];
    }
    this.handleMarketShareByRegion();
  }

  public onFilterByRegionPackageTypeChange(){
    if(this.filterByRegionSelectedPackageType.length == 0){
      this.filterByRegionSelectedPackageType = ['All'];
    }
    this.handleMarketShareByRegion();
  }

  public onFilterByMakerChange(){
    if(this.selectedMaker.length==0){
      this.selectedMaker = ['COCA-COLA'];
    }
    this.handleMarketShareByRegion();
  }

  public getShareOfCategory(categories: any, category: string){
    return IntageHelper.GetShareOfCategory(categories, category);
  }

  public getVsLyOfCategory(categories: any, category: string){
    return IntageHelper.GetVsLyOfCategory(categories, category);
  }

  // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
  public exportOTCMarketValueShareByRegion(isImage: boolean) {
    const fileName = 'OTC Market Value Share by Region';
    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      for (var i = 0; i < this.marketShareByRegionData.length; i++) {
        let region = this.marketShareByRegionData[i];
        for (var j = 0; j < region.categories.length; j++) {
          let category = region.categories[j];
          data.push({
            week: this.selectedWeek,
            aggregateBy : this.aggregateBy == null ? "wtd" : this.aggregateBy,
            maker: this.selectedMaker,
            channel: this.selectedRegionChannel,
            packagetype: this.filterByRegionSelectedPackageType,
            region: region.region,
            category: category.category,
            ty: category.ty,
            ly: category.ly,
            share: category.share,
            totalTy: category.totalTy,
            totalLy: category.totalLy,
            vsLy: category.vsLy
          });
        }
      }

      new AngularCsv(data, fileName, {
        showLabels: true,
        headers: ["Week", "Aggregate By", "Maker", "Channel", "Package Type", "Region", "Category", "TY_Value", "LY_Value", "Share_Value", "Total_TY_Value", "Total_LY_Value", "Diff_LY_Value"],
      });
    }
    // FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG
    this.showOptionDownload();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-market-value-region');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  @ViewChild('pdf_print_area_3', { static: false }) pdf_print_area_3: ElementRef;
  public downloadAsPDF(name) {
    return _downloadAsPDF(name, this.pdf_print_area_3.nativeElement)
  }

}
