import { Component, Renderer2, OnInit, Inject, ViewChild, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import Swal from 'sweetalert2';
@Component({
  selector: 'nsr-daily',
  templateUrl: './nsr-daily.component.html',
  styleUrls: ['./nsr-daily.component.scss']
})
export class NsrDailyComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedDate: any;
  @Input() aggregateBy: string;
  @Input() dailyData: any;
  @Input() monthlyData: any;
  qtd: any;
  nsrByChannelChart: ApexCharts;
  constructor(public datepipe: DatePipe) { }

  ngOnInit() {
    this.qtd = {'01': ['01'], '02': ['01', '02'], '03': ['01', '02', '03'], '04': ['04'], '05': ['04', '05'], '06': ['04', '05', '06'],
    '07': ['07'], '08': ['07', '08'], '09': ['07', '08', '09'], '10': ['10'], '11': ['10', '11'], '12': ['10', '11', '12']};
  }
  
  ngOnChanges() {
    if (this.dailyData != null && this.selectedDate != null)
    {
      if (this.aggregateBy != null && this.aggregateBy == 'daily')
      {
        let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
        this.bindNsrChannelsChart(selectedDate);
      }
    }

    if (this.monthlyData != null && this.selectedDate != null)
    {
      if (this.aggregateBy != null && this.aggregateBy != 'daily')
      {
        let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
        this.bindNsrChannelsChart(selectedDate);
      }
    }
    // if(((this.aggregateBy == 'daily' && this.dailyData != null) || this.monthlyData != null) && this.selectedDate != null){
    //   let selectedDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    //   this.bindNsrChannelsChart(selectedDate);
    // }
  }

  public bindNsrChannelsChart(selectedDate: Date) {
    if (this.nsrByChannelChart != null) {
      this.nsrByChannelChart.destroy();
    }

    let t = this;         
    let labes = [];
    let rawSeries = [];
    let percentSeries = [];
    let allColors = ['#F40009', '#58C9D0', '#5FB0FF', '#ADBBFF',
      '#EF86DB', '#BAFECC', '#FF956A', '#FDDD80', '#FFA500',
      '#A52A2A', '#800000', '#808080', '#C0C0C0', '#008000', '#808000',
      '#7FFD4', '#000000', '#FFFFFF'];
    let colors = [];
    let totalNsr = 0;
    let CHANNELS = [
      {
        name: "Vending",
        useRegion: false
      },
      {
        name: "SMDD",
        useRegion: false
      },
      {
        name: "CVS",
        useRegion: false
      },
      {
        name: "Retail",
        useRegion: false
      },
      {
        name: "On-Line",
        useRegion: false
      },
      {
        name: "Food",
        useRegion: false
      },
      {
        name: "Others",
        useRegion: false,
        region: '7200'
      },
      {
        name: "Alcohol",
        //useRegion: true,
        useRegion: true,
        region: '7200'  
      }
    ];
    let month = this.datepipe.transform(selectedDate, "yyyyMM");
    let year = this.datepipe.transform(selectedDate, "yyyy");
    for (var i = 0; i < CHANNELS.length; i++) {
      let channel = CHANNELS[i];
      labes.push(channel.name);
      let act=0;
      let currentChannel = AnalyticsHelper.ChannelLv2().find(x=> x.channel == channel.name);
      if(currentChannel == null && !channel.useRegion){
        continue;
      }
      if(this.aggregateBy == 'daily'){
        for(var j=0;j<this.dailyData.length;j++){
          let row = this.dailyData[j];
          if(channel.useRegion && channel.region != row.regionCode){
            continue;
          }
          if(!channel.useRegion){
            if(currentChannel.code.find(x=> row.channelCode.indexOf(x) == 0) == null || (channel.region != null && channel.region == row.regionCode)){
              continue;
            }
          }
          act += parseFloat(row.nsr_ty);
        }
      }else {
        for(var j=0;j<this.monthlyData.length;j++){
          let row = this.monthlyData[j];
          if(row.month.indexOf(year) < 0){
            continue;
          }
          if(this.aggregateBy == 'mtd' && row.month != month){
            continue;
          }
          if (this.aggregateBy == 'qtd')
          {
            let quarters = this.qtd[month.slice(4)];
            if (!quarters.includes(row.month.slice(4)))
              {
                continue;
              }
          }
          if(channel.useRegion && channel.region != row.regionCode){
            continue;
          }
          if(!channel.useRegion){
            if(currentChannel.code.find(x=> row.channelCode.indexOf(x) == 0) == null || (channel.region != null && channel.region == row.regionCode)){
              continue;
            }
          }
          act += parseFloat(row.nsr_ty);
        }
      }
      act = Math.round(act/1000000);
      rawSeries.push(act);
      totalNsr += act;
      colors.push(allColors[i]);
    }
    if (totalNsr > 0) {
      let usedPercent = 0;
      for (var i = 0; i < rawSeries.length; i++) {
        if (i == rawSeries.length - 1) {
          percentSeries.push(rawSeries[i]);
          // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
          labes[i] += " " + AnalyticsHelper.formatPercent((100 - usedPercent)) + "%";
          continue;
        }

        // FPT-HoiLT2: [2022/10/25][SENSE][1203212320245909] SENSE daily report issues
        var percent = parseFloat((rawSeries[i] * 100 / totalNsr).toFixed(1));
        labes[i] += " " + AnalyticsHelper.formatPercent(percent) + "%";
        usedPercent += percent;

        percentSeries.push(rawSeries[i])
      }

      let toBeSort = [];
      for (var i = 0; i < percentSeries.length; i++) {
        toBeSort.push({
          label: labes[i],
          percent: percentSeries[i],
          color: colors[i]
        });
      }

      toBeSort = toBeSort.sort((a, b) => a.percent > b.percent ? -1 : 1);
      const options = {
        chart: {
          type: 'donut',
          height: 600,
          toolbar: {
            show: true,
            tools: {
              download: ''
            }
          }
        },
        labels: toBeSort.map(x => x.label),
        series: toBeSort.map(x => x.percent),
        colors: toBeSort.map(x => x.color),
        legend: {
          position: 'bottom'
        },
        dataLabels: {
          enabled: false
        },
        yaxis: {
          labels: {
            formatter: function (val: number) {
              return AnalyticsHelper.formatNumber(val) + " (MM JPY)";
            }
          }
        },
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     legend: {
        //       position: 'bottom'
        //     }
        //   }
        // }]
      }

      document.querySelector("#nsr-chart").innerHTML = '';
      t.nsrByChannelChart = new ApexCharts(
        document.querySelector("#nsr-chart"),
        options
      );
      t.nsrByChannelChart.render()
      .then(() => setTimeout(() => {
        t.ready.emit(true);
      }));
    }else{
      //console.log('daily data pipeline issue' + new Date(), this.dailyData);
      AnalyticsHelper.openSweetAlert(`<p class="line1">Important Notice</p><p class="line2">Upstream data pipeline have issues and currently under investigation.</p><p class="line3">Apologies for the inconvenience, and thank you for your patience.</p>`, false);
      setTimeout(() => {
        t.ready.emit(true);
      });
    }
  }

  downloadPng() {
    this.nsrByChannelChart["exports"]?.exportToPng()
  }

  downloadCSV() {
    this.nsrByChannelChart["exports"]?.exportToCSV({
      series: this.nsrByChannelChart['w']['config']['series'],
      columnDelimiter: this.nsrByChannelChart['w'].config.chart.toolbar.export.csv.columnDelimiter
    });
  }


}
