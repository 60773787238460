import { Component, OnInit } from '@angular/core';
import { tokens } from 'src/app/model/constants';

@Component({
  selector: 'app-maintain-sku-customer',
  templateUrl: './maintain.component.html',
  styleUrls: ['./maintain.component.scss']
})
export class MaintainSkuCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
