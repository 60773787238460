<!-- Start Reset Password Area -->
<div class="reset-password-area bg-image">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="reset-password-content">
                <div class="contact-close" (click)="closeContact()">✕</div>
                <div class="row m-0">
                    
                    <div class="col-lg-12 p-0">
                        <div class="reset-password-form">
                            <h2>Contact Us</h2>
                            
                            <form (ngSubmit)="contactSubmit()" [formGroup]="contactForm">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Name" formControlName="name" required>
                                    <span class="label-title"><i class='bx bx-user'></i></span>
                                    <span class="text-danger"
                                        *ngIf="contactForm.get('name').errors && (contactForm.get('name').touched || contactForm.get('name').dirty)">
                                        <span *ngIf="contactForm.get('name').errors.required">
                                            Name is required
                                        </span>
                                    </span>
                                </div>

                                <div class="form-group">
                                    <input type="text" class="form-control" name="email" autocomplete="off" placeholder="Email"
                                        formControlName="email">
                                    <span class="label-title"><i class='bx bx-envelope'></i></span>
                                    <span class="text-danger"
                                        *ngIf="contactForm.get('email').errors && (contactForm.get('email').touched || contactForm.get('email').dirty)">
                                        <span *ngIf="contactForm.get('email').errors.required">
                                            Email is required
                                        </span>
                                        <span *ngIf="contactForm.get('email').errors.email">
                                            Email is invalid
                                        </span>
                                    </span>
            
                                    <div class="text-danger" *ngIf="contactForm.get('email').errors?.invalidMsg" [innerHtml]="contactForm.get('email').errors?.invalidMsg">
                                    </div>
                                </div>

                                <div class="form-group">
                                    <textarea class="form-control" rows="6" required name="message" placeholder="Tell us how can we help" formControlName="message"></textarea>
                                    <span class="label-title message-icon"><i class='bx bx-message-alt-dots'></i></span>
                                    <span class="text-danger"
                                        *ngIf="contactForm.get('message').errors && (contactForm.get('message').touched || contactForm.get('message').dirty)">
                                        <span *ngIf="contactForm.get('message').errors.required">
                                            Message is required
                                        </span>
                                    </span>
                                </div>

                                <button type="submit" class="reset-password-btn">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Reset Password Area -->