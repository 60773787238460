import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ctg-pkg-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class CtgPkgTableComponent implements OnInit {

  @Input() data: any;
  @Input() type: "NSR" | "BAPC";
  @Input() by: "category" | "package";

  // Category rows
  corebiz: any;
  ssd: any;
  juice: any;
  sports: any;
  coffee: any;
  tea: any;
  energy: any;
  water: any;

  // Package rows
  petss: any;
  petms: any;
  can: any;
  rtb: any;
  bottlecan: any;
  others: any;
  syrup: any;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges() {

    this.data.forEach(element => {

      const name = this.by === "category" ? "categoryName" : "packageName"
      const row = this.trimName(element?.[name]);
      const data = this.data.find(x => this.trimName(x[name]) === row);

      const vm = data.channels.find(x => x.channelName === "VM")
      const smdd = data.channels.find(x => x.channelName === "SMDD")
      const cvs = data.channels.find(x => x.channelName === "CVS")
      const retailFood = data.channels.find(x => x.channelName === "Retail/Food")
      const onLine = data.channels.find(x => x.channelName === "On-Line")
      const total = data.channels.find(x => x.channelName === "Total")

      if (this.type === "NSR") {
        this[row] = [
          `${this.tdContent(data?.impNSR)}${this.tdContent(data?.diffVsLyNSR)}`,
          `${this.tdContent(vm?.diffVsLyNSR)}${this.tdContent(vm?.percentVsLyNSR)}`,
          `${this.tdContent(smdd?.diffVsLyNSR)}${this.tdContent(smdd?.percentVsLyNSR)}`,
          `${this.tdContent(cvs?.diffVsLyNSR)}${this.tdContent(cvs?.percentVsLyNSR)}`,
          `${this.tdContent(retailFood?.diffVsLyNSR)}${this.tdContent(retailFood?.percentVsLyNSR)}`,
          `${this.tdContent(onLine?.diffVsLyNSR)}${this.tdContent(onLine?.percentVsLyNSR)}`,
          `${this.tdContent(total?.diffVsLyNSR)}${this.tdContent(total?.percentVsLyNSR)}`,
        ];
      } else if (this.type === "BAPC") {
        this[row] = [
          `${this.tdContent(data?.impBAPC)}${this.tdContent(data?.diffVsLyBAPC)}`,
          `${this.tdContent(vm?.diffVsLyBAPC)}${this.tdContent(vm?.percentVsLyBAPC)}`,
          `${this.tdContent(smdd?.diffVsLyBAPC)}${this.tdContent(smdd?.percentVsLyBAPC)}`,
          `${this.tdContent(cvs?.diffVsLyBAPC)}${this.tdContent(cvs?.percentVsLyBAPC)}`,
          `${this.tdContent(retailFood?.diffVsLyBAPC)}${this.tdContent(retailFood?.percentVsLyBAPC)}`,
          `${this.tdContent(onLine?.diffVsLyBAPC)}${this.tdContent(onLine?.percentVsLyBAPC)}`,
          `${this.tdContent(total?.diffVsLyBAPC)}${this.tdContent(total?.percentVsLyBAPC)}`,
        ];
      }

    });

  }

  trimName(str) {
    return str.replaceAll(' ', '').replaceAll('-', '').toLowerCase()
  }

  tdContent(str) {
    if (!str) return '';

    if (str.startsWith('-')) {
      return `<div class="text-red-500">${str}</div>`
    } else {
      return `<div>${str}</div>`
    }
  }

}
