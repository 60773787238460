<ng-container *ngIf="!ready">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="ready">
    <div class="filter-section no-title mega-wrapper">

        <div class="ng-section summary-section date-picker">
            <b>Date</b>
            <input [(ngModel)]="selectedDate" (dateSelect)="onDateSelect($event)" type="text" ngbDatepicker
                [minDate]="{year: 2024, month: 1, day: 1}"
                [maxDate]="{year: startDate?.year, month: startDate?.month, day: startDate?.day }" #d="ngbDatepicker"
                class="form-control" readonly placeholder="Choose a date" (click)="d.toggle()" />
        </div>
        
    </div>
    <p></p>
    <div class="analytics-data-wrapper">
        <div class="frame">
            <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <a ngbNavLink>Summary</a>
                    <ng-template ngbNavContent>
                        <div class="col-lg-12 col-md-12">
                            <div class="card mb-30">
                                <div class="summaries">
                                    <!-- Start NSR --->
                                    <div class="summary nsr">
                                        <div class="left">
                                            <h1>NSR MM JPY</h1>
                                        </div>
                                        <div class="right">

                                            <div class="row category">
                                                <div class="wrapper wtd">
                                                    <div class="title">WTD</div>
                                                    <div class="desc">Upper：Diff. LY(MM JPY), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="NSR" by="category"
                                                        [data]="data_categories_wtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper mtd">
                                                    <div class="title">MTD</div>
                                                    <div class="desc">Upper：Diff. LY(MM JPY), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="NSR" by="category"
                                                        [data]="data_categories_mtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper qtd">
                                                    <div class="title">QTD</div>
                                                    <div class="desc">Upper：Diff. LY(MM JPY), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="NSR" by="category"
                                                        [data]="data_categories_qtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper ytd">
                                                    <div class="title">YTD</div>
                                                    <div class="desc">Upper：Diff. LY(MM JPY), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="NSR" by="category"
                                                        [data]="data_categories_ytd"></ctg-pkg-table>
                                                </div>
                                            </div>
                                            <div class="row package">
                                                <div class="wrapper wtd">
                                                    <div class="title">WTD</div>
                                                    <div class="desc">Upper：Diff. LY(MM JPY), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="NSR" by="package"
                                                        [data]="data_packages_wtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper mtd">
                                                    <div class="title">MTD</div>
                                                    <div class="desc">Upper：Diff. LY(MM JPY), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="NSR" by="package"
                                                        [data]="data_packages_mtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper qtd">
                                                    <div class="title">QTD</div>
                                                    <div class="desc">Upper：Diff. LY(MM JPY), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="NSR" by="package"
                                                        [data]="data_packages_qtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper ytd">
                                                    <div class="title">YTD</div>
                                                    <div class="desc">Upper：Diff. LY(MM JPY), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="NSR" by="package"
                                                        [data]="data_packages_ytd"></ctg-pkg-table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- End NSR --->

                                    <!-- Start BAPC --->
                                    <div class="summary bapc">
                                        <div class="left">
                                            <h1>BAPC K case</h1>
                                        </div>
                                        <div class="right">

                                            <div class="row category">
                                                <div class="wrapper wtd">
                                                    <div class="title">WTD</div>
                                                    <div class="desc">Upper：Diff. LY(K case), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="BAPC" by="category"
                                                        [data]="data_categories_wtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper mtd">
                                                    <div class="title">MTD</div>
                                                    <div class="desc">Upper：Diff. LY(K case), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="BAPC" by="category"
                                                        [data]="data_categories_mtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper qtd">
                                                    <div class="title">QTD</div>
                                                    <div class="desc">Upper：Diff. LY(K case), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="BAPC" by="category"
                                                        [data]="data_categories_qtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper ytd">
                                                    <div class="title">YTD</div>
                                                    <div class="desc">Upper：Diff. LY(K case), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="BAPC" by="category"
                                                        [data]="data_categories_ytd"></ctg-pkg-table>
                                                </div>
                                            </div>
                                            <div class="row package">
                                                <div class="wrapper wtd">
                                                    <div class="title">WTD</div>
                                                    <div class="desc">Upper：Diff. LY(K case), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="BAPC" by="package"
                                                        [data]="data_packages_wtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper mtd">
                                                    <div class="title">MTD</div>
                                                    <div class="desc">Upper：Diff. LY(K case), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="BAPC" by="package"
                                                        [data]="data_packages_mtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper qtd">
                                                    <div class="title">QTD</div>
                                                    <div class="desc">Upper：Diff. LY(K case), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="BAPC" by="package"
                                                        [data]="data_packages_qtd"></ctg-pkg-table>
                                                </div>
                                                <div class="wrapper ytd">
                                                    <div class="title">YTD</div>
                                                    <div class="desc">Upper：Diff. LY(K case), Lower：% vs. LY</div>
                                                    <ctg-pkg-table type="BAPC" by="package"
                                                        [data]="data_packages_ytd"></ctg-pkg-table>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <!-- End BAPC --->
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <button (click)="getCategoryPackageReport()"
                    [disabled]="!isshowDownloadButton"
                    [class]="isshowDownloadButton ? '' : 'notdisplay'"
                    class="hover:underline transition duration-150 header-menu-title excel-download-title download-button-tracking" data-section="Summary">Download
                    Excel</button>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
        </div>
    </div>

</ng-container>