<div id="pdf_print_area_3" #pdf_print_area_3 class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>OTC Market Value Share by Region</h3>
            </div>
            <div class="filter-section ssmm-filter otc-region-section even-wrapper">

                <!-- <div class="ng-section summary-section native">
                    <b>Aggregate By</b>
                    <select class="form-control filter-by-category form-basic" (change)="onOTCMarketsizeAggregateChange($event.target.value)">
                        <option value="wtd">Weekly</option>
                        <option value="mtd">Monthly</option>
                        <option value="qtd">Quarterly</option>
                        <option value="ytd">Yearly</option>
                    </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Week to Date'}, {value: 'mtd', name: 'Month to Date'}, {value: 'qtd', name: 'Quarter to Date'}, {value: 'ytd', name: 'Year to Date'}]"
                    [model]="aggregateBy"
                    (change)="onOTCMarketsizeAggregateChange($event)">
                </single-dropdown>

                <div class="ng-section summary-section">
                    <b>Maker</b>
                    <ng-select #ngFilterMakersOTC class="filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="selectedMaker[0]" [multiple]="false" [closeOnSelect]="false"
                    (change)="onChangeMakerOTC();onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    [clearable]="selectedMaker.length > 1">
                        <ng-option *ngFor="let item of makers" [value]="item.maker"><input type="checkbox" class="cb-multi-select"/> {{item.maker}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterMakersOTC.close();onFilterByMakerChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    {{item}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Channel</b>
                    <ng-select #ngFilterChannelOTC class="filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="selectedRegionChannel[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterShareChannel($event)"
                    (change)="onRemoveFilterShareChannel($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    [clearable]="selectedRegionChannel.length > 1"
                    (add)="onAddFilterShareChannel($event)">
                        <ng-option *ngFor="let item of smddChanelOrder" [value]="item.channel">
                            <input type="checkbox" class="cb-multi-select"/> {{isSmddChild(item.channel) ? "&nbsp;&nbsp;&nbsp;" : ""}}{{item.channel}}</ng-option>
                            <ng-template ng-footer-tmp>
                                <button type="submit" class="btn btn-primary"
                                (click)="SaveOptionValueTemp(false);ngFilterChannelOTC.close();onFilterByRegionChannelChange()">Aggregate</button>
                            </ng-template>                                
                            <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    {{item}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Package Type</b>
                    <ng-select #ngFilterPackageTypeOTC class="filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="filterByRegionSelectedPackageType[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterSharePackageType($event)"
                    (change)="onRemoveFilterSharePackageType($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    [clearable]="filterByRegionSelectedPackageType.length > 1"
                    (add)="onAddFilterSharePackageType($event)">
                        <ng-option *ngFor="let item of summaryPackageTypes" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterPackageTypeOTC.close();onFilterByRegionPackageTypeChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    {{item}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="card-body">
                <div #scroll class="market-size region-market-size">
                    <table #screen class="intage-market-size">
                        <tr>
                            <th colspan="2" class="summary-row-col">Region</th>
                            <th *ngFor="let item of categories; index as i;" [style]="'background-color:' + getCategoryColor(item.category)">{{getCategoryDisplayName(item.category)}}</th>
                        </tr>
                        <ng-container *ngFor="let region of marketShareByRegionData; index as i;">
                            <tr>
                                <td rowspan="2" class="first-left-col region-name" [class]="i==0 ? 'bold': ''">{{region.region}}</td>
                                <td class="bg-row third-left-col third-left-region-col">Share</td>
                                <ng-container *ngFor="let item of categories; index as j;">
                                    <td class="bg-row"><b>{{getShareOfCategory(region.categories, item.category)}}</b></td>
                                </ng-container>
                            </tr>
                            <tr class="last-row">
                                <td class="third-left-col third-left-region-col">Diff.LY</td>
                                <ng-container *ngFor="let item of categories; index as j;">
                                    <td [class]="getVsLyOfCategory(region.categories, item.category).indexOf('-') != -1 ? 'text-red': ''">{{getVsLyOfCategory(region.categories, item.category).indexOf('-') != -1 ? '': '+'}}{{getVsLyOfCategory(region.categories, item.category)}}</td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </table>
                </div>
                <div class="d-flex flex-two-ends mt-4 hide-pdf-print">
                    <comment-button title1="OTC Market Value Share by Region​​" [screen]="screen"
                    [chartId]="chartId" [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd', selectedMaker, selectedRegionChannel, filterByRegionSelectedPackageType}}]"></comment-button>

                    <download-button>
                        <button ngbDropdownItem (click)="exportOTCMarketValueShareByRegion(true)">Download PNG</button>
                        <button ngbDropdownItem (click)="exportOTCMarketValueShareByRegion(false)">Download CSV</button>
                        <button ngbDropdownItem (click)="downloadAsPDF('OTC Market Value Share by Region')">Download PDF</button>
                    </download-button>
                </div>                
            </div>
            <!--FPT-MyNT15 [2022/11/30][SENSE][1203444563290052] Intage OTC report add download PNG-->
            <div id="download">
                <img #canvas>
                <a #downloadLink></a>
            </div>
        </div>
    </div>
</div>