import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import lodashClonedeep from 'lodash.clonedeep';
import { HttpClient } from '@angular/common/http';
import { SkuDeepdiveHelper } from 'src/app/components/pages/sku-deepdive/sku-deepdive-helper';
import { AreaDeepdiveHelper } from 'src/app/components/pages/area-deepdive/area-deepdive-helper';
import { DistrictRouterProgressHelper } from "src/app/components/pages/district-route-progress/district_route_progress_helper";
import { FILTER_CONFIGS } from 'src/app/model/constants';
import { FilterHelper } from './filter-helper';
import { isObjEmpty, objectToQueryString, getPageName } from 'src/app/helpers/utils';
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-more-filter',
  templateUrl: './filters.html',
  styleUrls: ['./filters.scss']
})

export class AddMoreFilter implements OnInit, OnChanges {
  @Input() isAlwaysShowApplyBtn?: boolean = false;
  @Input() filterModuleName : string = '';
  @Input() filters: any;
  @Input() lstOption_Selected: any;
  @Input() lstOption_States: any;
  @Input() listData_Filter: any;
  @Input() lstOption_IsUsed: any;
  @Input() defaultVisibleFilters: number;
  @Output() onUpdateChart = new EventEmitter<{ lstOption_States: any, lstOption_Selected: any }>();
  @Output() onResetFiltersGroup = new EventEmitter<{ data: any }>();

  @Input() lstAllOptionsCalendarData?: any;
  lstAllOptionsFilteredCalendarData: any;

  @Input() lstAllOptionsAreaData?: any;
  lstAllOptionsFilteredAreaData: any;

  @Input() lstAllOptionsAccountData?: any;
  lstAllOptionsFilteredAccountData: any;

  @Input() lstAllOptionsProductData?: any;
  lstAllOptionsFilteredProductData: any;

  @Input() lstAllOptionsData?: any;
  lstAllOptionsFilteredData: any;

  pageName: any = '';
  lstOptions: any;
  lstOptionsVisibleDefault: any;
  isShowAddMoreFilter: boolean;
  isAddMoreFilter: boolean;
  isResetFilter: boolean;
  isShowApplyBtn: boolean;

  private searchSubject = new Subject<string>();
  searchObj: any = {
    loading: false,
    keyName: '',
    data: [],
  }

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit() {
    this.pageName                 = getPageName(this.filterModuleName);
    this.lstOptions               = this.listData_Filter?.lstOptions_IndexDB ? this.listData_Filter?.lstOptions_IndexDB : FilterHelper.getDefaultFilterList(this.filters);
    this.lstOptionsVisibleDefault = FilterHelper.getDefaultVisibleFilterList(this.lstOption_States);
    this.isShowApplyBtn           = this.defaultVisibleFilters > 0;
    this.isShowAddMoreFilter      = Object.keys(this.lstOption_States).length > this.defaultVisibleFilters;
    this.isResetFilter = FilterHelper.isShowBtnResetFilter(this.lstOption_States,this.filters, this.defaultVisibleFilters);
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.listData_Filter?.lstAllOptionsFilteredCalendarData
      || this.listData_Filter?.lstAllOptionsFilteredAreaData
      || this.listData_Filter?.lstAllOptionsFilteredAccountData
      || this.listData_Filter?.lstAllOptionsFilteredProductData  )
    {

     this.lstAllOptionsFilteredCalendarData =  this.listData_Filter?.lstAllOptionsFilteredCalendarData ;
     this.lstAllOptionsFilteredAreaData =  this.listData_Filter?.lstAllOptionsFilteredAreaData ;
     this.lstAllOptionsFilteredAccountData =  this.listData_Filter?.lstAllOptionsFilteredAccountData ;
     this.lstAllOptionsFilteredProductData =  this.listData_Filter?.lstAllOptionsFilteredProductData ;

    } else {
      if (changes['lstAllOptionsCalendarData'] && this.lstAllOptionsCalendarData && !isObjEmpty(this.lstAllOptionsCalendarData)) {
        this.initCalendarOptionSelect('', []);
      }

      if (changes['lstAllOptionsAreaData'] && this.lstAllOptionsAreaData && !isObjEmpty(this.lstAllOptionsAreaData)) {
        this.initAreaOptionSelect('', []);
      }

      if (changes['lstAllOptionsAccountData'] && this.lstAllOptionsAccountData && !isObjEmpty(this.lstAllOptionsAccountData)) {
        this.initAccountOptionSelect('', []);
      }

      if (changes['lstAllOptionsProductData'] && this.lstAllOptionsProductData && !isObjEmpty(this.lstAllOptionsProductData)) {
        this.initProductOptionSelect('', []);
      }

      if (changes['lstAllOptionsData'] && this.lstAllOptionsData && !isObjEmpty(this.lstAllOptionsData)) {
        this.initAllOptionSelect('', []);
      }
    }
  }

  updateChart() {
    this.onUpdateChart.emit(
      {
        lstOption_States: this.lstOption_States,
        lstOption_Selected: this.lstOption_Selected
      }
    );
    FilterHelper.store_lstOption_States(
      this.filterModuleName,
        this.lstOption_States,
        this.lstOption_Selected ,
        this.lstOptions,
        this.lstAllOptionsFilteredCalendarData,
        this.lstAllOptionsFilteredAreaData,
        this.lstAllOptionsFilteredAccountData,
        this.lstAllOptionsFilteredProductData);
  }

  private getOptions(keyName: string, allOptions: any[]) {
    if (!this.lstOptions) {
      this.lstOptions = {};
    }

    if (this.lstOption_IsUsed[keyName]) {
      this.lstOptions[keyName] = [{ value: 'All', text: 'All' }, ...allOptions[keyName]?.map((item) => { return { value: item || 'NULL', text: item || 'NULL' }; })];
    }
  }

  private setOptions(filterGroup: string = '', childArr: any = [], data: any = {}) {
    if (Array.isArray(childArr) && childArr.length > 0) {
      childArr.map((elt: string) => {
        if (this.lstOption_IsUsed[elt]) {
          if (filterGroup === FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.calendarFilter) {
            this.lstAllOptionsFilteredCalendarData[elt] = data[elt] || {};
            this.getOptions(elt, this.lstAllOptionsFilteredCalendarData);
          }

          if (filterGroup === FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter) {
            this.lstAllOptionsFilteredAreaData[elt] = data[elt] || {};
            this.getOptions(elt, this.lstAllOptionsFilteredAreaData);
          }

          if (filterGroup === FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.accountFilter) {
            this.lstAllOptionsFilteredAccountData[elt] = data[elt] || {};
            this.getOptions(elt, this.lstAllOptionsFilteredAccountData);
          }

          if (filterGroup === FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter) {
            this.lstAllOptionsFilteredProductData[elt] = data[elt] || {};
            this.getOptions(elt, this.lstAllOptionsFilteredProductData);
          }

          if (filterGroup.length === 0) {
            this.lstAllOptionsData[elt] = data[elt] || {};
            this.getOptions(elt, this.lstAllOptionsData);
          }
        }
      });
    }
  }

  private resetOptions(childArr: any = []) {
    if (Array.isArray(childArr) && childArr.length > 0) {
      childArr.map((elt: string) => {
        if (this.lstOption_IsUsed[elt]) {
          this.lstOption_Selected[elt] = ['All'];
        }
      })
    }
  }

  private getQueryObj = (keyName: string) => {
    return FilterHelper.getQueryObj(this.filters, keyName, this.lstOption_Selected);
  }

  private getQueryObj2 = (keyName: string) => {
    return FilterHelper.getQueryObj2(this.filters, keyName, this.lstOption_Selected);
  }

  private initCalendarOptionSelect(keyName: string, selected: string[]): void {
    if (!keyName) {
      this.lstAllOptionsFilteredCalendarData = lodashClonedeep(this.lstAllOptionsCalendarData);
      this.getOptions('CHANNEL_LEV1_NAME', this.lstAllOptionsFilteredCalendarData);
      this.getOptions('CHANNEL_LEV2_NAME', this.lstAllOptionsFilteredCalendarData);
      this.getOptions('CHANNEL_LEV3_NAME', this.lstAllOptionsFilteredCalendarData);
      return;
    }

    const queryObj = {
      CacheKey: `FilterKeySelected=${keyName}&${keyName}=${encodeURIComponent(selected.toString())}${objectToQueryString(this.getQueryObj(keyName))}`,
      FilterKeySelected: keyName,
      Selected: {
        [keyName]: selected,
        ...this.getQueryObj(keyName)
      }
    };

    const pageName    = getPageName(this.filterModuleName);

    if (pageName === 'sku-deepdive') {
      SkuDeepdiveHelper.GetFilterCalendarOptions(this.http, queryObj).then((data) => {
        if (data) {
          const groupName = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.calendarFilter || '';

          switch (keyName) {
            case this.filters.CHANNEL_LEV1_NAME:
              this.setOptions(groupName, ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'], data);
              break;

            case this.filters.CHANNEL_LEV2_NAME:
              this.setOptions(groupName, ['CHANNEL_LEV3_NAME'], data);
              break;

            default:
              break;
          }
        }
      });
    }

    if (pageName === 'area-deepdive') {
      AreaDeepdiveHelper.GetFilterCalendarOptions(this.http, queryObj).then((data) => {
        if (data) {
          const groupName = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.calendarFilter || '';

          switch (keyName) {
            case this.filters.CHANNEL_LEV1_NAME:
              this.setOptions(groupName, ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME'], data);
              break;

            case this.filters.CHANNEL_LEV2_NAME:
              this.setOptions(groupName, ['CHANNEL_LEV3_NAME'], data);
              break;

            default:
              break;
          }
        }
      });
    }
  }

  private initAreaOptionSelect(keyName: string, selected: string[]): void {
    if (!keyName) {
      this.lstAllOptionsFilteredAreaData = lodashClonedeep(this.lstAllOptionsAreaData);
      this.getOptions('SALES_HQ_LEV1_NAME', this.lstAllOptionsFilteredAreaData);
      this.getOptions('SALES_HQ_LEV2_NAME', this.lstAllOptionsFilteredAreaData);
      this.getOptions('SALES_HQ_LEV3_NAME', this.lstAllOptionsFilteredAreaData);
      this.getOptions('SALES_CENTER_NAME', this.lstAllOptionsFilteredAreaData);
      this.getOptions('SALES_ROUTE_NAME', this.lstAllOptionsFilteredAreaData);
      this.getOptions('COM_DIST_SALES_REP_NAME', this.lstAllOptionsFilteredAreaData);
      this.getOptions('DEALER_NAME', this.lstAllOptionsFilteredAreaData);
      return;
    }

    const queryObj = {
      CacheKey: `FilterKeySelected=${keyName}&${keyName}=${encodeURIComponent(selected.toString())}${objectToQueryString(this.getQueryObj(keyName))}`,
      FilterKeySelected: keyName,
      Selected: {
        [keyName]: selected,
        ...this.getQueryObj(keyName)
      }
    };

    const pageName    = getPageName(this.filterModuleName);

    if (pageName === 'sku-deepdive') {
      SkuDeepdiveHelper.GetFilterAreaOptions(this.http, queryObj).then((data) => {
        if (data) {
          const groupName = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';

          switch (keyName) {
            case this.filters.SALES_HQ_LEV1_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_HQ_LEV1_NAME')?.data || [], data);
              break;

            case this.filters.SALES_HQ_LEV2_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_HQ_LEV2_NAME')?.data || [], data);
              break;

            case this.filters.SALES_HQ_LEV3_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_HQ_LEV3_NAME')?.data || [], data);
              break;

            case this.filters.SALES_CENTER_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_CENTER_NAME')?.data || [], data);
              break;

            case this.filters.SALES_ROUTE_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_ROUTE_NAME')?.data || [], data);
              break;

            case this.filters.COM_DIST_SALES_REP_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('COM_DIST_SALES_REP_NAME')?.data || [], data);
              break;

            default:
              break;
          }
        }
      });
    }

    if (pageName === 'area-deepdive') {
      AreaDeepdiveHelper.GetFilterAreaOptions(this.http, queryObj).then((data) => {
        if (data) {
          const groupName = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';

          switch (keyName) {
            case this.filters.SALES_HQ_LEV1_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_HQ_LEV1_NAME')?.data || [], data);
              break;

            case this.filters.SALES_HQ_LEV2_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_HQ_LEV2_NAME')?.data || [], data);
              break;

            case this.filters.SALES_HQ_LEV3_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_HQ_LEV3_NAME')?.data || [], data);
              break;

            case this.filters.SALES_CENTER_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_CENTER_NAME')?.data || [], data);
              break;

            case this.filters.SALES_ROUTE_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SALES_ROUTE_NAME')?.data || [], data);
              break;

            case this.filters.COM_DIST_SALES_REP_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('COM_DIST_SALES_REP_NAME')?.data || [], data);
              break;

            default:
              break;
          }
        }
      });
    }
  }

  private initAccountOptionSelect(keyName: string, selected: string[]): void {
    if (!keyName) {
      this.lstAllOptionsFilteredAccountData = lodashClonedeep(this.lstAllOptionsAccountData);
      this.getOptions('KAM_DEPT_LV2_NAME', this.lstAllOptionsFilteredAccountData);
      this.getOptions('KAM_DEPT_LV3_NAME', this.lstAllOptionsFilteredAccountData);
      this.getOptions('KAM_DEPT_LV4_NAME', this.lstAllOptionsFilteredAccountData);
      this.getOptions('KAM_CONTACT_PERSON_NAME', this.lstAllOptionsFilteredAccountData);
      this.getOptions('ACCOUNT_LEV2_NAME_EN', this.lstAllOptionsFilteredAccountData);
      this.getOptions('ACCOUNT_LEV3_NAME_EN', this.lstAllOptionsFilteredAccountData);
      return;
    }

    const queryObj = {
      CacheKey: `FilterKeySelected=${keyName}&${keyName}=${encodeURIComponent(selected.toString())}${objectToQueryString(this.getQueryObj(keyName))}`,
      FilterKeySelected: keyName,
      Selected: {
        [keyName]: selected,
        ...this.getQueryObj(keyName)
      }
    };

    const pageName    = getPageName(this.filterModuleName);

    if (pageName === 'sku-deepdive') {
      SkuDeepdiveHelper.GetFilterAccountOptions(this.http, queryObj).then((data) => {
        if (data) {
          const groupName = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';

          switch (keyName) {
            case this.filters.KAM_DEPT_LV2_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('KAM_DEPT_LV2_NAME')?.data || [], data);
              break;

            case this.filters.KAM_DEPT_LV3_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('KAM_DEPT_LV3_NAME')?.data || [], data);
              break;

            case this.filters.KAM_DEPT_LV4_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('KAM_DEPT_LV4_NAME')?.data || [], data);
              break;

            case this.filters.KAM_CONTACT_PERSON_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('KAM_CONTACT_PERSON_NAME')?.data || [], data);
              break;

            case this.filters.ACCOUNT_LEV2_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('ACCOUNT_LEV2_NAME_EN')?.data || [], data);
              break;

            case this.filters.ACCOUNT_LEV3_NAME_EN:
              break;

            default:
              break;
          }
        }
      });
    }

    if (pageName === 'area-deepdive') {
      AreaDeepdiveHelper.GetFilterAccountOptions(this.http, queryObj).then((data) => {
        if (data) {
          const groupName = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.areaFilter || '';

          switch (keyName) {
            case this.filters.KAM_DEPT_LV2_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('KAM_DEPT_LV2_NAME')?.data || [], data);
              break;

            case this.filters.KAM_DEPT_LV3_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('KAM_DEPT_LV3_NAME')?.data || [], data);
              break;

            case this.filters.KAM_DEPT_LV4_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('KAM_DEPT_LV4_NAME')?.data || [], data);
              break;

            case this.filters.KAM_CONTACT_PERSON_NAME:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('KAM_CONTACT_PERSON_NAME')?.data || [], data);
              break;

            case this.filters.ACCOUNT_LEV2_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('ACCOUNT_LEV2_NAME_EN')?.data || [], data);
              break;

            case this.filters.ACCOUNT_LEV3_NAME_EN:
              break;

            default:
              break;
          }
        }
      });
    }
  }

  private initProductOptionSelect(keyName: string, selected: string[]): void {
    if (!keyName) {
      this.lstAllOptionsFilteredProductData = lodashClonedeep(this.lstAllOptionsProductData);
      this.getOptions('CATEGORY_NAME_EN', this.lstAllOptionsFilteredProductData);
      this.getOptions('SUBCATEGORY_NAME_EN', this.lstAllOptionsFilteredProductData);
      this.getOptions('BRAND_NAME_EN', this.lstAllOptionsFilteredProductData);
      this.getOptions('PACKAGE_LEV1_NAME_EN', this.lstAllOptionsFilteredProductData);
      this.getOptions('PACKAGE_LEV2_NAME_EN', this.lstAllOptionsFilteredProductData);
      this.getOptions('PACKAGE_DTL_NAME', this.lstAllOptionsFilteredProductData);
      return;
    }

    const queryObj = {
      CacheKey: `FilterKeySelected=${keyName}&${keyName}=${encodeURIComponent(selected.toString())}${objectToQueryString(this.getQueryObj(keyName))}`,
      FilterKeySelected: keyName,
      Selected: {
        [keyName]: selected,
        ...this.getQueryObj(keyName)
      }
    };

    const pageName    = getPageName(this.filterModuleName);

    if (pageName === 'sku-deepdive') {
      SkuDeepdiveHelper.GetFilterProductOptions(this.http, queryObj).then((data) => {
        if (data) {
          const groupName = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter || '';

          switch (keyName) {
            case this.filters.CATEGORY_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('CATEGORY_NAME_EN')?.data || [], data);
              break;

            case this.filters.SUBCATEGORY_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SUBCATEGORY_NAME_EN')?.data || [], data);
              break;

            case this.filters.BRAND_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('BRAND_NAME_EN')?.data || [], data);
              break;

            case this.filters.PACKAGE_LEV1_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('PACKAGE_LEV1_NAME_EN')?.data || [], data);
              break;

            case this.filters.PACKAGE_LEV2_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('PACKAGE_LEV2_NAME_EN')?.data || [], data);
              break;

            case this.filters.PACKAGE_DTL_NAME:
              break;

            default:
              break;
          }
        }
      });
    }

    if (pageName === 'area-deepdive') {
      AreaDeepdiveHelper.GetFilterProductOptions(this.http, queryObj).then((data) => {
        if (data) {
          const groupName = FILTER_CONFIGS?.ADD_MORE_FILTER?.GROUP_NAME?.productFilter || '';

          switch (keyName) {
            case this.filters.CATEGORY_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('CATEGORY_NAME_EN')?.data || [], data);
              break;

            case this.filters.SUBCATEGORY_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('SUBCATEGORY_NAME_EN')?.data || [], data);
              break;

            case this.filters.BRAND_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('BRAND_NAME_EN')?.data || [], data);
              break;

            case this.filters.PACKAGE_LEV1_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('PACKAGE_LEV1_NAME_EN')?.data || [], data);
              break;

            case this.filters.PACKAGE_LEV2_NAME_EN:
              this.setOptions(groupName, FilterHelper.getFiltersChildGroup('PACKAGE_LEV2_NAME_EN')?.data || [], data);
              break;

            case this.filters.PACKAGE_DTL_NAME:
              break;

            default:
              break;
          }
        }
      });
    }
  }

  private initAllOptionSelect(keyName: string, selected: string[]) {
    if (!keyName) {
      this.getOptions('CHANNEL_LEV1_NAME', this.lstAllOptionsData);
      this.getOptions('CHANNEL_LEV2_NAME', this.lstAllOptionsData);
      this.getOptions('CHANNEL_LEV3_NAME', this.lstAllOptionsData);
      this.getOptions('CHANNEL_NL', this.lstAllOptionsData);
      this.getOptions('SALES_CENTER_NAME', this.lstAllOptionsData);
      this.getOptions('SALES_CONTROL_BASE_NAME', this.lstAllOptionsData);
      this.getOptions('SALES_CONTROL_BASE_ROUTE_CODE', this.lstAllOptionsData);
      this.getOptions('SALES_HQ_LEV1_NAME', this.lstAllOptionsData);
      this.getOptions('SALES_HQ_LEV2_NAME', this.lstAllOptionsData);
      this.getOptions('SALES_HQ_LEV3_NAME', this.lstAllOptionsData);
      return;
    }

    const queryObj = {
      CacheKey: `FilterKeySelected=${keyName}&${keyName}=${encodeURIComponent(selected.toString())}${objectToQueryString(this.getQueryObj(keyName))}`,
      FilterKeySelected: keyName,
      Selected: {
        [keyName]: selected,
        ...this.getQueryObj2(keyName)
      }
    };

    if (this.pageName === 'progress-district') {
      DistrictRouterProgressHelper.GetAllFilterOptions(this.http, '', queryObj).then((data) => {
        if (data) {
          let childs = [];

          switch (keyName) {
            case this.filters.SALES_HQ_LEV1_NAME:
              childs = ['SALES_HQ_LEV2_NAME', 'SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
              break;

            case this.filters.SALES_HQ_LEV2_NAME:
              childs = ['SALES_HQ_LEV3_NAME', 'SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
              break;

            case this.filters.SALES_HQ_LEV3_NAME:
              childs = ['SALES_CENTER_NAME', 'SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
              break;

            case this.filters.SALES_CENTER_NAME:
              childs = ['SALES_CONTROL_BASE_NAME', 'SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
              break;

            case this.filters.SALES_CONTROL_BASE_NAME:
              childs = ['SALES_CONTROL_BASE_ROUTE_CODE', 'CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
              break;

            case this.filters.SALES_ROUTE_NAME:
            case this.filters.SALES_CONTROL_BASE_ROUTE_CODE:
              childs = ['CHANNEL_LEV1_NAME', 'CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
              break;

            case this.filters.CHANNEL_LEV1_NAME:
              childs = ['CHANNEL_LEV2_NAME', 'CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
              break;

            case this.filters.CHANNEL_LEV2_NAME:
              childs = ['CHANNEL_LEV3_NAME', 'CHANNEL_NL'];
              break;

            case this.filters.CHANNEL_LEV3_NAME:
              childs = ['CHANNEL_NL'];
              break;

            case this.filters.CHANNEL_NL:
              childs = [];
              break;

            default:
              break;
          }

          this.setOptions('', childs, data);
        }
      });
    }
  }

  public onSaveItemsChange(event: any):void {
    const { keyName, selectedItems } = event;

    this.lstOption_Selected[keyName] = selectedItems;
    this.searchObj.data = [];

    if (['sku-deepdive', 'area-deepdive'].includes(this.pageName)) {
      this.changeRelationshipOptions(keyName, !FilterHelper.isAllSelected(selectedItems) ? selectedItems : '');
    } else {
      this.changeRelationshipOptions2(keyName, !FilterHelper.isAllSelected(selectedItems) ? selectedItems : '');
    }
  }

  private changeRelationshipOptions(keyName, selectedItems) {
    switch (keyName) {
      case this.filters.CHANNEL_LEV1_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('CHANNEL_LEV1_NAME')?.data || []);
        this.initCalendarOptionSelect(keyName, selectedItems);
        break;

      case this.filters.CHANNEL_LEV2_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('CHANNEL_LEV2_NAME')?.data || []);
        this.initCalendarOptionSelect(keyName, selectedItems);
        break;

      case this.filters.CHANNEL_LEV3_NAME:
        break;

      case this.filters.SALES_HQ_LEV1_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('SALES_HQ_LEV1_NAME')?.data || []);
        this.initAreaOptionSelect(keyName, selectedItems);
        break;

      case this.filters.SALES_HQ_LEV2_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('SALES_HQ_LEV2_NAME')?.data || []);
        this.initAreaOptionSelect(keyName, selectedItems);
        break;

      case this.filters.SALES_HQ_LEV3_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('SALES_HQ_LEV3_NAME')?.data || []);
        this.initAreaOptionSelect(keyName, selectedItems);
        break;

      case this.filters.SALES_CENTER_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('SALES_CENTER_NAME')?.data || []);
        this.initAreaOptionSelect(keyName, selectedItems);
        break;

      case this.filters.SALES_ROUTE_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('SALES_ROUTE_NAME')?.data || []);
        this.initAreaOptionSelect(keyName, selectedItems);
        break;

      case this.filters.COM_DIST_SALES_REP_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('COM_DIST_SALES_REP_NAME')?.data || []);
        this.initAreaOptionSelect(keyName, selectedItems);
        break;

      case this.filters.DEALER_NAME:
        break;

      case this.filters.KAM_DEPT_LV2_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('KAM_DEPT_LV2_NAME')?.data || []);
        this.initAccountOptionSelect(keyName, selectedItems);
        break;

      case this.filters.KAM_DEPT_LV3_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('KAM_DEPT_LV3_NAME')?.data || []);
        this.initAccountOptionSelect(keyName, selectedItems);
        break;

      case this.filters.KAM_DEPT_LV4_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('KAM_DEPT_LV4_NAME')?.data || []);
        this.initAccountOptionSelect(keyName, selectedItems);
        break;

      case this.filters.KAM_CONTACT_PERSON_NAME:
        this.resetOptions(FilterHelper.getFiltersChildGroup('KAM_CONTACT_PERSON_NAME')?.data || []);
        this.initAccountOptionSelect(keyName, selectedItems);
        break;

      case this.filters.ACCOUNT_LEV2_NAME_EN:
        this.resetOptions(FilterHelper.getFiltersChildGroup('ACCOUNT_LEV2_NAME_EN')?.data || []);
        this.initAccountOptionSelect(keyName, selectedItems);
        break;

      case this.filters.ACCOUNT_LEV3_NAME_EN:
        break;

      case this.filters.CATEGORY_NAME_EN:
        this.resetOptions(FilterHelper.getFiltersChildGroup('CATEGORY_NAME_EN')?.data || []);
        this.initProductOptionSelect(keyName, selectedItems);
        break;

      case this.filters.SUBCATEGORY_NAME_EN:
        this.resetOptions(FilterHelper.getFiltersChildGroup('SUBCATEGORY_NAME_EN')?.data || []);
        this.initProductOptionSelect(keyName, selectedItems);
        break;

      case this.filters.BRAND_NAME_EN:
        this.resetOptions(FilterHelper.getFiltersChildGroup('BRAND_NAME_EN')?.data || []);
        this.initProductOptionSelect(keyName, selectedItems);
        break;

      case this.filters.PACKAGE_LEV1_NAME_EN:
        this.resetOptions(FilterHelper.getFiltersChildGroup('PACKAGE_LEV1_NAME_EN')?.data || []);
        this.initProductOptionSelect(keyName, selectedItems);
        break;

      case this.filters.PACKAGE_LEV2_NAME_EN:
        this.resetOptions(FilterHelper.getFiltersChildGroup('PACKAGE_LEV2_NAME_EN')?.data || []);
        this.initProductOptionSelect(keyName, selectedItems);
        break;

      case this.filters.PACKAGE_DTL_NAME:
        break;

      default:
        break;
    }
  }

  private changeRelationshipOptions2(keyName, selectedItems) {
    this.resetOptions(FilterHelper.getFiltersChildGroup2(keyName)?.data || []);
    this.initAllOptionSelect(keyName, selectedItems);
  }

  public onFetchDataOnSearch(event: any): void {
    const {keyName, keySearch} = event;
    this.searchObj.keyName = keyName;
    this.searchSubject.next(keySearch);
  }

  public onConvertTentativeToEnableTemp(event: any): void {
    const { keyName } = event;

    if (keyName) {
      FilterHelper.convertTentativeToEnableTemp(this.lstOption_States, keyName);
      this.isResetFilter = FilterHelper.isShowBtnResetFilter(this.lstOption_States,this.filters, this.defaultVisibleFilters);
    }
  }

  public onConvertEnableTempToTentative(event: any): void {
    const { keyName } = event;

    if (keyName) {
      const selectedArr = this.lstOption_Selected[keyName] || [];

      if (FilterHelper.isAllSelected(selectedArr)) {
        FilterHelper.convertEnableTempToTentative(this.lstOption_States, keyName);
        this.isResetFilter = FilterHelper.isShowBtnResetFilter(this.lstOption_States,this.filters, this.defaultVisibleFilters);
      } else {
        const selectedArr = this.lstOption_Selected[keyName] || [];

        if (FilterHelper.isAllSelected(selectedArr)) {
          FilterHelper.convertEnableTempToTentative(this.lstOption_States, keyName);
        } else {
          let childFilters = null;

          if (['sku-deepdive', 'area-deepdive'].includes(this.pageName)) {
            childFilters = FilterHelper.getFiltersChildGroup(keyName);
          } else {
            childFilters = FilterHelper.getFiltersChildGroup2(keyName);
          }

          if (childFilters) {
            const { data = [] } = childFilters;
            data.unshift(keyName);
            this.onResetFiltersGroup.emit({ data });
          }

          if (['sku-deepdive', 'area-deepdive'].includes(this.pageName)) {
            this.changeRelationshipOptions(keyName, '');
          } else {
            this.changeRelationshipOptions2(keyName, '');
          }

          FilterHelper.convertEnableTempToTentative(this.lstOption_States, keyName);
        }

        this.isResetFilter = FilterHelper.isShowBtnResetFilter(this.lstOption_States,this.filters, this.defaultVisibleFilters);
      }
    }
  }

  public onAddMoreFilter(): void {
    this.isAddMoreFilter = true;
    this.isShowApplyBtn  = true;
    FilterHelper.convertAllEnableToEnableTemp(this.lstOption_States);
    FilterHelper.convertAllDisabledToTentative(this.lstOption_States);
  }

  public onClose(): void {
    this.isAddMoreFilter = false;
    this.isShowApplyBtn  = FilterHelper.isShowBtnApply(this.lstOption_States);
    FilterHelper.convertAllEnableTempToEnable(this.lstOption_States);
    FilterHelper.convertAllTentativeToDisable(this.lstOption_States);
  }

  public onResetFilter() {
    if (this.isAddMoreFilter) {
      FilterHelper.resetFilterConfigs1(this.lstOption_States, this.defaultVisibleFilters);
    } else {
      FilterHelper.resetFilterConfigs2(this.lstOption_States, this.defaultVisibleFilters);
    }

    this.isResetFilter = FilterHelper.isShowBtnResetFilter(this.lstOption_States,this.filters, this.defaultVisibleFilters);
    this.isShowApplyBtn = FilterHelper.isShowBtnApply(this.lstOption_States);

    if (['sku-deepdive', 'area-deepdive'].includes(this.pageName)) {
      this.resetOptions(['CHANNEL_LEV1_NAME', 'SALES_HQ_LEV1_NAME', 'KAM_DEPT_LV2_NAME', 'CATEGORY_NAME_EN']);
      this.changeRelationshipOptions('CHANNEL_LEV1_NAME', '');
      this.changeRelationshipOptions('SALES_HQ_LEV1_NAME', '');
      this.changeRelationshipOptions('KAM_DEPT_LV2_NAME', '');
      this.changeRelationshipOptions('CATEGORY_NAME_EN', '');
    } else {
      this.resetOptions(['SALES_HQ_LEV1_NAME']);
      this.changeRelationshipOptions2('SALES_HQ_LEV1_NAME', '');
    }
  }
}
