import { DistrictRouterProgressHelper } from 'src/app/components/pages/district-route-progress/district_route_progress_helper';
import { HQProgressHelper } from 'src/app/components/pages/hq-progress/hq_progress_helper';
import { ProgressRankingHelper } from 'src/app/components/pages/progress-ranking/progress-ranking-helper';

export const getSortColumnName = (type: string ,sortColumn: string) => {
  if (!type || !sortColumn) {
    return '';
  }

  let categories = null;

  switch (type) {
    case 'PROGRESS_BY_DISTRICT':
      categories = DistrictRouterProgressHelper.ListColumnProgressByDistrict();
      break;

    case 'PROGRESS_BY_ROUTE':
      categories = DistrictRouterProgressHelper.ListColumnByRoute();
      break;

    case 'HQ_LEVEL_1':
      categories = HQProgressHelper.ListColumnHQLevel1();
      break;

    case 'HQ_LEVEL_2':
      categories = HQProgressHelper.ListColumnHQLevel2();
      break;

    case 'HQ_NATIONALLOCAL':
      categories = HQProgressHelper.ListColumnNationalLocal();
      break;
    case 'AREA_SALE_RANKING':
      categories = ProgressRankingHelper.ListColumnAreaSalesRanking_Dealer();
      break;

    default:
      break;
  }

  if (!categories || !categories.length) {
    return '';
  }

  const column = categories.filter((elt: any) => elt.category === sortColumn)[0] || null;

  if (!column) {
    return '';
  }

  return column.displayName || '';
}

export const sortArrayByPriority = (arr, order, sortKey: string) => {
  const restArray = [...arr];

  const sortedRestArray = restArray.sort((a, b) => {
    const indexA = order.indexOf(a[sortKey]);
    const indexB = order.indexOf(b[sortKey]);


    if(!order.indexOf('NULL')){
      if (a[sortKey] === 'NULL' && b[sortKey] === 'NULL') {
        return 0; // Both are null
      } else if (a[sortKey] === 'NULL') {
        return -1; // a is null, b is not
      } else if (b[sortKey] === 'NULL') {
        return 1; // a is not null, b is null
      }

    }
     

    if (indexA === -1 && indexB === -1) {
      return 0; // Both elements are not in the priorityOrder
    } else if (indexA === -1) {
      return 1; // a is not in the priorityOrder, b is in the priorityOrder
    } else if (indexB === -1) {
      return -1; // a is in the priorityOrder, b is not in the priorityOrder
    } else {
      return indexA - indexB; // Both elements are in the priorityOrder
    }
  });

  return sortedRestArray.map(item => item);
}

export const sortArrayByMultiPriority = (arr, orderLevels) => {
  return [...arr].sort((a, b) => {
    for (const sortKey in orderLevels) {
      if (orderLevels.hasOwnProperty(sortKey)) {
        const order = orderLevels[sortKey];

        const valueA = a[sortKey];
        const valueB = b[sortKey];

        const indexA = order.indexOf(valueA);
        const indexB = order.indexOf(valueB);

        if(!order.indexOf('NULL')){
         if (valueA === 'NULL' && valueB === 'NULL') {
           continue;
         } else if (valueA === 'NULL') {
           return -1;
         } else if (valueB === 'NULL') {
           return 1;
         }
        }
        


        if (indexA === -1 && indexB === -1) {
          continue; 
        } else if (indexA === -1) {
          return 1;
        } else if (indexB === -1) {
          return -1;
        }

     
        if (indexA !== indexB) {
          return indexA - indexB;
        }
      }
    }

   
    return 0;
  });
}

export const PRIORITY_ORDER = {
  SALES_HQ_LEV1_NAME: [
    'VMBU Sales',
    'National Sales Division',
    'Customer Development Sales',
    'Food Area Sales',
    'Alcohol Sales',
    'Other Sales'
  ],
  SALES_HQ_LEV2_NAME: [
    'VM Execution East Japan',
    'VM Execution Kanto',
    'VM Execution Tokyo',
    'VM Execution Central Japan',
    'VM Execution Kinki',
    'VM Execution Chushikoku',
    'VM Execution Kyushu',
    'VM Execution Other',
    'FVJ Trade Sales',
    'North Kanto/South Tohoku',
    'Metropolitan',
    'Kansai/Chubu',
    'Kyushu/Chushikoku',
    'Customer Development Sales',
    'FS North Kanto/South Tohoku',
    'FS Metropolitan',
    'FS Kansai/Chubu',
    'FS Kyushu/Chushikoku',
    'FS Area Other',
    'Alcohol Sales',
    'Other Sales'
  ],
  SALES_HQ_LEV3_NAME: [
    'VM東日本地区統括部',
    'VM関東地区統括部',
    'VM東京地区統括部',
    'VM中部日本地区統括部',
    'VM近畿地区統括部',
    'VM中四国地区統括部',
    'VM九州地区統括部',
    'VMエリア営業本部その他',
    'FV東エリア',
    'FV西エリア',
    'WEX事業部',
    '宮城/山形地区統括部',
    '新潟/福島地区統括部',
    '北関東地区統括部',
    '埼玉地区統括部',
    '北関東/南東北営業本部その他',
    '東東京地区統括部',
    '西東京地区統括部',
    '千葉地区統括部',
    '神奈川地区統括部',
    '首都圏営業本部その他',
    '静岡/山梨地区統括部',
    '岐阜/三重/滋賀地区統括部',
    '愛知地区統括部',
    '大阪地区統括部',
    '兵庫/京奈和地区統括部',
    '関西/中部営業本部その他',
    '中国地区統括部',
    '四国地区統括部',
    '福岡地区統括部',
    '熊本/長崎/佐賀地区統括部',
    '鹿児島/大分/宮崎地区統括部',
    '九州/中四国営業本部その他',
    'FS北関東/南東北地区統括部',
    'FS首都圏地区統括部',
    'FS関西/中部地区統括部',
    'FS九州/中四国地区統括部',
    'FSエリア営業本部その他',
    'アルコール事業統括部',
    'カスタマーデベロップメント本部',
    '営業本部その他'
  ],
  CHANNEL_LEV1_NAME: [
    'Vending',
    'SMDD',
    'Retail',
    'Food',
    'CVS',
    'On-Line',
    'Others'
  ],
  CHANNEL_LEV2_NAME: [
    'Vending_FS',
    'Vending_REG',
    'SM',
    'D&D',
    'Retail',
    'Food',
    'CVS',
    'On-Line',
    'Others'
  ],
  CHANNEL_LEV3_NAME: [
    'Vending_KO',
    'Vending_MIX',
    'Vending_CVM',
    'Vending_PACK',
    'Vending_Other',
    'Vending_REG',
    'Vending_TPO',
    'Vending_OCS',
    'SM_N',
    'SM_L',
    'Drug_N',
    'Drug_L',
    'Discounter_N',
    'Discounter_L',
    'Retail',
    'Food_N',
    'Food_L',
    'CVS_N',
    'On-Line_N',
    'On-Line_L',
    'Others'
  ],

  CATEGORY_NAME_EN: [
    'SSD',
    'NST',
    'SPORTS DRINK',
    'COFFEE',
    'WATER',
    'JUICE',
    'BLACK TEA',
    'LACTIC',
    'ENERGY',
    'ALCOHOL',
    'OTHER BEVERAGE ',
    'OTHER',
    'FOOD',
  ],

  SUBCATEGORY_NAME_EN: [
    'COLA',
    'FLAVORED CSD',
    'CLEAR CSD',
    'GREEN TEA',
    'BARLEY TEA',
    'OOLONG TEA',
    'OTHER NON SUGAR TEA',
    'SPORTS',
    'BLACK',
    'CAFE AU LAIT',
    'STANDARD',
    'BITO',
    'SEASONAL COFFEE',
    'BOTTLE COFFEE',
    'SWEET&MILKY COFFEE',
    'OTHER',
    'NULL',
    'WATER',
    '0-99% FRUIT JUICE',
    '100% FRUIT JUICE',
    'VEGETABLE MIX JUICE',
    'ROYAL MILK TEA',
    'LEMON TEAS',
    'STRAIGHT TEAS',
    'OTHER FLAVOR TEAS',
    'OTHER MILK TEAS',
    'LACTIC',
    'FORTIFIED',
    'FUNCTIONAL',
    'CHUHI',
    'LIQUEUR',
    'NON-ALC. CHUHI',
    'OTHER_FOODS',
    'スナック',
    'パン',
    'ヌードル',
    'デザート',
  ],
  PACKAGE_LEV1_NAME_EN: [
    'CAN',
    'PET',
    'BOTTLE CAN',
    'PAPER-PACK',
    'TANK',
    'OWB',
    'RTB',
    'BIB',
    'HANDY-PACK',
    'POWDER',
    'SYRUP OTHER',
    'POUCH',
    'CUP CHILLED',
    'OTHER PKG  (NKO)',
    'OTHER PKG（CVM）',
  ],
  PACKAGE_LEV2_NAME_EN: [
    'CAN -350',
    'CAN 500',
    'S-PET',
    'PET 500',
    'M-PET',
    'PET 1500',
    'PET 2L',
    'HOT S-PET',
    'HOT PET 500',
    'BOTTLE CAN -350',
    'BOTTLE CAN 500',
    'PAPER-PACK',
    'TANK',
    'OWB',
    'RTB',
    'BIB',
    'HANDY-PACK',
    'POWDER',
    'SYRUP OTHER',
    'POUCH',
    'CUP CHILLED',
    'OTHER PKG  (NKO)',
    'OTHER PKG（CVM）',
  ],
};
