<ng-container *ngIf="!isdistsrictReady || !isRouteReady || !isTimeLineReady">
    <div class="text-center spinner-wrapper">
        <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</ng-container>

<div *ngIf="isLoading" class="loading-overlay">
    <div class="text-center spinner-wrapper">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
  </div>
</div>

<div id="pdf_print_area_intage_flash_vm_full" #pdf_print_area_intage_flash_vm_full [class]="isdistsrictReady && isRouteReady && isTimeLineReady ? '' : 'not-show'">
    <div id="pdf_print_area_2" #pdf_print_area_2 class="row" class="row">
        <div class="col-lg-12 col-md-12">
            <div class="card mb-30">
                <div class="hq-title-header">
                    <h2>District - Route Progress</h2>
                </div>

                <sales-date-filter
                    pageName="DistrictRouterProgress"
                    (onDateTypeSaveItemsChange)="onDateTypeSaveItemsChange($event)"></sales-date-filter>
        </div>
    </div>

    <progress-by-district
        [saleYearMonth]="lstOption_Selected_SalesDate_Applied"
        [lstAllOptionsData]="lstAllOptionsData"
        (ready)="distsrictReady($event)"></progress-by-district>

    <progress-by-route
        [lstAllOptionsData] ="lstAllOptionsData"
        [saleYearMonth]="lstOption_Selected_SalesDate_Applied"
        (ready)="routeReady($event)"></progress-by-route>

    <district-timeline
        [lstAllOptionsData] ="lstAllOptionsData"
        [saleYearMonth]="lstOption_Selected_SalesDate_Applied"
        (ready)="timelineReady($event)"></district-timeline>
</div>
