import { Pipe, PipeTransform } from '@angular/core';
import { debug } from 'console';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
    
  transform(ary: any,enableSort: Boolean, type: string): any {
    if(!enableSort){
      return ary;
    }

    if(type == 'asc'){
      const fnasc = function(a, b) {
        return a.value > b.value ? 1 : -1;
      };
      return ary.sort(fnasc);
    }else{
      const fndesc = function(a, b) {
        return b.value > a.value ? 1 : -1;
      };
      return ary.sort(fndesc);
    }
  }
}