import { Component, Renderer2, OnChanges, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { IntageHelper } from 'src/app/components/pages/intage-vm/intage_helper';
import { IntageHelper as IntageDashboardHelper } from 'src/app/components/pages/intage/intage_helper';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import html2canvas from 'src/html2canvas';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { DropdownValueToNgSelectFormat } from 'src/app/helpers/dropdown';
import { getColorsWithMovingAverage, getSeriesWithMovingAverage } from 'src/app/helpers/movingAverage';
import lodashClonedeep from 'lodash.clonedeep';
import { IndexDbHelper } from 'src/app/helpers/indexDb';
@Component({
  selector: 'intage-otc-value-vs-ly-vm',
  templateUrl: './otc-value-vs-ly-vm.component.html',
  styleUrls: ['./otc-value-vs-ly-vm.component.scss']
})
export class IntageVMOtcValueVsLyComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  categories: any;
  intageOtcChart: ApexCharts;
  otcWeeks: any;
  otcStartWeeks: number;
  otcHaveSingleSerie: boolean;
  otcHaveData: boolean;
  otcSingleSerie: string;
  otcSingleSerieColor: string;
  categoryColors: any;
  selectedCategory: any;
  makerLogo: any;
  marketShareMakers: any;
  otcSelectedRegion: any;
  populated: boolean;
  series: any;
  aggregateBy: any;
  monthSet: any;
  quarterSet: any;
  yearSet: any;
  start_week: any;
  marketValueVsLyData: any;
  categoriesFilter: any;
  districts: any;
  movingAverageWindowSizes: number[] = [4];
  items = [{}];
  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient) { }
  ngOnChanges() {
      if(!this.populated){
        this.otcSelectedRegion = ['CCBJI'];
        this.categoryColors = IntageHelper.CategoryColors();
        this.selectedCategory = ['Total'];
        this.makerLogo = IntageHelper.MakerLogos();
        this.marketShareMakers = [];
        this.makerLogo.forEach(x => {
          this.marketShareMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked,
            show: true
          });
        });
        this.populated = true;
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        this.categoriesFilter = IntageHelper.categoriesFilter();
        this.districts = IntageHelper.district();
        this.aggregateBy = 'wtd';
        this.otcStartWeeks = 52;
        this.handleIntageOtc();
      }     
  }

  filterMakers(makers: any): any {
    return makers.filter(e => e.show);
  }

  public onShareVsLyMakersCheckedChanged(maker: string){
    let currentMaker = this.marketShareMakers.find(x=> x.maker == maker);
    currentMaker.checked = !currentMaker.checked;
    this.handleIntageOtc();
  }

  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["selectedCategory"] = [...this.selectedCategory];
      window["otcSelectedRegion"] = [...this.otcSelectedRegion];
    } else {
      if (window["selectedCategory"] != null) { this.selectedCategory = window["selectedCategory"]; }
      if (window["otcSelectedRegion"] != null) { this.otcSelectedRegion = window["otcSelectedRegion"]; }
    }
  } 
  onRemoveFilterOTCCategory($event) {
    if(this.selectedCategory.length == 0){
      this.selectedCategory = ['Total'];
      this.SaveOptionValueTemp(false);
      this.onFilterByCategoryChange()
    }
    if ($event.value === 'Total') {
      this.selectedCategory = [];
      return;
    }
  }
  onAddFilterOTCCategory($event) {
    if ($event === 'Total') {
      this.selectedCategory = ['Total'];
      return;
    }
    if(this.selectedCategory.filter(x => x != 'Total').length == (this.categoriesFilter.length-1)){
      this.selectedCategory = ['Total'];
    }else{
      this.selectedCategory = this.selectedCategory.filter(e => e !== 'Total');;
    }
  } 
  onRemoveFilterOTCRegion($event) {
    if(this.otcSelectedRegion.length == 0){
      this.otcSelectedRegion = ['CCBJI'];
      this.SaveOptionValueTemp(false);
      this.onFilterByOtcRegionChange()
    }
    if ($event.value === 'CCBJI') {
      this.otcSelectedRegion = [];
      return;
    }
  }
  onAddFilterOTCRegion($event) {
    if ($event === 'CCBJI') {
      this.otcSelectedRegion = ['CCBJI'];
      return;
    }
    if(this.otcSelectedRegion.filter(x=> x != 'CCBJI').length == (this.districts.length-1)){
      this.otcSelectedRegion = ['CCBJI'];
    }else{
      this.otcSelectedRegion = this.otcSelectedRegion.filter(e => e !== 'CCBJI');;
    }
  } 
    
  async handleIntageOtc(){
    try
    {
      this.otcWeeks = await IndexDbHelper.Get('IntageVmWeeks');
      this.otcWeeks = this.otcWeeks.filter(x => x <= this.selectedWeek);
      let startWeek = this.otcWeeks.length >= this.otcStartWeeks ? this.otcWeeks[this.otcStartWeeks - 1] : this.otcWeeks[this.otcWeeks.length - 1]; 
      
      this.otcWeeks.reverse();

      this.items = this.dropdownValueToNgSelectFormat(this.otcWeeks);
      
      let data = lodashClonedeep(this.intage.filter(x => x.week >= startWeek && x.week <= this.selectedWeek && x.tyvalue != "" && x.lyvalue != "" && x.tyunit != "" && x.lyunit != ""));

      data = data.filter(x => x.botter_sci == this.otcSelectedRegion[0]);
      if (!this.selectedCategory.includes('Total'))
      {
        data = data.filter(x => x.category == this.selectedCategory[0]);
      }

      switch (this.aggregateBy) {
        case 'mtd':
          this.handleIntageOTCMonthly(data);
          break;
        case 'qtd':
          this.handleIntageOTCQuarterly(data);          
          break;
        case 'ytd':
          this.handleIntageOTCYearly(data);
          break;
        default:
          this.handleIntageOTCWeekly(data);
          break;
      }      
    }
    catch(error){
      console.log(error);
    }
    let t= this;

      setTimeout(() => {
        t.ready.emit(true);
      });    
  }

  handleIntageOTCWeekly(data)
  {
    this.marketValueVsLyData = [];
    let weeks = [...new Set(data.map(x=> x.week))];
    data = IntageHelper.transformData(data);

    this.marketShareMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      weeks.forEach(week => {
        let dataMakerWeek = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && x.week == week);

        let tyValueMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let lyValueMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.lyvalue, 0);

        let valueVsLyNumber = lyValueMaker == 0 ? NaN : (tyValueMaker - lyValueMaker) / lyValueMaker * 100;
        let valueVsLy = IntageHelper.formatIntageNumber(valueVsLyNumber);

        let tyUnitMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.tyunit, 0);
        let lyUnitMaker = dataMakerWeek.reduce((acc, curr) => acc + curr.lyunit, 0);
        let unitVsLyNumber = lyUnitMaker == 0 ? NaN : (tyUnitMaker - lyUnitMaker) / lyUnitMaker * 100;
        let unitVsLy = IntageHelper.formatIntageNumber(unitVsLyNumber);
        
        let marketValueVsLyWeek = this.marketValueVsLyData.find(x => x.week == week);
        if (marketValueVsLyWeek == null)
        {
          this.marketValueVsLyData.push({
            week: week,
            makers: [
              {
                maker: maker.maker,
                valueVsLy: valueVsLy,
                unitVsLy: unitVsLy
              }
            ]
          })
        }
        else
        {
          marketValueVsLyWeek.makers.push(
            {
              maker: maker.maker,
              valueVsLy: valueVsLy,
              unitVsLy: unitVsLy
            }
          )
        }
      })
    })

    this.marketValueVsLyData.reverse();
    weeks.reverse();
    this.renderValueVsLyChart(weeks);
  }

  handleIntageOTCMonthly(data)
  {
    this.marketValueVsLyData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_month = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.monthSet = [...new Set(intage_month.map(x=> x.month))];

    data = IntageHelper.transformData(data);
    this.marketShareMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.monthSet.forEach(month => {
        let intageMonth = intage_month.filter(x => x.month == month);
        let dataMakerMonth = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageMonth.some(m => m.week == x.week));
        
        let tyValueMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let lyValueMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.lyvalue, 0);

        let valueVsLyNumber = lyValueMaker == 0 ? NaN : (tyValueMaker - lyValueMaker) / lyValueMaker * 100;
        let valueVsLy = IntageHelper.formatIntageNumber(valueVsLyNumber);

        let tyUnitMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.tyunit, 0);
        let lyUnitMaker = dataMakerMonth.reduce((acc, curr) => acc + curr.lyunit, 0);

        let unitVsLyNumber = lyUnitMaker == 0 ? NaN : (tyUnitMaker - lyUnitMaker) / lyUnitMaker * 100;
        let unitVsLy = IntageHelper.formatIntageNumber(unitVsLyNumber);
  
        let marketShareTimeLineMonth = this.marketValueVsLyData.find(x => x.month == month);
        if (marketShareTimeLineMonth == null)
        {
          this.marketValueVsLyData.push({
            month: month,
            makers: [
              {
                maker: maker.maker,
                valueVsLy: valueVsLy,
                unitVsLy: unitVsLy
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineMonth.makers.push(
            {
              maker: maker.maker,
              valueVsLy: valueVsLy,
              unitVsLy: unitVsLy
            }
          )
        }
      })
    })

    this.renderValueVsLyChart(this.monthSet);
  }

  handleIntageOTCQuarterly(data)
  {
    this.marketValueVsLyData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_quarter = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.quarterSet = [...new Set(intage_quarter.map(x=> x.quarter))];

    data = IntageHelper.transformData(data);
    this.marketShareMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.quarterSet.forEach(quarter => {
        let intageQuarter = intage_quarter.filter(x => x.quarter == quarter);
        let dataMakerQuarter = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageQuarter.some(m => m.week == x.week));

        let tyValueMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let lyValueMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.lyvalue, 0);

        let valueVsLyNumber = lyValueMaker == 0 ? NaN : (tyValueMaker - lyValueMaker) / lyValueMaker * 100;
        let valueVsLy = IntageHelper.formatIntageNumber(valueVsLyNumber);

        let tyUnitMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.tyunit, 0);
        let lyUnitMaker = dataMakerQuarter.reduce((acc, curr) => acc + curr.lyunit, 0);
        let unitVsLyNumber = lyUnitMaker == 0 ? NaN : (tyUnitMaker - lyUnitMaker) / lyUnitMaker * 100;      
        let unitVsLy = IntageHelper.formatIntageNumber(unitVsLyNumber);

        let marketShareTimeLineQuarter = this.marketValueVsLyData.find(x => x.quarter == quarter);
        if (marketShareTimeLineQuarter == null)
        {
          this.marketValueVsLyData.push({
            quarter: quarter,
            makers: [
              {
                maker: maker.maker,
                valueVsLy: valueVsLy,
                unitVsLy: unitVsLy
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineQuarter.makers.push(
            {
              maker: maker.maker,
              valueVsLy: valueVsLy,
              unitVsLy: unitVsLy
            }
          )
        }
      })
    })

    this.renderValueVsLyChart(this.quarterSet);
  }

  handleIntageOTCYearly(data)
  {
    this.marketValueVsLyData = [];
    let start_week = this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks];
    let intage_year = this.intageMonth.filter(x => x.week >= start_week && x.week <= this.selectedWeek);
   
    this.yearSet = [...new Set(intage_year.map(x=> x.year))];

    data = IntageHelper.transformData(data);
    this.marketShareMakers.forEach(maker => {
      if (maker.maker == 'All')
      {
        return;
      }
      this.yearSet.forEach(year => {
        let intageYear = intage_year.filter(x => x.year == year);

        let dataMakerYear = data.filter(x => x.maker.toLowerCase() == maker.maker.toLowerCase() && intageYear.some(m => m.week == x.week));
        let tyValueMaker = dataMakerYear.reduce((acc, curr) => acc + curr.tyvalue, 0);
        let lyValueMaker = dataMakerYear.reduce((acc, curr) => acc + curr.lyvalue, 0);

        let valueVsLyNumber = lyValueMaker == 0 ? NaN : (tyValueMaker - lyValueMaker) / lyValueMaker * 100;
        let valueVsLy = IntageHelper.formatIntageNumber(valueVsLyNumber);

        let tyUnitMaker = dataMakerYear.reduce((acc, curr) => acc + curr.tyunit, 0);
        let lyUnitMaker = dataMakerYear.reduce((acc, curr) => acc + curr.lyunit, 0);
        let unitVsLyNumber = lyUnitMaker == 0 ? NaN : (tyUnitMaker - lyUnitMaker) / lyUnitMaker * 100;
        let unitVsLy = IntageHelper.formatIntageNumber(unitVsLyNumber);
  
        let marketShareTimeLineYear = this.marketValueVsLyData.find(x => x.year == year);
        if (marketShareTimeLineYear == null)
        {
          this.marketValueVsLyData.push({
            year: year,
            makers: [
              {
                maker: maker.maker,
                valueVsLy: valueVsLy,
                unitVsLy: unitVsLy
              }
            ]
          })
        }
        else
        {
          marketShareTimeLineYear.makers.push(
            {
              maker: maker.maker,
              valueVsLy: valueVsLy,
              unitVsLy: unitVsLy
            }
          )
        }
      })
    })

    this.renderValueVsLyChart(this.yearSet);
  }

  renderValueVsLyChart(categories: any){
    if(this.intageOtcChart != null){
      this.intageOtcChart.destroy();
    }

    this.series = [];
    let colors = [];
    for(let i=0;i<this.marketShareMakers.length;i++){
      let maker = this.marketShareMakers[i];
      if(maker.checked){
        let serie = {
          name : maker.maker,
          data : []
        };
        for(let j=0;j<this.marketValueVsLyData.length;j++){
          let currentMaker = this.marketValueVsLyData[j].makers.find(x=> x.maker == maker.maker);
          if(currentMaker == null){
            serie.data.push(null);
            continue;
          }
          serie.data.push(currentMaker.valueVsLy);
        }
        
        this.series.push(serie);
        colors.push(IntageDashboardHelper.GetMakerColor(serie.name));
      }
    }
    this.otcHaveData = false;
    for(let i=0;i<this.series.length;i++){
      if(this.series[i].data.length > 0){
        this.otcHaveData = true;
        break;
      }
    }
    this.otcHaveSingleSerie = false;
    if(this.otcHaveData && this.series.length == 1){
      this.otcHaveSingleSerie = true;
      this.otcSingleSerie = this.series[0].name;
      this.otcSingleSerieColor = colors[0];
    }
    // otcStartWeeks    
    for (let i = 0; i < this.series.length; i++) {
      this.series[i]['data'] = this.series[i]['data'].slice(-1 * this.otcStartWeeks)      
    }
    const options = {
        chart: {
            height: 500,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
              show: true,
              tools: {
                download: false
              }
            }
        },
        dataLabels: {
            enabled: this.otcHaveSingleSerie ? true: false,
            style:{
              colors: [
                function (w) {
                  if (w.series[w.seriesIndex][w.dataPointIndex] > 0) {
                    return w.w.config.colors[w.seriesIndex];
                  } else {
                    return "#FF2626";
                  }
                },
              ],
              fontSize : "14px"
            }
        },
        stroke: {
            //width: [5, 5, 5],
            curve: 'smooth',
            //dashArray: [5, 5, 5]
        },
        colors: getColorsWithMovingAverage({ colors, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        series: getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        legend: {
            show: true,
            onItemClick: {
              toggleDataSeries: true
            }
        },
        markers: {
            size: this.otcHaveSingleSerie ? 0 : 5,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
            categories: categories
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        yaxis: {
          forceNiceScale: true,
          //min: -50
        }
      }; 
    // FPT-ChienNX7 [2022/12/14][SENSE][1203444563290052] Rename file name png when export start

    document.querySelector("#intage-vm-market-value-vsLy-chart").innerHTML = '';
    this.intageOtcChart = new ApexCharts(
        document.querySelector("#intage-vm-market-value-vsLy-chart"),
        options
    );
    this.intageOtcChart.render();
  }
 
  public onFilterByOtcRegionChange(){
    if(this.otcSelectedRegion.length==0){
      this.otcSelectedRegion = ['CCBJI Territory'];
    }
    this.handleIntageOtc();
  }

  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }

  public onFilterByCategoryChange(){
    if(this.selectedCategory.length==0){
      this.selectedCategory = ['Total'];
    }
    this.handleIntageOtc();
  }

  public onSelectedStartWeekChanged($event){
    this.otcStartWeeks = $event;
    this.handleIntageOtc();
  }

  public onOtcValueAggregateChange(aggregateBy: string) {
    this.aggregateBy = aggregateBy;
    this.handleIntageOtc();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-otc-value');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  public exportOTCValuevsLY(isImage: boolean) {
    let weeks = this.otcWeeks.slice(-1 * this.otcStartWeeks);
    let fileName = ``;
    switch (this.aggregateBy) {
      case 'mtd':
        fileName = `Intage_VM_Dashboard_Value_vs_LY_Monthly_${this.selectedWeek}`;
      break;
      case 'qtd':
        fileName = `Intage_VM_Dashboard_Value_vs_LY_Quarterly_${this.selectedWeek}`;
      break;
      case 'ytd':
        fileName = `Intage_VM_Dashboard_Value_vs_LY_Yearly_${this.selectedWeek}`;
      break;
      default:
        fileName = `Intage_VM_Dashboard_Value_vs_LY_Weekly_${this.selectedWeek}`;
      break;
    }
    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];
      let makers = this.marketShareMakers.filter(x => x.checked);
      switch (this.aggregateBy) {
        case 'mtd':
          makers.forEach(maker => {
            let serie = this.series.find(x => x.name == maker.maker);
            for (let i = 0; i < this.monthSet.length; i++) {             
              data.push({
                month: this.monthSet[i],
                maker: maker.maker,
                category: this.selectedCategory,
                region: this.otcSelectedRegion,
                vsLy: IntageHelper.formatValueCsv(serie.data[i])
              });            
            }
          })

          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Month", "Maker", "Category", "Region", "% vs LY"]
          });
          break;
        case 'qtd':
          makers.forEach(maker => {
            let serie = this.series.find(x => x.name == maker.maker);
            for (let i = 0; i < this.quarterSet.length; i++) {        
                data.push({
                  quarter: this.quarterSet[i],
                  maker: maker.maker,
                  category: this.selectedCategory,
                  region: this.otcSelectedRegion,
                  vsLy: IntageHelper.formatValueCsv(serie.data[i])
                });               
              }
          })
          
            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: ["Quarter", "Maker", "Category", "Region", "% vs LY"]
            });         
          break;
        case 'ytd':
          makers.forEach(maker => {
            let serie = this.series.find(x => x.name == maker.maker);
            for (let i = 0; i < this.yearSet.length; i++) {
              data.push({
                year: this.yearSet[i],
                maker: maker.maker,
                category: this.selectedCategory,
                region: this.otcSelectedRegion,
                vsLy: IntageHelper.formatValueCsv(serie.data[i])
              });              
            }
          })
          
            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: ["Year", "Maker", "Category", "Region", "% vs LY"]
            });
          break;
        default:
          let seriesWTD = getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes });      
          makers.forEach(maker => {   
            let series = seriesWTD.filter(x => x.name.includes(maker.maker));
            series.forEach(serie => {
              for (let i = 0; i < weeks.length; i++) {
                data.push({
                  week: weeks[i],
                  maker: serie.name,
                  category: this.selectedCategory,
                  region: this.otcSelectedRegion,
                  vsLy: IntageHelper.formatValueCsv(serie.data[i])
                });
              }
            })                            
          })
          
            new AngularCsv(data, fileName, {
              showLabels: true,
              headers: ["Week", "Maker", "Category", "Region", "% vs LY"]
            });
          break;
      }
    }
    this.showOptionDownload();
  }

  dropdownValueToNgSelectFormat(value) {
    let values = DropdownValueToNgSelectFormat(value);
    if (values?.length && this?.otcStartWeeks) {
      if (!values.some(x => x.value == null || x.name == null))
      {
        if (values.length < this.otcStartWeeks) {
          setTimeout(() => {
            this.otcStartWeeks = values.length
          }, 500);
        }
      }
    }
    return values
  }

  onMovingAverageWindowSizeChange(value){
    this.movingAverageWindowSizes = value;
    this.handleIntageOtc();
  }

  public formatCategoryName(categoryName: any)
  {
    return IntageHelper.formatCategoryName(categoryName);
  }
}
