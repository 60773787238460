import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'single-dropdown',
  host: {'class': 'ng-section summary-section'},
  template: `
    <b>{{title}}</b>
    <ng-select [items]="items" bindLabel="name" bindValue="value"
      [ngModel]="model || items[0].value" (ngModelChange)="onModelChange($event)"
      [searchable]="false">
    </ng-select>
  `,
})
export class SingleDropdownComponent implements OnChanges {
  @Input() title: string;
  @Input() items: any[];
  @Input() model: string;
  @Output() modelChange = new EventEmitter<string>();
  @Output("change") change: EventEmitter<any> = new EventEmitter();
  @ViewChild('ngSelectComponent') ngSelectComponent: NgSelectComponent;

  ngOnChanges(changes: SimpleChanges) {

  }

  onModelChange(newValue: string) {
    this.modelChange.emit(newValue);
    this.change.emit(newValue);
  }
}
