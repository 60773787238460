import { AnalyticsHelper } from './../../../helpers/analytics';
import { SKUQueries, CustomerQueries } from './../../../model/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as tsvParse from 'd3-dsv';
import { AnalyticsQueries } from 'src/app/model/constants';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { processHide, processShow } from 'src/app/helpers/process';
import { dispatchDateSelectChange } from 'src/app/helpers/tracking';
const sort_columns = ["bapc_ty", "bapc_ly", "bapc_per_ly", "nsr_ty", "nsr_ly", "nsr_per_ly", "gp_ty", "gp_ly", "gp_per_ly"];

@Component({
  selector: 'app-sku-customer',
  templateUrl: './sku-customer.component.html',
  styleUrls: ['./sku-customer.component.scss']
})

export class SkuCustomerComponent implements OnInit {
    startDate = new Date();
    selectedDate: any;
    aggregateBy: string;
    imageProcess: string;
    titleProcess: string;
    desProcess: string;
    percentageProcess: number;
    processReady: boolean;
    customerReady: boolean;
    skuReady: boolean;
    skuData: any;
    skuCountData: any;
    skuDataInit: any;
    skuConditon = "";
    customerConditon = "";
    customerData: any;
    customerDataInit: any;
    customerCountData: any;
    numberSkuCombobox = 0;
    numberCustomerCombobox = 0;
    tempSkuDataInit = {};
    sale_area_names = [];
    sales_area_to_sales_district = {};
    groupcategorypackagecontainer = {};
    grouppackagecontainer = {};
    categoryList = [];
    pkgLev1List = [];
    containerList = [];
    groupjancodeProductnameBrandname = {};
    jancodeList = [];
    productname_enList = [];
    productname_jpList = [];
    brandnameList = [];
    groupBrandnameCategoryProductNameJp = {};
    groupBrandnameCategory = {};
    groupCategoryProduct = {};
    groupchannelLeve1toLevel2toLevel3 = {};
    channelLevel1List = [];
    channelLevel2List = [];
    channelLevel3List = [];
    tempCustomerDataInit = {};
    groupchannelLevel2toLevel3 = {};
    numberColumns = ['bapc_ty','bapc_ly','bapc_per_ly','nsr_ty','nsr_ly','nsr_per_ly','gp_ty','gp_ly','gp_per_ly'];
    SKUdisplayColumns = [];
    SKUsortbyColumn = "";
    SKUorderbyColumn = "";
    CustomerdisplayColumns = [];
    CustomersortbyColumn = "";
    CustomerorderbyColumn = "";


    constructor(private http: HttpClient, private router: Router) { }

    ngOnInit() {
        this.router.navigate(['/sku-customer/maintain']); 
        // this.skuReady = false;
        // this.customerReady = false;
        // this.aggregateBy = 'daily';   
        // this.startDate.setDate(this.startDate.getDate() - 1);
        // this.selectedDate = {
        //     year: this.startDate.getFullYear(),
        //     month: this.startDate.getMonth() + 1  ,
        //     day: this.startDate.getDate()
        // };
        // this.bindIconProcess();
        // this.getDataInit();
    }

    ngOnDestroy() {
        processHide();
    }    

    /**
     * [2022/10/19] bind Icon Process
     * @author FPT-HoiLT2
     */
    public bindIconProcess() {
        this.processReady = false;
        var currentDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
        let startDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
        startDate.setDate(1);
        let today = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month +
        (this.selectedDate.day > 9 ? '' : '0') + this.selectedDate.day;
        let queryUrl = environment.snowflakeProxy + "?code=" + AnalyticsQueries.percentPerformance + "&date=" + today;
        // let startMonth = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month + '01';
        // let month = this.selectedDate.year + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month;
        // queryUrl = queryUrl.replace('{0}', startMonth).replace('{0}', startMonth).replace('{1}', today).replace('{2}', month);
        var accessToken = 'Bearer ' + localStorage.getItem('accessToken');
        let httpOptions: Object = {
        headers: new HttpHeaders().append('Authorization', accessToken),
        responseType: 'text'
        }
        this.http.get<any>(queryUrl, httpOptions)
        .subscribe(
            response => {
            let arr = tsvParse.tsvParse('act	id\n' + response);
            if(arr[0].act == ''){
                arr[0].act = 0;
            }
            if(arr.length > 1 && arr[1].act == ''){
                arr[1].act = 0;
            }
            let percent = Math.round((parseFloat(arr[0].act) / parseFloat(arr[1].act)) * 100);
            this.titleProcess = 'Progress from <b>' + startDate.getFullYear() + '/' + ('0' + (startDate.getMonth() + 1)).slice(-2) + '/' + startDate.getDate() + '</b> to <b>'
                + currentDate.getFullYear() + '/' + ('0' + (currentDate.getMonth() + 1)).slice(-2) + '/' + currentDate.getDate() + '</b><br/>' + (parseFloat(arr[0].act) / 1000000).toFixed(1) + ' MM / ' + (parseFloat(arr[1].act) / 1000000).toFixed(1) + ' MM';
            this.percentageProcess = percent;
            this.desProcess = '*This chart is using the most updated master data and transaction data from HANA';
            if (percent < 10) {
                this.imageProcess = 'assets/img/image_process/0_percent.png';
            } else if (percent < 20) {
                this.imageProcess = 'assets/img/image_process/10_percent.png';
            } else if (percent < 30) {
                this.imageProcess = 'assets/img/image_process/20_percent.png';
            } else if (percent < 40) {
                this.imageProcess = 'assets/img/image_process/30_percent.png';
            } else if (percent < 50) {
                this.imageProcess = 'assets/img/image_process/40_percent.png';
            } else if (percent < 60) {
                this.imageProcess = 'assets/img/image_process/50_percent.png';
            } else if (percent < 70) {
                this.imageProcess = 'assets/img/image_process/60_percent.png';
            } else if (percent < 80) {
                this.imageProcess = 'assets/img/image_process/70_percent.png';
            } else if (percent < 90) {
                this.imageProcess = 'assets/img/image_process/80_percent.png';
            } else if (percent < 100) {
                this.imageProcess = 'assets/img/image_process/90_percent.png';
            } else {
                this.imageProcess = 'assets/img/image_process/perfect_percent.png';
            };
            this.processReady = true;

            processShow({ imageProcess: this.imageProcess, titleProcess: this.titleProcess, percentageProcess: this.percentageProcess, desProcess: this.desProcess });
            
            },
            error => console.log(error)
        );
    }
    public skuCheckReady(ready: boolean) {
        this.skuReady = ready;
    }
    public customerCheckReady(ready: boolean) {
        this.customerReady = ready;
    }
    /**
     * [2022/10/19] handle when AggregateBy selected
     * @author FPT-HoiLT2
     */
    public onAggregateByChange(aggregateBy: string) {
        this.aggregateBy = aggregateBy;
        this.bindIconProcess();
        //this.SKUdisplayColumns = [];
        //this.CustomerdisplayColumns = [];
        //this.bindingTableData({pageNumber: 1, isSearch: true});
        //this.bindingCustomerTableData({pageNumber: 1, isSearch: true});
    }

    /**
     * [2022/10/19] handle when date selected
     * @author FPT-HoiLT2
     */
    public onDateSelect(event) {
        dispatchDateSelectChange({...event, ...{element:"sku-customer"}});
        this.selectedDate = {
            year: event.year,
            month: event.month,
            day: event.day
        };
        this.bindIconProcess();
        this.SKUdisplayColumns = [];
        this.CustomerdisplayColumns = [];
        this.bindingTableData({pageNumber: 1, isSearch: true});
        this.bindingCustomerTableData({pageNumber: 1, isSearch: true});
    }

    public async getDataInit(){
        try
        {
        //binding data to SKU and Customer
        document.getElementsByClassName('sku_table')[0].classList.add('not-show');
        document.getElementsByClassName('sku_spinner')[0].classList.remove('not-show');
        document.getElementsByClassName('customer_table')[0].classList.add('not-show');
        document.getElementsByClassName('customer_spinner')[0].classList.remove('not-show');
        await Promise.all([this.bindingComboboxData(environment.snowflakeProxy + "?code=" + SKUQueries.salesArea),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + SKUQueries.salesDistrict),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + SKUQueries.channelLevel1),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + SKUQueries.salesAreaTosalesDistrict),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + SKUQueries.janCode),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" +  SKUQueries.groupcategorypackagecontainer),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" +  SKUQueries.groupBrandnameCategoryProductNameJp),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.salesArea),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.salesDistrict),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.salesAreaTosalesDistrict),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.categoryData),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.channelLevel1),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.channelLevel2),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.channelLevel3),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" +  CustomerQueries.channelLevel1toLevel2toLevel3),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.accountLevel2),
        this.bindingComboboxData(environment.snowflakeProxy + "?code=" + CustomerQueries.accountLevel3)
    ]).then(([
        skuareaData,skudistrictData, skuchannelLevel1Data,
        skusalesAreaTosalesDistrictData, skujanCodeData, skugroupcategoryPackageContainerData, skugroupBrandnameCategoryProductNameJpData,
        customerareaData, customerdistrictData, customersalesAreaTosalesDistrictData, 
        customercategoryData, customerchannelLevel1Data, customerchannelLevel2Data,
        customerchannelLevel3Data, customerchannelLevel1toLevel2toLevel3Data, customeraccountLevel2Data, customeraccountLevel3Data
    ]) => {
        this.tempSkuDataInit["areaData"] = tsvParse.tsvParse("cd	name\n" + skuareaData);
        this.tempSkuDataInit["districtData"] = tsvParse.tsvParse("cd	name\n" + skudistrictData);
        this.tempSkuDataInit["channelLevel1Data"] = tsvParse.tsvParse("cd	name\n" + skuchannelLevel1Data);

        var res_groupcategoryPackageContainerData = tsvParse.tsvParse("category_name	package_name	container_name\n" + skugroupcategoryPackageContainerData);
        res_groupcategoryPackageContainerData.forEach(x => {
            if (!(x.category_name in this.groupcategorypackagecontainer))
            {
              this.groupcategorypackagecontainer[x.category_name] = {};                 
              this.groupcategorypackagecontainer[x.category_name]['package_name'] = [];
              this.groupcategorypackagecontainer[x.category_name]['container_name'] = [];
            }

            if (!this.groupcategorypackagecontainer[x.category_name]['package_name'].includes(x.package_name)) 
            {
              this.groupcategorypackagecontainer[x.category_name]['package_name'].push(x.package_name);
            }

            if (!this.groupcategorypackagecontainer[x.category_name]['container_name'].includes(x.container_name))
            {
                this.groupcategorypackagecontainer[x.category_name]['container_name'].push(x.container_name);
            }

            if (!this.categoryList.includes(x.category_name))
            {
              this.categoryList.push(x.category_name);
            }


            if (!(x.container_name in this.grouppackagecontainer))
            {
                this.grouppackagecontainer[x.container_name] = [];
            }

            if (!this.grouppackagecontainer[x.container_name].includes(x.package_name)) 
            {
                this.grouppackagecontainer[x.container_name].push(x.package_name);
            }

            if (!this.pkgLev1List.includes(x.package_name))
            {
                this.pkgLev1List.push(x.package_name);
            }

            if (!this.containerList.includes(x.container_name))
            {
                this.containerList.push(x.container_name); 
            }                
        })

        this.tempSkuDataInit["groupcategoryPackageContainerData"] = {groupcategorypackagecontainer: this.groupcategorypackagecontainer, categoryList: this.categoryList, pkgLev1List: this.pkgLev1List, containerList: this.containerList, grouppackagecontainer: this.grouppackagecontainer};

        var res_groupBrandnameCategoryProductNameJpData = tsvParse.tsvParse("brandname	category_name	productname_jp\n" + skugroupBrandnameCategoryProductNameJpData);
            res_groupBrandnameCategoryProductNameJpData.forEach(x => {
                if (!(x.brandname in this.groupBrandnameCategory))
                {
                    this.groupBrandnameCategory[x.brandname] = [];
                    this.groupBrandnameCategoryProductNameJp[x.brandname] = {};
                }

                if (!this.groupBrandnameCategory[x.brandname].includes(x.category_name))
                {
                    this.groupBrandnameCategory[x.brandname].push(x.category_name);
                }

                if (!(x.category_name in this.groupBrandnameCategoryProductNameJp[x.brandname]))
                {
                    this.groupBrandnameCategoryProductNameJp[x.brandname][x.category_name] = [];                                
                }

                if (!this.groupBrandnameCategoryProductNameJp[x.brandname][x.category_name].includes(x.productname_jp))
                {
                    this.groupBrandnameCategoryProductNameJp[x.brandname][x.category_name].push(x.productname_jp);
                }
                    
                if (!this.brandnameList.includes(x.brandname))
                {
                    this.brandnameList.push(x.brandname);
                }

                //binding data to group category product
                if (!(x.category_name in this.groupCategoryProduct))
                {
                    this.groupCategoryProduct[x.category_name] = [];
                }

                if (!this.groupCategoryProduct[x.category_name].includes(x.productname_jp))
                {
                    this.groupCategoryProduct[x.category_name].push(x.productname_jp);
                }

                //push data to productname_jpList
                if (!this.productname_jpList.includes(x.productname_jp))
                {
                    this.productname_jpList.push(x.productname_jp);
                }               
            })

        this.tempSkuDataInit["groupBrandnameCategoryProductNameJpData"] = {groupBrandnameCategoryProductNameJp: this.groupBrandnameCategoryProductNameJp, groupBrandnameCategory: this.groupBrandnameCategory, brandnameList: this.brandnameList, groupCategoryProduct: this.groupCategoryProduct, productname_jpList: this.productname_jpList};

        var res_salesAreaTosalesDistrict = tsvParse.tsvParse("area_name	district_name\n" + skusalesAreaTosalesDistrictData);
            res_salesAreaTosalesDistrict.forEach(x => {
                if (!(x.area_name in this.sales_area_to_sales_district))
                {                         
                    this.sales_area_to_sales_district[x.area_name] = [];
                }

                if (!this.sales_area_to_sales_district[x.area_name].includes(x.district_name))
                {
                    this.sales_area_to_sales_district[x.area_name].push(x.district_name);        
                }   
            })

        this.tempSkuDataInit["salesAreaTosalesDistrictData"] = {sales_area_to_sales_district: this.sales_area_to_sales_district};

        var res_janCode = tsvParse.tsvParse("janCode\n" + skujanCodeData);
            res_janCode.forEach(x => {
                if (!this.jancodeList.includes(x.janCode))
                {
                    this.jancodeList.push(x.janCode);
                }
            })

        this.tempSkuDataInit["janCodeData"] = this.jancodeList;

        this.tempCustomerDataInit["areaData"] = tsvParse.tsvParse("cd	name\n" + customerareaData);
        this.tempCustomerDataInit["districtData"] = tsvParse.tsvParse("cd	name\n" + customerdistrictData);
        this.tempCustomerDataInit["channelLevel1Data"] = tsvParse.tsvParse("cd	name\n" + customerchannelLevel1Data);
        this.tempCustomerDataInit["channelLevel2Data"] = tsvParse.tsvParse("cd	name\n" + customerchannelLevel2Data);
        this.tempCustomerDataInit["channelLevel3Data"] = tsvParse.tsvParse("cd	name\n" + customerchannelLevel3Data);
        this.tempCustomerDataInit["accountLevel2Data"] = tsvParse.tsvParse("cd	name\n" + customeraccountLevel2Data);
        this.tempCustomerDataInit["accountLevel3Data"] = tsvParse.tsvParse("cd	name\n" + customeraccountLevel3Data);
        this.tempCustomerDataInit["categoryData"] = tsvParse.tsvParse("name\n" + customercategoryData);
        var res_customersalesAreaTosalesDistrict = tsvParse.tsvParse("area_name	district_name\n" + customersalesAreaTosalesDistrictData);
        res_customersalesAreaTosalesDistrict.forEach(x => {
            if (!(x.area_name in this.sales_area_to_sales_district))
            {                         
                this.sales_area_to_sales_district[x.area_name] = [];
            }

            if (!this.sales_area_to_sales_district[x.area_name].includes(x.district_name))
            {
                this.sales_area_to_sales_district[x.area_name].push(x.district_name);        
            }    
        })

        this.tempCustomerDataInit["salesAreaTosalesDistrictData"] = {sales_area_to_sales_district: this.sales_area_to_sales_district};

        var res_channelLevel1toLevel2toLevel3Data = tsvParse.tsvParse("channelLevel1	channelLevel2	channelLevel3\n" + customerchannelLevel1toLevel2toLevel3Data);
                    res_channelLevel1toLevel2toLevel3Data.forEach(x => {
                        if (!(x.channelLevel1 in this.groupchannelLeve1toLevel2toLevel3))
                        {
                          this.groupchannelLeve1toLevel2toLevel3[x.channelLevel1] = {};                                          
                          this.groupchannelLeve1toLevel2toLevel3[x.channelLevel1]['channelLevel2'] = [];
                          this.groupchannelLeve1toLevel2toLevel3[x.channelLevel1]['channelLevel3'] = [];
                        }

                        if (!this.groupchannelLeve1toLevel2toLevel3[x.channelLevel1]['channelLevel2'].includes(x.channelLevel2))
                        {
                          this.groupchannelLeve1toLevel2toLevel3[x.channelLevel1]['channelLevel2'].push(x.channelLevel2);
                        }

                        if (!this.groupchannelLeve1toLevel2toLevel3[x.channelLevel1]['channelLevel3'].includes(x.channelLevel3))
                        {
                          this.groupchannelLeve1toLevel2toLevel3[x.channelLevel1]['channelLevel3'].push(x.channelLevel3);
                        }

                        if (!this.channelLevel1List.includes(x.channelLevel1))
                        {
                          this.channelLevel1List.push(x.channelLevel1);
                        }

                        if (!this.channelLevel2List.includes(x.channelLevel2))
                        {
                          this.channelLevel2List.push(x.channelLevel2);
                        }

                        if (!this.channelLevel3List.includes(x.channelLevel3))
                        {
                          this.channelLevel3List.push(x.channelLevel3);
                        }


                        if (!(x.channelLevel2 in this.groupchannelLevel2toLevel3))
                        {
                            this.groupchannelLevel2toLevel3[x.channelLevel2] = [];
                        }

                        if (!this.groupchannelLevel2toLevel3[x.channelLevel2].includes(x.channelLevel3))
                        {
                            this.groupchannelLevel2toLevel3[x.channelLevel2].push(x.channelLevel3);
                        }
                        
                        this.tempCustomerDataInit["channelLevel1toLevel2toLevel3Data"] = {channelLevel1List: this.channelLevel1List, channelLevel2List: this.channelLevel2List, channelLevel3List: this.channelLevel3List, groupchannelLevel2toLevel3: this.groupchannelLevel2toLevel3, groupchannelLeve1toLevel2toLevel3: this.groupchannelLeve1toLevel2toLevel3 };                        
                    })

        this.skuDataInit = this.tempSkuDataInit;
        this.customerDataInit = this.tempCustomerDataInit;
        this.bindingTableData({pageNumber: 1, isSearch: true});
        this.bindingCustomerTableData({pageNumber: 1, isSearch: true}); 
        });
    }
    catch(err) 
    {
        console.log(err);
        this.router.navigate(['/sku-customer/error']); 
    } 
    }

    public bindingTableData(conditionObj){
        try
        {            
            this.skuConditon = "";
            const today = this.selectedDate.year + '-' + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month
            + '-' + (this.selectedDate.day > 9 ? '' : '0') + this.selectedDate.day;
            let startDt = today;
            var querycolName = "";
            var orderby = "";
            var skuQuery = "";
            
            if(this.aggregateBy === 'mtd'){
                startDt = this.selectedDate.year + '-' + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month + '-' + '01';
            }
            if(this.aggregateBy === 'ytd'){
                startDt = this.selectedDate.year + '-01-01';
            }

            if (conditionObj.condition || conditionObj.condition === '') {
                this.skuConditon = encodeURIComponent(conditionObj.condition);
            }

            if (conditionObj.isSearch) {
                if (conditionObj.displayColumns != null && conditionObj.displayColumns.length > 0)
                {
                    let aggregatedcolumns = conditionObj.displayColumns.join(',');
                    let skuCountDataGroupByColumns = "?code=" + SKUQueries.countDataGroupByColumns + 
                    "&date=" + today+ 
                    "&aggregateBy=" + this.aggregateBy +
                    "&aggregatedcolumns=" + aggregatedcolumns + 
                    "&condition="+ this.skuConditon;
                    this.bindingSkuDataCount(environment.snowflakeProxy + skuCountDataGroupByColumns);
                }
                else
                {
                    this.SKUdisplayColumns = [];
                    const skuCountData = "?code=" + SKUQueries.countData + 
                    "&date=" + today+ 
                    "&aggregateBy=" + this.aggregateBy + 
                    "&condition="+ this.skuConditon;
                    this.bindingSkuDataCount(environment.snowflakeProxy + skuCountData);
                }
            }

            if (conditionObj.colName)
            {
                if (conditionObj.colName === "bapc_ty")
                {
                    querycolName = "SUM_BAPC_TY";
                }     
                else if (conditionObj.colName === "bapc_ly")
                {
                    querycolName = "SUM_BAPC_LY";
                }
                else if (conditionObj.colName === "bapc_per_ly")
                {
                    querycolName = "BAPC % vs LY";
                }    
                else if (conditionObj.colName === "nsr_ty")
                {
                    querycolName = "SUM_NSR_TY";
                }   
                else if (conditionObj.colName === "nsr_ly")
                {
                    querycolName = "SUM_NSR_LY";
                }       
                else if (conditionObj.colName === "nsr_per_ly")
                {
                    querycolName = "NSR % vs LY";
                }     
                else if (conditionObj.colName === "gp_ty")
                {
                    querycolName = "SUM_GP_TY";
                }  
                else if (conditionObj.colName === "gp_ly")
                {
                    querycolName = "SUM_GP_LY";
                }  
                else if (conditionObj.colName === "gp_per_ly")
                {
                    querycolName = "GP % vs LY";
                } 

                this.SKUsortbyColumn = conditionObj.colName;

                if (conditionObj.orderby)
                {
                    orderby = conditionObj.orderby;
                    this.SKUorderbyColumn = orderby;
                }
            }
            else
            {
                querycolName = "SUM_NSR_TY";
                orderby = "DESC";
                this.SKUsortbyColumn = "nsr_ty";
                this.SKUorderbyColumn = "DESC";
            }
            
            const endRow = conditionObj.pageNumber * 100;
            const startRow = endRow - 99;

            if (conditionObj.displayColumns != null && conditionObj.displayColumns.length > 0)
            {
                let aggregatedcolumns = conditionObj.displayColumns.join(',');
                skuQuery = "?code=" + SKUQueries.allDataGroupByColumns + 
                "&date=" + today+ 
                "&aggregateBy=" + this.aggregateBy + 
                "&startRow="+ startRow + 
                "&endRow="+ endRow + 
                "&condition="+ this.skuConditon + 
                "&aggregatedcolumns=" + aggregatedcolumns + 
                "&querycolName=" + querycolName + 
                "&orderby=" + orderby;
                this.bindingSkuDataGroupBySelectedColumns(environment.snowflakeProxy + skuQuery, conditionObj.displayColumns);
            }
            else
            {
                this.SKUdisplayColumns = [];
                skuQuery = "?code=" + SKUQueries.allData + 
                "&date=" + today+ 
                "&aggregateBy=" + this.aggregateBy + 
                "&startRow="+ startRow + 
                "&endRow="+ endRow + 
                "&condition="+ this.skuConditon + 
                "&querycolName=" + querycolName + 
                "&orderby=" + orderby;
                this.bindingSkuData(environment.snowflakeProxy + skuQuery);
            }
        }
        catch(err) 
            {
                console.log(err);
                this.router.navigate(['/sku-customer/error']); 
            }        
    }


    bindingCustomerTableData(conditionObj)
    {
        try
        {
            const today = this.selectedDate.year + '-' + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month
            + '-' + (this.selectedDate.day > 9 ? '' : '0') + this.selectedDate.day;
          let startDt = today;
          this.customerConditon = "";
          var querycolName = "";
          var orderby = "";
          var customerQuery = "";
          
          if(this.aggregateBy === 'mtd'){
              startDt = this.selectedDate.year + '-' + (this.selectedDate.month > 9 ? '' : '0') + this.selectedDate.month + '-' + '01';
          }
          if(this.aggregateBy === 'ytd'){
              startDt = this.selectedDate.year + '-01-01';
          }
  
          if (conditionObj.condition || conditionObj.condition === '') {
              this.customerConditon = encodeURIComponent(conditionObj.condition);
          }
  
          if (conditionObj.isSearch) {
              if (conditionObj.displayColumns != null && conditionObj.displayColumns.length > 0)
              {
                  let aggregatedcolumns = conditionObj.displayColumns.join(',');
                  let customerCountDataGroupByColumns = "?code=" + CustomerQueries.countDataGroupByColumns + 
                  "&date=" + today+ 
                  "&aggregateBy=" + this.aggregateBy +
                  "&aggregatedcolumns=" + aggregatedcolumns + 
                  "&condition="+ this.customerConditon;
                  this.bindingCustomerDataCount(environment.snowflakeProxy + customerCountDataGroupByColumns);
              }
              else
              {
                  this.CustomerdisplayColumns = [];
                  const customerCountData = "?code=" + CustomerQueries.countData + 
                  "&date=" + today + 
                  "&aggregateBy=" + this.aggregateBy + 
                  "&condition="+ this.customerConditon;
                  this.bindingCustomerDataCount(environment.snowflakeProxy + customerCountData);
              }           
          }
  
          if (conditionObj.colName)
          {
              if (conditionObj.colName === "bapc_ty")
              {
                  querycolName = "SUM_BAPC_TY";
              }     
              else if (conditionObj.colName === "bapc_ly")
              {
                  querycolName = "SUM_BAPC_LY";
              }
              else if (conditionObj.colName === "bapc_per_ly")
              {
                  querycolName = "BAPC % vs LY";
              }    
              else if (conditionObj.colName === "nsr_ty")
              {
                  querycolName = "SUM_NSR_TY";
              }   
              else if (conditionObj.colName === "nsr_ly")
              {
                  querycolName = "SUM_NSR_LY";
              }       
              else if (conditionObj.colName === "nsr_per_ly")
              {
                  querycolName = "NSR % vs LY";
              }     
              else if (conditionObj.colName === "gp_ty")
              {
                  querycolName = "SUM_GP_TY";
              }  
              else if (conditionObj.colName === "gp_ly")
              {
                  querycolName = "SUM_GP_LY";
              }  
              else if (conditionObj.colName === "gp_per_ly")
              {
                  querycolName = "GP % vs LY";
              } 
  
              this.CustomersortbyColumn = conditionObj.colName;
  
              if (conditionObj.orderby)
              {
                  orderby = conditionObj.orderby;
                  this.CustomerorderbyColumn = orderby;
              }
          }
          else
          {
              querycolName = "SUM_NSR_TY";
              orderby = "DESC";
              this.CustomersortbyColumn = "nsr_ty";
              this.CustomerorderbyColumn = "DESC";
          }
  
          if (conditionObj.orderby)
          {
              orderby = conditionObj.orderby;
          }
          
          const endRow = conditionObj.pageNumber * 100;
          const startRow = endRow - 99;
  
  
          if (conditionObj.displayColumns != null && conditionObj.displayColumns.length > 0)
          {
              let aggregatedcolumns = conditionObj.displayColumns.join(',');
              customerQuery = "?code=" + CustomerQueries.allDataGroupByColumns + 
              "&date=" + today + 
              "&aggregateBy=" + this.aggregateBy + 
              "&startRow="+ startRow + 
              "&endRow="+ endRow + 
              "&condition="+ this.customerConditon + 
              "&aggregatedcolumns=" + aggregatedcolumns + 
              "&querycolName=" + querycolName + 
              "&orderby=" + orderby;
              this.bindingCustomerDataGroupBySelectedColumns(environment.snowflakeProxy + customerQuery, conditionObj.displayColumns);
          }
          else
          {
              this.CustomerdisplayColumns = [];
              customerQuery = "?code=" + CustomerQueries.allData + 
              "&date=" + today+ 
              "&aggregateBy=" + this.aggregateBy + 
              "&startRow="+ startRow + 
              "&endRow="+ endRow + 
              "&condition="+ this.customerConditon + 
              "&querycolName=" + querycolName + 
              "&orderby=" + orderby;
              this.bindingCustomerData(environment.snowflakeProxy + customerQuery);
          }
        }
        catch(error)
        {            
            console.log(error);
            this.router.navigate(['/sku-customer/error']);              
        }    
    }

    private async bindingComboboxData(queryUrl){
        const httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
        }
        let api = this.http.get<any>(queryUrl, httpOptions);
        return api.toPromise();
    }

    private bindingSkuDataCount(queryUrl){
        const httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
        }
        this.http.get<any>(queryUrl, httpOptions).subscribe(
            (response) => {
                this.skuCountData = +response;
            },
            (error) => {
                console.log(error);
                this.router.navigate(['/sku-customer/error']); 
            }
        );
    }

    private bindingCustomerDataCount(queryUrl){
        const httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
        }
        this.http.get<any>(queryUrl, httpOptions).subscribe(
            (response) => {
                this.customerCountData = +response;
            },
            (error) => {
                console.log(error);
                this.router.navigate(['/sku-customer/error']); 
            }
        );
    }

    bindingSkuData(queryUrl){
        document.getElementsByClassName('sku_table')[0].classList.add('not-show');
        document.getElementsByClassName('sku_spinner')[0].classList.remove('not-show');
        const httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
        }
        this.http.get<any>(queryUrl, httpOptions).subscribe(
            (response) => {
                
                const skuFullData = tsvParse.tsvParse("salesAreaCd	salesAreaNm	salesDistrictCd	salesDistrictNm	channelLev1Cd	channelLev1Nm	janCd	janNmEn	janNmJp	brand	category	container	pkgLev1	bapc_ty	bapc_ly	bapc_per_ly	nsr_ty	nsr_ly	nsr_per_ly	gp_ty	gp_ly	gp_per_ly\n" + response);
                this.skuData = skuFullData;
                document.getElementsByClassName('sku_table')[0].classList.remove('not-show');
                document.getElementsByClassName('sku_spinner')[0].classList.add('not-show');
            },
            (error) => {
                console.log(error);
                this.router.navigate(['/sku-customer/error']); 
            }
        );
    }

    
    bindingSkuDataGroupBySelectedColumns(queryUrl, columns){
        document.getElementsByClassName('sku_table')[0].classList.add('not-show');
        document.getElementsByClassName('sku_spinner')[0].classList.remove('not-show');
        const httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
        }

        this.SKUdisplayColumns = [];

        columns.forEach(c => {
            if (!this.SKUdisplayColumns.includes(c))
            {
                this.SKUdisplayColumns.push(c);
            }
        });

        this.http.get<any>(queryUrl, httpOptions).subscribe(
            (response) => {
                var cols = columns.join('	');
                const skuFullData = tsvParse.tsvParse(cols + "	" + "bapc_ty	bapc_ly	bapc_per_ly	nsr_ty	nsr_ly	nsr_per_ly	gp_ty	gp_ly	gp_per_ly\n" + response);                                
                this.skuData = skuFullData;
                document.getElementsByClassName('sku_table')[0].classList.remove('not-show');
                document.getElementsByClassName('sku_spinner')[0].classList.add('not-show');
            },
            (error) => {
                console.log(error);
                this.router.navigate(['/sku-customer/error']); 
            }
        );
    }

    bindingCustomerDataGroupBySelectedColumns(queryUrl, columns){
        document.getElementsByClassName('customer_table')[0].classList.add('not-show');
        document.getElementsByClassName('customer_spinner')[0].classList.remove('not-show');
        const httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
        }

        this.CustomerdisplayColumns = [];

        columns.forEach(c => {
            if (!this.CustomerdisplayColumns.includes(c))
            {
                this.CustomerdisplayColumns.push(c);
            }
        });

        this.http.get<any>(queryUrl, httpOptions).subscribe(
            (response) => {
                var cols = columns.join('	');
                const customerFullData = tsvParse.tsvParse(cols + "	" + "bapc_ty	bapc_ly	bapc_per_ly	nsr_ty	nsr_ly	nsr_per_ly	gp_ty	gp_ly	gp_per_ly\n" + response);                                
                this.customerData = customerFullData;
                document.getElementsByClassName('customer_table')[0].classList.remove('not-show');
                document.getElementsByClassName('customer_spinner')[0].classList.add('not-show');
            },
            (error) => {
                console.log(error);
                this.router.navigate(['/sku-customer/error']); 
            }
        );
    }

    bindingCustomerData(queryUrl){
        document.getElementsByClassName('customer_table')[0].classList.add('not-show');
        document.getElementsByClassName('customer_spinner')[0].classList.remove('not-show');
        let httpOptions: Object = {
            headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
            responseType: 'text'
        }
        this.http.get<any>(queryUrl, httpOptions).subscribe(
            (response) => {
                let customerFullData = tsvParse.tsvParse("salesAreaCd	salesAreaNm	salesDistrictCd	salesDistrictNm	categoryNm	channelLev1Cd	channelLev1Nm	channelLev2Cd	channelLev2Nm	channelLev3Cd	channelLev3Nm	accLevel2Cd	accLevel2Nm	accLevel3Cd	accLevel3Nm	bapc_ty	bapc_ly	bapc_per_ly	nsr_ty	nsr_ly	nsr_per_ly	gp_ty	gp_ly	gp_per_ly\n" + response);
                this.customerData = customerFullData;
                document.getElementsByClassName('customer_table')[0].classList.remove('not-show');
                document.getElementsByClassName('customer_spinner')[0].classList.add('not-show');
            },
            (error) => {
                console.log(error);
                this.router.navigate(['/sku-customer/error']); 
            }
        );
    }
}