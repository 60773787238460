import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CognitoIdentityProvider } from "@aws-sdk/client-cognito-identity-provider";
import { tokens } from 'src/app/model/constants';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import jwtDecode from "jwt-decode";
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType,AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import  { localStorageHelper } from 'src/app/helpers/localStorage';
declare var mixpanel;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  private readonly _destroying$ = new Subject<void>();
  @Input() message: any;



  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService, private formBuilder: FormBuilder, private router: Router) { }


  get registerFormControl() {
    return this.loginForm.controls;
  }


  ngOnInit() {
    localStorageHelper.clear();
  }
  
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


  public initLoginForm(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(2)]],
    });
  }

  public async login() {
    
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            localStorage.setItem("access_token",response.accessToken);
            this.router.navigate(['/profile']); 
          });
      } else {
        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            localStorage.setItem("access_token",response.accessToken);
            this.router.navigate(['/profile']);   
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
          this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } 
    }

    // if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //   if (this.msalGuardConfig.authRequest) {
    //     sessionStorage.clear();

    //     this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
    //       .subscribe((response: AuthenticationResult) => {
    //         this.authService.instance.setActiveAccount(response.account);
    //         localStorage.setItem("access_token_test",response.accessToken);
    //         this.router.navigate(['/dashboard']); 
    //       });
    //   } else {
    //     sessionStorage.clear();
    //     this.authService.loginPopup()
    //       .subscribe((response: AuthenticationResult) => {
    //         this.authService.instance.setActiveAccount(response.account);
    //         localStorage.setItem("access_token_test",response.accessToken);
    //         this.router.navigate(['/dashboard']);   
    //       });
    //   }
    // } else {
    //   if (this.msalGuardConfig.authRequest) {

        
    //     sessionStorage.clear();

    //     this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    //   } else {
    //     sessionStorage.clear()
    //     this.authService.loginRedirect();
    //   }
    // }



    //localStorage.clear();
    
    // const username = this.loginForm.get('username').value;
    // const password = this.loginForm.get('password').value;
    // // console.log(username, password);


    // const cognitoidentityserviceprovider = new CognitoIdentityProvider(
    //   {
    //     region: environment.region,
    //     credentials: {
    //       accessKeyId: environment.credentials.accessKeyId,
    //       secretAccessKey: environment.credentials.secretAccessKey
    //     }
    //   });


    // const params = {
    //   ClientId: environment.ClientId,
    //   AuthFlow: 'USER_PASSWORD_AUTH',
    //   AuthParameters: {
    //     USERNAME: username,
    //     PASSWORD: password
    //   }
    // };


    // cognitoidentityserviceprovider.initiateAuth(params, (err, data) => {
    //   if (err) {
    //     console.log("Failed to login: " + err);
    //     mixpanel.track('Signin Failed', {
    //       'username': username
    //     });
    //     AnalyticsHelper.openSweetAlert(err.toString().includes('User is disabled') ? 'Cannot sign in. Either your account has been locked, or your account is still in approval process!' : 'Incorrect username or password');
    //   }
    //   else {
    //     var sessionIdInfo = jwtDecode(data.AuthenticationResult.IdToken) as any;
    //     mixpanel.identify(sessionIdInfo.email);
    //     mixpanel.people.set({ "Username": sessionIdInfo["cognito:username"], "$name" : sessionIdInfo["cognito:username"], "$email" : sessionIdInfo.email });
    //     mixpanel.track('Signin Success', {
    //       'username': username
    //     });
    //     localStorage.setItem(tokens.accessToken, data.AuthenticationResult.AccessToken);
    //     setTimeout(() => {
    //       location.href = '/dashboard';
    //     }, 500);
    //   }
    // });


    }
  }
