import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, interval, Subject } from 'rxjs';
import { switchMap, catchError, takeWhile, tap, startWith, take } from 'rxjs/operators';
import { openWarningPopup, closeWarningPopup } from 'src/app/helpers/utils';
import { apiUrl } from 'src/app/model/constants';

@Injectable({
  providedIn: 'root'
})
export class CsvService {
  private apiUrl = apiUrl;
  private csvResponseSubject = new Subject<any>();

  constructor(private http: HttpClient) {}

  checkCondition(response: any): boolean {
    return response && (response.Status === 0 || response.Status === '0');
  }

  getCsvUrl(code: string, analyticsQuery: object, selectedGroupByLevel: string): Observable<any> {
    openWarningPopup();

    const bodyObj = {
      ...analyticsQuery,
      Code: code,
      CacheKey: `?code=${code}&IsExport=true&${analyticsQuery['CacheKey']}`,
      IsExport: true
    };

    const dateInJobClearCache = localStorage.getItem('se.dateinjobclearcache') || '';

    if (selectedGroupByLevel && selectedGroupByLevel.length > 0) {
       bodyObj['GroupByLevel'] = selectedGroupByLevel;
       bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&GroupByLevel=${selectedGroupByLevel}&LatestUpdate=${dateInJobClearCache}`;
    } else {
       bodyObj['CacheKey'] = `${bodyObj['CacheKey']}&LatestUpdate=${dateInJobClearCache}`;
    }

    const httpOptions: Object = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + localStorage.getItem('accessToken')),
      responseType: 'text'
    };

    interval(5000).pipe(
      startWith(0),
      switchMap(() => this.http.post(this.apiUrl, bodyObj, httpOptions).pipe(
        catchError(() => {
          closeWarningPopup();
          return of({ status: 'error' });
        })
      )),
      takeWhile((response: any) => {
        const data = JSON.parse(response);
        return this.checkCondition(data);
      }, true),
      tap((response: any) => {
        if (response) {
          const data = JSON.parse(response);
          const { Csv_Url } = data;

          if (Csv_Url && Csv_Url.length > 0) {
            closeWarningPopup();
            this.csvResponseSubject.next(data);
          }
        }
      }),
    ).subscribe();

    return this.csvResponseSubject.asObservable();
  }
}
