export class IndexDbHelper {
    static async Set(key: string, value: any){
        let db = window['db'] as IDBDatabase;
        if(db == null){
            return;
        }
        var tx = db.transaction("Report", "readwrite");
        var store = tx.objectStore("Report");
        await store.put({id: key, data: value});
    }

    static async Get(key: string){
        return new Promise (function(resolve) {
            let db = window['db'] as IDBDatabase;
            if(db == null){
                return resolve(null);
            }
            var tx = db.transaction("Report", "readwrite");
            var store = tx.objectStore("Report");
            let request = store.get(key);
            request.onsuccess = (event) => {
                return resolve(request.result?.data);
            };
        });
    }
}