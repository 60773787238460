<div class="row">
    <div class="col-lg-12 col-md-12">
        <div class="card mb-30">
            <div class="card-header d-flex justify-content-between align-items-center">
                <h3>Score Card​​</h3>
            </div>
            <div class="filter-section no-title mb-4">

                <!-- <div class="ng-section summary-section native">
                    <b>Aggregate By</b>
                    <select class="form-control filter-by-category form-basic option-sankey" (change)="onScoreCardAggregateChange($event.target.value)">
                        <option value="wtd">Weekly</option>
                        <option value="mtd">Monthly</option>
                        <option value="qtd">Quarterly</option>
                        <option value="ytd">Yearly</option>
                    </select>
                </div> -->
                <single-dropdown title="Aggregate By"
                    [items]="[{value: 'wtd', name: 'Week to Date'}, {value: 'mtd', name: 'Month to Date'}, {value: 'qtd', name: 'Quarter to Date'}, {value: 'ytd', name: 'Year to Date'}]"
                    [model]="aggregateBy"
                    (change)="onScoreCardAggregateChange($event)">
                </single-dropdown>

            </div>
            <div class="filter-section even-wrapper">
                <div class="ng-section summary-section">
                    <b>Makers</b>
                    <ng-select #ngFilterMakers class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="scoreCardSelectedMaker[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterMakers($event)"
                    (change)="onRemoveFilterMakers($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterMakers($event)"
                    [clearable]="scoreCardSelectedMaker.length > 1">
                        <ng-option *ngFor="let item of marketShareMakers" [value]="item.maker"><input type="checkbox" class="cb-multi-select"/> {{item.maker}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary" [disabled]="scoreCardSelectedMaker.length == 0" 
                            (click)="SaveOptionValueTemp(false);ngFilterMakers.close();onScoreCardMakerChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div class="ng-section summary-section">
                    <b>Channel</b>
                    <ng-select #ngFilterChannel class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="scoreCardSelectedChannel[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterScoreChannel($event)"
                    (change)="onRemoveFilterScoreChannel($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterScoreChannel($event)"
                    [clearable]="scoreCardSelectedChannel.length > 1">
                        <ng-option *ngFor="let item of otcChannels" [value]="item.channel">
                            <input type="checkbox" class="cb-multi-select"/> {{isSmddChild(item.channel) ? "&nbsp;&nbsp;&nbsp;" : ""}}{{item.channel}}</ng-option>
                            <ng-template ng-footer-tmp>
                                <button type="submit" class="btn btn-primary"
                                (click)="SaveOptionValueTemp(false);ngFilterChannel.close();onScoreCardChannelChange()">Aggregate</button>
                            </ng-template>                                
                            <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div  class="ng-section summary-section">
                    <b>Category</b>
                    <ng-select #ngFilterCategory class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="scoreCardSelectedCategory[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterScoreCategory($event)"
                    (change)="onRemoveFilterScoreCategory($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterScoreCategory($event)"
                    [clearable]="scoreCardSelectedCategory.length > 1">
                        <ng-option *ngFor="let item of categoriesFilter" [value]="item.category"><input type="checkbox" class="cb-multi-select"/> {{getCategoryDisplayName(item.category)}}</ng-option>
                            <ng-template ng-footer-tmp>
                                <button type="submit" class="btn btn-primary"
                                (click)="SaveOptionValueTemp(false);ngFilterCategory.close();onScoreCardCategoryChange()">Aggregate</button>
                            </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div  class="ng-section summary-section">
                    <b>Region</b>
                    <ng-select #ngFilterRegion class="form-control filter-by-category ng-region" [searchable]="false" [virtualScroll]="true" [(ngModel)]="scoreCardSelectedRegion[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterScoreRegion($event)"
                    (change)="onRemoveFilterScoreRegion($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterScoreRegion($event)"
                    [clearable]="scoreCardSelectedRegion.length > 1">
                        <ng-option *ngFor="let item of summaryRegions" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterRegion.close();onScoreCardRegionChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
                <div  class="ng-section summary-section package-type-section">
                    <b class="package-type">Package Type</b>
                    <ng-select #ngFilterPackageType class="form-control filter-by-category" [searchable]="false" [virtualScroll]="true" [(ngModel)]="scoreCardSelectedPackageType[0]" [multiple]="false" [closeOnSelect]="false"
                    (remove)="onRemoveFilterScorePackageType($event)"
                    (change)="onRemoveFilterScorePackageType($event);onChangeCheckBox($event)"
                    (open)="SaveOptionValueTemp(false);onChangeCheckBox($event)"
                    (blur)="SaveOptionValueTemp(true)"
                    (add)="onAddFilterScorePackageType($event)"
                    [clearable]="scoreCardSelectedPackageType.length > 1">
                        <ng-option *ngFor="let item of summaryPackageTypes" [value]="item"><input type="checkbox" class="cb-multi-select"/> {{item}}</ng-option>
                        <ng-template ng-footer-tmp>
                            <button type="submit" class="btn btn-primary"
                            (click)="SaveOptionValueTemp(false);ngFilterPackageType.close();onScoreCardPackageTypeChange()">Aggregate</button>
                        </ng-template>                                
                        <ng-template ng-multi-label-tmp let-items="items">
                            <ng-container *ngFor="let item of items; index as i">
                                <ng-container *ngIf="i==0">
                                    <div class="text-over-dropdown">{{item}}</div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="items.length > 1">
                                ...
                            </ng-container>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="card-body score-card-container top">
                <div class="col-12 col-sm-6 col-md-6 col-xxl-40">
                    <div class="stats-card-box value">
                        <div class="icon-box value-card">
                            <i class='bx bx-paper-plane'></i>
                        </div>
                        <span class="sub-title">Market Size Value (vs. LY)</span>
                        <span *ngIf="scoreCardData.vsLy == '-'" class='badge paddingl0 badge-red'><i class='bx'></i> {{scoreCardData.vsLy}}</span>
                        <span *ngIf="scoreCardData.vsLy != '-'" class='badge paddingl0'
                            [class]="scoreCardData.vsLy.indexOf('-') != -1 ? 'badge-red': 'badge-green'"><i class='bx'
                            [class]="scoreCardData.vsLy.indexOf('-') != -1 ? 'bx-down-arrow-alt': 'bx-up-arrow-alt'"></i> {{scoreCardData.vsLy}}% vs LY</span>
                    </div>
                </div>
        
                <div class="col-12 col-sm-6 col-md-6 col-xxl-40">
                    <div class="stats-card-box volume">
                        <div class="icon-box volume-card">
                            <i class='bx bx-bar-chart-square'></i>
                        </div>
                        <span class="sub-title">Market Size Volume (vs. LY)</span>
                        <span *ngIf="scoreCardData.vsLyVolume == '-'" class='badge paddingl0 badge-red'><i class='bx'></i> {{scoreCardData.vsLyVolume}}</span>
                        <span *ngIf="scoreCardData.vsLyVolume != '-'" class='badge paddingl0'
                            [class]="scoreCardData.vsLyVolume.indexOf('-') != -1 ? 'badge-red': 'badge-green'"><i class='bx'
                            [class]="scoreCardData.vsLyVolume.indexOf('-') != -1 ? 'bx-down-arrow-alt': 'bx-up-arrow-alt'"></i> {{scoreCardData.vsLyVolume}}% vs LY</span>
                    </div>
                </div>
            </div>
            <div class="card-body score-card-container bottom">
                <ng-container *ngFor="let maker of marketShareData; index as i;">
                    <div class="col-lg-20 col-12 col-sm-6 col-md-6"
                        [class]="i==marketShareData.length - 1 ? 'last-marketshare-col' : (i%3==2 ? 'last-marketshare-row-col' : '')">
                        <div class="table-wrapper">
                            <table>
                                <tr>
                                    <td class="card-logo" colspan="2">
                                        <ng-container *ngIf="maker.logo == ''">
                                            <span>{{maker.maker}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="maker.logo != ''">
                                            <img src="{{maker.logo}}" />
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr class="score-card-header">
                                    <td class="value-card">
                                        Market Size Value (vs. LY)
                                    </td>
                                    <td class="volume-card">
                                        Market Size Volume (vs. LY)
                                    </td>
                                </tr>
                                <tr class="score-card-body">
                                    <td>
                                        <span *ngIf="maker.channels[0].categories[0].vsLy == '-'" class="badge badge-red">
                                            <i class="bx"></i>
                                            {{maker.channels[0].categories[0].vsLy}}</span>
                                        <span *ngIf="maker.channels[0].categories[0].vsLy != '-'" class="badge"
                                            [class]="maker.channels[0].categories[0].vsLy.indexOf('-') != -1 ? 'badge-red': 'badge-green'">
                                            <i class="bx"
                                                [class]="maker.channels[0].categories[0].vsLy.indexOf('-') != -1 ? 'bx-down-arrow-alt': 'bx-up-arrow-alt'"></i>
                                            {{maker.channels[0].categories[0].vsLy}}%</span>
                                    </td>
                                    <td>
                                        <span *ngIf="maker.channels[0].categories[0].vsLyVolume == '-'" class="badge badge-red">
                                            <i class="bx"></i>
                                            {{maker.channels[0].categories[0].vsLyVolume}}</span>
                                        <span *ngIf="maker.channels[0].categories[0].vsLyVolume != '-'" class="badge"
                                            [class]="maker.channels[0].categories[0].vsLyVolume.indexOf('-') != -1 ? 'badge-red': 'badge-green'">
                                            <i class="bx"
                                                [class]="maker.channels[0].categories[0].vsLyVolume.indexOf('-') != -1 ? 'bx-down-arrow-alt': 'bx-up-arrow-alt'"></i>
                                            {{maker.channels[0].categories[0].vsLyVolume}}%</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="d-flex flex-two-ends hide-pdf-print">
                <!-- <comment-button title1="Score Card​​"
                [chartId]="chartId" [chatIdDependency]="[{selectedWeek, state:{aggregateBy: aggregateBy ?? 'wtd', scoreCardSelectedMaker, scoreCardSelectedChannel, scoreCardSelectedCategory, scoreCardSelectedRegion, scoreCardSelectedPackageType}}]"></comment-button> -->
            </div>
        </div>
    </div>
</div>