<div id="sales-by-customer">
  <div class="card mb-30">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h3>Sales By Customer​</h3>
    </div>
    <div class="filter-body">
      <!-- <b>Filter By</b> -->
      <div class="row seven-cols">
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Sales Area </b>
          <ng-select #ngFilterSalesArea class="filter-by" [searchable]="true" [virtualScroll]="true"
            [(ngModel)]="salesArea" [multiple]="true" [closeOnSelect]="false" (close)="onCloseSalesArea()"
            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterSalesArea($event)"
            (change)="onRemoveFilterSalesArea($event);onChangeCheckBox($event)" (open)="setSalesArea();onChangeCheckBox($event)"
            (add)="onAddFilterSalesArea($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of salesAreaList" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="salesArea.length == 0"
                (click)="SalesAreaAggregate();ngFilterSalesArea.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Sales District </b>
          <ng-select #ngFilterSalesDistrict class="filter-by" [searchable]="true" [virtualScroll]="true"
            [(ngModel)]="salesDistrict" [multiple]="true" [closeOnSelect]="false" (close)="onCloseSalesDistrict()"
            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterSalesDistrict($event);onChangeCheckBox($event)"
             (open)="setSalesDistrict();onChangeCheckBox($event)"
            (add)="onAddFilterSalesDistrict($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of salesDistrictBinding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="salesDistrict.length == 0"
                (click)="SalesDistrictAggregate();ngFilterSalesDistrict.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Category </b>
          <ng-select #ngFilterCategory class="filter-by" [searchable]="true" [loading]="loadingCategory" [virtualScroll]="true"
            (scroll)="onChangeCheckBox($event)" (close)="onCloseCategories()"
            (search)="searchCategories($event)" (scrollToEnd)="fetchMoreCategories(ngFilterCategory.searchTerm)"
            [(ngModel)]="category" [multiple]="true" [closeOnSelect]="false"
            [clearable]="false" (remove)="onRemoveFilterCategory($event);onChangeCheckBox($event)"
            (open)="setCategory();onChangeCheckBox($event)" (add)="onAddFilterCategory($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of selectedCategory" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of categoryBinding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="category.length == 0"
                (click)="CategoryAggregate();ngFilterCategory.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Channel Level 1 </b>
          <ng-select #ngFilterchannelLev1Code class="filter-by" [searchable]="true" [virtualScroll]="true"
            [(ngModel)]="channelLev1" [multiple]="true" [closeOnSelect]="false" (close)="onCloseChannelLevel1()"
            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterchannelLev1($event);onChangeCheckBox($event)"
            (open)="setChannelLevel1();onChangeCheckBox($event)"
            (add)="onAddFilterchannelLev1($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of channelLevel1List" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="channelLev1.length == 0"
                (click)="channelLev1Aggregate();ngFilterchannelLev1Code.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Channel Level 2 </b>
          <ng-select #ngFilterchannelLev2Code class="filter-by" [searchable]="true" [virtualScroll]="true"            
            [(ngModel)]="channelLev2" [multiple]="true" [closeOnSelect]="false" (close)="onCloseChannelLevel2()"
            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterchannelLev2($event);onChangeCheckBox($event)"
            (open)="setChannelLevel2();onChangeCheckBox($event)"
            (add)="onAddFilterchannelLev2($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of channelLev2Binding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="channelLev2.length == 0"
                (click)="channelLev2Aggregate();ngFilterchannelLev2Code.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Channel Level 3 </b>
          <ng-select #ngFilterchannelLev3Code class="filter-by" [searchable]="true" [virtualScroll]="true"
            [(ngModel)]="channelLev3" [multiple]="true" [closeOnSelect]="false"  (close)="onCloseChannelLevel3()"
            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterchannelLev3($event);onChangeCheckBox($event)"
             (open)="setChannelLevel3();onChangeCheckBox($event)"
            (add)="onAddFilterchannelLev3($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of channelLev3Binding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="channelLev3.length == 0"
                (click)="channelLev3Aggregate();ngFilterchannelLev3Code.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Account Level 2 </b>
          <ng-select #ngFilterAccountLev2Name class="filter-by" [loading]="loadingAccountLevel2" [searchable]="true" [virtualScroll]="true"
            (search)="searchAccountLevel2($event)" (close)="onCloseAccountLevel2()"
            [(ngModel)]="accountLevel2" [multiple]="true" [closeOnSelect]="false"
            (scroll)="onChangeCheckBox($event)" [clearable]="false" (remove)="onRemoveFilterAccountLev2($event);onChangeCheckBox($event)"
            (open)="setAccountLevel2();onChangeCheckBox($event)" (add)="onAddFilterAccountLev2($event);onChangeCheckBox($event)"
            (scrollToEnd)="fetchMoreAccountLevel2(ngFilterAccountLev2Name.searchTerm)">
            <ng-option *ngFor="let item of selectedAccountLevel2" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of accountLevel2Binding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="accountLevel2.length == 0"
                (click)="AccountLevel2Aggregate();ngFilterAccountLev2Name.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Account Level 3</b>
          <ng-select #ngFilterAccountLev3Name class="filter-by" [loading]="loadingAccountLevel3" [searchable]="true" [virtualScroll]="true"
            (search)="searchAccountLevel3($event)" (close)="onCloseAccountLevel3()" (scroll)="onChangeCheckBox($event)"
            [(ngModel)]="accountLevel3" [multiple]="true" [closeOnSelect]="false"
            [clearable]="false" (remove)="onRemoveFilterAccountLev3($event);onChangeCheckBox($event)"
            (open)="setAccountLevel3();onChangeCheckBox($event)" (add)="onAddFilterAccountLev3($event);onChangeCheckBox($event)"
            (scrollToEnd)="fetchMoreAccountLevel3(ngFilterAccountLev3Name.searchTerm)">
            <ng-option *ngFor="let item of selectedAccountLevel3" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-option *ngFor="let item of accountLevel3Binding" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="accountLevel3.length == 0"
                (click)="AccountLevel3Aggregate();ngFilterAccountLev3Name.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="row seven-cols">
        <div class="col-lg-1 col-md-6 filter-col">
          <b>Group By</b>
          <ng-select #ngFiltergroupByColumns class="filter-by" [searchable]="true" [virtualScroll]="true" (scroll)="onChangeCheckBox($event)"
            [(ngModel)]="groupByColumns" [multiple]="true" [closeOnSelect]="false" (close)="onClosegroupByColumns();onChangeCheckBox($event)"
            [clearable]="false" (remove)="onRemovegroupByColumns($event);onChangeCheckBox($event)"
            (open)="setgroupByColumns();onChangeCheckBox($event)" (add)="onAddgroupByColumns($event);onChangeCheckBox($event)">
            <ng-option *ngFor="let item of columnList" [value]="item">
              <input type="checkbox" class="cb-multi-select"/> {{item.name}}
            </ng-option>
            <ng-template ng-footer-tmp>
              <button type="submit" class="btn btn-primary" [disabled]="groupByColumns.length == 0"
                (click)="groupByColumnsAggregate();ngFiltergroupByColumns.close();">Aggregate</button>
            </ng-template>
            <ng-template ng-multi-label-tmp let-items="items">
              <ng-container *ngFor="let item of items; index as i">
                <ng-container *ngIf="i==0">
                  <div class="text-over-dropdown">{{item.name}}</div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="items.length > 1">
                <span class="more-items">...</span>
              </ng-container>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-lg-1 col-md-6 filter-col">
          <button type="submit" class="btn btn-primary mt-20"
                  (click)="searchCustomerData()">Search</button>
        </div>
      </div>
    </div>
    <div class="text-center spinner-wrapper customer_spinner">
      <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="card-body mt-3 customer_table">
      <ng-container>
        <div class="d-flex flex-row justify-content-between">
            <button (click)="htmlTableToExcel('xlsx')" class="hover:underline transition duration-150 header-menu-title excel-download-title" style="color: green">Excel抽出</button>
            <div class="d-flex flex-row justify-content-between">
              <pagination-controls id="customerPage" (pageChange)="handlePageChange($event)"></pagination-controls>
              <label class="font-bold" style="margin-left: 50px; transform: translate(10%, 10%)" for="fname">Go to Page: </label>  
              <input  type="number" id="gotopagecustomer" style="width: 50px; margin-left: 10px; transform: translate(10%, -15%)" class="whitespace-nowrap px-2 py-1 border-l border-gray-500 top-0 left-0 font-bold border-t border-b border-r" type="text">
              <i (click)="GotoPageEvent();" style="cursor: pointer; transform: translate(20%, -10%);" class='bx bx-md bxs-right-arrow-square'></i>
            </div>
        </div>
      </ng-container>
      <br>
      <div class="sales-by-customer-area">
        <table class="w-full rounded shadow relative" id="customerHeader">
          <thead class="sticky top-0 left-0 text-black bg-gray-200 font-normal z-20">
            <tr>
              <th colspan="16" class="text-center border-l border-gray-500 bg-red-100 text-lg top-0 left-0 font-bold" *ngIf="aggregateByDisplay == 'MTD';">
                {{aggregateByDisplay}}
              </th>         
              <th colspan="16" class="text-center border-l border-gray-500 bg-blue-100 text-lg top-0 left-0 font-bold" *ngIf="aggregateByDisplay == 'Daily';">
                {{aggregateByDisplay}}
              </th>
              <th colspan="16" class="text-center border-l border-gray-500 bg-gray-blue-100 text-lg top-0 left-0 font-bold" *ngIf="aggregateByDisplay == 'YTD';">
                {{aggregateByDisplay}}
              </th>
            </tr>
            </thead>
        </table>
      </div>
      <div class="sales-by-customer-area">
        <table *ngIf="CustomerdisplayColumns && CustomerdisplayColumns.length > 0; else show_all" class="w-full rounded shadow relative" id="customerId">
          <thead class="sticky top-0 left-0 text-black bg-gray-200 font-normal z-20">
          <tr class="text-xs font-normal text-center border-b border-gray-500">

            <ng-container *ngFor="let columnName of CustomerMappingdisplayColumns">
              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">{{columnName}}</th>
            </ng-container>

              <th style="cursor: pointer;" (click)="sort('bapc_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC TY </div> <div style="display: inline-block;"><i id="bapc_tyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_tyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div> </th>
              <th style="cursor: pointer;" (click)="sort('bapc_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"><div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC LY </div> <div style="display: inline-block;"><i id="bapc_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>             
              <th style="cursor: pointer;" (click)="sort('bapc_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC % vs LY </div> <div style="display: inline-block;"><i id="bapc_per_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_per_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
              <th style="cursor: pointer;" (click)="sort('nsr_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR TY </div> <div style="display: inline-block;"><i id="nsr_tyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_tyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
              <th style="cursor: pointer;" (click)="sort('nsr_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR LY </div> <div style="display: inline-block;"><i id="nsr_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
              <th style="cursor: pointer;" (click)="sort('nsr_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR % vs LY </div> <div style="display: inline-block;"><i id="nsr_per_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_per_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
              <th style="cursor: pointer;" (click)="sort('gp_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> GP TY </div> <div style="display: inline-block;"><i id="gp_tyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_tyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
              <th style="cursor: pointer;" (click)="sort('gp_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> GP LY </div> <div style="display: inline-block;"><i id="gp_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
              <th style="cursor: pointer;" (click)="sort('gp_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"><div style="display: inline-block; transform: translate(-10%, -40%);"> GP % vs LY </div> <div style="display: inline-block;"><i id="gp_per_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_per_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>

          </tr>
          </thead>
          <tbody class="text-xs">
            <ng-container *ngIf="!displayData || displayData.length === 0">
              <ng-container *ngFor="let skuCustomer of displayData | paginate: { id:'customerPage', itemsPerPage: pagination.sizeOnPage, currentPage: 1, totalItems: 0 }">
              </ng-container>
              <tr>
                <td class="px-2 py-1 border-l border-gray-500 whitespace-nowrap border-r" colspan="23">No items founds</td>
              </tr>
            </ng-container>
            <ng-container *ngIf="displayData && displayData.length > 0">
              <ng-container *ngFor="let skuCustomer of displayData | paginate: { id:'customerPage', itemsPerPage: pagination.sizeOnPage, currentPage: pagination.current, totalItems: pagination.totalItem }">
                <tr>  
                  
                  <ng-container *ngFor="let columnName of CustomerdisplayColumns">
                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer[columnName]}}</td>
                  </ng-container>

                  <td *ngIf="formatNumber(skuCustomer['bapc_ty']) != 'NaN'; else bapc_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['bapc_ty'])}}</td>
                  <ng-template #bapc_ty_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                  </ng-template>  


                  <td *ngIf="formatNumber(skuCustomer['bapc_ly']) != 'NaN'; else bapc_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['bapc_ly'])}}</td>
                  <ng-template #bapc_ly_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                  </ng-template>    
                  
                  
                  <td *ngIf="formatNumber(skuCustomer['bapc_per_ly']) != 'NaN'; else bapc_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['bapc_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['bapc_per_ly'])}}</td>
                  <ng-template #bapc_per_ly_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['bapc_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                  </ng-template>   


                  <td *ngIf="formatNumber(skuCustomer['nsr_ty']) != 'NaN'; else nsr_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['nsr_ty'])}}</td>
                  <ng-template #nsr_ty_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                  </ng-template> 


                  <td *ngIf="formatNumber(skuCustomer['nsr_ly']) != 'NaN'; else nsr_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['nsr_ly'])}}</td>
                  <ng-template #nsr_ly_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                  </ng-template> 

                  <td *ngIf="formatNumber(skuCustomer['nsr_per_ly']) != 'NaN'; else nsr_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['nsr_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['nsr_per_ly'])}}</td>
                  <ng-template #nsr_per_ly_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['nsr_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                  </ng-template> 

                  <td *ngIf="formatNumber(skuCustomer['gp_ty']) != 'NaN'; else gp_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['gp_ty'])}}</td>
                  <ng-template #gp_ty_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                  </ng-template> 


                  <td *ngIf="formatNumber(skuCustomer['gp_ly']) != 'NaN'; else gp_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['gp_ly'])}}</td>
                  <ng-template #gp_ly_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                  </ng-template> 


                  <td *ngIf="formatNumber(skuCustomer['gp_per_ly']) != 'NaN'; else gp_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 border-r {{skuCustomer['gp_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['gp_per_ly'])}}</td>
                  <ng-template #gp_per_ly_NA>
                    <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 border-r {{skuCustomer['gp_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                  </ng-template>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>

        <ng-template #show_all>
          <table class="w-full rounded shadow relative" id="customerId">
            <thead class="sticky top-0 left-0 text-black bg-gray-200 font-normal z-20">
            <tr class="text-xs font-normal text-center border-b border-gray-500">
              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Category</th>
              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Channel Level 2</th>
              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Channel Level 3</th>
              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Account Level 2</th>
              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Account Level 3</th>

                <th style="cursor: pointer;" (click)="sort('bapc_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC TY </div> <div style="display: inline-block;"><i id="bapc_tyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_tyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div> </th>
                <th style="cursor: pointer;" (click)="sort('bapc_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"><div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC LY </div> <div style="display: inline-block;"><i id="bapc_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>             
                <th style="cursor: pointer;" (click)="sort('bapc_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> BAPC % vs LY </div> <div style="display: inline-block;"><i id="bapc_per_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="bapc_per_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('nsr_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR TY </div> <div style="display: inline-block;"><i id="nsr_tyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_tyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('nsr_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR LY </div> <div style="display: inline-block;"><i id="nsr_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('nsr_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> NSR % vs LY </div> <div style="display: inline-block;"><i id="nsr_per_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="nsr_per_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('gp_ty')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> GP TY </div> <div style="display: inline-block;"><i id="gp_tyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_tyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('gp_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"> <div style="display: inline-block; transform: translate(-10%, -40%);"> GP LY </div> <div style="display: inline-block;"><i id="gp_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>
                <th style="cursor: pointer;" (click)="sort('gp_per_ly')" class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center"><div style="display: inline-block; transform: translate(-10%, -40%);"> GP % vs LY </div> <div style="display: inline-block;"><i id="gp_per_lyASCCTM" style=" display: block;" class='bx bxs-up-arrow' ></i> <i id="gp_per_lyDESCCTM" style="display: block;" class='bx bxs-down-arrow' ></i></div></th>

              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Sales Area</th>
              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Sales District</th>
              <th class="whitespace-nowrap border-l px-2 py-1 border-gray-500 top-0 left-0 font-bold border-t border-b border-r text-center">Channel Level 1</th>
            </tr>
            </thead>
            <tbody class="text-xs">
              <ng-container *ngIf="!displayData || displayData.length === 0">
                <ng-container *ngFor="let skuCustomer of displayData | paginate: { id:'customerPage', itemsPerPage: pagination.sizeOnPage, currentPage: 1, totalItems: 0 }">
                </ng-container>
                <tr>
                  <td class="px-2 py-1 border-l border-gray-500 whitespace-nowrap border-r" colspan="23">No items founds</td>
                </tr>
              </ng-container>
              <ng-container *ngIf="displayData && displayData.length > 0">
                <ng-container *ngFor="let skuCustomer of displayData | paginate: { id:'customerPage', itemsPerPage: pagination.sizeOnPage, currentPage: pagination.current, totalItems: pagination.totalItem }">
                  <tr>          
                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['categoryNm']}}</td>
                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['channelLev2Nm']}}</td>
                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['channelLev3Nm']}}</td>
                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['accLevel2Nm']}}</td>
                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['accLevel3Nm']}}</td>


                    <td *ngIf="formatNumber(skuCustomer['bapc_ty']) != 'NaN'; else bapc_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['bapc_ty'])}}</td>
                    <ng-template #bapc_ty_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template>  


                    <td *ngIf="formatNumber(skuCustomer['bapc_ly']) != 'NaN'; else bapc_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['bapc_ly'])}}</td>
                    <ng-template #bapc_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template>    
                    
                    
                    <td *ngIf="formatNumber(skuCustomer['bapc_per_ly']) != 'NaN'; else bapc_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['bapc_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['bapc_per_ly'])}}</td>
                    <ng-template #bapc_per_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['bapc_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                    </ng-template>   


                    <td *ngIf="formatNumber(skuCustomer['nsr_ty']) != 'NaN'; else nsr_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['nsr_ty'])}}</td>
                    <ng-template #nsr_ty_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template> 


                    <td *ngIf="formatNumber(skuCustomer['nsr_ly']) != 'NaN'; else nsr_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['nsr_ly'])}}</td>
                    <ng-template #nsr_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template> 

                    <td *ngIf="formatNumber(skuCustomer['nsr_per_ly']) != 'NaN'; else nsr_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['nsr_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['nsr_per_ly'])}}</td>
                    <ng-template #nsr_per_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 {{skuCustomer['nsr_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                    </ng-template> 

                    <td *ngIf="formatNumber(skuCustomer['gp_ty']) != 'NaN'; else gp_ty_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['gp_ty'])}}</td>
                    <ng-template #gp_ty_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template> 


                    <td *ngIf="formatNumber(skuCustomer['gp_ly']) != 'NaN'; else gp_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500">{{formatNumber(skuCustomer['gp_ly'])}}</td>
                    <ng-template #gp_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500">N/A</td>
                    </ng-template> 


                    <td *ngIf="formatNumber(skuCustomer['gp_per_ly']) != 'NaN'; else gp_per_ly_NA" class="text-right px-2 py-1 border-l border-b border-r border-gray-500 border-r {{skuCustomer['gp_per_ly'] > 0 ? '' : 'text-red-500'}}">{{formatNumber(skuCustomer['gp_per_ly'])}}</td>
                    <ng-template #gp_per_ly_NA>
                      <td class="text-right px-2 py-1 border-l border-b border-r border-gray-500 border-r {{skuCustomer['gp_per_ly'] > 0 ? '' : 'text-red-500'}}">N/A</td>
                    </ng-template>

                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['salesAreaNm']}}</td>
                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['salesDistrictNm']}}</td>                  
                    <td class="text-over px-2 py-1 border-l border-b border-r border-gray-500">{{skuCustomer['channelLev1Nm']}}</td>

                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </ng-template>
        </div>
    </div>
  </div>
</div>