export function DropdownValueToNgSelectFormat(input) {
    if (!input) return [{}]

    // TO-DO: use a proper hash function
    const hash = JSON.stringify(input[0]) + input.length + "start-week"

    if (window[hash]) { return window[hash] }
    window[hash] = []
    const output = window[hash];
    for (let i = 0; i < input.length; i++) {
        output.push({ value: input.length - i, name: input[i] })
    }
    // console.log(JSON.stringify(output))
    return output;
}

export function DropdownValueToNgSelectFormat2(input) {
    if (!input) return [{}]

    // TO-DO: use a proper hash function
    const hash = JSON.stringify(input[0]) + input.length + "week"

    if (window[hash]) { return window[hash] }
    window[hash] = []
    const output = window[hash];
    for (let i = 0; i < input.length; i++) {
        output.push({ value: input[i], name: input[i] })
    }
    // console.log(JSON.stringify(output))
    return output;
}