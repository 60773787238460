<table>
    <thead>
        <tr>
            <th *ngIf="by === 'category'">By<br />Category</th>
            <th *ngIf="by === 'package'">By<br />Package</th>
            <th>Imp.<br />Diff. LY</th>
            <th>
                <div><img src="/assets/img/ctg-pkg-table/image8.png" alt="">VM</div>
            </th>
            <th>
                <div><img src="/assets/img/ctg-pkg-table/image13.png" alt="">SM/DD</div>
            </th>
            <th>
                <div><img src="/assets/img/ctg-pkg-table/image11.png" alt="">CVS</div>
            </th>
            <th>
                <div><img src="/assets/img/ctg-pkg-table/image14.png" alt="">Retail/<br/>Food</div>
            </th>
            <th>
                <div><img src="/assets/img/ctg-pkg-table/image12.png" alt="">On-Line</div>
            </th>
            <th>
                <div><img src="/assets/img/ctg-pkg-table/image31.png" alt="">Total</div>
            </th>
        </tr>
    </thead>
    <tbody *ngIf="by === 'category'">
        <tr>
            <td>SSD</td>
            <td [innerHTML]="ssd?.[0]"></td>
            <td [innerHTML]="ssd?.[1]"></td>
            <td [innerHTML]="ssd?.[2]"></td>
            <td [innerHTML]="ssd?.[3]"></td>
            <td [innerHTML]="ssd?.[4]"></td>
            <td [innerHTML]="ssd?.[5]"></td>
            <td [innerHTML]="ssd?.[6]"></td>
        </tr>
        <tr>
            <td>Energy</td>
            <td [innerHTML]="energy?.[0]"></td>
            <td [innerHTML]="energy?.[1]"></td>
            <td [innerHTML]="energy?.[2]"></td>
            <td [innerHTML]="energy?.[3]"></td>
            <td [innerHTML]="energy?.[4]"></td>
            <td [innerHTML]="energy?.[5]"></td>
            <td [innerHTML]="energy?.[6]"></td>
        </tr>
        <tr>
            <td>Coffee</td>
            <td [innerHTML]="coffee?.[0]"></td>
            <td [innerHTML]="coffee?.[1]"></td>
            <td [innerHTML]="coffee?.[2]"></td>
            <td [innerHTML]="coffee?.[3]"></td>
            <td [innerHTML]="coffee?.[4]"></td>
            <td [innerHTML]="coffee?.[5]"></td>
            <td [innerHTML]="coffee?.[6]"></td>
        </tr>
        <tr>
            <td>Juice</td>
            <td [innerHTML]="juice?.[0]"></td>
            <td [innerHTML]="juice?.[1]"></td>
            <td [innerHTML]="juice?.[2]"></td>
            <td [innerHTML]="juice?.[3]"></td>
            <td [innerHTML]="juice?.[4]"></td>
            <td [innerHTML]="juice?.[5]"></td>
            <td [innerHTML]="juice?.[6]"></td>
        </tr>
        <tr>
            <td>Tea</td>
            <td [innerHTML]="tea?.[0]"></td>
            <td [innerHTML]="tea?.[1]"></td>
            <td [innerHTML]="tea?.[2]"></td>
            <td [innerHTML]="tea?.[3]"></td>
            <td [innerHTML]="tea?.[4]"></td>
            <td [innerHTML]="tea?.[5]"></td>
            <td [innerHTML]="tea?.[6]"></td>
        </tr>
        <tr>
            <td>Sports</td>
            <td [innerHTML]="sports?.[0]"></td>
            <td [innerHTML]="sports?.[1]"></td>
            <td [innerHTML]="sports?.[2]"></td>
            <td [innerHTML]="sports?.[3]"></td>
            <td [innerHTML]="sports?.[4]"></td>
            <td [innerHTML]="sports?.[5]"></td>
            <td [innerHTML]="sports?.[6]"></td>
        </tr>
        <tr>
            <td>Water</td>
            <td [innerHTML]="water?.[0]"></td>
            <td [innerHTML]="water?.[1]"></td>
            <td [innerHTML]="water?.[2]"></td>
            <td [innerHTML]="water?.[3]"></td>
            <td [innerHTML]="water?.[4]"></td>
            <td [innerHTML]="water?.[5]"></td>
            <td [innerHTML]="water?.[6]"></td>
        </tr>
        <tr>
            <td>CORE Biz</td>
            <td></td>
            <td [innerHTML]="corebiz?.[1]"></td>
            <td [innerHTML]="corebiz?.[2]"></td>
            <td [innerHTML]="corebiz?.[3]"></td>
            <td [innerHTML]="corebiz?.[4]"></td>
            <td [innerHTML]="corebiz?.[5]"></td>
            <td [innerHTML]="corebiz?.[6]"></td>
        </tr>
    </tbody>
    <tbody *ngIf="by === 'package'">
        <tr>
            <td>PET (SS)</td>
            <td [innerHTML]="petss?.[0]"></td>
            <td [innerHTML]="petss?.[1]"></td>
            <td [innerHTML]="petss?.[2]"></td>
            <td [innerHTML]="petss?.[3]"></td>
            <td [innerHTML]="petss?.[4]"></td>
            <td [innerHTML]="petss?.[5]"></td>
            <td [innerHTML]="petss?.[6]"></td>
        </tr>
        <tr>
            <td>PET (MS)</td>
            <td [innerHTML]="petms?.[0]"></td>
            <td [innerHTML]="petms?.[1]"></td>
            <td [innerHTML]="petms?.[2]"></td>
            <td [innerHTML]="petms?.[3]"></td>
            <td [innerHTML]="petms?.[4]"></td>
            <td [innerHTML]="petms?.[5]"></td>
            <td [innerHTML]="petms?.[6]"></td>
        </tr>
        <tr>
            <td>CAN</td>
            <td [innerHTML]="can?.[0]"></td>
            <td [innerHTML]="can?.[1]"></td>
            <td [innerHTML]="can?.[2]"></td>
            <td [innerHTML]="can?.[3]"></td>
            <td [innerHTML]="can?.[4]"></td>
            <td [innerHTML]="can?.[5]"></td>
            <td [innerHTML]="can?.[6]"></td>
        </tr>
        <tr>
            <td>Bottle CAN</td>
            <td [innerHTML]="bottlecan?.[0]"></td>
            <td [innerHTML]="bottlecan?.[1]"></td>
            <td [innerHTML]="bottlecan?.[2]"></td>
            <td [innerHTML]="bottlecan?.[3]"></td>
            <td [innerHTML]="bottlecan?.[4]"></td>
            <td [innerHTML]="bottlecan?.[5]"></td>
            <td [innerHTML]="bottlecan?.[6]"></td>
        </tr>
        <tr>
            <td>Syrup / Powder</td>
            <td [innerHTML]="syrup?.[0]"></td>
            <td [innerHTML]="syrup?.[1]"></td>
            <td [innerHTML]="syrup?.[2]"></td>
            <td [innerHTML]="syrup?.[3]"></td>
            <td [innerHTML]="syrup?.[4]"></td>
            <td [innerHTML]="syrup?.[5]"></td>
            <td [innerHTML]="syrup?.[6]"></td>
        </tr>
        <tr>
            <td>RTB</td>
            <td [innerHTML]="rtb?.[0]"></td>
            <td [innerHTML]="rtb?.[1]"></td>
            <td [innerHTML]="rtb?.[2]"></td>
            <td [innerHTML]="rtb?.[3]"></td>
            <td [innerHTML]="rtb?.[4]"></td>
            <td [innerHTML]="rtb?.[5]"></td>
            <td [innerHTML]="rtb?.[6]"></td>
        </tr>
        <tr>
            <td>Others</td>
            <td [innerHTML]="others?.[0]"></td>
            <td [innerHTML]="others?.[1]"></td>
            <td [innerHTML]="others?.[2]"></td>
            <td [innerHTML]="others?.[3]"></td>
            <td [innerHTML]="others?.[4]"></td>
            <td [innerHTML]="others?.[5]"></td>
            <td [innerHTML]="others?.[6]"></td>
        </tr>
        <tr>
            <td>CORE Biz</td>
            <td></td>
            <td [innerHTML]="corebiz?.[1]"></td>
            <td [innerHTML]="corebiz?.[2]"></td>
            <td [innerHTML]="corebiz?.[3]"></td>
            <td [innerHTML]="corebiz?.[4]"></td>
            <td [innerHTML]="corebiz?.[5]"></td>
            <td [innerHTML]="corebiz?.[6]"></td>
        </tr>
    </tbody>    
</table>