
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { IntageHelper } from 'src/app/components/pages/intage-vm/intage_helper';
function getLighterColor(hexColor) {
    // Remove the '#' symbol from the hexadecimal color
    const color = hexColor.replace('#', '');

    // Parse the hexadecimal color into its RGB components
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);

    // Calculate the lighter color by increasing each RGB component by 40%
    const percentage = 0.6;
    const lighterR = Math.min(Math.floor(r + (255 - r) * percentage), 255);
    const lighterG = Math.min(Math.floor(g + (255 - g) * percentage), 255);
    const lighterB = Math.min(Math.floor(b + (255 - b) * percentage), 255);

    // Convert the lighter RGB components back to a hexadecimal color
    const lighterHexColor = `#${[lighterR, lighterG, lighterB].map(c => c.toString(16).padStart(2, '0')).join('')}`;

    // Return the lighter color as a hexadecimal value
    return lighterHexColor;
}

export function getColorsWithMovingAverage({ colors, aggregateBy = "wtd", windowSizes }) {

    // only show if aggregation is at weekly 
    if (aggregateBy !== "wtd" || windowSizes.length === 0) {
        return colors;
    }

    const toReturn = [];

    for (let i = 0; i < colors.length; i++) {
        //lighter version of the same color for Moving Average
        for (let j = 0; j < windowSizes.length; j++) {
            toReturn.push(getLighterColor(colors[i]));
        }
        toReturn.push(colors[i]);
    }
    console.log(toReturn)
    return toReturn;
}

// 4 weeks moving average 
export function getSeriesWithMovingAverage({ series, aggregateBy = "wtd", windowSizes }) {

    // only show if aggregation is at weekly 
    if (aggregateBy !== "wtd" || windowSizes.length === 0) {
        return series;
    }

    const toReturn = [];

    for (let i = 0; i < series.length; i++) {
        const { name } = series[i];
        for (let j = 0; j < windowSizes.length; j++) {
            const windowSize = windowSizes[j];
            toReturn.push(calculateMovingAverage({ series: [series[i]], windowSize, name })[0]);
        }
        toReturn.push(series[i]);
    }    

    return toReturn;
}

function calculateMovingAverage({ series, windowSize = 4, name }) {

    const prefix = [];
    const movingAverages = [];

    for (let i = 0; i < windowSize - 1; i++) {
        prefix.push(null);
    }

    const data = series[0]["data"].map(num => parseFloat(num));
    const seriesClone = JSON.parse(JSON.stringify(series));

    // console.log(data);

    for (let i = windowSize - 1; i < data.length; i++) {
        const sum = parseFloat(data.slice(i - windowSize + 1, i + 1).reduce((a, b) => a + b).toFixed(1));
        const average = sum / windowSize;
        movingAverages.push(average);
    }

    seriesClone[0]["data"] = [...prefix, ...movingAverages.map(num => IntageHelper.formatIntageNumber(num))];
    seriesClone[0]["name"] = `${name} MAV`;

    // console.log(seriesClone[0]["data"]);

    return seriesClone;
}