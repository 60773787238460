import { Component, Input, OnInit } from '@angular/core';

const colorTable = {
  "A": "#7041ff",
  "B": "#2d81b3",
  "C": "#ff6d3f",
  "D": "#2ba94f",
  "E": "#d442a7",
  "F": "#488c89",
  "G": "#ff7433",
  "H": "#4e6eff",
  "I": "#ff7a4d",
  "J": "#2ba96c",
  "K": "#ff6e98",
  "L": "#5ac8ff",
  "M": "#ff5b3f",
  "N": "#2ba96b",
  "O": "#d442a7",
  "P": "#7684ff",
  "Q": "#ff526a",
  "R": "#2ba95a",
  "S": "#ff5b53",
  "T": "#6da3ff",
  "U": "#ff8d66",
  "V": "#2ba98d",
  "W": "#ff7a33",
  "X": "#7a7cff",
  "Y": "#ff8463",
  "Z": "#2ba984"
}




@Component({
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() email: string;
  firstLetter: string;
  color: string;

  constructor() { }

  ngOnInit() {
    this.firstLetter = this.email ? this.email[0].toLocaleUpperCase() : '?';
    this.color = colorTable[this.firstLetter] ?? '#dc143c';
  }

}
