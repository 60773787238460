export class IntageDashboardHelper {
  static GenerateModelData(key: string,
    data: any,
    channelType: string,
    shareVsSankeySelectedChannel: any,
    shareVsSankeySelectedCategory: any,
    shareVsSankeySelectedRegion: any,
    shareVsSankeySelectedPackageType: any,
    shareVsSankeySelectedMaker: any) {
    var channels = [...new Map(data.map(item => [item["channel"], item.channel])).values()];
    var regions = [...new Map(data.map(item => [item["region"], item.region])).values()];
    var categories = [...new Map(data.map(item => [item["category"], item.category])).values()];
    var packageTypes = [...new Map(data.map(item => [item["packageType"], item.packageType])).values()];
    var makers = [...new Map(data.map(item => [item["maker"], item.maker])).values()];
    var sankeyDataModel = [];
    if (shareVsSankeySelectedChannel.includes("SMDD")) {
      shareVsSankeySelectedChannel = shareVsSankeySelectedChannel.filter(x => x != "SMDD");
      shareVsSankeySelectedChannel = shareVsSankeySelectedChannel.concat(["SM", "Drug", "Discounter"]);
    }
    if (key == "channel") {
      channels.forEach(element => {
        if (shareVsSankeySelectedChannel.includes("All") || shareVsSankeySelectedChannel.includes(element)) {
          if (channelType == "1" && (element == "SM" || element == "Drug" || element == "Discounter")) {
            if (!sankeyDataModel.some(x => x.name == "SMDD")) {
              sankeyDataModel.push({
                name: "SMDD",
                nameForMap: "SMDD",
                ty: 0,
                subItems: shareVsSankeySelectedChannel.includes("All") ? ["SM", "Drug", "Discounter"] : [element],
              });
            } else {
              sankeyDataModel.find(x => x.name == "SMDD").subItems.push(element);
            }
          } else {
            sankeyDataModel.push(
              {
                name: element,
                nameForMap: element,
                ty: 0,
                subItems: []
              });
          }
        }
      });
    }
    else if (key == "category") {
      categories.forEach(element => {
        if (shareVsSankeySelectedCategory.includes("All") || shareVsSankeySelectedCategory.includes(element)) {
          sankeyDataModel.push(
            {
              name: element,
              nameForMap: element == "Other" ? element + " (CTG)" : element,
              ty: 0,
              subItems: []
            });
        }
      });
    }
    else if (key == "region") {
      regions.forEach(element => {
        if (shareVsSankeySelectedRegion.includes("All") || shareVsSankeySelectedRegion.includes(element)) {
          sankeyDataModel.push(
            {
              name: element,
              nameForMap: element,
              ty: 0,
              subItems: []
            });
        }
      });
    }
    else if (key == "packageType") {
      packageTypes.forEach(element => {
        if (shareVsSankeySelectedPackageType.includes("All") || shareVsSankeySelectedPackageType.includes(element)) {
          if (element != '') {
            sankeyDataModel.push(
              {
                name: element,
                nameForMap: element == "Other" ? element + " (PT)" : element,
                ty: 0,
                subItems: []
              });
          }
        }
      });
    }
    else if (key == "maker") {
      makers.forEach(element => {
        if (shareVsSankeySelectedMaker.includes("All") || shareVsSankeySelectedMaker.includes(element)) {
          sankeyDataModel.push(
            {
              name: element,
              nameForMap: element,
              ty: 0,
              subItems: []
            });
        }
      });
    }    
    return sankeyDataModel;
  }
  static filterOptionNotSelect(keys: any, data: any,
    shareVsSankeySelectedChannel: any,
    shareVsSankeySelectedCategory: any,
    shareVsSankeySelectedRegion: any,
    shareVsSankeySelectedPackageType: any,
    shareVsSankeySelectedMaker: any) {
    keys.forEach(key => {
      if (key == "channel") {
        if (!shareVsSankeySelectedChannel.includes("All")) {
          if (shareVsSankeySelectedChannel.includes("SMDD")) {
            shareVsSankeySelectedChannel = shareVsSankeySelectedChannel.concat(["SM", "Drug", "Discounter"])
          }
          data = data.filter(x => shareVsSankeySelectedChannel.includes(x.channel));
        }
      }
      else if (key == "category") {
        if (!shareVsSankeySelectedCategory.includes("All")) {
          data = data.filter(x => shareVsSankeySelectedCategory.includes(x.category));
        }
      }
      else if (key == "region") {
        if (!shareVsSankeySelectedRegion.includes("All")) {
          data = data.filter(x => shareVsSankeySelectedRegion.includes(x.region));
        }
      }
      else if (key == "packageType") {
        if (!shareVsSankeySelectedPackageType.includes("All")) {
          data = data.filter(x => shareVsSankeySelectedPackageType.includes(x.packageType));
        }
      }
      else if (key == "maker") {
        if (!shareVsSankeySelectedMaker.includes("All")) {
          data = data.filter(x => shareVsSankeySelectedMaker.includes(x.maker));
        }
      }
    });
    return data;
  }
} 