import { Component, Renderer2, OnChanges, Inject, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AnalyticsHelper } from 'src/app/helpers/analytics';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { IntageHelper } from 'src/app/components/pages/intage/intage_helper';
import html2canvas from 'src/html2canvas';
import { DropdownValueToNgSelectFormat } from 'src/app/helpers/dropdown';
import { getColorsWithMovingAverage, getSeriesWithMovingAverage } from 'src/app/helpers/movingAverage';
import { getChartStateFromUrl } from 'src/app/helpers/queryString';
import { Router } from '@angular/router';
@Component({
  selector: 'intage_value-share-timeline',
  templateUrl: './value-share-timeline.component.html',
  styleUrls: ['./value-share-timeline.component.scss']
})
export class IntageValueShareTimelineComponent implements OnChanges {
  @Output() ready = new EventEmitter<boolean>();
  @Input() selectedWeek: string;
  @Input() intage: any;
  @Input() intageMonth: any;
  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('scroll') scroller: ElementRef;
  categories: any;
  otcChannels: any;
  otcWeeks: any;
  otcStartWeeks: number;  
  categoryColors: any;
  categoriesFilter: any;
  makerLogo: any;
  shareDisplayType: string;
  marketShareTimelineData: any;
  marketShareChart: ApexCharts;
  marketShareHaveSingleSerie: boolean;
  marketShareHaveData: boolean;
  marketShareSingleSerie: string;
  marketShareSingleSerieColor: string;
  marketShareMakers: any;
  summaryRegions: any;
  summaryPackageTypes: any;
  shareTimelineSelectedChannel: any;
  shareTimelineSelectedCategory: any;
  shareTimelineSelectedRegion: any;
  shareTimelineSelectedPackageType: any;
  populated: boolean;
  series: any;
  aggregateBy: any;
  otcMonths: any;
  otcQuarters: any;
  otcYears: any;
  movingAverageWindowSizes: number[] = [4];
  chartId = "value-share-volume-share";

  constructor(public datepipe: DatePipe, private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document,
    private http: HttpClient, private router: Router) { }

  ngOnInit() {
    const chartState = getChartStateFromUrl(this.router, this.chartId);
    for (const key in chartState) {
      this[key] = chartState[key]
    }
  }
  
  ngOnChanges() {
      if(!this.populated){
        this.shareTimelineSelectedChannel = ['OTC Total'];
        this.shareTimelineSelectedCategory = ['Total'];
        this.shareTimelineSelectedRegion = ['CCBJI Territory'];
        this.shareTimelineSelectedPackageType = ['All'];
        this.categoryColors = IntageHelper.CategoryColors();
        this.shareDisplayType = 'value';
        this.makerLogo = IntageHelper.MakerLogos();
        this.marketShareMakers = [{maker:'All'}];
        this.makerLogo.forEach(x => {
          this.marketShareMakers.push({
            maker: x.maker,
            logo: x.logo,
            checked: x.checked,
            show: x.maker != 'Red Bull'
          });
        });
        this.populated = true;
      }
      if(this.intage != null && this.selectedWeek != null && this.intageMonth != null){
        let commonData = IntageHelper.PopulateCommonData(this.intage, this.selectedWeek);
        this.categories = commonData.categories;
        this.categoriesFilter = commonData.categoriesFilter;
        this.otcChannels = commonData.otcChannels;
        this.summaryPackageTypes = commonData.summaryPackageTypes;
        this.summaryRegions = commonData.summaryRegions;
        this.otcStartWeeks = 52;
        this.handleMarketShareTimeline();
        // Reset the start week when end date changed
      
        //this.renderMarketShareTimelineChart();
      }


  }
  onChangeCheckBox(event) {
    AnalyticsHelper.hanldeCheckBoxSelect(event);
  }
  SaveOptionValueTemp(rollBack: boolean) {
    if (!rollBack) {
      window["shareTimelineSelectedChannel"] = [...this.shareTimelineSelectedChannel];
      window["shareTimelineSelectedCategory"] = [...this.shareTimelineSelectedCategory];
      window["shareTimelineSelectedRegion"] = [...this.shareTimelineSelectedRegion];
      window["shareTimelineSelectedPackageType"] = [...this.shareTimelineSelectedPackageType];
    } else {
      if (window["shareTimelineSelectedChannel"] != null) { this.shareTimelineSelectedChannel = window["shareTimelineSelectedChannel"]; }
      if (window["shareTimelineSelectedCategory"] != null) { this.shareTimelineSelectedCategory = window["shareTimelineSelectedCategory"]; }
      if (window["shareTimelineSelectedRegion"] != null) { this.shareTimelineSelectedRegion = window["shareTimelineSelectedRegion"]; }
      if (window["shareTimelineSelectedPackageType"] != null) { this.shareTimelineSelectedPackageType = window["shareTimelineSelectedPackageType"]; }
    }
  }

  skipAllValue(makers: any): any {
    return makers.filter(e => e.maker != 'All' && e.show);
  }
  
  onRemoveFilterTimelineChannel($event) {
    if(this.shareTimelineSelectedChannel.length == 0){
      this.shareTimelineSelectedChannel = ['OTC Total'];
      this.SaveOptionValueTemp(false);
      this.onShareTimeLineChannelChange();
    }
    if ($event.value === 'OTC Total') {
      this.shareTimelineSelectedChannel = [];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event.value)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event.value);
      this.shareTimelineSelectedChannel = this.shareTimelineSelectedChannel.filter(function (el) {
        return arrChilds.indexOf(el) < 0;
      });
    }
    if (AnalyticsHelper.isSmddChild($event.value)) {
      var parent = AnalyticsHelper.getSmddParent($event.value);
      this.shareTimelineSelectedChannel = this.shareTimelineSelectedChannel.filter(e => e !== parent);
    }
  }
  onAddFilteTimelineChannel($event) {
    if ($event === 'OTC Total') {
      this.shareTimelineSelectedChannel = ['OTC Total'];
      return;
    }
    if (AnalyticsHelper.isSmddParent($event)) {
      var arrChilds = AnalyticsHelper.getSmddChildIntage($event);
      this.shareTimelineSelectedChannel = this.shareTimelineSelectedChannel.filter(e => !arrChilds.includes(e));
    }
    if (AnalyticsHelper.isSmddChild($event)) {
      var parent = AnalyticsHelper.getSmddParent($event);
      var checkChildSelected = true;
      var arrChilds = AnalyticsHelper.getSmddChildIntage(parent);
      arrChilds.forEach(child => {
        if (this.otcChannels.some(x=>x.channel==child) && !this.shareTimelineSelectedChannel.includes(child)) {
          checkChildSelected = false;
        }
      });
      if (checkChildSelected) {
        var arrTemp = [parent];
        this.shareTimelineSelectedChannel = this.shareTimelineSelectedChannel.filter(e => !arrChilds.includes(e));
        this.shareTimelineSelectedChannel = arrTemp.concat(this.shareTimelineSelectedChannel);
      }else{
        this.shareTimelineSelectedChannel = this.shareTimelineSelectedChannel.filter(e => e !== parent);
      }
    }
    var checkParentFilter = true;
    var filterNotCheck = this.otcChannels.filter(e => !this.shareTimelineSelectedChannel.includes(e.channel) && e.channel!='OTC Total');
    filterNotCheck.forEach(element => {
      if (!AnalyticsHelper.isSmddChild(element.channel) || !this.shareTimelineSelectedChannel.includes(AnalyticsHelper.getSmddParent(element.channel))) {
        checkParentFilter=false;
      }
    });
    if(checkParentFilter){
      this.shareTimelineSelectedChannel = ['OTC Total'];
    }else{
      this.shareTimelineSelectedChannel = this.shareTimelineSelectedChannel.filter(e => e !== 'OTC Total');
    }
  }   
  onRemoveFilterShareCategory($event) {
    if(this.shareTimelineSelectedCategory.length == 0){
      this.shareTimelineSelectedCategory = ['Total'];
      this.SaveOptionValueTemp(false);
      this.onShareTimeLineCategoryChange()
    }
    if ($event.value === 'Total') {
      this.shareTimelineSelectedCategory = [];
      return;
    }
  }
  onAddFilterShareCategory($event) {
    if ($event === 'Total') {
      this.shareTimelineSelectedCategory = ['Total'];
      return;
    }
    if(this.shareTimelineSelectedCategory.filter(x=>x!='Total').length==(this.categoriesFilter.length-1)){
      this.shareTimelineSelectedCategory = ['Total'];
    }else{
      this.shareTimelineSelectedCategory = this.shareTimelineSelectedCategory.filter(e => e !== 'Total');;
    }
  }
  onRemoveFilterTimelineRegion($event) {
    if(this.shareTimelineSelectedRegion.length == 0){
      this.shareTimelineSelectedRegion = ['CCBJI Territory'];
      this.SaveOptionValueTemp(false);
      this.onShareTimeLineRegionChange()
    }
    if ($event.value === 'CCBJI Territory') {
      this.shareTimelineSelectedRegion = [];
      return;
    }
  }
  onAddFilterSTimelineRegion($event) {
    if ($event === 'CCBJI Territory') {
      this.shareTimelineSelectedRegion = ['CCBJI Territory'];
      return;
    }
    if(this.shareTimelineSelectedRegion.filter(x=> x != 'CCBJI Territory').length == (this.summaryRegions.length-1)){
      this.shareTimelineSelectedRegion = ['CCBJI Territory'];
    }else{
      this.shareTimelineSelectedRegion = this.shareTimelineSelectedRegion.filter(e => e !== 'CCBJI Territory');;
    }
  }  
  onRemoveFilterTimelinePackageType($event) {
    if(this.shareTimelineSelectedPackageType.length == 0){
      this.shareTimelineSelectedPackageType = ['All'];
      this.SaveOptionValueTemp(false);
      this.onShareTimeLinePackageTypeChange()
    }
  }
  onAddFilterTimelinePackageType($event) {
    if ($event === 'All') {
      this.shareTimelineSelectedPackageType = ['All'];
      return;
    }
    if(this.shareTimelineSelectedPackageType.filter(x=> x!='All').length == (this.summaryPackageTypes.length-1)){
      this.shareTimelineSelectedPackageType = ['All'];
    }else{
      this.shareTimelineSelectedPackageType = this.shareTimelineSelectedPackageType.filter(e => e !== 'All');;
    }
  } 
  isSmddChild(channel: string){
    return AnalyticsHelper.isSmddChild(channel);
  }
  handleMarketShareTimeline(){
    try
    {
      this.otcWeeks = [...new Set(this.intage.map(x=> x.week))];
      let data = [];
      this.marketShareMakers.find(x=>x.maker == 'Red Bull').show = this.shareTimelineSelectedCategory.some(x => x == "Energy");
      if(!this.shareTimelineSelectedCategory.some(x => x == "Energy")){
        this.marketShareMakers.find(x=>x.maker == 'Red Bull').checked = false;
      }
      let selectedWeekInt = parseInt(this.selectedWeek.replace('-','').replace('-',''));
      for(var i = 0;i<this.intage.length;i++){
        let row = this.intage[i];
        if(parseInt(row.week.replace('-','').replace('-','')) > selectedWeekInt){
          continue;
        }
        else {
          if(this.shareTimelineSelectedChannel.includes('OTC Total') || this.shareTimelineSelectedChannel.includes(row.channel)
          || (this.shareTimelineSelectedChannel.includes('SMDD') && (row.channel == 'SM' || row.channel == 'Drug' || row.channel == 'Discounter'))){
            if(this.shareTimelineSelectedCategory.includes('Total') || this.shareTimelineSelectedCategory.includes(row.category)){
              if(this.shareTimelineSelectedRegion.includes('CCBJI Territory') || this.shareTimelineSelectedRegion.includes(row.region)){
                  data.push(row);              
              }
            }
          }
        }
      }
      this.otcWeeks = [...new Set(data.map(x=> x.week))];

      switch (this.aggregateBy) {
        case 'mtd':
          this.handleIntageOTCMonthly(data);
          break;
        case 'qtd':
          this.handleIntageOTCQuarterly(data);          
          break;
        case 'ytd':
          this.handleIntageOTCYearly(data);
          break;
        default:
          this.handleIntageOTCWeekly(data);
          break;
      }
   
    }
    catch(e){

    }
    let t= this;
      setTimeout(() => {
        t.ready.emit(true);
      });    
  }

  handleIntageOTCWeekly(data: any)
  {
    this.marketShareTimelineData = [];
    let totals = [];
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let totalWeek = totals.find(x=> x.week == row.week);
      if(totalWeek == null){
        totalWeek = {
          week: row.week,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: 0,
            ly: 0,
            tyVolume:0,
            lyVolume:0
          }]
        }
        totals.push(totalWeek);
      }
      let week = this.marketShareTimelineData.find(x=> x.week == row.week);
      if(week == null){
        week = {
          week: row.week,
          makers: []
        }
        this.marketShareTimelineData.push(week);
      }
      let maker = week.makers.find(x=> x.maker == row.maker);
      
      totalWeek.data[0].ty +=parseFloat(row.ty);
      totalWeek.data[0].ly +=parseFloat(row.ly);
      totalWeek.data[0].tyVolume +=parseFloat(row.tyVolume);
      totalWeek.data[0].lyVolume +=parseFloat(row.lyVolume);

      if(this.shareTimelineSelectedPackageType != 'All' && this.shareTimelineSelectedPackageType != row.packageType){
        continue;
      }

      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: '',
              shareVolume: ''
            }]
          }]
        };
        week.makers.push(maker);
      }
      maker.channels[0].categories[0].ty+= parseFloat(row.ty);
      maker.channels[0].categories[0].ly+= parseFloat(row.ly);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
    }

    for(var i=0;i<this.marketShareTimelineData.length;i++){
      let week = this.marketShareTimelineData[i];
      for(var m=0;m<week.makers.length;m++){
        let maker = week.makers[m];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            let totalWeek = totals.find(x=> x.week == week.week);
            let total = totalWeek.data.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTy = total.ty;
            category.totalLy = total.ly;              

            category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);
            let share_noround =  category.totalTy == 0 ? null : ((category.ty / category.totalTy) * 100) + '';
            let shareLy_noround = category.totalLy == 0 ? null : ((category.ly / category.totalLy) * 100) + '';  
            category.vsLy = (share_noround == null || shareLy_noround == null) ? '-' : (parseFloat(share_noround) - parseFloat(shareLy_noround)).toFixed(1);

            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
            let share_volume_noround =  category.totalTyVolume == 0 ? null : ((category.tyVolume / category.totalTyVolume) * 100) + '';
            let shareLy_volume_noround = category.totalLyVolume == 0 ? null : ((category.lyVolume / category.totalLyVolume) * 100) + '';
            category.vsLyVolume = (share_volume_noround == null || shareLy_volume_noround == null) ? '-' : (parseFloat(share_volume_noround) - parseFloat(shareLy_volume_noround)).toFixed(1);
          }
        }
      }
      week.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    this.marketShareTimelineData.reverse();
    this.otcWeeks.reverse();
    this.renderMarketShareTimelineChart(this.otcWeeks.slice(-1 * this.otcStartWeeks));
  }

  handleIntageOTCMonthly(data: any)
  {
    this.marketShareTimelineData = [];
    this.otcMonths = [];
    let totals = [];
    var start_week = this.otcWeeks[this.otcStartWeeks - 1];
    var intage_month = this.intageMonth.filter(x => this.otcWeeks.includes(x.week));
    if (start_week != null)
    {
      data = data.filter(x => x.week >= start_week);
    }    
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let totalWeek = totals.find(x=> x.week == row.week);
      if(totalWeek == null){
        totalWeek = {
          week: row.week,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: 0,
            ly: 0,
            tyVolume:0,
            lyVolume:0
          }]
        }
        totals.push(totalWeek);
      }

      totalWeek.data[0].ty +=parseFloat(row.ty);
      totalWeek.data[0].ly +=parseFloat(row.ly);
      totalWeek.data[0].tyVolume +=parseFloat(row.tyVolume);
      totalWeek.data[0].lyVolume +=parseFloat(row.lyVolume);

      var month = intage_month.find(x => x.week == row.week).month;

      if (!this.otcMonths.includes(month))
      {
        this.otcMonths.push(month);
      }
      
      var totalMonth = totals.find(x => x.month == month);
      if (totalMonth == null)
      {
        totalMonth = {
          month: month,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume)
          }]
        }
        totals.push(totalMonth);
      }
      else
      {
        totalMonth.data[0].ty += parseFloat(row.ty);
        totalMonth.data[0].ly += parseFloat(row.ly);
        totalMonth.data[0].tyVolume += parseFloat(row.tyVolume);
        totalMonth.data[0].lyVolume += parseFloat(row.lyVolume);
      }

      if(this.shareTimelineSelectedPackageType != 'All' && this.shareTimelineSelectedPackageType != row.packageType){
        continue;
      }

      var month_data = this.marketShareTimelineData.find(x => x.month == month);
      if (month_data == null)
      {
        month_data = {
          month : month,
          makers: []
        }
        this.marketShareTimelineData.push(month_data);
      }
      let maker = month_data.makers.find(x=> x.maker == row.maker);

      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: '',
              shareVolume: ''
            }]
          }]
        };
        month_data.makers.push(maker);
      }
      maker.channels[0].categories[0].ty+= parseFloat(row.ty);
      maker.channels[0].categories[0].ly+= parseFloat(row.ly);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
    }

    totals = totals.filter(x => x.month != null);

    for(var i=0;i<this.marketShareTimelineData.length;i++){
      let month = this.marketShareTimelineData[i];
      for(var m=0;m<month.makers.length;m++){
        let maker = month.makers[m];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            let totalMonth = totals.find(x=> x.month == month.month);
            let total = totalMonth.data.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTy = total.ty;
            category.totalLy = total.ly;              

            category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);

            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
          }
        }
      }
      month.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    this.marketShareTimelineData.reverse();
    this.otcWeeks.reverse();
    this.otcMonths.reverse();
    this.renderMarketShareTimelineChart(this.otcMonths);
  }

  handleIntageOTCQuarterly(data: any)
  {
    this.marketShareTimelineData = [];
    this.otcQuarters = [];
    let totals = [];
    var start_week = this.otcWeeks[this.otcStartWeeks - 1];
    var intage_month = this.intageMonth.filter(x => this.otcWeeks.includes(x.week));
    if (start_week != null)
    {
      data = data.filter(x => x.week >= start_week);
    }
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let totalWeek = totals.find(x=> x.week == row.week);
      if(totalWeek == null){
        totalWeek = {
          week: row.week,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: 0,
            ly: 0,
            tyVolume:0,
            lyVolume:0
          }]
        }
        totals.push(totalWeek);
      }

      totalWeek.data[0].ty +=parseFloat(row.ty);
      totalWeek.data[0].ly +=parseFloat(row.ly);
      totalWeek.data[0].tyVolume +=parseFloat(row.tyVolume);
      totalWeek.data[0].lyVolume +=parseFloat(row.lyVolume);

      var quarter = intage_month.find(x => x.week == row.week).quarter;

      if (!this.otcQuarters.includes(quarter))
      {
        this.otcQuarters.push(quarter);
      }
      
      var totalQuarter = totals.find(x => x.quarter == quarter);
      if (totalQuarter == null)
      {
        totalQuarter = {
          quarter: quarter,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume)
          }]
        }
        totals.push(totalQuarter);
      }
      else
      {
        totalQuarter.data[0].ty += parseFloat(row.ty);
        totalQuarter.data[0].ly += parseFloat(row.ly);
        totalQuarter.data[0].tyVolume += parseFloat(row.tyVolume);
        totalQuarter.data[0].lyVolume += parseFloat(row.lyVolume);
      }

      if(this.shareTimelineSelectedPackageType != 'All' && this.shareTimelineSelectedPackageType != row.packageType){
        continue;
      }

      var quarter_data = this.marketShareTimelineData.find(x => x.quarter == quarter);
      if (quarter_data == null)
      {
        quarter_data = {
          quarter : quarter,
          makers: []
        }
        this.marketShareTimelineData.push(quarter_data);
      }
      let maker = quarter_data.makers.find(x=> x.maker == row.maker);

      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: '',
              shareVolume: ''
            }]
          }]
        };
        quarter_data.makers.push(maker);
      }
      maker.channels[0].categories[0].ty+= parseFloat(row.ty);
      maker.channels[0].categories[0].ly+= parseFloat(row.ly);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
    }

    totals = totals.filter(x => x.quarter != null);

    for(var i=0;i<this.marketShareTimelineData.length;i++){
      let quarter = this.marketShareTimelineData[i];
      for(var m=0;m<quarter.makers.length;m++){
        let maker = quarter.makers[m];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            let totalQuarter = totals.find(x=> x.quarter == quarter.quarter);
            let total = totalQuarter.data.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTy = total.ty;
            category.totalLy = total.ly;              

            category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);

            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
          }
        }
      }
      quarter.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    this.marketShareTimelineData.reverse();
    this.otcWeeks.reverse();
    this.otcQuarters.reverse();
    this.renderMarketShareTimelineChart(this.otcQuarters);
  }

  handleIntageOTCYearly(data: any)
  {
    this.marketShareTimelineData = [];
    this.otcYears = [];
    let totals = [];
    var start_week = this.otcWeeks[this.otcStartWeeks - 1];
    var intage_month = this.intageMonth.filter(x => this.otcWeeks.includes(x.week));
    if (start_week != null)
    {
      data = data.filter(x => x.week >= start_week);
    }
    for(var i=0;i<data.length;i++){
      let row = data[i];
      let totalWeek = totals.find(x=> x.week == row.week);
      if(totalWeek == null){
        totalWeek = {
          week: row.week,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: 0,
            ly: 0,
            tyVolume:0,
            lyVolume:0
          }]
        }
        totals.push(totalWeek);
      }

      totalWeek.data[0].ty +=parseFloat(row.ty);
      totalWeek.data[0].ly +=parseFloat(row.ly);
      totalWeek.data[0].tyVolume +=parseFloat(row.tyVolume);
      totalWeek.data[0].lyVolume +=parseFloat(row.lyVolume);

      var year = intage_month.find(x => x.week == row.week).year;

      if (!this.otcYears.includes(year))
      {
        this.otcYears.push(year);
      }
      
      var totalYear = totals.find(x => x.year == year);
      if (totalYear == null)
      {
        totalYear = {
          year: year,
          data: [{
            channel: 'OTC Total',
            category: 'Total',
            ty: parseFloat(row.ty),
            ly: parseFloat(row.ly),
            tyVolume: parseFloat(row.tyVolume),
            lyVolume: parseFloat(row.lyVolume)
          }]
        }
        totals.push(totalYear);
      }
      else
      {
        totalYear.data[0].ty += parseFloat(row.ty);
        totalYear.data[0].ly += parseFloat(row.ly);
        totalYear.data[0].tyVolume += parseFloat(row.tyVolume);
        totalYear.data[0].lyVolume += parseFloat(row.lyVolume);
      }

      if(this.shareTimelineSelectedPackageType != 'All' && this.shareTimelineSelectedPackageType != row.packageType){
        continue;
      }

      var year_data = this.marketShareTimelineData.find(x => x.year == year);
      if (year_data == null)
      {
        year_data = {
          year : year,
          makers: []
        }
        this.marketShareTimelineData.push(year_data);
      }
      let maker = year_data.makers.find(x=> x.maker == row.maker);

      if(maker == null){
        let makerLogo = this.makerLogo.find(x=> x.maker == row.maker);
        maker = {
          maker: row.maker,
          index: makerLogo != null ? makerLogo.index : 100,
          logo: makerLogo != null ? makerLogo.logo: "",
          channels: [{
            channel: 'OTC Total',
            categories: [{
              category : 'Total',
              ty: 0,
              ly: 0,
              totalTy: 0,
              totalLy: 0,
              share: '',
              vsLy: '',
              tyVolume: 0,
              lyVolume: 0,
              totalTyVolume: 0,
              totalLyVolume: 0,
              vsLyVolume: '',
              shareVolume: ''
            }]
          }]
        };
        year_data.makers.push(maker);
      }
      maker.channels[0].categories[0].ty+= parseFloat(row.ty);
      maker.channels[0].categories[0].ly+= parseFloat(row.ly);
      maker.channels[0].categories[0].tyVolume+= parseFloat(row.tyVolume);
      maker.channels[0].categories[0].lyVolume+= parseFloat(row.lyVolume);
    }

    totals = totals.filter(x => x.year != null);

    for(var i = 0;i < this.marketShareTimelineData.length; i++){
      let year = this.marketShareTimelineData[i];
      for(var m = 0;m < year.makers.length; m++){
        let maker = year.makers[m];
        for(var j=0;j<maker.channels.length;j++){
          for(var k=0;k<maker.channels[j].categories.length;k++){
            let category = maker.channels[j].categories[k];
            let totalYear = totals.find(x=> x.year == year.year);
            let total = totalYear.data.find(x=> x.category == category.category && x.channel == maker.channels[j].channel);
            category.totalTy = total.ty;
            category.totalLy = total.ly;              

            category.share = category.totalTy == 0 ? "-" : ((category.ty / category.totalTy) * 100).toFixed(1);

            category.totalTyVolume = total.tyVolume;
            category.totalLyVolume = total.lyVolume;
            category.shareVolume = category.totalTyVolume == 0 ? "-" : ((category.tyVolume / category.totalTyVolume) * 100).toFixed(1);
          }
        }
      }
      year.makers.sort((a, b) => a.index > b.index ? 1 : -1);
    }

    this.marketShareTimelineData.reverse();
    this.otcWeeks.reverse();
    this.otcYears.reverse();
    this.renderMarketShareTimelineChart(this.otcYears);
  }

  onValueShareAggregateChange(aggregateBy: string)
  {
    this.aggregateBy = aggregateBy;
    this.handleMarketShareTimeline();
  }

  quarterTransform(month: string): any {
    let monthOnly = month.substring(5,7);
    switch(monthOnly) {
      case '01':
      case '02':
      case '03':
        return month.substring(0,5)+'01';
      case '04':
      case '05':
      case '06':
        return month.substring(0,5)+'02';
      case '07':
      case '08':
      case '09':
        return month.substring(0,5)+'03';
      case '10':
      case '11':
      case '12':
        return month.substring(0,5)+'04';
    } 
  }
  
  renderMarketShareTimelineChart(categories: any){
    if(this.marketShareChart != null){
      this.marketShareChart.destroy();
    }

    this.series = [];
    let colors = [];
    for(var i=0;i<this.marketShareMakers.length;i++){
      let maker = this.marketShareMakers[i];
      if(maker.checked){
        let serie = {
          name : maker.maker,
          data : []
        };
        for(var j=0;j<this.marketShareTimelineData.length;j++){
          let currentMaker = this.marketShareTimelineData[j].makers.find(x=> x.maker == maker.maker);
          if(currentMaker == null){
            serie.data.push("-");
            continue;
          }
          if (this.shareDisplayType == 'value') {
            var valueTemp = currentMaker.channels[0].categories[0].share;
          }
          else if (this.shareDisplayType == 'volume') {
            var valueTemp = currentMaker.channels[0].categories[0].shareVolume;
          }
          serie.data.push(valueTemp);
        }
        
        this.series.push(serie);
        colors.push(IntageHelper.GetMakerColor(serie.name));
      }
    }

    this.marketShareHaveData = false;
    for(var i=0;i<this.series.length;i++){
      if(this.series[i].data.length > 0){
        this.marketShareHaveData = true;
        break;
      }
    }
    this.marketShareHaveSingleSerie = false;
    if(this.marketShareHaveData && this.series.length == 1){
      this.marketShareHaveSingleSerie = true;
      this.marketShareSingleSerie = this.series[0].name;
      this.marketShareSingleSerieColor = colors[0];
    }

    let t = this;
    // FPT-ChienNX7 [2022/12/14][SENSE][1203444563290052] Rename file name png when export start
    const fileNameExport = this.shareDisplayType == 'value' ? 'Value Share Timeline by Maker+' + this.shareTimelineSelectedChannel + '+'
        + this.shareTimelineSelectedCategory + '+' + this.shareTimelineSelectedRegion + '+' + this.shareTimelineSelectedPackageType + '+' + this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks] + '+' + this.selectedWeek :
        'Volume Share Timeline by Maker+' + this.shareTimelineSelectedChannel + '+'
        + this.shareTimelineSelectedCategory + '+' + this.shareTimelineSelectedRegion + '+' + this.shareTimelineSelectedPackageType + '+' + this.otcWeeks[this.otcWeeks.length - this.otcStartWeeks] + '+' + this.selectedWeek;

    // otcStartWeeks    
    for (let i = 0; i < this.series.length; i++) {
      this.series[i]['data'] = this.series[i]['data'].slice(-1 * this.otcStartWeeks)      
    }
    const options = {
        chart: {
            height: 500,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
              show: true,
              tools: {
                download: false
              }
            }
        },
        dataLabels: {
            enabled: this.marketShareHaveSingleSerie ? true : false,
            style:{
              colors: [
                function (w) {
                  if (w.series[w.seriesIndex][w.dataPointIndex] > 0) {
                    return w.w.config.colors[w.seriesIndex];
                  } else {
                    return "#FF2626";
                  }
                },
              ],
              fontSize : "14px"
            }
        },
        stroke: {
            //width: [5, 5, 5],
            curve: 'smooth',
            //dashArray: [5, 5, 5]
        },
        colors: getColorsWithMovingAverage({ colors, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        series: getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes }),
        legend: {
            show: true,
            onItemClick: {
              toggleDataSeries: true
            }
        },
        markers: {
            size: this.marketShareHaveSingleSerie ? 0 : 5,
            hover: {
                sizeOffset: 6
            }
        },
        xaxis: {
          categories: categories
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        yaxis: {
          forceNiceScale: true,
          //min: -50
        }
      };        
    // FPT-ChienNX7 [2022/12/14][SENSE][1203444563290052] Rename file name png when export end

    document.querySelector("#intage-market-share-chart").innerHTML = '';
    this.marketShareChart = new ApexCharts(
        document.querySelector("#intage-market-share-chart"),
        options
    );
    this.marketShareChart.render();
  }
  public getCategoryDisplayName(category: string){
    return IntageHelper.GetCategoryDisplayName(category);
  }

  public onShareDisplayTypeChange(type: string){
    this.shareDisplayType = type;
    this.handleMarketShareTimeline();
  }

  public onShareTimeLineChannelChange(){
    if(this.shareTimelineSelectedChannel.length==0){
      this.shareTimelineSelectedChannel=['OTC Total'];
    }
    this.handleMarketShareTimeline();
  }

  public onShareTimeLineCategoryChange(){
    if(this.shareTimelineSelectedCategory.length==0){
      this.shareTimelineSelectedCategory = ['Total'];
    }
    this.handleMarketShareTimeline();
  }

  public onShareTimeLineRegionChange(){
    if(this.shareTimelineSelectedRegion.length==0){
      this.shareTimelineSelectedRegion = ['CCBJI Territory'];
    }
    this.handleMarketShareTimeline();
  }

  public onShareTimeLinePackageTypeChange(){
    if(this.shareTimelineSelectedPackageType.length==0){
      this.shareTimelineSelectedPackageType = ['All'];
    }
    this.handleMarketShareTimeline();
  }

  public onShareMakersCheckedChanged(maker: string){
    let currentMaker = this.marketShareMakers.find(x=> x.maker == maker);
    currentMaker.checked = !currentMaker.checked;
    this.handleMarketShareTimeline();
  }

  public onSelectedStartWeekChanged($event){
    this.otcStartWeeks = $event;
    this.handleMarketShareTimeline();
  }

  public exportValueShareTimeline(isImage: boolean) {
    let weeks = this.otcWeeks.slice(-1 * this.otcStartWeeks);
    switch (this.aggregateBy) {
      case 'mtd':
        var fileName = this.shareDisplayType == 'value' ? "Value Share " : "Volume Share " +  this.otcMonths[0] + ' To ' + this.otcMonths[this.otcMonths.length - 1];  
        break;
      case 'qtd':
        var fileName = this.shareDisplayType == 'value' ? "Value Share " : "Volume Share " +  this.otcQuarters[0] + ' To ' + this.otcQuarters[this.otcQuarters.length - 1];       
        break;
      case 'ytd':
        var fileName = this.shareDisplayType == 'value' ? "Value Share " : "Volume Share " +  this.otcYears[0] + ' To ' + this.otcYears[this.otcYears.length - 1];
        break;
      default:
        var fileName = this.shareDisplayType == 'value' ? "Value Share " : "Volume Share " +  weeks[0] + ' To ' + weeks[weeks.length - 1];
        break;
    }
    if (isImage) {
      // FPT-ChienNX7 [2022/12/09][SENSE][1203444563290052] Fix bug image download incorrect
      this.scroller.nativeElement.scrollTop = 0;
      this.scroller.nativeElement.scrollLeft = 0;
      setTimeout(() => {
        html2canvas(this.screen.nativeElement).then(canvas => {
          this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
          this.downloadLink.nativeElement.download = fileName;
          this.downloadLink.nativeElement.click();
        });
      }, 500);
    } else {
      let data = [];

      switch (this.aggregateBy) {
        case 'mtd':
          for (var i = 0; i < this.otcMonths.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  month: this.otcMonths[i],
                  maker: this.series[k].name,
                  display: this.shareDisplayType == 'value' ? "Value Share" : "Volume Share",
                  channel: this.shareTimelineSelectedChannel,
                  category: this.shareTimelineSelectedCategory,
                  region: this.shareTimelineSelectedRegion,
                  packagetype: this.shareTimelineSelectedPackageType,              
                  share: this.series[k].data[i]
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Month", "Maker", "Display", "Channel", "Category", "Region", "Package Type",  "Share Value"]
          });
          break;
        case 'qtd':
          for (var i = 0; i < this.otcQuarters.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  quarter: this.otcQuarters[i],
                  maker: this.series[k].name,
                  display: this.shareDisplayType == 'value' ? "Value Share" : "Volume Share",
                  channel: this.shareTimelineSelectedChannel,
                  category: this.shareTimelineSelectedCategory,
                  region: this.shareTimelineSelectedRegion,
                  packagetype: this.shareTimelineSelectedPackageType,              
                  share: this.series[k].data[i]
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Quarter", "Maker", "Display", "Channel", "Category", "Region", "Package Type",  "Share Value"]
          });       
          break;
        case 'ytd':
          for (var i = 0; i < this.otcYears.length; i++) {
            for (var k = 0; k < this.series.length; k++)
              {
                data.push({
                  year: this.otcYears[i],
                  maker: this.series[k].name,
                  display: this.shareDisplayType == 'value' ? "Value Share" : "Volume Share",
                  channel: this.shareTimelineSelectedChannel,
                  category: this.shareTimelineSelectedCategory,
                  region: this.shareTimelineSelectedRegion,
                  packagetype: this.shareTimelineSelectedPackageType,              
                  share: this.series[k].data[i]
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Year", "Maker", "Display", "Channel", "Category", "Region", "Package Type",  "Share Value"]
          });
          break;
        default:
          var series = getSeriesWithMovingAverage({ series: this.series, aggregateBy: this.aggregateBy, windowSizes: this.movingAverageWindowSizes });        
            for (var k = 0; k < series.length; k++)
            {
              for (var i = 0; i < weeks.length; i++) {
                data.push({
                  week: weeks[i],
                  maker: series[k].name,
                  display: this.shareDisplayType == 'value' ? "Value Share" : "Volume Share",
                  channel: this.shareTimelineSelectedChannel,
                  category: this.shareTimelineSelectedCategory,
                  region: this.shareTimelineSelectedRegion,
                  packagetype: this.shareTimelineSelectedPackageType,              
                  share: series[k].data[i]
                });
              }
            }
        
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: ["Week", "Maker/MAV", "Display", "Channel", "Category", "Region", "Package Type",  "Share Value"]
          });
          break;
      }

    }
    this.showOptionDownload();
  }

  showOptionDownload() {
    const download = document.getElementById('download-option-share-timeline');
    if (download.style.display === 'block') {
      download.style.display = 'none';
    } else {
      download.style.display = 'block';
    }
  }

  dropdownValueToNgSelectFormat(value) {
    const values = DropdownValueToNgSelectFormat(value);
    if (values?.length && this?.otcStartWeeks) {
      if (values.length < this.otcStartWeeks) {
        setTimeout(() => {
          this.otcStartWeeks = values.length
        }, 500);
      }
    }
    return values
  }

  onMovingAverageWindowSizeChange(value){
    this.movingAverageWindowSizes = value;
    this.handleMarketShareTimeline();
  } 

}
