import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FILTER_CONFIGS, AreaDeepdiveQueries } from 'src/app/model/constants';
import { Chart } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AreaDeepdiveHelper } from 'src/app/components/pages/area-deepdive/area-deepdive-helper';
import { FilterHelper } from 'src/app/components/elements/add-more-filter/filter-helper';
import { HttpClient } from '@angular/common/http';
import lodashClonedeep from 'lodash.clonedeep';
import { AngularCsv } from "angular-csv-ext/dist/Angular-csv";
import * as htmlToImage from 'html-to-image';
import { downloadAsPDF } from 'src/app/helpers/pdfExtend';
import { PDFProperty } from 'src/app/model/pdfProperty.model';
import { isObjEmpty } from 'src/app/helpers/utils';
import { Router } from '@angular/router';
import { CsvService } from 'src/app/services/csv.services';

@Component({
  selector: 'app-area-deepdive-vs-ly-timeline',
  templateUrl: './vs-ly-timeline.component.html',
  styleUrls: ['./vs-ly-timeline.component.scss']
})
export class VsLyAreaTimelineComponent implements OnInit {
  FILTERS: any                  = FILTER_CONFIGS?.ADD_MORE_FILTER?.DEFAULT || [];
  AREA_DEEPDIVE_FILTERS         = FILTER_CONFIGS?.ADD_MORE_FILTER?.COLLECTIONS?.AREA_DEEPDIVE;
  defaultVisibleFilters: number = FILTER_CONFIGS?.ADD_MORE_FILTER?.VISIBLE_COUNT ?? 4;

  @Input() lstOption_Selected_DateType: any;
  @Input() lstOption_Selected_SalesDate: any;

  @Input() lstAllOptionsCalendarData: any;
  @Input() lstAllOptionsAreaData: any;
  @Input() lstAllOptionsAccountData: any;
  @Input() lstAllOptionsProductData: any;
  @Output() ready = new EventEmitter<boolean>();
  @ViewChild('downloadLink') downloadLink: ElementRef;

  vsLYByTimelineData: any = null;
  vsLYByTimelineData_CSV: any = null;
  //listGroupTimelineDetail: any[] = null;

  isLoading: boolean = false;
  isNoDataMode = true;
  listData_Filter: any = null;
  lstOption_States: any = {};
  lstOption_States_Applied: any = {};
  lstOption_Selected: any = {};
  lstOption_IsUsed: any = {};
  lstOption_Selected_Applied: any = {};
  areaFilterQuery: object = {};

  arrBAPCTY: any[];
  hozBarBAPCTY: any;
  hozBarOppBAPCTY: any;
  hozBarTitleBAPCTY: string;
  hozBarIndexBAPCTY: string;
  isBAPCTYHasNegativeElement: boolean;

  arrBAPCVSLY: any[];
  hozBarBAPCVSLY: any;
  hozBarOppBAPCVSLY: any;
  hozBarTitleBAPCVSLY: string;
  hozBarTitleOppBAPCVSLY: string;

  arrNSRTY: any[];
  hozBarNSRTY: any;
  hozBarOppNSRTY: any;
  hozBarTitleNSRTY: string;
  hozBarTitleOppNSRTY: string;
  hozBarIndexNSRTY: string;
  isNSRTYHasNegativeElement: boolean;

  arrNSRVSLY: any[];
  hozBarNSRVSLY: any;
  hozBarOppNSRVSLY: any;
  hozBarTitleNSRVSLY: string;
  hozBarTitleOppNSRVSLY: string;

  arrGPTY: any[];
  hozBarGPTY: any;
  hozBarOppGPTY: any;
  hozBarTitleGPTY: string;
  hozBarTitleOppGPTY: string;
  hozBarIndexGPTY: string;
  isGPTYHasNegativeElement: boolean;

  arrGPVSLY: any[];
  hozBarGPVSLY: any;
  hozBarOppGPVSLY: any;
  hozBarTitleGPVSLY: string;
  hozBarTitleOppGPVSLY: string;

  arrDays: any[];

  isHasFistLoad: boolean = false;

  constructor(private http: HttpClient, private router: Router, private csvService: CsvService) {}

  ngOnInit() {
    const lstOption = FilterHelper.init_lstOption_States('VsLyAreaTimelineComponent',this.FILTERS, this.defaultVisibleFilters) || {};
    this.lstOption_States   = lstOption.lstOption_States;
    this.lstOption_Selected = lstOption.lstOption_Selected;
    this.listData_Filter  =lstOption.listData_Filter;
    this.areaFilterQuery     = AreaDeepdiveHelper.skuSetQueryString(lstOption.lstOption_Selected);
    this.lstOption_IsUsed   = FilterHelper.getListIsUsedFilter(this.FILTERS, this.AREA_DEEPDIVE_FILTERS) || {};
    this.onUpdateCurrent_Filter_Applied();
    //this.onSearchData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (((changes['lstOption_Selected_DateType'] && this.lstOption_Selected_DateType && this.lstOption_Selected_DateType.length > 0)
        || (changes['lstOption_Selected_SalesDate'] && this.lstOption_Selected_SalesDate && this.lstOption_Selected_SalesDate.length > 0))) {
      if(this.isHasFistLoad){
        this.onSearchData();
      }
      this.handleonReady();
    }
    this.isHasFistLoad = true;
  }

  onResetFiltersGroup($event: any) {
    const {data} = $event;
    if (data && Array.isArray(data) && data.length > 0) {
      data.map(key => {
        if (key && key.length > 0) {
          this.lstOption_Selected[key] = ['All'];
        }
      });
    }
  }

  onUpdateChart(event: any) {
    const {lstOption_States, lstOption_Selected} = event;

    if (lstOption_States && lstOption_Selected) {
      this.lstOption_Selected = {};
      this.lstOption_Selected = lstOption_Selected;
      this.lstOption_States   = {};
      this.lstOption_States   = lstOption_States;
      this.onUpdateCurrent_Filter_Applied();
      this.updateVsLyByTimelineData();
    }
  }

  onUpdateCurrent_Filter_Applied() {
    this.lstOption_States_Applied = lodashClonedeep(this.lstOption_States);
    this.lstOption_Selected_Applied = lodashClonedeep(this.lstOption_Selected);
  }


  public updateVsLyByTimelineData(): void {
    this.onSearchData();
  }

  onSearchData() {
    this.isLoading = true;
    this.areaFilterQuery = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);

    AreaDeepdiveHelper.GetDataByTimeline(this.http, this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD', !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.areaFilterQuery).then((data) => {
      if (data) {
        const { Data } = data;

        if (Data) {
          this.vsLYByTimelineData = Data;
          this.transformData();
          this.isLoading = false;
        }
      }
    })
  }

  handleonReady(){
    let t = this;
    setTimeout(() => {
      t.ready.emit(true);
    });
  }

  //chart
  labels = ['A', 'B', 'C', 'D', 'E', 'X', 'Y', 'Z'];

  data = {
    labels: this.labels,
    datasets: [{
      data: [65, 59, 80, 84, 0], min: -100, max: 100,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0
    }]
  };

  exportAreaTimeline_CVS() {
    this.areaFilterQuery = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);

    AreaDeepdiveHelper.GetDataByTimeline(this.http, this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD', !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [], this.areaFilterQuery,true).then((data) => {
      if (data) {
        const { Data } = data;

        if (Data) {
          this.vsLYByTimelineData_CSV = Data;
          const fileName = "vs_LY_Timeline"
          let data = [];
          var filters: any;
          filters = {
            DateType: this.lstOption_Selected_DateType,
            SaleDateMonth: this.lstOption_Selected_SalesDate,
            channelLv1: this.lstOption_Selected_Applied.CHANNEL_LEV1_NAME,
            channelLv2: this.lstOption_Selected_Applied.CHANNEL_LEV2_NAME,
            channelLv3: this.lstOption_Selected_Applied.CHANNEL_LEV3_NAME,
            saleHQLev1: this.lstOption_Selected_Applied.SALES_HQ_LEV1_NAME,
            saleHQLev2: this.lstOption_Selected_Applied.SALES_HQ_LEV2_NAME,
            saleHQLev3: this.lstOption_Selected_Applied.SALES_HQ_LEV3_NAME,
            SalesCenter: this.lstOption_Selected_Applied.SALES_CENTER_NAME,
            SalesRoute: this.lstOption_Selected_Applied.SALES_ROUTE_NAME,
            KamDeptLv2: this.lstOption_Selected_Applied.KAM_DEPT_LV2_NAME,
            KamDeptLv3: this.lstOption_Selected_Applied.KAM_DEPT_LV3_NAME,
            KamDeptLv4: this.lstOption_Selected_Applied.KAM_DEPT_LV4_NAME,
            KamContactPerson: this.lstOption_Selected_Applied.KAM_CONTACT_PERSON_NAME,
            AccountLev2: this.lstOption_Selected_Applied.ACCOUNT_LEV2_NAME_EN,
            AccountLev3: this.lstOption_Selected_Applied.ACCOUNT_LEV3_NAME_EN,
            Category: this.lstOption_Selected_Applied.CATEGORY_NAME_EN,
            Subcategory: this.lstOption_Selected_Applied.SUBCATEGORY_NAME_EN,
            Brand: this.lstOption_Selected_Applied.BRAND_NAME_EN,
            PackageLev1: this.lstOption_Selected_Applied.PACKAGE_LEV1_NAME_EN,
            PackageLev2: this.lstOption_Selected_Applied.PACKAGE_LEV2_NAME_EN,
          };
      
      
          this.vsLYByTimelineData_CSV.forEach(items => {
            if(this.lstOption_Selected_DateType == "MTD") {
              data.push({
                DateType: filters === null ? '' : String(filters.DateType),
                SaleDateMonth: filters === null ? '' : String(filters.SaleDateMonth),
                ... this.toRowCVS(items, filters)
              })
            } else {
              data.push({
                DateType: filters === null ? '' : String(filters.DateType),
                SaleDateDay:filters === null ? '' : this.lstOption_Selected_SalesDate[0] + ' to ' + this.lstOption_Selected_SalesDate[1],
                ... this.toRowCVS(items, filters)
              })
            }
          });
      
          new AngularCsv(data, fileName, {
            showLabels: true,
            headers: [
              'Date Type',
              'Sales Date',
              'Channel Lev1',
              'Channel Lev2',
              'Channel Lev3',
              'Sales HQ Lev1',
              'Sales HQ Lev2',
              'Sales HQ Lev3',
              'Sales Center',
              'Sales Route',
              'Kam Dept Lev2',
              'Kam Dept Lev3',
              'Kam Dept Lev4',
              'Kam Account Mgr 4',
              'Account Lev2',
              'Account Lev3',
              'Category',
              'Subcategory',
              'Brand',
              'Package Lev1',
              'Package Lev2',
              'Date/Month',
              'BAPC TY',
              'BAPC LY',
              'BAPC % vs LY',
              'BAPC Diff LY',
              'NSR TY',
              'NSR LY',
              'NSR % vs LY',
              'NSR Diff LY',
              'GP TY',
              'GP LY',
              'GP % vs LY',
              'GP Diff LY'
            ]
          });
        }
      }
    })


  }

  exportAreaTimeline_CVS_by_Url() {
    const obj       = AreaDeepdiveHelper.skuSetQueryString(this.lstOption_Selected_Applied);
    const dateType  =  this.lstOption_Selected_DateType ?  this.lstOption_Selected_DateType:  'MTD';
    const salesDate = !AreaDeepdiveHelper.isAllSelected(this.lstOption_Selected_SalesDate) ? this.lstOption_Selected_SalesDate : [];

    this.areaFilterQuery = {
      ...obj,
      CacheKey: `DATE_TYPE=${encodeURIComponent(dateType)}&SALES_DATE=${salesDate}&${obj['CacheKey']}`,
      Selected: {
        ...obj['Selected'],
        DATE_TYPE: dateType,
        SALES_DATE: salesDate
      }
    };

    this.csvService.getCsvUrl(AreaDeepdiveQueries.byTimeline, this.areaFilterQuery, '')
      .subscribe((data) => {
        if (data) {
          this._downloadCsv(data?.Csv_Url);
        }
    });
  }

  toRowCVS(row: any, filters: any) {
    return {
      channelLv1: filters === null ? '' : String(filters.channelLv1),
      channelLv2: filters === null ? '' : String(filters.channelLv2),
      channelLv3: filters === null ? '' : String(filters.channelLv3),
      saleHQLev1: filters === null ? '' : String(filters.saleHQLev1),
      saleHQLev2: filters === null ? '' : String(filters.saleHQLev2),
      saleHQLev3: filters === null ? '' : String(filters.saleHQLev3),
      SalesCenter: filters === null ? '' : String(filters.SalesCenter),
      SalesRoute: filters === null ? '' : String(filters.SalesRoute),
      KamDeptLv2: filters === null ? '' : String(filters.KamDeptLv2),
      KamDeptLv3: filters === null ? '' : String(filters.KamDeptLv3),
      KamDeptLv4: filters === null ? '' : String(filters.KamDeptLv4),
      KamContactPerson: filters === null ? '' : String(filters.KamContactPerson),
      AccountLev2: filters === null ? '' : String(filters.AccountLev2),
      AccountLev3: filters === null ? '' : String(filters.AccountLev3),
      Category: filters === null ? '' : String(filters.Category),
      Subcategory: filters === null ? '' : String(filters.Subcategory ?? 'NULL'),
      Brand: filters === null ? '' : String(filters.Brand),
      PackageLev1: filters === null ? '' : String(filters.PackageLev1),
      PackageLev2: filters === null ? '' : String(filters.PackageLev2),

      Date: row['SALES_DATE_Display'],
      BAPC_TY: row['BAPC_TY_Display'] === null ? '' : row['BAPC_TY_Display'],
      BAPC_LY: row['BAPC_LY_Display'] === null ? '' : row['BAPC_LY_Display'],
      BAPC_VS_LY: row['BAPC_VS_LY_Display'] === null ? '' : row['BAPC_VS_LY_Display'],
      BAPC_Diff_LY: row['BAPC_DIFF_LY_Display'] === null ? '' : row['BAPC_DIFF_LY_Display'],
      NSR_TY: row['NSR_TY_Display'] === null ? '' : row['NSR_TY_Display'],
      NSR_LY: row['NSR_LY_Display'] === null ? '' : row['NSR_LY_Display'],
      NSR_VS_LY: row['NSR_VS_LY_Display'] === null ? '' : row['NSR_VS_LY_Display'],
      NSR_Diff_LY: row['NSR_DIFF_LY_Display'] === null ? '' : row['NSR_DIFF_LY_Display'],
      GP_TY: row['GP_TY_Display'] === null ? '' : row['GP_TY_Display'],
      GP_LY: row['GP_LY_Display'] === null ? '' : row['GP_LY_Display'],
      GP_VS_LY: row['GP_VS_LY_Display'] === null ? '' : row['GP_VS_LY_Display'],
      GP_Diff_LY: row['GP_DIFF_LY_Display'] === null ? '' : row['GP_DIFF_LY_Display']
    }
  }

  @ViewChild('pdf_print_area_2', { static: false }) pdf_print_area_2: ElementRef;
  exportPDF(name) {
    const $ = window["jQuery"];
    $("body").addClass("pdf-printing");
    $(".table-container").css('height', 'auto');
    $(".table-container").css('overflow-y', 'scroll');
    $(".filter-section").css('display', 'none');

    var area = this.pdf_print_area_2.nativeElement;
    htmlToImage.toCanvas(this.pdf_print_area_2.nativeElement, { quality: 1 })
      .then(function (canvas) {
        var pdfProperty: PDFProperty = {
          option: { margin: [50, 0, 46, 0] },
          canvas: canvas
        }
        downloadAsPDF(name, area, false, pdfProperty);
      });
  }

kFormatter(num: any) {
  return Number(num / 1000)
}

mFormatter(num: any) {
  return Number(num / 1000000)
}

transformData() {

  this.isNoDataMode = this.vsLYByTimelineData.length == 0;

    //BAPCTY data
    const bapctyData: {
        BY_FILTER: string;
        BAPC_TY: number }[] =
    this.vsLYByTimelineData.map((item: any) => ({
        BY_FILTER: item.BY_FILTER,
        BAPC_TY: item.BAPC_TY,
    }));
    const objBAPCTY = this.formatChartData("BAPC_TY", bapctyData);
    this.arrBAPCTY = objBAPCTY.result;
    this.hozBarBAPCTY = objBAPCTY.hozBarCss;
    this.hozBarOppBAPCTY = objBAPCTY.hozBarOppCss;
    this.hozBarTitleBAPCTY = `${this.kFormatter(objBAPCTY.hozBarTitle).toLocaleString()}`;
    this.hozBarIndexBAPCTY = "0";
    this.isBAPCTYHasNegativeElement = objBAPCTY.result.some(e => !e.isPositive);

    //BAPCVSLY data
    const bapcvslyData: {
        BY_FILTER: string;
        BAPC_VS_LY: number;
    }[] = this.vsLYByTimelineData.map((item: any) => ({
        BY_FILTER: item.BY_FILTER,
        BAPC_VS_LY: (parseFloat(item.BAPC_VS_LY)*100).toFixed(1) ,
    }));
    const objBAPCVSLY = this.formatChartData("BAPC_VS_LY", bapcvslyData);
    this.arrBAPCVSLY = objBAPCVSLY.result;
    this.hozBarBAPCVSLY = objBAPCVSLY.hozBarCss;
    this.hozBarOppBAPCVSLY = objBAPCVSLY.hozBarOppCss;
    this.hozBarTitleBAPCVSLY = `+${objBAPCVSLY.hozBarTitle.toFixed(1)}`;
    this.hozBarTitleOppBAPCVSLY = `-${objBAPCVSLY.hozBarTitle.toFixed(1)}`;

    //NSRTY data
    const nsrtyData: {
        BY_FILTER: string;
        NSR_TY: number;
    }[] = this.vsLYByTimelineData.map((item: any) => ({
        BY_FILTER: item.BY_FILTER,
        NSR_TY: item.NSR_TY,
    }));
    const objNSRTY = this.formatChartData("NSR_TY", nsrtyData);
    this.arrNSRTY = objNSRTY.result;
    this.hozBarNSRTY = objNSRTY.hozBarCss;
    this.hozBarOppNSRTY = objNSRTY.hozBarOppCss;
    this.hozBarTitleNSRTY = `${this.mFormatter(objNSRTY.hozBarTitle).toLocaleString()}`;
    this.hozBarIndexNSRTY = `0`;
    this.isNSRTYHasNegativeElement = objNSRTY.result.some(e => !e.isPositive);

    //NSRVSLY data
    const nsrvslyData: {
        BY_FILTER: string;
        NSR_VS_LY: number;
    }[] = this.vsLYByTimelineData.map((item: any) => ({
        BY_FILTER: item.BY_FILTER,
        NSR_VS_LY: (parseFloat(item.NSR_VS_LY)*100).toFixed(1) ,
    }));
    const objNSRVSLY = this.formatChartData("NSR_VS_LY", nsrvslyData);
    this.arrNSRVSLY = objNSRVSLY.result;
    this.hozBarNSRVSLY = objBAPCVSLY.hozBarCss;
    this.hozBarOppNSRVSLY = objNSRVSLY.hozBarOppCss;
    this.hozBarTitleNSRVSLY = `+${objNSRVSLY.hozBarTitle.toFixed(1)}`;
    this.hozBarTitleOppNSRVSLY = `-${objNSRVSLY.hozBarTitle.toFixed(1)}`;

    //GPTY data
    const gptyData: {
        BY_FILTER: string;
        GP_TY: number;
    }[] = this.vsLYByTimelineData.map((item: any) => ({
        BY_FILTER: item.BY_FILTER,
        GP_TY: item.GP_TY,
    }));
    const objGPTY = this.formatChartData("GP_TY", gptyData);
    this.arrGPTY = objGPTY.result;
    this.hozBarGPTY = objGPTY.hozBarCss;
    this.hozBarOppGPTY = objGPTY.hozBarOppCss;
    this.hozBarTitleGPTY = `${this.mFormatter(objGPTY.hozBarTitle).toLocaleString()}`;
    this.hozBarIndexGPTY = `0`;
    this.isGPTYHasNegativeElement = objGPTY.result.some(e => !e.isPositive);

    //GPVSLY data
    const gpvslyData: {
        BY_FILTER: string;
        GP_VS_LY: number;
    }[] = this.vsLYByTimelineData.map((item: any) => ({
        BY_FILTER: item.BY_FILTER,
        GP_VS_LY: (parseFloat(item.GP_VS_LY)*100).toFixed(1) ,
    }));
    const objGPVSLY = this.formatChartData("GP_VS_LY", gpvslyData);
    this.arrGPVSLY = objGPVSLY.result;
    this.hozBarGPVSLY = objGPVSLY.hozBarCss;
    this.hozBarOppGPVSLY = objGPVSLY.hozBarOppCss;
    this.hozBarTitleGPVSLY = `+${objGPVSLY.hozBarTitle.toFixed(
        1
    )}`;
    this.hozBarTitleOppGPVSLY = `-${objGPVSLY.hozBarTitle.toFixed(1)}`;
    this.arrDays = this.vsLYByTimelineData.map((item: any) => ({
        value: item.SALES_DATE ,
    }));
}


roundMaxValue(value: number, minvalue :number = 100): number {
  if (value <= minvalue) {
      return minvalue;
  } else {
      const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(value) - 1));
      let result = Math.ceil(value / orderOfMagnitude) * orderOfMagnitude;
      let secondDigit = parseInt(result.toString().charAt(1));
      if (secondDigit % 2 !== 0) {
        secondDigit += 1;
        const firstDigit = parseInt(result.toString().charAt(0));
        if (secondDigit === 10) {
          secondDigit = 0;
          result = (firstDigit + 1) * Math.pow(10, Math.floor(Math.log10(value)));
        } else {
          const resultString = result.toString();
          result = parseInt(resultString.charAt(0) + secondDigit + resultString.substring(2));
        }
      }
      return result;
  }
}

formatChartData(category: string,data: any[]): {result: any[]; hozBarCss: any; hozBarOppCss: any; hozBarTitle: number;} {
  let result: any[];
  let maxValue: number;
  let hozBarCss: any;
  let hozBarOppCss: any;
  let hozBarTitle: number;
  let isHasNegative: boolean;
  switch (category) {
      case "BAPC_TY":
          maxValue = this.roundMaxValue(Math.abs(Math.max(...data.map((item) => item.BAPC_TY))),1000);

          isHasNegative = data.some(item => item.BAPC_TY < 0);
          result = data.map(item => {
              const percentage = isHasNegative ? (Math.abs(item.BAPC_TY) / (maxValue / 2) * 100) : (parseFloat(item.BAPC_TY) / maxValue * 100);
              const formattedNumber = this.kFormatter(item.BAPC_TY);
              const formattedValue = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(formattedNumber);
              const value = `${formattedValue}`;
              const height = Math.min(Math.abs(percentage), isHasNegative ? 195 : 99);
              const isPositive = item.BAPC_TY >= 0;
              return { value, height, isPositive };
          });

          hozBarCss = {
              top: "auto",
              bottom: `${50}%`,
          };
          hozBarOppCss = {
              top: "auto",
              bottom: `-${52}%`,
          };
          hozBarTitle = maxValue / 2;
          return { result, hozBarCss, hozBarOppCss, hozBarTitle };
      case "NSR_TY":
      case "GP_TY":
          maxValue = this.roundMaxValue(Math.abs(Math.max(...data.map((item) => Math.abs(item[category])))),1000000);
          isHasNegative = data.some(item => item[category] < 0);
          result = data.map(item => {
              const percentage = isHasNegative ? (Math.abs(item[category]) / (maxValue / 2) * 100) : (parseFloat(item[category]) / maxValue * 100);
              const formattedNumber = this.mFormatter(item[category]);
              const formattedValue = new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(Math.round(formattedNumber));
              const value = `${formattedValue}`;
              const height =  Math.min(Math.abs(percentage), isHasNegative ? 195 : 99);
              const isPositive = item[category] >= 0;
              return { value, height, isPositive };
          });

          hozBarCss = {
              top: "auto",
              bottom: `${50}%`,
          };
          hozBarOppCss = {
              top: "auto",
              bottom: `-${52}%`,
          };
          hozBarTitle = maxValue / 2;
          return { result, hozBarCss, hozBarOppCss, hozBarTitle };
      case "BAPC_VS_BP":
      case "BAPC_VS_LY":
      case "NSR_VS_BP":
      case "NSR_VS_LY":
      case "GP_VS_BP":
      case "GP_VS_LY":
          maxValue = 100;
          result = data.map((item) => {
          const percentage = (parseFloat(item[category]) / maxValue * 100);
          const height = Math.min(Math.abs(percentage), 195);
          return {
              value: isNaN(parseFloat(item[category])) ? '' : `${parseFloat(item[category]).toFixed(1)}`,
              height: height,
              isPositive: item[category] >= 0,
            };
          });

          hozBarCss = {
              top: "auto",
              bottom: `${50}%`,
          };
          hozBarOppCss = {
              top: "auto",
              bottom: `-${50}%`,
          };
          hozBarTitle = maxValue;
          return { result, hozBarCss, hozBarOppCss, hozBarTitle };
      default:
          result = data;
          return { result, hozBarCss, hozBarOppCss, hozBarTitle };
  }
}

  private _downloadCsv(fileUrl: any) {
    if (fileUrl && fileUrl.length > 0) {
      const link = this.downloadLink.nativeElement;
      link.href = fileUrl;
      link.download = '';
      link.click();
    }
  }
}
